<mat-toolbar class="mat-elevation-z8">
    <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened">
            menu
        </mat-icon>
        <mat-icon *ngIf="sidenav.opened">
            close
        </mat-icon>
    </button>
    Responsive side navigation
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8">

        <img class="avatar mat-elevation-z8" src="https://source.unsplash.com/c_GmwfHBDzk/200x200" />

        <h4 class="name">User Name</h4>

        <mat-divider></mat-divider>

        <button mat-button class="menu-button">
            <mat-icon>home</mat-icon>
            <span>Home</span>
        </button>
        <button mat-button class="menu-button">
            <mat-icon>person</mat-icon>
            <span>Influencers</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button">
            <mat-icon>card_giftcard</mat-icon>
            <span>Offre</span>
        </button>
        <button mat-button class="menu-button">
            <mat-icon>card_giftcard</mat-icon>
            <span>Campagne</span>
        </button>
        <mat-divider></mat-divider>

        <button mat-button class="menu-button">
            <mat-icon>assessment</mat-icon>
            <span>Company</span>
        </button>
        <button mat-button class="menu-button">
            <mat-icon>assessment</mat-icon>
            <span>Contact</span>
        </button>

    </mat-sidenav>
    <mat-sidenav-content>
        <div class="content mat-elevation-z8">
            Main content here!
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>