import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maj',
  templateUrl: './maj.component.html',
  styleUrls: ['./maj.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class MajComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
