import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contrat',
  templateUrl: './contrat.component.html',
  styleUrls: ['./contrat.component.css', '../../edito/edito.css']
})
export class ContratComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
