<html>
    <body >
        <div class="container page">
            <a *ngIf="modifNewElement" [routerLink]="['/detailOffer/', offer._id]" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
            <mat-card  class="mt-1">
                <mat-card-content>
                    <div class="containerBox">
                        <div class="box">
                            <div class="text-center">
                                <mat-label class="title">UPLOAD YOUR STATISTICS 🤓</mat-label>
                            </div>
                            <div class="boxtxt text-center">
                                <mat-label class="labelFont">Please upload your campaign statistics for each content separately (ex : Story 1, Story 2, Post 1, Réel 1...).</mat-label>
                            </div>
                        </div>
                    </div>
                        <form *ngIf="modifNewElement" class="align-items-center mt-3 container"  (keyup.enter)="keytab($event)" [formGroup]="newElement ">
                            <div [ngClass]="modifContent ? 'chartDesignActive' : 'chartDesign'">
                                <div class="col-12 col-lg-12 col-md-12 ">
                                    <div class="row">
                                        <div class="col-12 col-lg-12 col-md-6 mb-2">
                                            <div class="row input-row  " flex-center>
                                                <div class="col-md-12 col-lg-6 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-6   col-md-6 ">
                                                            <mat-label [ngClass]="createContent ? 'disabledLabel' : 'labelFont'"  class="labelFont">Content Type</mat-label>
                                                        </div>
                                                        <div class="col-lg-6   col-md-6 ">
                                                            <mat-form-field >
                                                                <mat-select [(ngModel)]="contentType" (ngModelChange)="addnewElement()" [ngModelOptions]="{standalone: true}">
                                                                    <mat-option [value]="'Post'">
                                                                        Post
                                                                    </mat-option>
                                                                    <!-- <mat-option *ngIf="plateFormType != 'Instagram'" [value]="'Video'">
                                                                        Video
                                                                    </mat-option> -->
                                                                    <mat-option *ngIf="plateFormType == 'Instagram'" [value]="'Reel'">
                                                                        Reel
                                                                    </mat-option>
                                                                    <mat-option *ngIf="plateFormType == 'Instagram'" [value]="'Story'">
                                                                        Story
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                        
                                        <div *ngIf="modifNewElement" class="col-12 col-lg-6 col-md-6">
                                            <div class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-6   col-md-6 ">
                                                            <mat-label [ngClass]="createContent ? 'disabledLabel' : 'labelFont'" class="labelFont">Impression</mat-label>
                                                            <i [ngClass]="createContent ? 'disabledLabel' : 'labelFont'" class="tooltipT fa fa-info ml-1">
                                                                <span>
                                                                    <img [src]="getimgTooltip('Impressions')">
                                                                </span>
                                                            </i>
                                                        </div>
                                                        <div class="col-lg-6   col-md-6 ">
                                                            <mat-form-field  flex-center>
                                                                <input [disabled]="this.createContent" formControlName="impressions" mask="separator.2" matInput required  >
                                                                <mat-icon matSuffix *ngIf="newElement.value['impressions'] && !newElement.get('impressions')?.errors" color="success">check</mat-icon>
                                                                <mat-icon matSuffix *ngIf="newElement.value['impressions'] && !newElement.get('impressions')?.valid" color="error">close</mat-icon>
                                                            
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-6   col-md-6 ">
                                                            <mat-label [ngClass]="createContent ? 'disabledLabel' : 'labelFont'" class="labelFont">Reach</mat-label>
                                                            <i [ngClass]="createContent ? 'disabledLabel' : 'labelFont'" class="tooltipT fa fa-info ml-1">
                                                                <span>
                                                                    <img [src]="getimgTooltip('Reach')">
                                                                </span>
                                                            </i>
                                                        </div>
                                                        <div class="col-lg-6   col-md-6">
                                                            <mat-form-field >
                                                                <input formControlName="reach"  mask="separator.2" matInput required  >
                                                                <mat-icon matSuffix *ngIf="newElement.value['reach'] && !newElement.get('reach')?.errors" color="success">check</mat-icon>
                                                                <mat-icon matSuffix *ngIf="newElement.value['reach'] && !newElement.get('reach')?.valid" color="error">close</mat-icon>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div *ngIf="contentType != 'Story'" class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-6   col-md-6 ">
                                                            <mat-label [ngClass]="createContent ? 'disabledLabel' : 'labelFont'" class="labelFont">Engagement</mat-label>
                                                            <i [ngClass]="createContent ? 'disabledLabel' : 'labelFont'" class="tooltipT fa fa-info ml-1">
                                                                <span>
                                                                    <img [src]="getimgTooltip('Engagement')">
                                                                </span>
                                                            </i>
                                                        </div>
                                                        <div class="col-lg-6   col-md-6 ">
                                                            <mat-form-field >
                                                                <input  matInput mask="separator.2" formControlName="engagement" required  >
                                                                <mat-icon matSuffix *ngIf="newElement.value['engagement'] && !newElement.get('engagement')?.errors" color="success">check</mat-icon>
                                                                <mat-icon matSuffix *ngIf="newElement.value['engagement'] && !newElement.get('engagement')?.valid" color="error">close</mat-icon>
        
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                        <div *ngIf="modifNewElement" class="col-12 col-sm-12 col-lg-6 col-md-6">
                                            <div class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div class="col-lg-6   col-md-6 ">
                                                            <mat-label [ngClass]="createContent ? 'disabledLabel' : 'labelFont'" class="labelFont">Upload Screenshot</mat-label>
                                                            <i [ngClass]="createContent ? 'disabledLabel' : 'labelFont'" class="tooltipT fa fa-info ml-1">
                                                                <span>
                                                                    <img mat-card-image class="img-responsive imgFeed"    [defaultImage]="'assets/images/tooltip/uploadPicture.jpg'" [errorImage]="'assets/images/tooltip/uploadPicture.jpg'"  [lazyLoad]="'assets/images/tooltip/uploadPicture.jpg'">
                                                                </span>
                                                            </i>
                                                        </div>
                                                        <div class="col-lg-6   col-md-6 ">
                                                            <mat-form-field>
                                                                <ngx-mat-file-input [(ngModel)]="imgName" [ngModelOptions]="{standalone: true}" (change)="addPicture($event, newElement, 'newElement')"  ></ngx-mat-file-input>
                                                                <mat-icon matSuffix>attach_file</mat-icon>
                                                            </mat-form-field>
                                                            
                                                        </div>
                                                        <div  class="col-lg-12 col-md-12 display-mid" flex-center >
                                                            <div class="imgrow">
                                                                <div  *ngIf="newElement.value['pictures']">
                                                                    <div class="img_wrapper_multi">
                                                                        <span (click)="deleteImg(newElement.value['pictures'],newElement)" class="close">&times;</span>
                                                                        <img  [src]="fctBasic.checkUrlPath(newElement.value['pictures'])"    class="img-responsive center-block imgFeedMulti">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div> 
                                                
                                            </div>
                                            
                                        </div>
                                        <div class="container mt-2 mb-3">
                                            <div class="row">
                                                <div flex-center class="col-md-12 col-lg-12 text-center">
                                                <button  (click)="addNewContent()"class="btn  btn-secondary shadow-none" >Send</button>
                                            </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="containerBox  mt-3" *ngIf="ContenueArray.length > 0">
                        </div>
                        <form class="align-items-center container mt-3" *ngFor = "let x of ContenueArray">
                            <div class="mt-3 mb-2" [ngClass]="x.isDisabled  ? 'chartDesign':'chartDesignModif' ">
                                <div [ngClass]="!x.isDisabled ? 'disabledLabel' : 'labelFont'" class="profileIf">
                                    {{x.instagramInfoPost.type}}
                                </div>
                                <div class="col-12 col-lg-12 col-md-12 ">
                                    <div class="row">
                                        
                                        <div *ngIf="modifNewElement" class="col-12 col-lg-6 col-md-6">
                                            <div class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-6   col-md-6 ">
                                                            <mat-label [ngClass]="!x.isDisabled ? 'disabledLabel' : 'labelFont'">Impression</mat-label>
                                                            <i [ngClass]="!x.isDisabled ? 'disabledLabel' : 'labelFont'" class="tooltipT fa fa-info ml-1">
                                                                <span>
                                                                    <img [src]="getimgTooltip('Impressions')">
                                                                </span>
                                                            </i>
                                                        </div>
                                                        <div class="col-lg-6   col-md-6 ">
                                                            <mat-form-field  flex-center>
                                                                <input [disabled]="x.isDisabled ? false : true" class="disabledtextSaisie" [(ngModel)]="x.totalStatistics.totalView"  [ngModelOptions]="{standalone: true}" mask="separator 2" matInput required  >
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-6   col-md-6 ">
                                                            <mat-label [ngClass]="!x.isDisabled ? 'disabledLabel' : 'labelFont'">Reach</mat-label>
                                                            <i [ngClass]="!x.isDisabled ? 'disabledLabel' : 'labelFont'" class="tooltipT fa fa-info ml-1">
                                                                <span>
                                                                    <img [src]="getimgTooltip('Reach')">
                                                                </span>
                                                            </i>
                                                        </div>
                                                        <div class="col-lg-6   col-md-6">
                                                            <mat-form-field >
                                                                <input [disabled]="x.isDisabled ? false : true" class="disabledtextSaisie" [(ngModel)]="x.totalStatistics.totalReach" [ngModelOptions]="{standalone: true}" mask="separator 2" matInput required  >
                                                                
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div *ngIf="x.instagramInfoPost.type != 'Story'" class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-6   col-md-6 ">
                                                            <mat-label [ngClass]="!x.isDisabled ? 'disabledLabel' : 'labelFont'">Engagement</mat-label>
                                                            <i [ngClass]="!x.isDisabled ? 'disabledLabel' : 'labelFont'" class="tooltipT fa fa-info ml-1">
                                                                <span>
                                                                    <img [src]="getimgTooltip('Engagement')">
                                                                </span>
                                                            </i>
                                                        </div>
                                                        <div class="col-lg-6   col-md-6 ">
                                                            <mat-form-field >
                                                                <input [disabled]="x.isDisabled ? false : true" class="disabledtextSaisie" matInput mask="separator 2" [(ngModel)]="x.totalStatistics.totalEngagement" [ngModelOptions]="{standalone: true}" required  >
                                                                
        
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                        <div *ngIf="modifNewElement" class="col-12 col-sm-12 col-lg-6 col-md-6">
                                            <div class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div class="col-lg-6   col-md-6 ">
                                                            <mat-label [ngClass]="!x.isDisabled ? 'disabledLabel' : 'labelFont'">Upload Screenshot</mat-label>
                                                            <i [ngClass]="!x.isDisabled ? 'disabledLabel' : 'labelFont'" class="tooltipT fa fa-info ml-1">
                                                                <span>
                                                                    <img mat-card-image class="img-responsive imgFeed"    [defaultImage]="'assets/images/tooltip/uploadPicture.jpg'" [errorImage]="'assets/images/tooltip/uploadPicture.jpg'"  [lazyLoad]="'assets/images/tooltip/uploadPicture.jpg'">
                                                                </span>
                                                            </i>
                                                        </div>
                                                        <div class="col-lg-6   col-md-6 ">
                                                            <mat-form-field>
                                                                <ngx-mat-file-input (change)="modifPicture($event, x, 'newElement')"></ngx-mat-file-input>
                                                                <mat-icon matSuffix>attach_file</mat-icon>
                                                            </mat-form-field>
                                                            
                                                        </div>
                                                        <div  class="col-lg-12 col-md-12 display-mid" flex-center *ngIf="x.instagramInfoPost.picture">
                                                            <div class="imgrow">
                                                                <div  >
                                                                    <div class="img_wrapper_multi">
                                                                        <span (click)="deleteImgForModif(x.instagramInfoPost.picture, 0, x)" class="close">&times;</span>
                                                                        <img  [src]="fctBasic.checkUrlPath(x.instagramInfoPost.picture)"    class="img-responsive center-block imgFeedMulti">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div> 
                                                
                                            </div>
                                            
                                        </div>
                                        <div class="container mt-3 mb-3">
                                            <div class="row">
                                                <div class="col-md-12 col-lg-12 " *ngIf="!x.isDisabled">
                                                    <div   class="float-right" style="margin-right: auto;" ><i (click)="contentModifyVal(x)">Modify </i><i (click)="delContent(x)" class="fa fa-trash ml-3"></i></div>
                                                </div>
                                                <div flex-center *ngIf="x.isDisabled" class="col-md-12 col-lg-12 text-center">
                                                    <button  (click)="saveModifyContent(x)" class="btn  btn-secondary shadow-none"  >Save</button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        
                        
                        <div class="containerBox mt-4" *ngIf="ContenueArray.length > 0">
                            <div class="box">
                                <div class="boxtxt text-center">
                                    <mat-label > <i>Thank you very much for your great work on this campaign !</i> </mat-label>
                                    
                                </div>
                                <div class="boxtxt">
                                    <mat-label class=""> <i>For any question regarding campaign management, feel free to contact : cm@influencelab.co  & for invoice and payments : gestion@influencelab.co</i> </mat-label>
                                </div>
                            </div>
                        </div>
                        <div class="container mt-3 mb-3" *ngIf="ContenueArray.length > 0">
                            <div class="row">
                                <div flex-center class="col-md-12 col-lg-12 text-center">
                                    <button (click)="succesPost()" class="btn  btn-secondary shadow-none" >Save !</button>
                                </div>
                            </div>
                        </div>
                        
                </mat-card-content>
            </mat-card>
        </div>
    </body>
</html>