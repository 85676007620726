    <!-- Navigation -->

    <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <div class="container-fluid">
          <a routerLink="/" class="navbar-brand"><img class="img-fluid mb-3" style="max-width: 200px; max-height: 61px; width: auto; padding-top: 5px;" src="../../../assets/images/logo/InfluenceLablogoBlack.png"></a>
          <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" href="#About">ABOUT US</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" routerLink="/brand">BRAND</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger"  routerLink="/influencer">INFLUENCER</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" href="#Contact">CONTACT</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" routerLink="/login">LOGIN</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" routerLink="/signup">SIGNUP</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  
