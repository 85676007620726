import { Component, OnDestroy, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './service/sidenav.service';
import { AuthGuardService } from './service/auth-guard.service';
import {UserLoginService} from 'src/app/service/user-login.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', '../assets/styles/body.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent {
  name = 'Influence Lab';
  subscription: Subscription;
  @ViewChild('sidenav') 
  public create_sidenav: MatSidenav;
  boolInfluencer = false
  boolAdmin = false
  boolContact = false
  constructor(
    private router: Router, 
    private observer: BreakpointObserver,
    private sidenav: SidenavService,
    private auth: AuthGuardService,
    private cdRef:ChangeDetectorRef,
    private authService : UserLoginService,
    ){

      
    this.subscription = this.router.events.subscribe((event) => {
      this.sidenav.close()

      const path = this.authService.getUserType();
      const urlOk = this.auth.userIsAllowed(this.router.url)
     
      if (path != "" && event && urlOk == true){
        switch (path) {
          case "influencer":
              this.sidenav.open()
              this.boolInfluencer = true
              this.boolAdmin = false
              this.cdRef.detectChanges();
            break;
          case "admin":
              this.boolAdmin = true
              this.boolInfluencer = false
              this.sidenav.open()
              this.cdRef.detectChanges();
            break;

          case "contact":
            this.boolContact = true
            this.boolInfluencer = false
            this.boolAdmin = false
            this.sidenav.open()
            this.cdRef.detectChanges();
            break;
          default:
            console.log("this.router.url ", this.router.url)
            break;
        }
      } else if (urlOk == true && this.router.url == "/home"){
        this.sidenav.open()
        this.boolInfluencer = true
        this.cdRef.detectChanges();
      }
    });
    
  }
  ngAfterViewInit() {
    //recupérer l'user, si pas user =>  this.sidenav.close();
    this.sidenav.setSidenav(this.create_sidenav);
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (true) {
        this.create_sidenav.close();
      }
      let check = this.authService.getUserType();
      if (res.matches) {
        this.create_sidenav.mode = 'over';
        this.cdRef.detectChanges();
        this.sidenav.close();
      } else  {
        if (check  && (this.boolAdmin || this.boolInfluencer)){
          this.create_sidenav.mode = 'side';
          this.cdRef.detectChanges();
          this.sidenav.open();
        } else {
          this.create_sidenav.mode = 'side';
          this.cdRef.detectChanges();
        }
        
      }
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  logoutAdmin(){
    this.authService.logout()
    this.router.navigate(['/login']);
  }


}