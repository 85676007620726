import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class MediaInfluenceurService {

  constructor(
    public ref: ChangeDetectorRef ,
  ) { }

  checkUrlPath(elem : string){
    if (elem.includes('/api/picture/Images/') == true){
      return elem
    } else {
      return '/api/picture/Images/' + elem
    }
  }


  getExtentionImg(file : any){
    switch (file.type) {
      case "image/png":
        return true      
      case "image/jpeg":
        return true      
      default:
        return false
    }
  }
}
