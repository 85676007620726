import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {userModel} from 'src/app/model/user-model.model'
import {OfferModel} from 'src/app/model/offer-model.model'
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { pigePicJson } from '../pige/pige-admin/pige-admin.component';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  dataSourceOffer : OfferModel[]
  constructor(
    private httpClient: HttpClient,
   
  ) { }
  /////////////////////////////LOGIN/////////////////////////////////////

  public getFacebookUrl(jsonInfluencer : any): Observable<any> {
    return this.httpClient.get<any>('/api/facebook/without', jsonInfluencer).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error)
      }))
  }

  public postLogin(jsonInfluencer : any): Observable<any> {
    return this.httpClient.post<any>('/api/loginadmin/', jsonInfluencer).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error)
      }))
  }

  public Login(jsonInfluencer : any): Observable<any> {
    return this.httpClient.post<any>('/api/login/', jsonInfluencer).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error)
      }))
  }

  public LoginWithurl(jsonInfluencer : any): Observable<any> {
    return this.httpClient.post<any>('/api/loginWithUrl/', jsonInfluencer).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error)
      }))
  }

  public singupInf(jsonInfluencer : any): Observable<any> {
    return this.httpClient.post<any>('/api/signup/', jsonInfluencer).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error)
      }))
  }
  public sendlinkforgot(email :string): Observable<any> {
    return this.httpClient.get('/api/email/forget/' + email).pipe(
      catchError(error => {
        console.log('sendlinkforgot error -> ', error)
        return (error)
      }))
}

public getLink(link :string): Observable<any> {
  return this.httpClient.get('/api/reset-pass/' + link).pipe(
    catchError(error => {
      console.log('sendlinkforgot error -> ', error)
      return (error)
    }))
}

public changePassword(json:any): Observable<any> {
  return this.httpClient.post('/api/reset/', json).pipe(
    catchError(error => {
      console.log('sendlinkforgot error -> ', error)
      return (error)
    }))
}

  public getUser(): Observable<any> {
    return this.httpClient.get('/api/auth/me').pipe(
    map(this.extractData),
    catchError(this.handleErrorObservable)
  )
}

private extractData(res: any) {
  let body = res;
  return body;
} 

public handleErrorObservable(error: any) {
  console.error(error.message || error);
  return throwError(error);
}

public putUser(id: string, user : any): Observable<any> {
  return this.httpClient.put<any>('/api/influencer/' + id, user);
}

public putUserInfluenceurInfo(id: string, info : any): Observable<any> {
  return this.httpClient.put<any>('/api/influencer/influenceurInfo/' + id, info);
}

public deleteUser(id: string): Observable<any> {
  return this.httpClient.delete<any>('/api/influencer/User/' + id);
}

public logout(): Observable<any> {
  return this.httpClient.get<any>('/api/logout/');
}
  //////////////////////////////API//////////////////////////////////////////////
  public getYoutubeApi(): Observable<any> {
    return this.httpClient.get<any>('/api/youtubeAPI/getNewYoutubeToken/');
  }

  public getFacebookTokentoAccount(): Observable<any>{
    return this.httpClient.get<any>('/api/facebookAPI/getNewFacebookToken/');
  }

  public getYoutubeToAccount(): Observable<any>{
    return this.httpClient.get<any>('/api/youtubeAPI/getNewYoutubeTokenForManage/');
  }

  public createYoutubeContenus(array : any): Observable<any>{
    return this.httpClient.post<any>('/api/export/youtubecreatePost/', array);
  }
  public createFbContenus(array : any): Observable<any>{
    return this.httpClient.post<any>('/api/export/facebookcreatePost/', array);
  }
  /////////////////////////////CAMPAGES/////////////////////////////////////
  public getCampaigns(): Observable<any> {
    return this.httpClient.get<any>('api/campaign/');
  }

  public getCampaignsForContact(campagne : any): Observable<any> {
    return this.httpClient.post<any>('api/campaign/contactcampaigns/', campagne).pipe(
      catchError(error => {
        console.log('Caught in getCampaignsForContact. Returning 0', error)
        return (error.status)
      }))
  }

  public getCampaignsPaginate(page: number, limit: number): Observable<any> {
    return this.httpClient.get<any>('api/campaign/paginate/?page=' + page + "&limit=" + limit);
  }
  public getCampaignsPaginateFilter(search : string, limit: number): Observable<any> {
    return this.httpClient.get<any>('api/campaign/findElement/?search=' + search + "&limit=" + limit);
  }
  public getCampaignsHome(): Observable<any> {
    return this.httpClient.get<any>('api/campaign/home/').pipe(
      catchError(error => {
        console.log('CatchError getChangeStatusCampaign', error)
        return (error.status)
      }))
  }

  public getCampaign(id: number): Observable<any> {
    return this.httpClient.get<any>('/api/campaign/' + id);
  }

  public putCampaign(id: string, campagne : any): Observable<any> {
    return this.httpClient.put<any>('/api/campaign/' + id, campagne);
  }

  public getChangeStatusCampaign(option: string, id: string): Observable<any> {
    return this.httpClient.get<any>('/api/campaign/changeStatus/' + option + '/' + id).pipe(
      catchError(error => {
        console.log('CatchError getChangeStatusCampaign', error)
        return (error.status)
      }))
  }

  public getUserOnCampaignSelector(status: any): Observable<any> {
    return this.httpClient.get<any>('/api/campaign/campaignSelector/' + status).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }

  public postCampaign(campagne: any): Observable<any> {
    return this.httpClient.post<any>('api/campaign/', campagne).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }
  /////////////////////////////OFFER/////////////////////////////////////
  public getOffre(id: number): Observable<any> {
    return this.httpClient.get<any>('/api/offer/' + id);
  }
  public getOffresPaginate(page: number, limit: number): Observable<any> {
    return this.httpClient.get<any>('api/offer/paginate/?page=' + page + "&limit=" + limit);
  }

  public getOffresPaginateFilter(search : string, limit: number): Observable<any> {
    return this.httpClient.get<any>('api/offer/findElement/?search=' + search + "&limit=" + limit);
  }
  public getOffresHome(): Observable<any> {
    return this.httpClient.get<any>('api/offer/home/');
  }
  public getOffreInfluencer(jsonInfluencer : any): Observable<any> {
    return this.httpClient.post<any>('/api/offer/InfluencerId', jsonInfluencer);
  }
  public getOffres() {
    return this.httpClient.get<any>('/api/offer/');
  }
  public putOffre(id: string, offer : any): Observable<any> {
    return this.httpClient.put<any>('/api/offer/' + id, offer);
  }
  public postOffer(offer: any): Observable<any> {
    return this.httpClient.post<any>('api/offer/', offer).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }
  public getChangeStatusOffer(option: any, id: string): Observable<any> {
    return this.httpClient.get<any>('/api/offer/changeStatus/' + option + '/' + id).pipe(
      catchError(error => {
        console.log('CatchError getChangeStatusCampaign', error)
        return (error.status)
      }))
  }
  public getIgContenue(id: number): Observable<any> {
    return this.httpClient.get<any>('/api/offer/getContenuIg/' + id);
  }

  public getYtContenue(id: number): Observable<any> {
    return this.httpClient.get<any>('/api/offer/getContenuYt/' + id);
  }

  public getFbContenue(id: number): Observable<any> {
    return this.httpClient.get<any>('/api/offer/getContenuFb/' + id);
  }
  /////////////////////////////INFLUENCEUR/////////////////////////////////////
  public getInfluencer(id: number): Observable<any> {
    return this.httpClient.get<any>('/api/influencer/' + id);
  }

  public getInfluencerNetworkSplitWithmetrics(id: number): Observable<any> {
    return this.httpClient.get<any>('/api/influencer/networkSplit/' + id);
  }

  public getInfluencers(): Observable<any> {
    return this.httpClient.get<any>('/api/influencer/');
  }
  public getInfluencersPaginate(page: number, limit: number): Observable<any> {
    return this.httpClient.get<any>('api/influencer/paginate/?page=' + page + "&limit=" + limit);
  }
  public getInfluencersHome(): Observable<any> {
    return this.httpClient.get<any>('api/influencer/home/');
  }

  public getInfluencersPaginateFilter(search : string, limit: number): Observable<any> {
    return this.httpClient.get<any>('api/influencer/findElement/?search=' + search + "&limit=" + limit);
  }
  /////////////////////////////COMPANY/////////////////////////////////////
  public getCompany(id: any) {
    return this.httpClient.get<any>('/api/companyAccount/' + id);
  }

  public getCompanies() {
    return this.httpClient.get<any>('/api/companyAccount/');
  }

  public postMandatCompany(company : any) {
    return this.httpClient.post<any>('/api/companyAccount/mandat', company);
  }

  public putCompany(id : any, company : any) {
    return this.httpClient.put<any>('/api/companyAccount/' + id, company);
  }

  public getCompanyPaginateFilter(search : string, limit: number): Observable<any> {
    return this.httpClient.get<any>('api/companyAccount/findElement/?search=' + search + "&limit=" + limit);
  }

  public createCompany(company : any) {
    return this.httpClient.post<any>('/api/companyAccount/', company).pipe(
      catchError(error => {
        console.log('Caught in createCompany. Returning 0', error)
        return (error.status)
      }))
  }
  /////////////////////////////CONTACT/////////////////////////////////////
  public getContact(id : any) {
    return this.httpClient.get<any[]>('/api/companyContact/' + id);
  }

  public getContacts() {
    return this.httpClient.get<any>('/api/companyContact/');
  }

  public getContactsForAfilliedOnCampagne(id: string) {
    return this.httpClient.get<any>('/api/campaign/contactOnCampagne/' + id);
  }
  
  public getContactsPaginateFilter(search : string, limit: number): Observable<any> {
    return this.httpClient.get<any>('api/companyContact/findElement/?search=' + search + "&limit=" + limit);
  }

  public getContactsUsers() {
    return this.httpClient.get<any>('/api/influencer/userContactAll/');
  }

  public getContactsUser(id: string) {
    return this.httpClient.get<any>('/api/influencer/userContact/' + id);
  }
  public getContactsUserForCheck(id: string) {
    return this.httpClient.get<any>('/api/influencer/checkuserContact/' + id);
  }
  public putContact(id : any, contact : any) {
    return this.httpClient.put<any>('/api/companyContact/' + id, contact);
  }

  public putUserTypeContact(id : any, contact : any, iduser : string) {
    return this.httpClient.put<any>('/api/companyContact/' + id + '/usercontact/' + iduser, contact);
  }


  public createContact(contact : any) {
    return this.httpClient.post<any>('/api/companyContact/', contact).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }

  public createContactWithUser(contact : any, id: any) {
    return this.httpClient.post<any>('/api/companyContact/withcompany/' + id, contact).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }

  public createUserContact(contact : any): Observable<any> {
    return this.httpClient.post<any>('/api/companyContact/contactSignup/', contact).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }
  /////////////////////////////IMAGE/////////////////////////////////////
  
  public getPicture(filename: any): Observable<any> {
    return this.httpClient.get<any>('api/picture/getPicture/' + filename).pipe(
      catchError(error => {
        console.log('postPicture  status ->', error)
        return throwError(error);
      }))
  }
  
  public postPicture(fd: FormData): Observable<any> {
    return this.httpClient.post<any>('api/picture/', fd).pipe(
      catchError(error => {
        return throwError(error);
      }))
  }

  public postFile(fd: FormData): Observable<any> {
    return this.httpClient.post<any>('api/file/', fd).pipe(
      catchError(error => {
        return (error)
      }))
  }

  public deleteFile(pathFile: string): Observable<any> {
    return this.httpClient.delete<string>('api/file/' + pathFile).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }

  public deletePictureExpressPige(pathFile: string): Observable<any> {
    return this.httpClient.delete<string>(pathFile).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }

  public deletePicture(pathFile: string): Observable<any> {
    return this.httpClient.delete<string>('api/picture/' + pathFile).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }
  /////////////////////////////EXPORT/////////////////////////////////////
  public exportFile(id: number, start: any, end: any): Observable<any> {
    return this.httpClient.get<any>("/api/export/jsonFile/" + id + "/" + start + "/" + end);
  }

  public exportFilePigeExtern(str : string, id: string, start: any, end: any): Observable<any> {
    return this.httpClient.get<any>("/api/export/pigeExtern/" + str + "/" + id + "/" + start + "/" + end)
  }

  public exportFilePigeExternExpress(picPige : pigePicJson): Observable<any> {
    return this.httpClient.post<any>("/api/export/pigeExternExpress/", picPige)
  }

  public exportFileYoutube(str : string, id: number, start: any, end: any): Observable<any> {
    return this.httpClient.get<any>("/api/export/jsonFileYoutube/" + str + "/" + id + "/" + start + "/" + end);
  }


  /////////////////////////////Message/////////////////////////////////////

  public getallMessage(id: string): Observable<any> {
    return this.httpClient.get<any>("/api/message/" + id);
  }

  public getallUserForMessage(): Observable<any> {
    return this.httpClient.get<any>("/api/influencer/userAll");
  }

  public sendMessage(message : any): Observable<any> {
    return this.httpClient.post<any>("/api/message", message).pipe(
      catchError(error => {
        console.log('Caught in message. Returning 0', error)
        return (error.status)
      }))
  }

  public modifMessage(message: string, id: string): Observable<any> {
    return this.httpClient.put<any>("/api/message/" + id, message);
  }


  public messageAdminOffer(str : string, id: number, start: any, end: any): Observable<any> {
    return this.httpClient.get<any>("/api/export/jsonFile/" + str + "/" + id + "/" + start + "/" + end);
  }

  public messageInfluenceurOffer(str : string, id: number, start: any, end: any): Observable<any> {
    return this.httpClient.get<any>("/api/export/jsonFile/" + str + "/" + id + "/" + start + "/" + end);
  }

  /////////////////////////////DASHBOARD/////////////////////////////////////



  public getDashboardCampagne(id: number): Observable<any> {
    return this.httpClient.get<any>('/api/dashboard/campagneAngular/' + id);
  }
  public dowloadAndCreateDashboard(jsonDash: any): Observable<any> {
    return this.httpClient.post<any>('/api/export/dowloadInstaImg/', jsonDash).pipe(
      catchError(error => {
        console.log('Caught in CatchError. Returning 0', error)
        return (error.status)
      }))
  }

  public getDashboardOffer(id: number): Observable<any> {
    return this.httpClient.get<any>('/api/dashboard/offerAngular/' + id);
  }
 
  ////////////////////////////////MAIL/////////////////////////////////////////
  public inscriptionEmail(mail: string): Observable<any> {
    return this.httpClient.get<any>('/api/email/inscriptionSend/' + mail)
  }

  public inscriptionValidateEmail(mail: string): Observable<any> {
    return this.httpClient.get<any>('/api/email/inscriptionSend/' + mail)
  }

  public inscriptionRefused(mail: string): Observable<any> {
    return this.httpClient.get<any>('/api/email/inscriptionSend/' + mail)
  }

  public sendOfferEmail(mail: string): Observable<any> {
    return this.httpClient.get<any>('/api/email/sendOffer/' + mail)
  }

  public sendOfferInReviewEmail(mail: string): Observable<any> {
    return this.httpClient.get<any>('/api/email/inscriptionSend/' + mail)
  }

  public offerValidationEmail(mail: string, start: Date, end: Date): Observable<any> {
    return this.httpClient.get<any>('/api/email/inscriptionSend/' + mail)
  }

  public inscriptionUserContactEmail(mail: string, password : string): Observable<any> {
    return this.httpClient.get<any>('/api/email/inscriptionSend/' + mail)
  }

  public userSendHerInvoice(userName: string, campagneName: string): Observable<any> {
    return this.httpClient.get<any>('/api/email/UserSendinvoice/' + userName + "/" + campagneName)
  }

}
