<html>
  <body>
      <div *ngIf="loadOffer"  class="page container-fluid  ">
        <div class="row mb-3">
            <input type="text" (change)="findOffer()" [(ngModel)]="tmp" style="font-family: Open Sans,Helvetica,Arial,sans-serif,FontAwesome;" class="form-control text-input mb-1 col-6 col-xl-3 col-lg-3 " name=""  placeholder="&#xf002; Search offers... ">
        </div>
        <div class="row">
          <div class="tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1" >
            <table  class="table table-hover  tableTitle  text-center">
              <thead>
                  <tr class="tableTitle ">
                    <th [appSort]="dataSourceOffer" data-order="desc" data-name="status" class=" text-center">Status</th>
                    <th [appSort]="dataSourceOffer" data-order="desc" data-name="creationDate" class="text-center">Date</th>
                    <th [appSort]="dataSourceOffer" data-order="desc" data-name="offer.influencerObjects.name"class="center-text">Influencer</th>
                    <th [appSort]="dataSourceOffer" data-order="desc" data-name="campaignName"class="col-1 col-md-1 col-lg-1 text-center">Name</th>
                    <th [appSort]="dataSourceOffer" data-order="desc" data-name="platformeType"class=" text-center">Platform  </th>
                    <th  [appSort]="dataSourceOffer" data-order="desc" data-name="mission"class=" text-center">Mission  </th>
                    <th [appSort]="dataSourceOffer" data-order="desc" data-name="currentDelivery"class=" text-center">Current Delivery  </th>
                    <th [appSort]="dataSourceOffer" data-order="desc" data-name="deliveryTarget"class=" text-center">Delivery Target  </th>
                    <th [appSort]="dataSourceOffer" data-order="desc" data-name="currentRevenue"class=" text-center">Current Revenue  </th>
                    <th  [appSort]="dataSourceOffer" data-order="desc" data-name="revenueTarget"class=" text-center">Revenue Target  </th>
                    <th class="col-lg-1 text-cente">Action</th>
                  </tr>
                </thead>
              <tbody class="bodyTable">
                <tr *ngFor = "let offer of dataSourceOffer  | paginate:{itemsPerPage: count, currentPage: p, totalItems: total } ">
                  <td class=" text-center">
                    <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(offer.status)}" > {{fctBasic.getNameByStatus(offer.status)}}</label>
                  </td>
                  <td class=" text-center">
                    {{offer.startDate | date:'yyyy/MM/dd'}} - {{offer.endDate | date:'yyyy/MM/dd'}}
                  </td>
                  <td class=" text-center">
                    <div *ngIf="offer.influencerObjects && offer.influencerObjects.name; else elseBlock">
                      {{offer.influencerObjects.name}}
                    </div>
                    <ng-template #elseBlock>
                      N/A
                    </ng-template>
                  </td>
                  <td class=" text-center">
                    {{offer.campaignName}}
                  </td>
                  <td class=" text-center">
                    <button  (click)="showProfileInf(offer)" type="button" [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(offer.platformeType)}" style="color:white" class="btn btn-sm btn-block btnTable shadow-none rounded-pill">
                      <i  *ngIf="offer.platformeType == 'Facebook'" class="fa mr-2 fa-facebook "></i>
                      <i *ngIf="offer.platformeType == 'Instagram'" class="fa mr-2 fa-instagram "></i>
                      <i *ngIf="offer.platformeType == 'Youtube'" class="fa mr-2 fa-youtube "></i>
                      <i *ngIf="offer.platformeType == 'Blog'" class="fa mr-2 fa-bold "></i>
                      <i *ngIf="offer.platformeType == 'multiNetwork'" class="fa mr-2 fa-globe "></i>
                      {{fctBasic.getNetworkNameForInfluenceur(offer.influencerObjects, offer.platformeType) | slice:0:20}}</button>
                  </td>
                  <td class=" text-center">
                    {{offer.mission}}
                  </td>
                  <td class="  text-center">
                    {{offer.currentRevenue | mask:"separator.2"}}
                  </td>
                  <td class=" text-center">
                    {{offer.deliveryTarget | mask:"separator.2"}}
                  </td>
                  <td class=" text-center">
                    {{ offer.currentDelivery | mask:"separator.2"}} {{fctBasic.getsaleModels(offer.saleModels) }}€
                  </td>
                  <td class=" text-center">
                    {{ offer.revenueTarget | mask:"separator.2"}} {{fctBasic.getsaleModels(offer.saleModels) }}€
                  </td>
                  <td class=" text-center">
                    <button (click)="openOfferDetailInNewWindow(offer._id)" type="button" style="font-size: smaller;" class="btn btn-secondary shadow-none"><span class="fa fa-search"></span></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <pagination-controls  (pageChange)="paginateOffer($event)" previousLabel = "" nextLabel = ""></pagination-controls>
            </div>
        </div>
      </div>
    </div>
  </body>
</html>





