import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrandComponent } from './edito/brand/brand.component';
import { InfluencerComponent } from './edito/influencer/influencer.component';
import { AccueilComponent } from './edito/accueil/accueil.component';
import { SignupComponent } from './Inscription-Connexion/signup/signup.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { CguComponent } from './legal/cgu/cgu.component';
import { ContratComponent } from './legal/contrat/contrat.component';
import { CookiesComponent } from './legal/cookies/cookies.component';
import { LegalComponent } from './legal/legal/legal.component';
import { MentionsComponent } from './legal/mentions/mentions.component';
import { HomeAdminComponent } from './home/home-admin/home-admin.component';
import { HomeInfluenceurComponent } from './home/home-influenceur/home-influenceur.component';
import { SigninAdminComponent } from './Inscription-Connexion/signin-admin/signin-admin.component';
import { ErrorComponent } from './status/error/error.component';
import { SuccessRegisterComponent } from './status/success-register/success-register.component';
import { AuthGuardService as AuthGuard } from './service/auth-guard.service';
import { ListOfferComponent } from './offer/list-offer/list-offer.component';
import { DashboardCampagneComponent } from './campagne/dashboard-campagne/dashboard-campagne.component';
import { CampagneSelectorComponent } from './campagne/campagne-selector/campagne-selector.component';
import { CreateCampagneComponent } from './campagne/create-campagne/create-campagne.component';
import { ListCampagneComponent } from './campagne/listCampagne/listCampagne.component';
import { DetailCampagneComponent } from './campagne/detail-campagne/detail-campagne.component';
import { ListComponent } from './influencer/list/list.component';
import { DetailInfluenceurAdminComponent } from './influencer/detail-influenceur-admin/detail-influenceur-admin.component';
import { OfferDetailAdminComponent } from './offer/offer-detail-admin/offer-detail-admin.component';
import { OfferDetailInfluenceurComponent } from './offer/offer-detail-influenceur/offer-detail-influenceur.component';
import { ListContactComponent } from './contact/list-contact/list-contact.component';
import { CreateContactComponent } from './contact/create-contact/create-contact.component';
import { ModifContactComponent } from './contact/modif-contact/modif-contact.component';
import { ModifCompanyComponent } from './company/modif-company/modif-company.component';
import { CreateCompanyComponent } from './company/create-company/create-company.component';
import { ListCompanyComponent } from './company/list-company/list-company.component';
import { ListOfferInfluenceurComponent } from './offer/list-offer-influenceur/list-offer-influenceur.component';
import { DetailProfilComponent } from './influencer/detail-profil/detail-profil.component';
import { InfluenceurReelsFormComponent } from './offer/influenceur-reels-form/influenceur-reels-form.component';
import { InfluenceurBillingComponent } from './influencer/influenceur-billing/influenceur-billing.component';
import { MajComponent } from './status/maj/maj.component';
import { LoginInfluenceurComponent } from './Inscription-Connexion/login-influenceur/login-influenceur.component';
import { InscriptionComponent } from './Inscription-Connexion/inscription/inscription.component';
import { ForgotPasswordComponent } from './Inscription-Connexion/forgot-password/forgot-password.component';
import { HomeContactComponent } from './user-contact/home-contact/home-contact.component';
import { ContactDetailedCampagneComponent } from './user-contact/contact-detailed-campagne/contact-detailed-campagne.component';
import { DashboardInfluenceurComponent } from './offer/dashboard-influenceur/dashboard-influenceur.component';
import { DashboardAdminYoutubeComponent } from './offer/dashboard-admin-youtube/dashboard-admin-youtube.component';
import { DashboardAdminComponent } from './offer/dashboard-admin/dashboard-admin.component'
import { InvoiceAdminComponent } from './offer/invoice-admin/invoice-admin.component';
import { InsightsProfileComponent } from './influencer/insights-profile/insights-profile.component';
import { OfferDataInputAdminComponent } from './offer/offer-data-input-admin/offer-data-input-admin.component';
import { DashboardYoutubeComponent } from './campagne/dashboard-youtube/dashboard-youtube.component';
import { PigeAdminComponent } from './pige/pige-admin/pige-admin.component';

const routes: Routes = [
  { path: 'selectorCampaign/:id', component: CampagneSelectorComponent, canActivate: [AuthGuard], data: {role: 'admin'} },
  { path: 'newCampaign', component: CreateCampagneComponent, canActivate: [AuthGuard], data: {role: 'admin'} },
  { path: 'listCampaign', component: ListCampagneComponent, canActivate: [AuthGuard], data: {role: 'admin'} },
  { path: 'detailCampaign/:id', component: DetailCampagneComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'campaignDash/:id', component: DashboardCampagneComponent, canActivate: [AuthGuard], data: {role: 'admin'} },
  { path: 'campaignDashYoutube/:id', component: DashboardYoutubeComponent, canActivate: [AuthGuard], data: {role: 'admin'} },
  { path: 'listOffers', component: ListOfferComponent, canActivate: [AuthGuard], data: {role: 'admin'} },
  { path: 'myOffers', component: ListOfferInfluenceurComponent, canActivate: [AuthGuard], data: {role: 'influencer'} },
  { path: 'detailOfferAdmin/:id', component: OfferDetailAdminComponent, canActivate: [AuthGuard], data: {role: 'admin'} },
  { path: 'detailOffer/:id', component: OfferDetailInfluenceurComponent, canActivate: [AuthGuard], data: {role: 'influencer'} },
  { path: 'formOffer/:id', component: InfluenceurReelsFormComponent, canActivate: [AuthGuard], data: {role: 'influencer'}},
  { path: 'formOffer/:id/:plateformeType', component: InfluenceurReelsFormComponent, canActivate: [AuthGuard], data: {role: 'influencer'} },
  { path: 'formOfferAdmin/:id', component: OfferDataInputAdminComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'formOfferAdmin/:id/:plateformeType', component: OfferDataInputAdminComponent, canActivate: [AuthGuard], data: {role: 'admin'} },
  { path: 'offerDashboard/:id', component: DashboardAdminComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'offerDashboard-youtube/:id', component: DashboardAdminYoutubeComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'dashboard/:id/:plateformeType', component: DashboardInfluenceurComponent, canActivate: [AuthGuard], data: {role: 'influencer'}},
  { path: 'home-admin', component: HomeAdminComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'listInfluencer', component: ListComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'billing-influencer/:id', component: InfluenceurBillingComponent, canActivate: [AuthGuard], data: {role: 'influencer'}},
  { path: 'invoice/:id', component: InvoiceAdminComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'list-contact', component: ListContactComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'create-contact', component: CreateContactComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'detail-contact/:id', component: ModifContactComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'list-company', component: ListCompanyComponent, canActivate: [AuthGuard], data: {role: 'admin'}}, 
  { path: 'create-company', component: CreateCompanyComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'detail-company/:id', component: ModifCompanyComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'detail-influencer/:id', component: DetailInfluenceurAdminComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'insight-profil/:id', component: InsightsProfileComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'pigeExternal', component: PigeAdminComponent, canActivate: [AuthGuard], data: {role: 'admin'}},
  { path: 'profile', component: DetailProfilComponent, canActivate: [AuthGuard], data: {role: 'influencer'}},
  { path: 'home-contact', component: HomeContactComponent, canActivate: [AuthGuard], data: {role: 'contact'}},
  { path: 'dashboard-contact/:id', component: ContactDetailedCampagneComponent, canActivate: [AuthGuard], data: {role: 'contact'}},
  { path: 'home', component: HomeInfluenceurComponent},
  { path: 'home/:plateformeTypeErr', component: HomeInfluenceurComponent },
  { path: 'brand', component: BrandComponent },
  { path: 'influencer', component: InfluencerComponent },
  { path: 'login', component: LoginInfluenceurComponent },
  { path: 'login/:type/:id', component: LoginInfluenceurComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password/:link', component: ForgotPasswordComponent },
  { path: 'loginadmin', component: SigninAdminComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'register', component: InscriptionComponent},
  { path: 'register/:plateformeTypeErr', component: InscriptionComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'legal/cgu', component: CguComponent },
  { path: 'legal/mentionLegal', component: MentionsComponent },
  { path: 'legal/contratInfluencer', component: ContratComponent },
  { path: 'legal/cookiePolitique', component: CookiesComponent },
  { path: '', component: AccueilComponent },
  { path: 'success-registration', component: SuccessRegisterComponent },
  { path: 'failed', component: ErrorComponent },
  { path: 'maintenance', component: MajComponent },
  { path: 'success-registration/:toastr', component: SuccessRegisterComponent },
  { path: '**', component: AccueilComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), MatTabsModule, MatTableModule, /*NgbConfig*/],
  exports: [RouterModule]
})
export class AppRoutingModule { }
