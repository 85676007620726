import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import {toast} from 'src/app/model/toast'

@Component({
  selector: 'app-del-content-influenceur',
  templateUrl: './del-content-influenceur.component.html',
  styleUrls: ['./del-content-influenceur.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class DelContentInfluenceurComponent implements OnInit {
  jsonContenu : any
  toast = toast
  constructor(
    private dialogRef: MatDialogRef<DelContentInfluenceurComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private apiService: ApiService,
    private toastr: ToastrService,
  ) { 
    this.jsonContenu = data;
  }

  ngOnInit(): void {
    
  }

  findIndexOnOffer(contents : any, contentToDelete : any){
    var newIndex = -1
    contents.forEach((elem: any, index: number) => {
      if (elem.creationDate == contentToDelete.creationDate){
        newIndex = index
      }
    });
    return newIndex
  }

  deletePage(){
    let inde = this.findIndexOnOffer(this.jsonContenu.offer.posts, this.jsonContenu.elem)
    if (inde != -1){
      this.jsonContenu.offer.posts.splice(inde, 1)
      this.apiService.putOffre(this.jsonContenu.offer._id, this.jsonContenu.offer).subscribe(res => {
        this.toastr.success(this.toast.deleteNetwork)
      })
    }
  }

}
