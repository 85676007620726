<html>
  <body>
    <div class="page container">
      <a *ngIf="readyToShow" [routerLink]="['/home-contact']" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
    <mat-card *ngIf="readyToShow">
      <div *ngIf="pigeReady" class="container">
        <div   class="row mb-4">
          <a  href="{{excelOverviewUrl}}">
            <button   mat-button class="btn btn-sm btn-secondary "  >Overview <i class="fa ml-1 fa-file-excel-o" aria-hidden="true"></i></button>
          </a>
          
          <a  href="{{pptxUrl}}">
            <button  mat-button class="btn btn-sm ml-1 btn-secondary "  >PPTX <i class="fa fa-file-powerpoint-o ml-1" aria-hidden="true"></i>
            </button>
          </a>
          
          
        </div>
    </div>
      <div class="title-card" text-center>
        <div>
          <mat-label class="title"> Campaign </mat-label>
      </div>
        <div>
            <mat-label class="title"><i>🤗{{dashboardData.campagne.campaignName}}🚀</i></mat-label>
        </div>
    </div>
    <mat-divider ></mat-divider>
      <div class="title-card text-center mt-5 mb-3">
        <div class="col-lg-12  display-mid">
            <div class="col-lg-3 col-md-3 col-sm-3 col-3 text-center" >
                <label class="col-12 labelFont text-center">Revenue Target<span class="ml-2 fa fa-bullseye"></span></label>
                <div  class=" text-center fileText">
                    {{dashboardData.campagne.revenueTarget}}
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3 col-3">
                <label class="col-12 labelFont text-center">Campaign Date<span class="ml-2 fa fa-calendar"></span></label>
                <div class="fileText text-center">
                    {{dashboardData.campagne.startDate | date:'yyyy/MM/dd'}} - {{dashboardData.campagne.endDate | date:'yyyy/MM/dd'}}
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                <label class="col-12 labelFont text-center">Mission<span class="ml-2 fa fa-rocket"></span></label>
                <div class="fileText text-center">
                    {{dashboardData.campagne.mission}}
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                <label class="col-12 labelFont text-center">Social Network<span class="ml-2 fa fa-share-alt-square"></span></label>
                <div class=" text-center">
                    <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(dashboardData.campagne.platformeType)}" style="color:white">
                        <i  *ngIf="dashboardData.campagne.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                        <i *ngIf="dashboardData.campagne.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                        <i *ngIf="dashboardData.campagne.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                        <i *ngIf="dashboardData.campagne.platformeType == 'Blog'" class="fa fa-bold "></i>
                        <i *ngIf="dashboardData.campagne.platformeType == 'multiNetwork'" class="fa fa-globe  "></i>
                      </button>
                    
                </div>
            </div>
        </div>
    </div>
    <mat-divider ></mat-divider>
      <mat-card-content>
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTabIndexContent" (selectedTabChange)="onTabChanged($event)" >
          <mat-tab  label="Overview" >
            <div class="container" >
              <div class="cardMetrics mt-3">
                <div class="contentMetrics">
                  <mat-card>
                    <mat-card-content (click)="showOverviewChart('impression')">
                      <div class="fa-content">
                        <div style="color: #4bdbaa;">
                          <i style="font-size:50px;" class="fa fa-eye"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                           Impressions
                          </div>
                          <div  class="numberCard">
                            <span>{{dashboardData.campagneTotalStats.totalImpression | mask:"separator.2"}}</span>
                            
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="contentMetrics">
                  <mat-card>
                    <mat-card-content (click)="showOverviewChart('plays')">
                      <div class="fa-content">
                        <div style="color: #4bdbaa;">
                          <i style="font-size:50px;" class="fa fa-play"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                           Plays <i class="fa fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                           matTooltip="Number of times the reels starts to play after an impression is already counted. This is defined as video sessions with 1 ms or more of playback and excludes replays." 
                           aria-label="Button that displays a tooltip when focused or hovered over"           
                           matTooltipPosition="right"
                           matTooltipClass="allow-cr"
                           aria-tooltip="Button that displays and hides a tooltip triggered by other buttons"></i>
                          </div>
                          <div  class="numberCard">
                            <span>{{dashboardData.campagneTotalStats.totalPlays | mask:"separator.2"}}</span>
                            
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="contentMetrics">
                  <mat-card (click)="showOverviewChart('reach')">
                    <mat-card-content>
                      <div class="fa-content">
                        <div style="color: #5bb2ee;">
                          <i style="font-size:50px;" class="fa fa-users"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                           Reach
                          </div>
                          <div class="numberCard ">
                            <span>{{dashboardData.campagneTotalStats.totalReach | mask:"separator.2"}}</span>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="contentMetrics">
                  <mat-card (click)="showOverviewChart('engagement')">
                    <mat-card-content>
                      <div class="fa-content">
                        <div style="color: #dc2626;">
                          <i style="font-size:50px;" class="fa fa-heart"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                            Engagement
                          </div>
                          <div class="numberCard ">
                            <span>{{dashboardData.campagneTotalStats.totalLike + dashboardData.campagneTotalStats.totalComment | mask:"separator.2"}}</span>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div class="chartPositionCard mt-3">
                <div  class="cardcharttitle ">
                  <div >
                    <div class="labelFont" *ngIf="overviewImpSeleted">Impressions Delivered (Daily)</div> 
                    <div class="labelFont" *ngIf="overviewPlaysSeleted">Plays (Daily)</div> 
                    <div  class="labelFont" *ngIf="overviewReachSeleted">Reach (Daily)</div> 
                    <div class="labelFont" *ngIf="overviewEngagementSeleted">Engagement (Daily)</div> 
                  </div>
                </div>
                <apx-chart *ngIf="overviewImpSeleted"
                [series]="dashboardData.dashboardCampaign.impressionviewsdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaign.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [colors]="chartOptions.colors"
                [responsive]="chartOptions.responsive"
              ></apx-chart>
              <apx-chart *ngIf="overviewPlaysSeleted"
                [series]="dashboardData.dashboardCampaignReel.impressionplaysdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaignReel.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [colors]="chartOptions.colors"
                [responsive]="chartOptions.responsive"
              ></apx-chart>
              <apx-chart *ngIf="overviewReachSeleted"
                [series]="dashboardData.dashboardCampaign.impressionreachdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaign.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fillReach"
                [colors]="chartOptions.colorsReach"
              ></apx-chart>
              <apx-chart *ngIf="overviewEngagementSeleted"
                [series]="dashboardData.dashboardCampaign.impressionengagementdata"
                [chart]="chartOptions.chartEngagement"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaign.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fillEngagement"
                [colors]="chartOptions.colorsEngagement"
                [responsive]="chartOptions.responsive"
              ></apx-chart>
                
              </div>
              
            </div>
          </mat-tab>
          <mat-tab *ngIf="dashboardData.contenuCampaignPost.length > 0" label="Posts" >
            <div class="container" *ngIf="showPost">
              <div class="cardMetrics mt-3">
                <div class="contentMetrics">
                  <mat-card>
                    <mat-card-content (click)="showPostChart('impression')">
                      <div class="fa-content">
                        <div style="color: #4bdbaa;">
                          <i style="font-size:50px;" class="fa fa-eye"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                           Impressions
                          </div>
                          <div  class="numberCard">
                            <span>{{dashboardData.campagneTotalStatsPost.totalImpression | mask:"separator.2"}}</span>
                            
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="contentMetrics">
                  <mat-card (click)="showPostChart('reach')">
                    <mat-card-content>
                      <div class="fa-content">
                        <div style="color: #5bb2ee;">
                          <i style="font-size:50px;" class="fa fa-users"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                           Reach
                          </div>
                          <div class="numberCard ">
                            <span>{{dashboardData.campagneTotalStatsPost.totalReach | mask:"separator.2"}}</span>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="contentMetrics">
                  <mat-card (click)="showPostChart('engagement')">
                    <mat-card-content>
                      <div class="fa-content">
                        <div style="color: #dc2626;">
                          <i style="font-size:50px;" class="fa fa-heart"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                            Engagement
                          </div>
                          <div class="numberCard ">
                            <span>{{dashboardData.campagneTotalStatsPost.totalComment + dashboardData.campagneTotalStatsPost.totalLike | mask:"separator.2"}}</span>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div class="chartPositionCard mt-3">
                <div  class="cardcharttitle ">
                  <div >
                    <div class="labelFont" *ngIf="postImpSeleted">Impressions Delivered (Daily)</div> 
                    <div  class="labelFont" *ngIf="postReachSeleted">Reach (Daily)</div> 
                    <div class="labelFont" *ngIf="postEngagementSeleted">Engagement (Daily)</div> 
                  </div>
                </div>
                <apx-chart *ngIf="postImpSeleted"
                [series]="dashboardData.dashboardCampaignPost.impressionviewsdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaignPost.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fill"
                [colors]="chartOptions.colors"
              ></apx-chart>
              <apx-chart *ngIf="postReachSeleted"
                [series]="dashboardData.dashboardCampaignPost.impressionreachdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaignPost.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fillReach"
                [colors]="chartOptions.colorsReach"
              ></apx-chart>
              <apx-chart *ngIf="postEngagementSeleted"
                [series]="dashboardData.dashboardCampaignPost.impressionengagementdata"
                [chart]="chartOptions.chartEngagement"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [dataLabels]="chartOptions.dataLabels"
                [labels]="dashboardData.dashboardCampaignPost.labelData"
                [fill]="chartOptions.fillEngagement"
                [colors]="chartOptions.colorsEngagement"
                [responsive]="chartOptions.responsive"
              ></apx-chart>
                
              </div>
              
            </div>
          </mat-tab>
          <mat-tab *ngIf="dashboardData.contenuCampaignReel.length > 0" label="Reels" >
            <div class="container" *ngIf="showReel">
              <div class="cardMetrics mt-3">
                <div class="contentMetrics">
                  <mat-card>
                    <mat-card-content (click)="showReelChart('impression')">
                      <div class="fa-content">
                        <div style="color: #4bdbaa;">
                          <i style="font-size:50px;" class="fa fa-play"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                           Plays <i class="fa fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                           matTooltip="Number of times the reels starts to play after an impression is already counted. This is defined as video sessions with 1 ms or more of playback and excludes replays." 
                           aria-label="Button that displays a tooltip when focused or hovered over"           
                           matTooltipPosition="right"
                           matTooltipClass="allow-cr"
                           aria-tooltip="Button that displays and hides a tooltip triggered by other buttons"></i>
                          </div>
                          <div  class="numberCard">
                            <span>{{dashboardData.campagneTotalStatsReel.totalPlays | mask:"separator.2"}}</span>
                            
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="contentMetrics">
                  <mat-card (click)="showReelChart('reach')">
                    <mat-card-content>
                      <div class="fa-content">
                        <div style="color: #5bb2ee;">
                          <i style="font-size:50px;" class="fa fa-users"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                           Reach
                          </div>
                          <div class="numberCard ">
                            <span>{{dashboardData.campagneTotalStatsReel.totalReach | mask:"separator.2"}}</span>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="contentMetrics">
                  <mat-card (click)="showReelChart('engagement')">
                    <mat-card-content>
                      <div class="fa-content">
                        <div style="color: #dc2626;">
                          <i style="font-size:50px;" class="fa fa-heart"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                            Engagement
                          </div>
                          <div class="numberCard ">
                            <span>{{dashboardData.campagneTotalStatsReel.totalComment + dashboardData.campagneTotalStatsReel.totalLike | mask:"separator.2"}}</span>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div class="chartPositionCard mt-3">
                <div  class="cardcharttitle ">
                  <div >
                    <div class="labelFont" *ngIf="reelImpSeleted">Plays (Daily)</div> 
                    <div  class="labelFont" *ngIf="reelReachSeleted">Reach (Daily)</div> 
                    <div class="labelFont" *ngIf="reelEngagementSeleted">Engagement (Daily)</div> 
                  </div>
                </div>
                <apx-chart *ngIf="reelImpSeleted"
                [series]="dashboardData.dashboardCampaignReel.impressionplaysdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaignReel.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fill"
                [colors]="chartOptions.colors"
              ></apx-chart>
              <apx-chart *ngIf="reelReachSeleted"
                [series]="dashboardData.dashboardCampaignReel.impressionreachdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaignReel.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fillReach"
                [colors]="chartOptions.colorsReach"
              ></apx-chart>
              <apx-chart *ngIf="reelEngagementSeleted"
                [series]="dashboardData.dashboardCampaignReel.impressionengagementdata"
                [chart]="chartOptions.chartEngagement"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [dataLabels]="chartOptions.dataLabels"
                [labels]="dashboardData.dashboardCampaignReel.labelData"
                [fill]="chartOptions.fillEngagementReels"
                [colors]="chartOptions.colorsEngagementReels"
                [responsive]="chartOptions.responsive"
              ></apx-chart>
                
              </div>
              
            </div>
          </mat-tab>
          <mat-tab  *ngIf="dashboardData.contenuCampaignStory.length > 0" label="Stories" >
            <div class="container" *ngIf="showStory">
              <div class="cardMetrics mt-3">
                <div class="contentMetrics">
                  <mat-card>
                    <mat-card-content (click)="showStoriesChart('impression')">
                      <div class="fa-content">
                        <div style="color: #4bdbaa;">
                          <i style="font-size:50px;" class="fa fa-eye"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                           Impressions
                          </div>
                          <div  class="numberCard">
                            <span>{{dashboardData.campagneTotalStatsStory.totalImpression | mask:"separator.2"}}</span>
                            
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="contentMetrics">
                  <mat-card (click)="showStoriesChart('reach')">
                    <mat-card-content>
                      <div class="fa-content">
                        <div style="color: #5bb2ee;">
                          <i style="font-size:50px;" class="fa fa-users"></i>
                        </div>
                        <div  class="cardMetricsContent ">
                          <div class="labelFont">
                           Reach
                          </div>
                          <div class="numberCard ">
                            <span>{{dashboardData.campagneTotalStatsStory.totalReach | mask:"separator.2"}}</span>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                
              </div>
              <div class="chartPositionCard mt-3">
                <div  class="cardcharttitle ">
                  <div >
                    <div class="labelFont" *ngIf="storieImpSeleted">Impressions Delivered (Daily)</div> 
                    <div  class="labelFont" *ngIf="storieReachSeleted">Reach (Daily)</div> 
                    <div class="labelFont" *ngIf="storieEngagementSeleted">Engagement (Daily)</div> 
                  </div>
                </div>
                <apx-chart *ngIf="storieImpSeleted"
                [series]="dashboardData.dashboardCampaignStory.impressionreachdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaignStory.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fill"
                [colors]="chartOptions.colors"
              ></apx-chart>
              <apx-chart *ngIf="storieReachSeleted"
                [series]="dashboardData.dashboardCampaignStory.impressionreachdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaignStory.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fillReach"
                [colors]="chartOptions.colorsReach"
              ></apx-chart>
              </div>
              
            </div>
          </mat-tab>
        </mat-tab-group>
    </mat-card-content>
    
    </mat-card >

    <mat-card class="mt-3" *ngIf="readyToShow && showOffer && dashboardData.contenuCampaignStory.length > 0">
      <mat-card-content>
        <div class="container">
          <div  class="cardcharttitle ">
            <div class="labelFont" >Storie(s) ({{dashboardData.contenuCampaignStory.length}})</div> 
        </div>
          
          <div flex-center class="row col-lg-12 col-12">
            <div  class="card  mt-2 mb-2">
              <div  class="profileIf ">
                <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
              </div>
              <img  [src]="storiesPicOverviewSeleted" class="post-image" alt="">
              <div (click)="expendedOverviewStories=!expendedOverviewStories" class="card-content">
                <p>{{ (expendedOverviewStories) ? storiesTxtOverviewSeleted : storiesTxtOverviewSeleted | slice:0:150}}</p>
                <div  (click)="expendedOverviewStories=true" *ngIf="!expendedOverviewStories">...</div>
                <div  (click)="expendedOverviewStories=false" *ngIf="expendedOverviewStories">...</div>
              </div>
          </div>
          </div>
          
        <div flex-center class="thumbnails mt-4">
          <div *ngFor="let post of dashboardData.contenuCampaignStory;let index = index;" >
            <div class="img_wrapper ">
              
              <img  (click)="showPic(post, 'Overview', 'Storie')" mat-card-image class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture" >
            </div>
          </div>
        </div>
        </div>
        
      </mat-card-content>
    </mat-card>
    <mat-card class="mt-3" *ngIf="readyToShow && showOffer && dashboardData.contenuCampaignPost.length > 0">
      <div  class="cardcharttitle ">
        <div class="labelFont" >Post(s) ({{dashboardData.contenuCampaignPost.length}})</div> 
    </div>
      <mat-card-content>
        <div class="container">
          <div flex-center class="row col-lg-12 col-12">
            <div  class="card  mt-2 mb-2">
              <div  class="profileIf ">
                <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
              </div>
              <img  [src]="postPicOverviewSeleted" class="post-image" alt="">
              <div class="card-content" (click)="expendedOverViewPosts=!expendedOverViewPosts" >
                <div>{{(expendedOverViewPosts) ? postTxtOverviewSeleted : postTxtOverviewSeleted | slice:0:150}} <div  (click)="expendedOverViewPosts=true" *ngIf="!expendedOverViewPosts">...</div>
                  
                  <div  (click)="expendedOverViewPosts=false" *ngIf="expendedOverViewPosts">...</div>
                </div>
                
              </div>
          </div>
          </div>
          
        <div flex-center class="thumbnails mt-4">
          <div *ngFor="let post of dashboardData.contenuCampaignPost;let index = index;" >
            <div class="img_wrapper ">
              <img  (click)="showPic(post,'Overview', 'Post')"  class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture" >
            </div>
          </div>
        </div>
        </div>
        
      </mat-card-content>
    </mat-card>
    <mat-card class="mt-3" *ngIf="readyToShow && showOffer && dashboardData.contenuCampaignReel.length > 0">
      <div  class="cardcharttitle ">
        <div class="labelFont" >Reel(s) ({{dashboardData.contenuCampaignReel.length}})</div> 
    </div>
      <mat-card-content>
        <div class="container">
          <div flex-center class="row col-lg-12 col-12">
            <div  class="card  mt-2 mb-2">
              <div  class="profileIf ">
                <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
              </div>
              <img  [src]="reelPicOverviewSeleted" class="post-image" alt="">
              <div class="card-content" (click)="expendedOverViewPosts=!expendedOverViewPosts" >
                <div>{{(expendedOverViewPosts) ? reelTxtOverviewSeleted : reelTxtOverviewSeleted | slice:0:150}} <div  (click)="expendedOverViewPosts=true" *ngIf="!expendedOverViewPosts">...</div>
                  
                  <div  (click)="expendedOverViewPosts=false" *ngIf="expendedOverViewPosts">...</div>
                </div>
                
              </div>
          </div>
          </div>
          
        <div flex-center class="thumbnails mt-4">
          <div *ngFor="let post of dashboardData.contenuCampaignReel;let index = index;" >
            <div class="img_wrapper ">
              <img  (click)="showPic(post,'Overview', 'Reel')"  class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture" >
            </div>
          </div>
        </div>
        </div>
        
      </mat-card-content>
    </mat-card>
    <mat-card class="mt-3" *ngIf="showPost && dashboardData.contenuCampaignPost.length > 0">
      <div  class="cardcharttitle">
        <div class="labelFont">Posts ({{dashboardData.contenuCampaignPost.length}})</div>
    </div>
      <mat-card-content>
        <div flex-center>
          <div  class="card mt-2  mb-2">
            <div  class="profileIf ">
              <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
            </div>
            <img  [src]="srcPostImg" class="post-image" alt="">
            <div class="card-content" (click)="expendedPosts = !expendedPosts">
              <div>{{(expendedPosts) ? srcPostCaption : srcPostCaption | slice:0:150}} </div>
              <div  (click)="expendedPosts=true" *ngIf="!expendedPosts">...</div>
                
                <div  (click)="expendedPosts=false" *ngIf="expendedPosts">...</div>
            </div>
        </div>
        </div>
        <div flex-center class="thumbnails mt-3">
          <div *ngFor="let post of dashboardData.contenuCampaignPost;let index = index;" >
            <div class="img_wrapper ">
              <img  (click)="showPicPost(post)"  class="img-responsive imgFeed"   [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture">
            </div>
          </div>
        </div>
        
      </mat-card-content>
    </mat-card>

    <mat-card class="mt-3" *ngIf="showReel && dashboardData.contenuCampaignReel.length > 0">
      <div  class="cardcharttitle">
        <div class="labelFont">Reel ({{dashboardData.contenuCampaignReel.length}})</div>
    </div>
      <mat-card-content>
        <div flex-center>
          <div  class="card mt-2  mb-2">
            <div  class="profileIf ">
              <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
            </div>
            <img  [src]="srcReelImg" class="post-image" alt="">
            <div class="card-content" (click)="expendedPosts = !expendedPosts">
              <div>{{(expendedPosts) ? srcReelCaption : srcReelCaption | slice:0:150}} </div>
              <div  (click)="expendedPosts=true" *ngIf="!expendedPosts">...</div>
                
                <div  (click)="expendedPosts=false" *ngIf="expendedPosts">...</div>
            </div>
        </div>
        </div>
        <div flex-center class="thumbnails mt-3">
          <div *ngFor="let post of dashboardData.contenuCampaignReel;let index = index;" >
            <div class="img_wrapper ">
              <img  (click)="showPicReel(post)"  class="img-responsive imgFeed"   [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture">
            </div>
          </div>
        </div>
        
      </mat-card-content>
    </mat-card>

    <mat-card class="mt-3" *ngIf="showStory && dashboardData.contenuCampaignStory.length > 0">
      <div  class="cardcharttitle">
          <div class="labelFont">Storie(s) {{dashboardData.contenuCampaignStory.length}}</div>
    </div>
      <mat-card-content>
        <div flex-center>
          <div  class="card mt-2  mb-2">
            <div  class="profileIf ">
              <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
            </div>
            <img  [src]="srcSelectedStory" class="post-image" alt="">
            <!-- <div class="card-content">
              <p>{{textSelectedStory}}</p>
            </div> -->
            <div (click)="expendedStories = !expendedStories" class="card-content">
              <p>{{ (expendedStories) ? textSelectedStory : textSelectedStory | slice:0:50}}</p>
              <div  (click)="expendedStories=true" *ngIf="!expendedStories">...</div>
              <div  (click)="expendedStories=false" *ngIf="expendedStories">...</div>
            </div>
        </div>
        </div>
        <div flex-center  class="thumbnails mt-3">
          <div *ngFor="let post of dashboardData.contenuCampaignStory;let index = index;" >
            <div class="img_wrapper ">
              <img  (click)="showPicStory(post)" mat-card-image class="img-responsive imgFeed" [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture">
            </div>
          </div>
        </div>
        
      </mat-card-content>
    </mat-card>
</div>
</body>
</html>