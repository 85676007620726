import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import {Router} from '@angular/router'

@Component({
  selector: 'app-choice-invoice',
  templateUrl: './choice-invoice.component.html',
  styleUrls: ['./choice-invoice.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class ChoiceInvoiceComponent implements OnInit {
  offer: any
  constructor(
    private dialogRef: MatDialogRef<ChoiceInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private router: Router,
  ) { 
    this.offer = data;
  }

  ngOnInit(): void {
  }

  openOfferDetail() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/detailOffer/${this.offer._id}`])
    );
    window.open(url);
  }

  openInvoice() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/billing-influencer/${this.offer._id}`])
    );
    // window.open(url, '_blank');
    window.open(url);
  }

}
