<html>
    <body >
        <div class="page container-fluid">
          <div class="row mb-3">
            <button  routerLink="/create-contact" class="btn btn-secondary shadow-none " > <i class="fa fa-plus mr-2" aria-hidden="true"></i>Create </button>
            <input type="text" (change)="findContact()" [(ngModel)]="tmp" style="font-family: Open Sans,Helvetica,Arial,sans-serif,FontAwesome;" class="form-control text-input col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12  mt-2" name=""  placeholder="&#xf002; Search contacts" >
          </div>
          <div class="row">
            <div class="tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
              <table class="table table-hover tableTitle text-center">
                <thead>
                    <tr class="tableTitle ">
                      <th class="col-1 tableTitle"scope="col">Prénom</th>
                      <th class="col-1" scope="col">Nom </th>
                      <th class="col-1"scope="col">Fonction </th>
                      <th class="col-1"scope="col">Email </th>
                      <th class="col-1"scope="col">Téléphone </th>
                      <th class="col-1"scope="col">Company Name</th>
                      <th class="col-1" scope="col">Action</th>
                    </tr>
                  </thead>
                <tbody *ngIf="loadListContact === true" class="bodyTable">
                  <tr   *ngFor="let contact of dataSourceContact | paginate: { itemsPerPage: count, currentPage: p }">
                    <td class=" text-center">
                        {{contact.firstname}}
                    </td>
                    <td class="text-center">
                        {{contact.lastname}}
                    </td>
                    <td class="text-center">
                        {{contact.fonction}}
                    </td>
                    <td class="text-center">
                        {{contact.email}}
                    </td>
                    <td class="text-center">
                        {{contact.phone}}
                    </td>
                    <td class="text-center">
                        {{contact.company}}
                    </td>
                    <td class="text-center">
                      <!-- detail-company -->
                      <button  (click)="openContactDetailInNewWindow(contact._id)" type="button" style="font-size: smaller;" class="btn btn-secondary"><span class="fa fa-search"></span></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination">
                <pagination-controls (pageChange)="p = $event" previousLabel = "" nextLabel = ""></pagination-controls>
              </div>
            </div>
          </div>
        </div>
    </body>
</html>