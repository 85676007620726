import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import {toast} from 'src/app/model/toast'
@Component({
  selector: 'app-deletepage',
  templateUrl: './deletepage.component.html',
  styleUrls: ['./deletepage.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class DeletepageComponent implements OnInit {
  jsonContenu : any
  toast = toast
  constructor(
    private dialogRef: MatDialogRef<DeletepageComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cdRef:ChangeDetectorRef,
    private toastr: ToastrService,
    private apiService : ApiService,
    private router: Router,
  ) { 
    this.jsonContenu = data;
  }

  ngOnInit(): void {
  }

  deletePageInf(){
    let index = this.jsonContenu.user.userObject.indexOf(this.jsonContenu.network)
    if (index != -1){
      this.jsonContenu.user.userObject.splice(index, 1)
      this.apiService.putUser(this.jsonContenu.user._id, this.jsonContenu.user).subscribe(res => {
        this.toastr.success(this.toast.deleteNetwork)
      })
    }
    if (this.jsonContenu.user.userObject.length === 0){
      if (this.jsonContenu.user.influencerInfo.picture != ""){
        this.apiService.deletePicture(this.jsonContenu.user.influencerInfo.picture).subscribe(res => {
          this.apiService.deleteUser(this.jsonContenu.user._id).subscribe(res => {
            this.router.navigate(['/listInfluencer'])
          })
        })
        
      } else {
        this.apiService.deleteUser(this.jsonContenu.user._id).subscribe(res => {
          this.router.navigate(['/listInfluencer'])
        })
      }
    }
    this.dialogRef.close(this.jsonContenu.user.userObject);
  }
  


}
