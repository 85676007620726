<!DOCTYPE html>
<html lang="en">

    <body>
    <app-header></app-header>

      <section id="About" class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">ABOUT US</h1>
              <p class=" textcard text-center mt-4">Our mission is to help brands to leverage the power of recommandation of Everyday Influencers thanks to a powerful technology, driven by data & performance.</p>
              <p class=" textcard text-center mt-4">We enable influencers to share their expertise and create engaging content for their favorite brands.</p>
              <p class=" textcard text-center mt-4">Ultimatly, we provide organic and authentic content for happier internet users.</p>
          </div>
        </div>
      </section>
      <section  class="projects-section bg-light">
        <div class="container">
          <div class="card">
    <div class="card-body">
      <h4 class="card-title">CGU</h4>
      <a href="/legal/cgu" class="card-link">Cliquer Ici</a>
    </div>
    </div>
        </div>
      </section>
      <section class="projects-section bg-light">
        <div class="container">
          <div class="card">
    <div class="card-body">
      <h4 class="card-title">Mentions Legales</h4>
      <a href="/legal/mentionLegal" class="card-link">Cliquer Ici</a>
    </div>
  </div>
        </div>
      </section>
      <section  class="projects-section bg-light">
        <div class="container">
          <div class="card">
    <div class="card-body">
      <h4 class="card-title">Contrat Influencer</h4>
      <a href="/legal/contratInfluencer" class="card-link">Cliquer Ici</a>
    </div>
  </div>
        </div>
      </section>

      <section  class="projects-section bg-light">
        <div class="container">
          <div class="card">
    <div class="card-body">
      <h4 class="card-title">Politique d'utilisation des cookies</h4>
      <a href="/legal/cookiePolitique" class="card-link">Cliquer Ici</a>
    </div>
  </div>
        </div>
      </section>

      <!-- Footer -->
      <footer class="bg-black small text-center text-white-50">
        <div class="container-fluid">
          <span>Copyright &copy; Influence Lab</span>
          <a href="/legal">
            <span> Legal</span>
          </a>
        </div>
      </footer>

      <!-- Bootstrap core JavaScript -->
      <script src="vendor/jquery/jquery.min.js"></script>
      <script src="vendor/bootstrap/js/bootstrap.bundle.min.js"></script>

      <!-- Plugin JavaScript -->
      <script src="vendor/jquery-easing/jquery.easing.min.js"></script>

      <!-- Custom scripts for this template -->
      <script src="js/grayscale.min.js"></script>
  </body>
</html>


