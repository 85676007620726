<h4 class="dialogHeadlineGenerique" mat-dialog-title>Select contact</h4>
<mat-dialog-content style="width: auto;height: auto;" class=" mt-2">
    <p class="mt-1">Select contact email</p>
    <select class="form-control mb-1" [(ngModel)]="selectedDevice" (ngModelChange)="onChange()">
        <option value=""></option>
        <option *ngFor="let x of contacts ; index as i" [ngValue]="x" (click)="addContact(x)" >{{x.email}}</option>
    </select>
    <div class="right align-items-end">
        <div *ngIf="arrayContacts.length > 0" class=" mt-2 ">
            <span (click)="deleteContact(z, i)" *ngFor="let z of arrayContacts; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                {{ z.email}} x
            </span>
        </div>
    </div>
</mat-dialog-content>

