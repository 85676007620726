import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import {userModel} from 'src/app/model/user-model.model'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class ListComponent implements OnInit {
  dataSourceInfluencers: userModel[];
  p: number = 1;
  total: number
  respTrue: boolean = true
  count: number = 50;
  term: string;
  tmp: string
  public loadInfluencers = false

  constructor(private apiService : ApiService, 
    public fctBasic: CampagneAndOfferFctService, 
    private cdRef:ChangeDetectorRef,
    public router: Router) {
      this.apiService.getInfluencersPaginate(1, this.count).subscribe(res => { 
        if (res != 400){
          this.dataSourceInfluencers = res.influencers;
          this.total = res.total
          this.cdRef.detectChanges()
        }
      })
    }

  ngOnInit(): void {
    this.loadInfluencers = true
    
  }

  openInfluencerInNewWindow(id : any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/detail-influencer/${id}`])
    );
    window.open(url, '_blank');
  }

  findInfluenceur(){
    this.apiService.getInfluencersPaginateFilter(this.tmp, this.count).subscribe(res => {
      this.total = res.total;
      this.dataSourceInfluencers = res.influenceurs
      this.p = 1
      this.cdRef.detectChanges();
    })
  }

  listceil(nbr : number){
    return Math.ceil(nbr)
  }

  paginateInfluencer(event : any){
    this.p = event
    this.apiService.getInfluencersPaginate(this.p, this.count).subscribe(res => { 
      if (res != 400){
        this.dataSourceInfluencers = res.influencers;
        this.cdRef.detectChanges();
      }
    })

  }

}
