import { Component, OnInit } from '@angular/core';
import {MediaInfluenceurService } from 'src/app/service/media-influenceur.service';
import {UserLoginService} from 'src/app/service/user-login.service';
import { ApiService } from 'src/app/service/api.service';
import { RefuseContratComponent } from 'src/app/dialog/refuse-contrat/refuse-contrat.component';
import { MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-finish-register',
  templateUrl: './finish-register.component.html',
  styleUrls: ['./finish-register.component.css', '../../dialog/facebook-dialog/facebook-dialog.component.css','../../../assets/styles/body.css','../../../assets/styles/dialog.css','../../../assets/styles/button.css',],
  providers: [MediaInfluenceurService]
})
export class FinishRegisterComponent implements OnInit {

  clickAcceptContract = false;
  srcPic = ""
  constructor(
    private authService : UserLoginService, 
    private apiService : ApiService,
    public picService: MediaInfluenceurService,
    public dialog: MatDialog,
    ) {

  }
  async valid(){
    this.putUserForFinishInscription().then(newUser =>{
      this.apiService.putUser(newUser._id, newUser).subscribe(check => {
        this.sendEmail(newUser).then(res => {
          this.authService.logoutInfluencerAfterRegister()
        })
      })
    })
  }

  async putUserForFinishInscription(){
    var newUser = this.authService.getUserValue()
    newUser.confirm = true
    newUser.userType = "influencer"
    return newUser
  }

  async sendEmail(user :any){
    if (user.influencerInfo.secondarymail != ""  && user.influencerInfo.secondarymail != null){
      this.apiService.inscriptionEmail(user.influencerInfo.secondarymail).subscribe()
    } else {
      this.apiService.inscriptionEmail(user.email).subscribe()
    }
  }

  refusecontrat(){
    this.openDialogError(RefuseContratComponent, {
      panelClass: 'custom-dialog-container',
     })
  }

  openDialogError(compenentName: any, option : any) {
    const dialogRef = this.dialog.open(compenentName, option);
    dialogRef.afterClosed().subscribe(result => {
      
    });
}

  ngOnInit(): void {}

}
