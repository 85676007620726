<!-- <!DOCTYPE html>
<html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main mastheadd">

		<div flex-center class="container-fluid">
			<div >
			<div flex-center>
				<a href="/"><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
						class="img-fluid" /></a>
			</div>
			<div flex-center>
				<h3 class="text-normal mt-3 mb-3 text-center" style="color: white;">Forgot Password ?</h3>
			</div>
			<div *ngIf="changepasseword == false && ready" class="form-container mt-3">
				<form [formGroup]="forgotemail">
					<div class="row input-row  " >
						<div class="col-md-12 col-lg-12 ">
							<div class=" display-mid">
								<div class="col-lg-4  col-12 col-sm-4 col-md-4 ">
									<mat-label style="color: white;" >Email *</mat-label>
								</div>
								<div class="col-lg-8 col-12 col-sm-8 col-md-8 ">
									<mat-form-field >
										<input type="email" matInput placeholder=""  formControlName="email" >
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					
					<div flex-center class="center-block mt-3">
						<button type="submit" (click)="submit()" class="btn btncustom btn-lg btn-light text-uppercase">Send reset link</button>
					</div>
				</form>
			</div>
			<div  *ngIf="changepasseword == true && ready" class="form-container mt-3">
				<form [formGroup]="forgotpassword">
					<div class="row input-row" >
						<div class="col-md-12 col-lg-12 col-12 col-sm-12">
							<div class=" display-mid">
								<div class="col-lg-4 col-12 col-md-4">
									<mat-label  style="color: white;" >Email *</mat-label>
								</div>
								<div class="col-lg-8 col-12 col-md-8">
									<mat-form-field >
										<input  matInput placeholder=""  formControlName="username" >
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group form-group-lg">
						<input class="form-control" type="email" formControlName="email" placeholder="Email" required="required">
					</div>
					<div class="form-group form-group-lg">
						<input class="form-control" type="password" formControlName="password" placeholder="Password" required="required">
					</div>
					<div class="form-group form-group-lg">
						<input class="form-control" type="password" formControlName="confirmPassword" placeholder="Confirm password" required="required">
					</div>
					<div flex-center class="center-block ">
						<button type="submit" (click)="submitnewPassword()" class="btn btncustom btn-lg btn-light text-uppercase">Reset Password</button>
					</div>
				</form>
			</div>
			<div class="form-container">
				<div flex-center>
					<p class="text-account mt-3" style="color: white;">Already have an account ? <a style="color: #a7a7a7;"
						href="/login">Just log in.</a></p>
				</div>
			</div>
			</div>
			
		</div>
	</div>
</body>

</html> -->

<!DOCTYPE html>
<html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main mastheadd">

		<div flex-center class="container-fluid">
			<div class="widht-size">
			<div flex-center>
				<a><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
						class="img-fluid" /></a>
			</div>
			<div flex-center>
				<h3 class="text-normal mt-3 mb-4 text-center" style="color: white;">Forgot Password ?</h3>
			</div>
			<div *ngIf="changepasseword == false && ready" class="form-container ">
				<form [formGroup]="forgotemail">
					<div class=" row input-row  " >
						<div class="col-md-12 col-lg-12 ">
							<div class=" display-mid">
								<div class="col-lg-4  col-12 col-sm-4 col-md-4 ">
									<mat-label style="color: white;" >Email *</mat-label>
								</div>
								<div class="col-lg-8 col-12 col-sm-8 col-md-8 ">
									<mat-form-field >
										<input type="email" matInput placeholder=""  formControlName="email" >
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					
					<div flex-center class="center-block mt-2">
						<button type="submit" (click)="submit()" class="btn btncustom btn-lg btn-light text-uppercase">Send reset link</button>
					</div>
				</form>
			</div>
			<div  *ngIf="changepasseword == true && ready" class="form-container ">
				<form [formGroup]="forgotpassword">
					<div class="row input-row" >
						<div class="col-md-12 col-lg-12 col-12 col-sm-12">
							<div class=" display-mid">
								<div class="col-lg-4 col-12 col-md-4">
									<mat-label  style="color: white;" >Email *</mat-label>
								</div>
								<div class="col-lg-8 col-12 col-md-8">
									<mat-form-field >
										<input  matInput placeholder=""  formControlName="email" >
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<div class="row input-row" >
						<div class="col-md-12 col-lg-12 col-12 col-sm-12">
							<div class=" display-mid">
								<div class="col-lg-4 col-12 col-md-4">
									<mat-label  style="color: white;" >Password *</mat-label>
								</div>
								<div class="col-lg-8 col-12 col-md-8">
									<mat-form-field >
										<input  matInput type="password" placeholder="" formControlName="password" [type]="hide ? 'password' : 'text'" >
										<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<div class="row input-row" >
						<div class="col-md-12 col-lg-12 col-12 col-sm-12">
							<div class=" display-mid">
								<div class="col-lg-4 col-12 col-md-4">
									<mat-label  style="color: white;" >Confirm password *</mat-label>
								</div>
								<div class="col-lg-8 col-12 col-md-8">
									<mat-form-field >
										<input   type="password" matInput placeholder="" [type]="hideconfirm ? 'password' : 'text'" formControlName="confirmPassword" >
										<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hideconfirm = !hideconfirm">{{hideconfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<div flex-center class="center-block ">
						<button type="submit" (click)="submitnewPassword()" class="btn btncustom btn-lg btn-light text-uppercase mt-2">Reset Password</button>
					</div>
				</form>
			</div>
			<p class="text-account text-center mt-3" style="color: white;">Already have an account ? <a style="color: #a7a7a7;"
				href="/login">Just log in.</a></p>
			</div>
		</div>
	</div>
</body>

</html>