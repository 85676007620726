<!DOCTYPE html>
<html lang="en">

  <head>

    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="">
  </head>


    <body>
    <app-header></app-header>
      <section class="projects-section bg-light" style="margin-top:5%;">
        <div class="container">
        <div>
            <h1 class="text-center logo">CONTRAT INFLUENCEUR</h1>
            <h1 class="text-center logo">INFLUENCE LAB</h1>
              <p class=" textcard  mt-4">Le présent Contrat Influenceur est conclu entre, d’une part, la société INFLUENCE LAB, société par actions simplifiée au capital social de 10 000,00 euros, inscrite au Registre du Commerce et des Sociétés de Paris sous le numéro 842 653 602, et ayant son siège social 90, rue Raynouard à Paris 16e (75016) (« INFLUENCE LAB »), et, d’autre part, un Utilisateur au sens des Conditions Générales d’Utilisation du site Internet influencelab.co (le « Site ») (les « CGU »), à savoir une personne physique ou morale s’étant inscrite en son nom propre sur le Site, par elle-même ou par le biais d’un représentant dûment mandaté, en suivant la procédure décrite à l’Article 3.2 des CGU (un « Utilisateur ») (ensemble les « Parties »).</p>
              <p class=" textcard  mt-4">Le Contrat Influenceur constitue, avec les CGU et les Offres éventuellement acceptées par l’Utilisateur, le cadre contractuel complet des relations entre INFLUENCE LAB et l’Utilisateur.</p>
              <p class=" textcard  mt-4">L’acceptation des CGU et du Contrat Influenceur par l’Utilisateur est matérialisée par la confirmation de l’Utilisateur au moment de son inscription sur la Plateforme.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">1) Definitions</h1>
              <p class=" textcard  mt-4">Les termes et expressions définis à l’Article 1 des CGU auront, au sein du Contrat Influenceur, les mêmes définitions.</p>
              <p class=" textcard  mt-4">En outre, les termes et expressions ci-après auront les définitions suivantes :</p>
              <li class="textcard  mt-4">« <b>Annonceurs</b> » : les clients d’INFLUENCE LAB souhaitant promouvoir leurs produits, services et/ou image de marque (ou, dans le cas d’agences et autres intermédiaires, les produits, services et/ou image de marque de leurs propres clients) à travers des Campagnes publicitaires.</li>
              <li class="textcard  mt-4">« <b>Campagne publicitaire</b> » : une opération de marketing commandée à INFLUENCE LAB par un Annonceur sous la forme d’un ordre d’insertion précisant le Réseau social visé, les produits, services ou image de marque à promouvoir, l’objectif de visibilité ou d’engagement à atteindre, ainsi que les Contenus Annonceur à publier ou relayer, le cas échéant.</li>
              <li class="textcard  mt-4">« <b>Console Analytics</b> » : l’interface développée par INFLUENCE LAB et mise à disposition sur la Plateforme, permettant à chaque Utilisateur et à INFLUENCE LAB de mesurer les performances des différents Contenus Utilisateur publiés par cet Utilisateur.</li>
              <li class="textcard  mt-4">« <b>Console Analytics</b> » : tout contenu publié par un Utilisateur sur l’un quelconque des Réseaux sociaux dans le cadre de sa participation à une Campagne publicitaire, quelle que soit la nature de ce contenu (texte, image, vidéo, etc.).</li>
              <li class="textcard  mt-4">« <b>Contenu Annonceur</b> » : un contenu créé par l’Annonceur à l’initiative de la Campagne publicitaire, ou par INFLUENCE LAB ou un tiers pour le compte de cet Annonceur, en vue d’être publié et relayé par les Utilisateurs sélectionnés dans le cadre de la Campagne publicitaire.</li>
              <li class="textcard  mt-4">« <b>Maximum</b> » : le montant maximal de Rémunération qu’un Utilisateur peut obtenir dans le cadre de sa participation à une Campagne publicitaire donnée, s’il atteint ou dépasse l’Objectif fixé dans l’Offre correspondante.</li>
              <li class="textcard  mt-4">« <b>Objectif</b>» : l’indicateur de performance à atteindre par un Utilisateur dans le cadre de sa participation à une Campagne publicitaire, lui permettant, s’il atteint ou dépasse cet indicateur, d’obtenir une Rémunération égale au Maximum.</li>
              <li class="textcard  mt-4">« <b>Offre</b> » : la proposition émise par INFLUENCE LAB à destination d’un Utilisateur consistant pour cet Utilisateur à participer à une Campagne publicitaire donnée, dans la limite d’un Objectif assorti d’un Maximum.</li>
              <li class="textcard  mt-4">« <b>Rémunération</b> » : le montant reversé à l’Utilisateur à raison de sa participation effective à la Campagne publicitaire, calculé conformément à l’Article 4 ci-après.</li>
              <li class="textcard  mt-4">« <b>Réseaux sociaux</b> » : les réseaux sociaux pris en charge par la Plateforme, sur lesquels les Utilisateurs peuvent publier des Contenus Utilisateur et publier ou relayer des Contenus Annonceur dans le cadre d’une Campagne publicitaire.</li>
              <p class="textcard   mt-4">L’ensemble des termes et expressions ci-avant peuvent être employés aussi bien au singulier qu’au pluriel.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">2) Objet</h1>
              <p class=" textcard  mt-4">Le Contrat Influenceur a pour objet de fixer les conditions dans lesquelles INFLUENCE LAB sélectionnera les Utilisateurs dans le cadre des Campagnes publicitaires, et dans lesquelles ces Utilisateurs participeront à ces Campagnes publicitaires et percevront des Rémunérations en retour, ainsi que les droits et obligations de chaque Partie dans ce contexte.</p>
              <p class=" textcard  mt-4">Il est rappelé que le Contrat Influenceur doit être lu en combinaison avec les CGU, qui régissent la navigation sur le Site et l’utilisation de la Plateforme par les Utilisateurs, et auxquelles le présent Contrat Influenceur s’ajoute sans rien y retrancher.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
          <h1 class="text-center logo">3) Fonctionnement de la Plateforme</h1>
          <h4 class="mt-4"><b>3.1 Recrutement des Utilisateurs</b></h4>
              <p class=" textcard  mt-4">En s’inscrivant sur la Plateforme, l’Utilisateur manifeste sa disponibilité pour recevoir des Offres de la part d’INFLUENCE LAB et ainsi participer à des Campagnes publicitaires.</p>
              <p class=" textcard  mt-4">Il remplit pour cela un « profil Utilisateur » comprenant a minima un ou plusieurs lien(s) vers ses différent(e)s comptes et pages sur les Réseaux sociaux.</p>
              <p class=" textcard  mt-4">INFLUENCE LAB sélectionnera, pour chaque Campagne publicitaire qui lui sera confiée par un Annonceur, un ou plusieurs Utilisateur(s) au(x)quel(s) elle adressera une Offre en vue de lui (leur) permettre de participer à cette Campagne publicitaire.</p>
              <p class=" textcard  mt-4">Elle utilisera pour ce faire ses algorithmes et méthodes propriétaires, qui lui permettent de sélectionner le ou les Utilisateur(s) le(s) plus pertinent(s) pour assurer le succès de la Campagne publicitaire. Les critères pris en compte pour évaluer la pertinence peuvent inclure (sans que cette liste ne soit aucunement limitative ni n’engage INFLUENCE LAB à prendre en compte tout ou partie de ces critères dans chaque cas) l’objet de la Campagne publicitaire, l’objectif général visé par l’Annonceur, le Réseau social concerné, ou encore les indicateurs de visibilité et d’engagement des précédentes publications de l’Utilisateur sur ce Réseau social (nombre de vues, de réactions, de commentaires, de partages, etc.).</p>
              <p class=" textcard  mt-4"><b>L’Utilisateur comprend et accepte qu’il n’a aucun droit à être sélectionné pour une Campagne publicitaire donnée, et qu’INFLUENCE LAB n’a aucune obligation de lui adresser des Offres.</b></p>
              <p class=" textcard  mt-4">INFLUENCE LAB n’est pas non plus tenue d’émettre une Offre à un quelconque nombre minimum d’Utilisateurs pour une Campagne publicitaire donnée.</p>
              <p class=" textcard  mt-4">L’Utilisateur peut seulement, en vue de maximiser ses chances d’être sélectionné, compléter au mieux son « profil Utilisateur » sur la Plateforme, et s’efforcer d’améliorer ses indicateurs de visibilité et d’engagement sur les Réseaux sociaux.</p>
          <h4 class="mt-4"><b>3.2 Offres</b></h4>
          <p class=" textcard  mt-4">Pour une Campagne publicitaire donnée, INFLUENCE LAB adressera à l’Utilisateur (aux Utilisateurs) sélectionné(s) une Offre, en vue de permettre à ce(s) dernier(s) de participer à la Campagne publicitaire.</p>
          <p class=" textcard  mt-4">L’Offre comprendra les détails de la Campagne publicitaire (dates de la Campagne publicitaire ; nature des produits/services/image de marque à promouvoir ; Réseau(x) social(ux) concerné(s)), les Contenus Annonceur à publier ou relayer (le cas échéant), les conditions spécifiques de mise à disposition des produits et matériels fournis pour les besoins de la Campagne publicitaire (le cas échéant), ainsi que l’indication de l’Objectif, du Maximum et du barème de calcul de la Rémunération. Elle pourra également inclure un certain nombre d’éléments et/ou de consignes devant obligatoirement être intégrés et/ou pris en compte dans les Contenus Utilisateurs pour la Campagne publicitaire concernée « Must include ».</p>
          <p class=" textcard  mt-4">L’Objectif pourra être exprimé en fonction des indicateurs de performance suivants, relatifs aux Contenus Utilisateur :</p>
          <li class="textcard mt-4">Nombre d’impressions ;</li>
          <li class="textcard mt-4">Nombre d’engagements (clic, commentaire, partage, like) ;</li>
          <li class="textcard mt-4">Nombre de vues de vidéo (visionnage en intégralité), si le Contenu Utilisateur est une vidéo ;</li>
          <li class="textcard mt-4">Nombre de Contenus Utilisateur (vidéos, post image + texte, article, « insta-story », etc.).</li>
          <p class=" textcard  mt-4">L’Offre sera assortie d’un délai pour son acceptation par l’Utilisateur. Passé ce délai, l’Offre sera réputée caduque et l’Utilisateur n’aura plus aucun droit de se prévaloir de l’Offre, ni de participer d’une quelconque façon à la Campagne publicitaire.</p>
          <p class=" textcard  mt-4">Les éléments indiqués dans l’Offre ont valeur contractuelle et engagent l’Utilisateur qui accepte l’Offre. Ils ne pourront être ni négociés ni modifiés, que ce soit avant ou après l’acceptation de l’Offre par l’Utilisateur.</p>
          <h4 class="mt-4"><b>3.3. Déroulement de la Campagne publicitaire</b></h4>
          <p class="textcard mt-4">L’Utilisateur qui accepte l’Offre s’engage à contribuer à la Campagne publicitaire conformément aux conditions fixées par les CGU, le présent Contrat Influenceur et l’Offre telle qu’acceptée.</p>
          <p class="textcard mt-4">Pour ce faire, l’Utilisateur devra (i) publier des Contenus Utilisateur qu’il aura lui-même créés, et/ou (ii) publier et/ou relayer les Contenus Annonceur qui lui auront été spécifiquement communiqués par INFLUENCE LAB, le cas échéant, le tout sur le(s) Réseau(x) social(ux) visé(s) dans l’Offre.</p>
          <p class="textcard mt-4">Les Contenus Utilisateur devront impérativement être publiés depuis la Plateforme, qui contient une interface dédiée à cet effet. Les contenus publiés directement sur les Réseaux sociaux, sans passer par la Plateforme, ne pourront pas être pris en compte pour le calcul des Rémunérations, de même que les contenus publiés en-dehors des dates de la Campagne publicitaire telles que stipulées dans l’Offre.</p>
          <p class="textcard mt-4">INFLUENCE LAB se réserve le droit de valider les Contenus Utilisateur et/ou de les faire valider par l’Annonceur concerné, préalablement à leur publication effective sur les Réseaux sociaux.</p>
          <p class="textcard mt-4">L’Utilisateur comprend et accepte que la Campagne publicitaire pourra être interrompue, suspendue, reportée ou annulée en tout ou partie, au seul choix de l’Annonceur concerné, y compris à tout moment en cours de Campagne publicitaire. Dans une telle hypothèse, INFLUENCE LAB en notifiera par écrit les Utilisateurs ayant reçu et accepté l’Offre concernée, et seuls les Contenus Utilisateur effectivement publiés avant la date et l’heure d’envoi de cette notification pourront être pris en compte pour le calcul des Rémunérations. Le cas échéant, INFLUENCE LAB notifiera également par écrit les mêmes Utilisateurs de la reprise de la Campagne publicitaire, en indiquant les éventuelles modifications des modalités de l’Offre ; les Contenus Utilisateur publiés entre la date et l’heure d’envoi de la notification d’interruption/suspension/report/annulation et la date et l’heure d’envoi de la notification de reprise ne seront pas pris en compte dans le calcul des Rémunérations.</p>
          <p class="textcard mt-4">De même, l’Utilisateur comprend et accepte que les modalités de l’Offre pourront être modifiées en tout ou partie, au seul choix de l’Annonceur concerné, y compris à tout moment en cours de Campagne publicitaire. Dans une telle hypothèse, INFLUENCE LAB en notifiera par écrit les Utilisateurs ayant reçu et accepté l’Offre concernée, et les Contenus Utilisateur seront évalués, pour le calcul des Rémunérations, au regard des modalités de l’ancienne version ou de la nouvelle version de l’Offre respectivement, selon qu’ils ont été effectivement publiés antérieurement ou postérieurement à la date et à l’heure d’envoi de cette notification.</p>
          <h4 class="text-left mt20"><b>3.4. Paiement et versement des Rémunérations</b></h4>
          <p class="textcard text-left mt10">Au terme de la Campagne publicitaire (tel que stipulé dans l’Offre ou résultant d’une annulation ou d’une interruption de la Campagne publicitaire au choix de l’Annonceur), l’Utilisateur verra son Compte Utilisateur crédité du montant de sa Rémunération, calculé selon la méthode décrite à l’Article 4 ci-après.</p>
          <p class="textcard text-left mt10">En toute hypothèse, la Rémunération afférente à une Campagne publicitaire donnée ne pourra être créditée sur le Compte Utilisateur de l’Utilisateur concerné qu’à compter du parfait paiement de cette Campagne publicitaire par l’Annonceur concerné.</p>
          <p class="textcard text-left mt10">L’Utilisateur pourra procéder au retrait des Rémunérations créditées sur son Compte Utilisateur lorsque le total de ces Rémunérations excédera un montant de cent (100) euros. Pour ce faire, il devra saisir sur la Plateforme un relevé d’identité bancaire (RIB) valable, correspondant au compte bancaire sur lequel il souhaite recevoir les Rémunérations. Il est entendu que l’Utilisateur est seul responsable de la validité et de l’exactitude du RIB qu’il communique à INFLUENCE LAB via la Plateforme.</p>
          <p class="textcard text-left mt10">En acceptant le present Contrat, l'Utilisateur s'engage sur l'honneur être à jour de ses cotisations et charges sociales et patronales.</p>
          <p class="textcard text-left mt10">L'Utilisateur s'engage à fournir les attestations nécessaires sur simple demande ou en cas d'audit par Influence Lab, sous 5 jours ouvrés (ex de documents : Kbis de moins de 3 mois ; Attestation de vigilance URSSAF ; Attestation de Régularité fiscale).</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">4) Calcul des Rémunérations</h1>
              <p class=" textcard  mt-4">Le calcul de la Rémunération de l’Utilisateur, pour une Campagne publicitaire donnée, s’effectuera comme suit :</p>
              <li class="textcard mt-4"><b>Si l’Utilisateur a atteint ou dépasse l’Objectif,</b> la Rémunération créditée sur son Compte Utilisateur sera égale au Maximum. L’Utilisateur comprend et accepte en conséquence que même en cas de dépassement de l’Objectif, il ne pourra prétendre à une Rémunération supérieure au Maximum.</li>
              <li class="textcard mt-4"><b>Si l’Utilisateur n’a pas atteint l’Objectif,</b> la Rémunération sera calculée en fonction des performances effectives des Contenus Utilisateur, selon le barème de calcul fixé dans l’Offre.</li>
              <p class=" textcard  mt-4">Pour l’évaluation des performances effectives des Contenus Utilisateur (et partant pour évaluer si l’Utilisateur a atteint ou dépassé l’Objectif), il est entendu que les statistiques affichées sur la Console Analytics feront foi.</p>
              <p class=" textcard  mt-4">L’Utilisateur s’engage à ce titre à octroyer à INFLUENCE LAB un accès aux statistiques (analytics) de ses différent(e)s comptes et pages de Réseaux sociaux utilisé(e)s dans le cadre des Campagnes publicitaires, afin de permettre à INFLUENCE LAB de collecter et d’afficher via la Console Analytics les performances des Contenus Utilisateur publiés par l’Utilisateur sur ces différent(e)s comptes et pages. Cet accès est impérativement octroyé via la Plateforme lors de la création du Compte Utilisateur ou de l’ajout d’un(e) nouveau(lle) page ou compte de Réseau social à ce Compte Utilisateur, par le biais d’une interface applicative (API) mise en place par INFLUENCE LAB.</p>
              <p class=" textcard  mt-4">Il est entendu qu’en cas de refus, de retrait, de blocage ou de restriction (volontaire ou non) de cet accès, l’Utilisateur ne pourra percevoir aucune Rémunération, faute pour INFLUENCE LAB de pouvoir vérifier les performances effectives des Contenus Utilisateur.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
          <h1 class="text-center logo">5)	Obligations des Parties</h1>
          <h4 class="mt-4"><b>5.1 Obligations d’INFLUENCE LAB</b></h4>
          <p class=" textcard  mt-4">INFLUENCE LAB s’engage à mettre à disposition la Plateforme dans les conditions indiquées dans les CGU, et à procéder au versement des Rémunérations sur le compte indiqué par l’Utilisateur lorsque ce versement est demandé par l’Utilisateur conformément à l’Article 3.4 ci-avant.</p>
          <p class=" textcard  mt-4">La Plateforme permet notamment aux Utilisateurs de :</p>
          <li class=" textcard  mt-4">Publier des Contenus Utilisateur sur les Réseaux sociaux depuis une interface dédiée ;</li>
          <li class=" textcard  mt-4">Suivre les performances de leurs Contenus Utilisateur via la Console Analytics ;</li>
          <li class=" textcard  mt-4">Demander le versement des Rémunérations dans les conditions décrites à l’Article 3.4 ci-avant.</li>
          <h4 class="mt-4"><b>5.2 Obligations de l’Utilisateur</b></h4>
          <p class=" textcard  mt-4">L’Utilisateur déclare et garantit avoir dûment procédé à son inscription auprès de tous organismes nécessaires afin de pouvoir licitement (i) exercer l’activité d’influenceur, (ii) conclure le Contrat Influenceur et les CGU, et (iii) facturer et recevoir des Rémunérations. Il déclare et garantit en outre être à jour de l’ensemble des cotisations et autres versements obligatoires à l’égard de ces organismes.</p>
          <p class=" textcard  mt-4">Lorsqu’il a accepté une Offre, l’Utilisateur s’engage à participer activement à la Campagne publicitaire concernée en publiant des Contenus Utilisateur de quantité et de qualité suffisantes, en tenant compte des modalités de la Campagne publicitaire telles que stipulées dans l’Offre. En particulier, l’Utilisateur s’engage à intégrer les éléments et tenir compte des consignes stipulées sous la section « Must include » de l’Offre, ce au sein de chacun des Contenus Utilisateur publiés dans le cadre de la Campagne publicitaire concernée.</p>
          <p class=" textcard  mt-4">Sauf demande contraire expresse d’INFLUENCE LAB ou de l’Annonceur concerné, l’Utilisateur s’engage à laisser les Contenus Utilisateur en ligne, de façon publiquement accessible et tels exactement qu’ils ont été initialement mis en ligne, pendant une durée minimale de trois (3) mois à compter de leur mise en ligne. Ce paragraphe ne s’applique pas aux Contenus Utilisateur par nature temporaires tels que les « insta-stories ».</p>
          <p class=" textcard  mt-4">En acceptant une Offre, l’Utilisateur déclare et certifie n’avoir aucun lien d’intérêt d’une quelconque nature avec un quelconque concurrent de l’Annonceur concerné, et plus généralement n’être pris dans aucun conflit d’intérêts d’aucune sorte en lien avec l’Annonceur concerné. L’Utilisateur s’engage à promouvoir loyalement les produits, services et image de marque de l’Annonceur, dans le souci constant des meilleurs intérêts de l’Annonceur.</p>
          <p class=" textcard  mt-4">L’Utilisateur garantit que les Contenus Utilisateur qu’il publie ou relaie sont licites et ne portent atteinte à aucun droit d’aucun tiers, en ce compris, mais sans s’y limiter, tous droits d’auteur, droits de marques, dessins et modèles et/ou droits de la personnalité.</p>
          <p class=" textcard  mt-4">Le cas échéant, l’Utilisateur garantit disposer de tous les droits et autorisations nécessaires pour publier ou relayer les Contenus Utilisateur, et s’engage à tenir à disposition d’INFLUENCE toutes preuves écrites nécessaires pour établir ces droits et autorisations, et à les lui fournir à première demande.</p>
          <p class=" textcard  mt-4">De manière générale, l’Utilisateur s’engage à ne publier aucun contenu susceptible de porter atteinte aux intérêts ou à l’image de marque d’un Annonceur, en ce compris (mais sans que cette liste soit limitative) :</p>
          <li class=" textcard  mt-4">Des contenus injurieux, diffamatoires, haineux ou autrement illicites ;</li>
          <li class=" textcard  mt-4">Des contenus portant atteinte aux droits d’un tiers, en ce compris notamment des droits de propriété intellectuelle ou des droits de la personnalité ;</li>
          <li class=" textcard  mt-4">Des contenus à caractère pornographique ou incluant de la nudité ;</li>
          <li class=" textcard  mt-4">Des contenus à caractère polémique ou politique ;</li>
          <li class=" textcard  mt-4">Des contenus constitutifs de dénigrement ou dépréciant sous une forme quelconque l’image de marque de l’Annonceur ;</li>
          <li class=" textcard  mt-4">Des contenus relatifs aux produits, services ou activités d’un concurrent de l’Annonceur ;</li>
          <li class=" textcard  mt-4">Des contenus trompeurs ou dolosifs à l’égard des qualités réelles des produits, services et activités de l’Annonceur.</li>
          <p class=" textcard  mt-4">L’Utilisateur s’engage à informer sans délai INFLUENCE LAB de toute difficulté rencontrée ou constatée dans le cadre de la Campagne publicitaire, en particulier dans l’hypothèse où les contenus publiés par l’Utilisateur (Contenus Utilisateur et/ou Contenus Annonceur) donneraient lieu à des réactions négatives, polémiques, abusives, injurieuses, dénigrantes ou diffamatoires de la part du public (« bad buzz »), ou de façon plus générale à toutes réponses ou réactions de ce public susceptibles de nuire à l’image de marque et/ou à la réputation d’INFLUENCE LAB ou de l’Annonceur concerné. Dans une telle situation, l’Utilisateur s’engage à se conformer exactement et sans délai aux instructions qui lui seraient données par INFLUENCE LAB, et en toute hypothèse à faire ses meilleurs efforts pour faire cesser ou à tout le moins limiter l’impact de la situation, y compris en modérant les commentaires abusifs ou illicites.</p>
          <p class=" textcard  mt-4">Dans l’hypothèse où la Campagne publicitaire impliquerait la mise à disposition de produits et/ou de matériels entre les mains de l’Utilisateur (par exemple dans le cadre d’une Campagne publicitaire centrée sur des vidéos de test des produits de l’Annonceur), l’Utilisateur s’engage :</p>
          <li class=" textcard  mt-4">A ne pas utiliser ces produits et/ou matériels à d’autres fins que les besoins de la Campagne publicitaire, étant entendu que la mise à disposition n’emporte aucun transfert de propriété ou d’un quelconque droit réel au profit de l’Utilisateur ;</li>
          <li class=" textcard  mt-4">A conserver à tout moment de la mise en disposition les produits et/ou matériels sous sa garde et son contrôle personnels et exclusifs ;</li>
          <li class=" textcard  mt-4">A restituer à INFLUENCE LAB l’intégralité des produits et matériels en question (hors consommables), incluant leur emballage d’origine, propres et vierges de toutes détériorations, sous trente (30) jours calendaires maximum à compter de la date de fin de la Campagne publicitaire telle qu’indiquée dans l’Offre ; et</li>
          <li class=" textcard  mt-4">A respecter l’ensemble des conditions spécifiques de mise à disposition qui seront indiquées dans l’Offre, le cas échéant.</li>
          <p class=" textcard  mt-4">L’Utilisateur certifie par ailleurs disposer de la capacité d’émettre des factures et d’en recevoir paiement, dans le respect des conditions légales et réglementaires applicables.</p>
          <p class=" textcard  mt-4">L’Utilisateur garantit l’ensemble de ses engagements et déclarations stipulés au présent Article 5.2 et à l’Article 6 ci-après en s’engageant à tenir INFLUENCE LAB indemne de toute réclamation, contestation, plainte ou action de toute nature introduite par un quelconque tiers, ainsi que de toute sanction de toute nature d’une quelconque autorité ou juridiction, lorsque cette réclamation, contestation, plainte, action ou sanction aurait pour fondement ou pour origine le non-respect de ses obligations par l’Utilisateur. Cette garantie couvre notamment (mais sans s’y limiter), outre le montant des condamnations pécuniaires, l’ensemble des frais supportés par INFLUENCE LAB pour la défense de ses intérêts, tels que des frais d’assistance juridique et/ou de représentation en justice.</p>
          <p class=" textcard  mt-4">Il est entendu que les garanties précédentes ne concernent pas les Contenus Annonceur publiés ou relayés par l’Utilisateur, dans la mesure où ces derniers sont créés et/ou validés par les Annonceurs, sous réserve toutefois pour l’Utilisateur (i) de ne modifier aucune partie des Contenus Annonceur concernés, (ii) de n’en retirer aucune partie et de n’y ajouter aucun élément supplémentaire, y compris par voie de mise en contexte, et (iii) de les publier ou de les relayer dans des conditions exemptes de tout risque de dénaturation de leur contenu ou de leur message.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">6) Cession de droits d’auteur</h1>
              <p class=" textcard  mt-4">En publiant un Contenu Utilisateur via la Plateforme, l’Utilisateur consent à céder à INFLUENCE LAB l’ensemble des droits de reproduction et droits de représentation afférents à ce Contenu Utilisateur.</p>
              <p class=" textcard  mt-4">Par « droits de reproduction », est entendu le droit de copier, imiter, modifier, transposer, traduire, adapter, augmenter, couper, intégrer, numériser, dupliquer et/ou imprimer tout ou partie du Contenu Utilisateur, par tous moyens connus ou inconnus à la date du présent Contrat Influenceur.</p>
              <p class=" textcard  mt-4">Par « droits de représentation », est entendu le droit de publier, projeter, diffuser, mettre en ligne, mettre à disposition, partager, relayer, communiquer ou envoyer tout ou partie du Contenu Utilisateur, auprès de tout tiers et de tout public, par tous moyens connus ou inconnus à la date du présent Contrat Influenceur.</p>
              <p class=" textcard  mt-4">La cession est consentie à titre exclusif et à titre gratuit au profit d’INFLUENCE LAB, pour une durée de cinq (5) ans, pour tous pays et territoires, et pour tous supports connus ou inconnus à la date du présent Contrat Influenceur. Elle inclut le droit de céder ou de licencier tout ou partie des droits cédés à tout tiers à la seule discrétion d’INFLUENCE LAB.</p>
              <p class=" textcard  mt-4">L’Utilisateur garantit disposer de tous droits et autorisations nécessaires pour consentir valablement à cette cession, et s’engage à apporter toute preuve écrite permettant d’établir ces droits et autorisations à première demande d’INFLUENCE LAB</p>
              <p class=" textcard  mt-4">Il est entendu que la présente cession de droits ne concerne pas les Contenus Annonceur publiés ou relayés par l’Utilisateur, dans la mesure où ces derniers sont créés et/ou validés par les Annonceurs, sous réserve toutefois pour l’Utilisateur (i) de ne modifier aucune partie des Contenus Annonceur concernés, et (ii) et de n’y ajouter aucun élément supplémentaire, y compris par voie de mise en contexte. Dans l’hypothèse où l’une quelconque au moins des conditions (i) ou (ii) ne serait pas remplie, l’Utilisateur consent à céder tous droits visés aux précédents paragraphes relativement aux éléments modifiés ou ajoutés au Contenu Annonceur par ses soins.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">7) Responsabilité</h1>
              <p class=" textcard  mt-4">L’Utilisateur demeurera responsable de plein droit du respect de ses obligations au titre du Contrat Influenceur, quand bien même il en déléguerait l’exécution en tout ou partie à un quelconque tiers, et/ou se ferait assister par un quelconque tiers pour leur exécution.</p>
              <p class=" textcard  mt-4">L’Utilisateur déclare disposer de toutes polices d’assurance en cours de validité, nécessaires pour couvrir et réparer les préjudices causés par l’inexécution ou la mauvaise exécution de ses obligations.</p>
              <p class=" textcard  mt-4">INFLUENCE LAB ne pourra en aucun cas être tenue responsable des conséquences des décisions prises par les Annonceurs affectant le déroulement de la Campagne publicitaire, en ce compris toutes décisions d’annulation, de suspension, d’interruption ou de report de la Campagne publicitaire, ainsi que toutes décisions de modification des modalités de la Campagne publicitaire telles que stipulées dans l’Offre. L’Utilisateur comprend et accepte en conséquence qu’il ne pourra poursuivre ou exiger aucun dédommagement ou indemnisation d’aucune nature auprès d’INFLUENCE LAB, dans le cas où une décision de l’Annonceur lui causerait un quelconque grief, dommage ou préjudice, en ce compris tous manques à gagner ou pertes de chance, et ce quelle que soit la cause ou la raison de la décision de l’Annonceur.</p>
          </div>
        </div>
      </section>
      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">8) Indépendance et non-concurrence</h1>
              <p class=" textcard  mt-4">Aucune clause du présent Contrat Influenceur ou des CGU ne saurait être interprétée comme instituant un quelconque lien de représentation entre l’Utilisateur et INFLUENCE LAB.</p>
              <p class=" textcard  mt-4">De même, il est précisé qu’INFLUENCE LAB n’agit à aucun moment comme le mandataire ou le représentant d’un quelconque Annonceur.</p>
              <p class=" textcard  mt-4">INFLUENCE LAB agit en effet en qualité de régie au sens de la loi n°93-122 du 29 janvier 1993 relative à la prévention de la corruption et à la transparence de la vie économique et des procédures publiques (dite « loi Sapin »), en recherchant des Annonceurs pour leur proposer à la vente les espaces publicitaires des Utilisateurs (les Contenus Utilisateur).</p>
              <p class=" textcard  mt-4">L’Utilisateur, les Annonceurs et INFLUENCE LAB agissent ainsi en leurs noms propres et pour leurs propres comptes, et déterminent librement la conduite de leurs intérêts en tant que personnes autonomes, dans le respect des CGU et du Contrat Influenceur.</p>
              <p class=" textcard  mt-4">Sans préjudice de ce qui précède, l’Utilisateur s’interdit expressément de contracter avec l’Annonceur ou de recevoir un quelconque paiement ou avantage de ce dernier pour des prestations équivalentes, similaires ou associables à celles réalisées dans le cadre du présent Contrat Influenceur, que ce soit directement ou par personne interposée (autre qu’INFLUENCE LAB), pendant une durée de trois (3) ans à compter de la fin de la Campagne publicitaire concernée.</p>
            </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">9)	Intégralité</h1>
              <p class=" textcard  mt-4">Le Contrat Influenceur, ensemble les CGU et les Offres éventuellement acceptées par l’Utilisateur, forment et reflètent l’intégralité de l’accord des Parties quant à leur objet.</p>
              <p class=" textcard  mt-4">Tous autres documents, échanges ou messages écrits ou oraux sont donc expressément exclus du champ contractuel, sauf à avoir pris la forme d’un avenant postérieur à la date d’entrée en vigueur des CGU et du Contrat Influenceur, dûment signé par l’ensemble des Parties.</p>
            </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">10)	Entrée en vigueur – Durée – Résiliation </h1>
              <p class=" textcard  mt-4">Le Contrat Influenceur est conclu pour une durée indéterminée, à compter de la date de son acceptation par l’Utilisateur, manifestée par l’inscription de ce dernier sur la Plateforme.</p>
              <p class=" textcard  mt-4">Il peut être résilié à tout moment par l’Utilisateur, en supprimant son Compte Utilisateur conformément à l’Article 10.1 des CGU.</p>
              <p class=" textcard  mt-4">INFLUENCE LAB dispose quant à elle du droit de suspendre et/ou supprimer le Compte Utilisateur de l’Utilisateur dans les conditions décrites à l’Article 10.2 des CGU, étant entendu que la suppression du Compte Utilisateur vaut résiliation des CGU et du Contrat Influenceur à l’égard de l’Utilisateur concerné, aux torts exclusifs de ce dernier.</p>
            </div>
        </div>
      </section>
      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">11) Droit applicable et juridiction compétente</h1>
              <p class=" textcard  mt-4">Dans l’hypothèse où (i) l’Utilisateur est un professionnel utilisant le Site et/ou la Plateforme à des fins entrant dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole, ou représente un tel professionnel, ainsi que dans l’hypothèse où (ii) l’Utilisateur serait un ressortissant ou serait domicilié sur le territoire d’un autre Etat que la France, toute contestation, différend, réclamation ou litige concernant l’existence, la validité, l’interprétation ou l’exécution du Contrat Influenceur sera soumis(e) au droit français, et tranché(e) par les juridictions compétentes du ressort de Paris, nonobstant toute pluralité de défendeurs et tout appel en garantie.</p>
            </div>
        </div>
      </section>




      <!-- Footer -->
      <footer class="bg-black small text-center text-white-50">
        <div class="container-fluid">
          <span>Copyright &copy; Influence Lab</span>
          <a href="/legal">
            <span> Legal</span>
          </a>
        </div>
      </footer>
  </body>
</html>

