<html>
    <body >
        <div *ngIf="loadInfluencers" class="page container-fluid">
          <div class="row mb-3">
            <input type="text" style="font-family: Open Sans,Helvetica,Arial,sans-serif,FontAwesome;" class="form-control text-input col-8 col-xl-3 col-lg-3 mb-1" name="" [(ngModel)]="tmp" (change)="findInfluenceur()" placeholder="&#xf002; Search influencer">
        </div>
          <div class="row">
            <div class="tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
            
              <table class="table table-hover tableTitle text-center">
                <thead>
                    <tr class="tableTitle ">
                      <th [appSort]="dataSourceInfluencers" data-order="desc" data-name="status" class="col-1"scope="col">Status</th>
                      <th class="col-1" scope="col">Picture</th>
                      <th [appSort]="dataSourceInfluencers" data-order="desc" data-name="influencerInfo.firstname" class="col-1"scope="col">First Name </th>
                      <th class="col-1"scope="col">Last Name </th>
                      <th class="col-1"scope="col">Email </th>
                      <th class="col-1"scope="col">Engagement Rate</th>
                      <th class="col-1"scope="col">Rating </th>
                      <th class="col-1" scope="col">Keywords </th>
                      <th class="col-1"scope="col">Creation Date  </th>
                      <th class="col-1"scope="col">Action </th>
                    </tr>
                  </thead>
                <tbody  class="bodyTable">
                  <tr   *ngFor = "let influencer of dataSourceInfluencers | filter:term | paginate: { itemsPerPage: count, currentPage: p, totalItems: total }">
                    <td class="col-1 text-center">
                      <label class="labelCustom mt5" [ngStyle]="{'background-color':fctBasic.getColorByUserStatus(influencer.valid)}" > {{fctBasic.getNameByUserStatus(influencer.valid)}}</label>
                    </td>
                    <td class="col-1 text-center">
                      <img *ngIf="influencer.influencerInfo.picture" [defaultImage]="'assets/images/logo/profile-default.jpeg'" [errorImage]="'assets/images/logo/profile-default.jpeg'"  [lazyLoad]="fctBasic.checkUrlPath(influencer.influencerInfo.picture)"  style="height: 75px; width: 75px; border-radius: 50px;">
                      <img *ngIf="!influencer.influencerInfo.picture" src="assets/images/logo/profile-default.jpeg" style="height: 75px; width: 75px; border-radius: 50px;">
                    </td>
                    <td class="col-1 text-center">
                      {{influencer.influencerInfo.firstname}}
                    </td>
                    <td class="col-1 text-center">
                      {{influencer.influencerInfo.lastname}}
                    </td>
                    <td class="col-1 text-center">
                      <div *ngIf="influencer.influencerInfo.secondarymail && influencer.influencerInfo.secondarymail != ''; else elseBlock">{{influencer.influencerInfo.secondarymail}}</div>
                      <ng-template #elseBlock>
                        <div>{{influencer.email}}</div>
                      </ng-template>
                    </td>
                    <td class="col-1 text-center">
                      <div>{{influencer.engagementRate}} %</div>
                    </td>
                    <td class="col-1 text-center">
                      <p *ngIf="influencer.rating"><ng-rating-bar  colorActive="rgb(86, 86, 86)" [styles]="{fontSize: '13px'}" [value]="listceil(influencer.rating)" [ratingCount]="5" [disabled]=true  ></ng-rating-bar></p>
                      <p *ngIf="!influencer.rating"><ng-rating-bar  colorActive="rgb(86, 86, 86)" [styles]="{fontSize: '13px'}" [value]="0" [ratingCount]="5" [disabled]=true  ></ng-rating-bar></p>
                    </td>
                    
                    <td class="col-1 text-center">
                      <div *ngFor="let key of influencer.influencerInfo.keywords | slice:0:3">{{key}}</div>
                    </td>
                    <td class="col-1 text-center">
                      {{influencer.influencerInfo.creationDate | date:'yyyy/MM/dd'}}
                    </td>
                    <td class="col-1 text-center">
                      <button (click)=openInfluencerInNewWindow(influencer._id) type="button" style="font-size: smaller;" class="btn btn-secondary"><span class="fa fa-search"></span></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination">
                <pagination-controls (responsive)=respTrue  (pageChange)="paginateInfluencer($event)" previousLabel = "" nextLabel = ""></pagination-controls>
              </div>
            </div>
          </div>
          
        </div>
    </body>
</html>

