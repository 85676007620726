import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import {toast} from 'src/app/model/toast'
@Component({
  selector: 'app-create-contact',
  templateUrl: './create-contact.component.html',
  styleUrls: ['./create-contact.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css']
})
export class CreateContactComponent implements OnInit {
  contact: FormGroup;
  toast = toast
  isDisabled = true
  isDisabledPassword = false
  firstname = new FormControl("")
  lastname = new FormControl("", [Validators.required])
  fonction = new FormControl("")
  email = new FormControl("", [Validators.required]);
  companyName = new FormControl("", [Validators.required])
  phone = new FormControl();
  phone2 = new FormControl()
  adress = new FormControl("")
  town = new FormControl("")
  country = new FormControl("")
  company = new FormControl("")
  zipCode = new FormControl()
  contactUserId = new FormControl()
  aditionalInformation = new FormControl("")
  creationDate = new FormControl(new Date())
  fax = new FormControl();
  password  = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  listAdvertiser : any
  newCompany : any

  constructor(
    private fb: FormBuilder, 
    public api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private cdRef:ChangeDetectorRef
    ) {
    
   }

  ngOnInit(): void {
    this.contact = this.fb.group({
      firstname: this.firstname,
      lastname: this.lastname,
      fonction: this.fonction,
      email: this.email,
      company: this.companyName,
      phone: this.phone,
      phone2: this.phone2,
      fax: this.fax,
      adress: this.adress,
      zipCode: this.zipCode,
      town: this.town,
      country: this.country,
      aditionalInformation: this.aditionalInformation,
      creationDate: this.creationDate,
      contactUserId: this.contactUserId,
      password: this.password,
    })
    this.api.getCompanies().subscribe(company => {
      this.listAdvertiser = company
    })
  }

  async checkUserContact(){
    if (this.contact.value["email"]){
      let newuserContact = {
        "username": this.contact.value["email"],
        "password": this.contact.value["password"],
      };
      this.api.createUserContact(newuserContact).subscribe(res => {
        if (res && res.err){
          this.toastr.warning(res.err)
          return
        }
        this.contact.value["contactUserId"] = res._id
        this.api.createContactWithUser(this.contact.value, this.newCompany._id).subscribe(res => {
          this.toastr.success(this.toast.contactCreated);
          this.router.navigate(['/list-contact'])
        })
      })
    } else {
      this.api.createContact(this.contact.value).subscribe(res => {
        this.toastr.success(this.toast.contactCreated);
        this.router.navigate(['/list-contact'])
      })
    }
  }

  setCompany(elem : any){
    this.newCompany = elem
  }

  async createCompanyOnDb(){
    if (this.contact.valid === true){
      this.checkUserContact()
    } else {
      this.toastr.warning(this.toast.InvalidEntry)
    }
  }

  clipboard(){
    this.toastr.success(this.toast.textCopied)
  }

}
