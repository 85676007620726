<html>
    <body >
        <div class="page container" >
            <div flex-center>
                <button (click)="modify()" mat-button class="btn btn-sm mt-2 btn-secondary " >Modify</button>
            </div>
            <mat-card class="mt-3" >
                <div class="title-card" text-center>
                    <div>
                        <mat-label class="title">🤗 Your Account 🚀</mat-label>
                    </div>
                </div>
                <mat-divider ></mat-divider>
                <mat-card-content class=" mt-4">
                    <form *ngIf="init" class="align-items-center" [formGroup]="influenceur" >
    
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-6 col-sm-12   ">
                                <div class="col-md-12 col-lg-12  display-mid">
                                    <div class="col-lg-4   col-md-4 ">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">First Name *</mat-label>
                                    </div>
                                    <div class="col-lg-8   col-md-8 ">
                                        <mat-form-field >
                                            <input  matInput class="disabledtext" required  formControlName="firstname">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6  col-lg-6   col-sm-12">
                                <div class="col-md-12 display-mid  col-lg-12">
                                    <div class="col-lg-4   col-md-4 ">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Last Name  *</mat-label>
                                    </div>
                                    <div class="col-lg-8  col-md-8 ">
                                        <mat-form-field >
                                            <input matInput class="disabledtext" required formControlName="lastname">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Contact Email  *</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field >
                                            <input matInput  class="disabledtext" formControlName="secondarymail"  required>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Phone Number *</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field>
                                            <input matInput class="disabledtext" mask="00 00 00 00 00"  formControlName="phone">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Address  *</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field >
                                            <input matInput  class="disabledtext" required   formControlName="adress">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Post Code *</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field>
                                            <input matInput class="disabledtext" required   formControlName="postcode">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">City *</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field >
                                            <input matInput class="disabledtext" required formControlName="city">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Country *</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field >
                                            <mat-select formControlName="country">
                                                <mat-option  *ngFor="let country of countries" [value]="country">
                                                    {{ country }}
                                                </mat-option>
                                            </mat-select>
                                            <input matInput class="disabledtext" required placeholder="" >                            
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Gender</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field>
                                            <mat-select formControlName="gender">
                                                <mat-option [value]="'male'">
                                                    Homme
                                                </mat-option>
                                                <mat-option [value]="'female'">
                                                    Femme
                                                </mat-option>
                                            </mat-select>
                                            <input [disabled]="this.isDisabled" matInput placeholder=""  >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Birth Date</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field  >
                                            <input matInput [matDatepicker]="picker"  formControlName="birthday">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Main Language *</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field>
                                            <mat-select required formControlName="language">
                                                <mat-option>None</mat-option>
                                                <mat-option *ngFor="let language of languages" [value]="language">
                                                    {{ language }}
                                                </mat-option>
                                            </mat-select>
                                            <input matInput class="disabledtext" [disabled]="this.isDisabled"  placeholder=""  >
                                        </mat-form-field>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Other Languages</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field >
                                            <input matInput  class="disabledtext" placeholder=""  formControlName="secondaryLanguage">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="row input-row paddingButton">
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4 textareapostion">
                                        <mat-label  [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Topics *</mat-label>
                                        <div class="textsecondary" >Please choose between 1 and 6 topics</div>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field >
                                            <mat-select required [disabled]="this.isDisabled"  formControlName="topics" multiple>
                                                <mat-select-trigger>
                                                </mat-select-trigger>
                                              <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                            </mat-select>
                                            
                                          </mat-form-field>
                                          
                                            <div  *ngFor="let topic of influenceur.value.topics; index as i" class="badge  mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                                {{ topic }} 
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Keywords *</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                            <mat-form-field  >
                                                <input [disabled]="this.isDisabled"   formControlName="valueArrTmp" matInput type="text" >
                                                <button [disabled]="this.isDisabled"  matSuffix mat-icon-button aria-label="Clear" (click)="addKeyWords()">
                                                  <mat-icon>add</mat-icon>
                                                </button>
                                            </mat-form-field>
                                            <div class="textsecondary" >#What describes you the best ?</div>
                                            <div class="textsecondary">Ex : Hiking ; mountain ; cabin ; wildlife ; wilderness ; outdoors ; natgeotravel</div>
                                            <div>
                                                <span  (click)="deleteKeyWords(i)"  *ngFor="let keyword of influenceur.value['keywords']; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                                    #{{ keyword }} x 
                                                </span>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Upload Profile Picture</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <mat-form-field>
                                            <ngx-mat-file-input [disabled]="this.isDisabled"  (change)="addPicture($event)"  ></ngx-mat-file-input>
                                            <mat-icon matSuffix>attach_file</mat-icon>
                                        </mat-form-field>
                                        <div class="col-lg-12 col-md-12 row" *ngIf="influenceur.value['picture']">
                                            <div class="imgrow">
                                                <div class="img_wrapper_inf">
                                                    <span (click)="deleteImg(influenceur.value['picture'])" class="close">&times;</span>
                                                    <img  [src]="picService.checkUrlPath(influenceur.value['picture'])" class="img-responsive center-block imgFeedMulti">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : 'labelFont'">Manage Networks</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <div>
                                            <a *ngIf="fbInit" href="{{fbUrl}}">
                                                <button type="button" style="background-color:#5285fb;color:white;" class="btn btn-sm col-lg-12 shadow-none"><i   class="fa mr-2 left fa-facebook "></i>Manage</button> 
                                                <button type="button" style="background-color:#8a3ab9;color:white;" class="btn btn-sm col-lg-12 mt-3 shadow-none"><i   class="fa mr-2 left fa-instagram "></i>Manage</button>
                                            </a>
                                            <a *ngIf="ytInit" href="{{ytUrl}}">
                                                <button type="button" style="background-color:#cc181e;color:white;" class="btn btn-sm col-lg-12 mt-3 shadow-none"><i   class="fa mr-2 left fa-youtube "></i>Manage</button> 
                                            </a>
                                            <form action="">
                                                <button (click)="moreSocialNetwork()" class="btn mt-3 btn-secondary col-lg-12 shadow-none " > <b>Go !</b> </button>
                                            </form>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div *ngIf="init" flex-center class="mt-2 mb-3">
                            <button  [disabled]="isDisabled" (click)="saveModification()" class="btn btn-secondaryWithBorder shadow-none " >Save</button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
            <mat-card class="register-card mt-3">
                <mat-card-content class=" mt-1">
                  <div class="tableResponsiveAnnex table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  ">
                      <div  class="panel-header-campaign ">
                          <div class="col-lg-12">
                            <div  class="labelFont">My Networks</div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                        <mat-divider class="dividerColor"></mat-divider>
                    </div>
                      <table  class="table table-hover  tableTitle  text-center">
                        <thead>
                            <tr class="tableTitle ">
                                <th class=" text-center"scope="col"> Network </th>
                                <th class=" text-center" scope="col">Community Size </th>
                                <th class=" text-center" scope="col"> Lieux principaux </th>
                                <th class=" text-center"scope="col"> Tranche d'age </th>
                                <th class=" text-center"scope="col">Genre</th>
                                <th class=" text-center"scope="col">Action</th>
                            </tr>
                          </thead>
                        <tbody class="bodyTable">
                            <tr *ngFor = "let network of currentUser.userObject">
                                <td *ngIf="network.facebookPage && network.facebookPage.token" class="text-center">
                                    <a *ngIf="network.facebookPage.link" target="blank" href="{{network.facebookPage.link}}"><span >{{network.facebookPage.name }}</span>
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                    <div *ngIf="!network.facebookPage.link">{{network.facebookPage.name}} </div>
                                    <div><i class="fa fa-facebook"></i></div>
                                    <div  class="fileText" *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.name">
                                        {{network.facebookPage.instagramObject.name }}
                                    </div>
                                    <div>
                                        <i *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.name" class="fa fa-instagram"></i>
                                    </div>
                                </td>
                                <td *ngIf="network.youtubePage" class=" bodyTable text-center">
                                    <a *ngIf="network.urlYtoutube" target="blank" href="{{network.urlYtoutube}}"><p  class="fileText">{{network.youtubePage.name }}</p></a>
                                    <p ng-click="yt(x)" *ngIf="!network.urlYtoutube">{{network.youtubePage.name}}</p>
                                <i class="fa fa-youtube"></i>
                                </td>
                                <td *ngIf="network.blogToken" class="text-center">
                                    <p >{{network.blogToken.url}}</p>
                                <i class="fa fa-bold"></i>
                                </td>
                                <td *ngIf="network.tiktokjson" class="text-center">
                                    <p >{{network.tiktokjson.url}}</p>
                                    <mat-icon>tiktok</mat-icon>
                                </td>
                                <td *ngIf="network.instagramUrl" class="text-center">
                                    <a  *ngIf="network.instagramUrl" target="blank" href="{{network.instagramUrl}}"><p  class="fileText">{{fctBasic.getInfluenceurNetwork(network.instagramUrl) }}</p></a>
                                    <i class="fa fa-instagram"></i>
                                </td>
                                <td class=" text-center">
                                    <p *ngIf="network.facebookPage && network.facebookPage.followers">{{network.facebookPage.followers | number}}</p>
                                    <p *ngIf="network.facebookPage && !network.facebookPage.followers">0</p>
                                    <p *ngIf="network.instagramUrl">0</p>
                                    <p *ngIf="network.tiktokjson">0</p>
                                    <p *ngIf="network.blogToken && network.blogToken.url">0</p>
                                    <p *ngIf="network.youtubePage && network.youtubePage.followers">{{network.youtubePage.followers | number}}</p>
                                    <p *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.followers">{{network.facebookPage.instagramObject.followers | number}}</p>
                                </td>
                                <td class=" text-center">
                                <apx-chart flex-center *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.audienceCountry; else elseBlock"
                                  [series]="network.facebookPage.instagramObject.audienceCountry.series"
                                  [chart]="chartOptions.chart"
                                  [labels]="network.facebookPage.instagramObject.audienceCountry.labels"
                                  [legend]="chartOptions.legend"
                                  [dataLabels]="chartOptions.dataLabels"
                                  [colors]="chartOptions.colors"
                                  [stroke]="chartOptions.stroke"
                              ></apx-chart>
                              <ng-template #elseBlock>
                                  <apx-chart flex-center
                                      [series]="[100]"
                                      [chart]="chartOptions.chart"
                                      [labels]="['N/A']"
                                      [legend]="chartOptions.legend"
                                      [dataLabels]="chartOptions.dataLabels"
                                      [colors]="chartOptions.colorsNA"
                                      [stroke]="chartOptions.stroke"
                                      [states]="chartOptions.states"
                                  ></apx-chart>
                                  </ng-template>
                                </td>
                                
                                <td class=" text-center">
                                  <apx-chart flex-center *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.audienceAge; else elseBlock"
                                  [series]="network.facebookPage.instagramObject.audienceAge.series"
                                  [chart]="chartOptions.chart"
                                  [labels]="network.facebookPage.instagramObject.audienceAge.labels"
                                  [legend]="chartOptions.legend"
                                  [dataLabels]="chartOptions.dataLabels"
                                  [colors]="chartOptions.colors"
                                  [stroke]="chartOptions.stroke"
                              ></apx-chart>
                                <ng-template #elseBlock>
                                  <apx-chart flex-center
                                      [series]="[100]"
                                      [chart]="chartOptions.chart"
                                      [labels]="['N/A']"
                                      [legend]="chartOptions.legend"
                                      [dataLabels]="chartOptions.dataLabels"
                                      [colors]="chartOptions.colorsNA"
                                      [stroke]="chartOptions.stroke"
                                      [states]="chartOptions.states"
                                  ></apx-chart>
                                  </ng-template>
                                </td>
                                <td class=" text-center">
                                  <apx-chart flex-center *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.audienceGender; else elseBlock"
                                      [series]="network.facebookPage.instagramObject.audienceGender.series"
                                      [chart]="chartOptions.chart"
                                      [labels]="network.facebookPage.instagramObject.audienceGender.labels"
                                      [legend]="chartOptions.legend"
                                      [dataLabels]="chartOptions.dataLabels"
                                      [colors]="chartOptions.colors"
                                      [stroke]="chartOptions.stroke"
                                  ></apx-chart>
                                <ng-template #elseBlock>
                                  <apx-chart flex-center
                                      [series]="[100]"
                                      [chart]="chartOptions.chart"
                                      [labels]="['N/A']"
                                      [legend]="chartOptions.legend"
                                      [dataLabels]="chartOptions.dataLabels"
                                      [colors]="chartOptions.colorsNA"
                                      [stroke]="chartOptions.stroke"
                                      [states]="chartOptions.states"
                                  ></apx-chart>
                                  </ng-template>
                                </td>
                                <td>
                                    <span style="font-size:20px;" class="fa fa-trash "></span>
                                </td>
                                
                            </tr>
                        </tbody>
                      </table>
                  </div>
              </mat-card-content>
          </mat-card>
        </div> 
    </body>
</html>