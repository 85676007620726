import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offer-data-input-influenceur',
  templateUrl: './offer-data-input-influenceur.component.html',
  styleUrls: ['./offer-data-input-influenceur.component.css']
})
export class OfferDataInputInfluenceurComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
