<html>
  <body>
    <div *ngIf="chartest" class="page container">
      <a  [routerLink]="['/detail-influencer/', networkInsightAccount._id]" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
    <mat-card class="mt-1">
      <div class="title-card" text-center>
        <div>
          <mat-label class="title">Account Statistics</mat-label>
      </div>
        <div>
            <mat-label class="title"><i>🤗 {{networkInsightAccount.influencerInfo.firstname}} 🚀</i></mat-label>
        </div>
    </div>
    </mat-card >
    <mat-divider></mat-divider>
    <mat-card   class="mt-4"  *ngFor="let insightNetwork of networkInsightAccount.userObject">
      <div *ngIf="chartest" class="title-card text-center  mb-3">
        <div flex-center class="col-lg-12  display-mid">
            <div class="col-lg-4 col-md-3 col-sm-3 col-3">
                <label class="col-12 disabledLabel text-center">{{insightNetwork.name}}<span class="ml-2 fa fa-rocket"></span></label>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-3 col-3">
                <label class="col-12 disabledLabel text-center">Followers<span class="ml-2 fa fa-rocket"></span></label>
                <div class="fileText  text-center">
                   {{insightNetwork.followers  | mask:"separator.2"}}
                </div>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-3 col-3">
                <label class="col-12 disabledLabel text-center">Social Network<span class="ml-2 fa fa-share-alt-square"></span></label>
                <div class=" text-center">
                    <button mat-mini-fab type="button"[ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(insightNetwork.status)}" style="color:white"  class="btn btn-sm shadow-none ">
                      <i  *ngIf="insightNetwork.status == 'Facebook'" class="fa  fa-facebook "></i>
                      <i *ngIf="insightNetwork.status == 'Instagram'" class="fa  fa-instagram "></i>
                      <i *ngIf="insightNetwork.status == 'Youtube'" class="fa fa-youtube "></i>
                      <i *ngIf="insightNetwork.status == 'Blog'" class="fa fa-bold "></i>
                      <i *ngIf="insightNetwork.status == 'multiNetwork'" class="fa fa-globe  "></i>
                    </button>
                    
                </div>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
        <mat-card-content *ngIf="chartest" class="mt-5">
            <div  flex-center class="row">
                <div (click)="prevIndexOnAccount(insightNetwork)" class="col-1 col-lg-1" style="display:flex;flex-direction:column; justify-content:center; ">
                    <mat-icon style="font-size:50px;">keyboard_arrow_left</mat-icon>
                </div>
                <div *ngIf="chartest && insightNetwork.indexDetail == 0"  class="col-5 col-lg-4" >
                    <div class="panel-header-nofont disabledLabel">
                        Top Cities <i class="fa ml-1 fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                        matTooltip="Period: lifetime 
                        Description: Cities of followers for whom we have demographic data" 
                        aria-label="Button that displays a tooltip when focused or hovered over"           
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                    </i>
                    </div>
                    <apx-chart *ngIf="networkInsightAccountBool"
                    [series]="[{name:'%', data: insightNetwork.audienceCity.series}]"
                    [chart]="charbart.chart"
                    [dataLabels]="charbart.dataLabels"
                    [plotOptions]="charbart.plotOptions"
                    [labels]="insightNetwork.audienceCity.labels"
                    [xaxis]="charbart.xaxisGeneral"
                    [grid]="charbart.grid"
                ></apx-chart>
                </div>
                <div *ngIf="chartest && insightNetwork.indexDetail == 0" class="col-6 col-lg-4" >
                    <div class="panel-header-nofont disabledLabel">
                        Top Countries <i class="fa ml-1 fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                        matTooltip="Period: lifetime 
                        Description: Countries of followers for whom we have demographic data." 
                        aria-label="Button that displays a tooltip when focused or hovered over"           
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                    </i>
                    </div>
                    <apx-chart 
                    [series]="[{name:'%', data: insightNetwork.audienceCountry.series}]"
                    [chart]="charbart.chart"
                    [dataLabels]="charbart.dataLabels"
                    [plotOptions]="charbart.plotOptions"
                    [labels]="insightNetwork.audienceCountry.labels"
                    [xaxis]="charbart.xaxisGeneral"
                    [grid]="charbart.grid"
                ></apx-chart>
                </div>
                <div *ngIf="chartest && insightNetwork.indexDetail == 1" class="col-6 col-lg-4" >
                    <div class="panel-header-nofont disabledLabel">
                        Top Age Ranges  <i class="fa ml-1 fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                        matTooltip="Period: lifetime 
                        Description: The gender and age distribution of followers for whom we have demographic data. Possible values: M (male), F (female), U (unknown)." 
                        aria-label="Button that displays a tooltip when focused or hovered over"           
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                    </i>
                    </div>
                    <apx-chart 
                    [series]="[{name:'%', data: insightNetwork.audienceAge.series}]"
                    [chart]="charbart.chart"
                    [dataLabels]="charbart.dataLabels"
                    [plotOptions]="charbart.plotOptions"
                    [labels]="insightNetwork.audienceAge.labels"
                    [xaxis]="charbart.xaxisGeneral"
                    [grid]="charbart.grid"
                ></apx-chart>
                </div>
                <div *ngIf="chartest && insightNetwork.indexDetail == 1" class="col-6 col-lg-4" >
                    <div class="panel-header-nofont text-center disabledLabel">
                        Gender <i class="fa ml-1 fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                        matTooltip="Period: lifetime 
                        Description: The gender and age distribution of followers for whom we have demographic data. Possible values: M (male), F (female), U (unknown)." 
                        aria-label="Button that displays a tooltip when focused or hovered over"           
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                    </i>
                    </div>
                    <apx-chart 
                    [chart]="charbart.piechart"
                    [dataLabels]="charbart.dataLabelsPie"
                    [tooltip]="charbart.tooltip"
                    [plotOptions]="charbart.pieplotOptions"
                    [series]="insightNetwork.audienceGender.series"
                    [legend]="charbart.legend"
                    [labels]="insightNetwork.audienceGender.labels"
                    [colors]="charbart.colorpie"
                    ></apx-chart>
                </div>
                <div *ngIf="chartest && insightNetwork.indexDetail == 2" class="col-6 col-lg-4" >
                    <div class="panel-header-nofont disabledLabel">
                        Reach <i class="fa ml-1 fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                        matTooltip="Period: (days_28) 
                        Description: Total number of times the IG User's IG Media have been viewed. " 
                        aria-label="Button that displays a tooltip when focused or hovered over"           
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                    </i>
                    </div>
                    <apx-chart 
                    [series]="[{name:'', data: insightNetwork.reachProfile.series}]"
                    [chart]="charbart.chart"
                    [dataLabels]="charbart.dataLabels"
                    [plotOptions]="charbart.plotOptions"
                    [labels]="insightNetwork.reachProfile.labels"
                    [xaxis]="charbart.xaxisGeneral"
                    [grid]="charbart.grid"
                    [legend]="charbart.legend"
                ></apx-chart>
                </div>
                <div *ngIf="chartest && insightNetwork.indexDetail == 2" class="col-6 col-lg-4" >
                    <div class="panel-header-nofont disabledLabel">
                        Impressions <i class="fa ml-1 fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                        matTooltip="Period: (days_28)  
                        Description: Total number of times the IG User's IG Media have been viewed. " 
                        aria-label="Button that displays a tooltip when focused or hovered over"           
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                    </i>
                    </div>
                    <apx-chart 
                    [series]="[{name:'', data: insightNetwork.impressionsProfile.series}]"
                    [chart]="charbart.chart"
                    [dataLabels]="charbart.dataLabels"
                    [plotOptions]="charbart.plotOptions"
                    [labels]="insightNetwork.impressionsProfile.labels"
                    [xaxis]="charbart.xaxisGeneral"
                    [grid]="charbart.grid"
                    [legend]="charbart.legend"
                ></apx-chart>
                </div>
                
                <div (click)="setIndexOnAccount(insightNetwork)" class="col-1 col-lg-1" style="display:flex;flex-direction:column; justify-content:center; ">
                    <mat-icon style="font-size:50px;">keyboard_arrow_right</mat-icon>
                </div>
            </div>
            
        </mat-card-content>
    </mat-card>
    
    <!-- <div  class="mt-2"style="display:flex;flex-direction:row-reverse;">
        <i >*Last 12 month</i>
     </div> -->

</div>
</body>
</html>