import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import {toast} from 'src/app/model/toast'
@Component({
  selector: 'app-modif-company',
  templateUrl: './modif-company.component.html',
  styleUrls: ['./modif-company.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css']
})
export class ModifCompanyComponent implements OnInit {
  company: FormGroup;
  show = false
  companyObject : any
  toast = toast
  listContact : any
  constructor(
    private fb: FormBuilder, 
    public api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private cdRef:ChangeDetectorRef,
    private route: ActivatedRoute, 
    ) {
    
   }

  ngOnInit(): void {
    this.api.getCompany(this.route.snapshot.params.id).subscribe(res => {
      this.companyObject = res
      this.company = this.fb.group({
        companyName: res.companyName,
        creationDate: res.creationDate,
        siren: res.siren,
        siret: res.siret,
        filiale: res.filiale,
        type: res.type,
        mandatFiles: res.mandatFiles,
        email: res.email,
        phone: res.phone,
        website: res.website,
        fax: res.fax,
        prelevement: res.prelevement,
        tvaIntracommunautaire: res.tvaIntracommunautaire,
        apeCode: res.apeCode,
        secteur: res.secteur,
        files: res.files,
      })
      this.api.getCompanies().subscribe(res => {
        this.listContact = res
      })
      this.show = true
      this.cdRef.detectChanges()
    })
    
  }

  modifCompany(){
    if (this.company.valid === true){
      this.api.putCompany(this.route.snapshot.params.id, this.company.value).subscribe(res => {
        this.toastr.success(this.toast.companyCardModified)
        this.router.navigate(['/list-company'])
      })
    }
  }

}
