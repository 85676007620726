<header>
</header>
<html>
    <body >
        <div class="page container-fluid">
          <div class="row mb-3">
            <button  routerLink="/newCampaign"  type="button" class="btn btn-secondary shadow-none" > <i class="fa fa-plus mr-2" ></i>Create </button>
            
            <input type="text" (change)="findCampaigns()" [(ngModel)]="tmp" style="font-family: Open Sans,Helvetica,Arial,sans-serif,FontAwesome;" class="form-control text-input col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12  mt-2" name=""  placeholder="&#xf002; Search campaign" >
          </div>
          <div class="row">
            <div class="tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
              <table class="sortable table table-hover tableTitle text-center">
                <thead>
                    <tr class="tableTitle ">
                      <th [appSort]="dataSourceCampaign" data-order="desc" data-name="status" class="col-1 tableTitle"scope="col">Status</th>
                      <th [appSort]="dataSourceCampaign" data-order="desc" data-name="creationDate"class="col-2" scope="col">Date</th>
                      <th [appSort]="dataSourceCampaign" data-order="desc" data-name="campaignName"class="col-1"scope="col">Name</th>
                      <th [appSort]="dataSourceCampaign" data-order="desc" data-name="platformeType"class="col-1"scope="col">Network</th>
                      <th [appSort]="dataSourceCampaign" data-order="desc" data-name="mission"class="col-1"scope="col">Mission</th>
                      <th [appSort]="dataSourceCampaign" data-order="desc" data-name="saleModels"class="col-1" scope="col">Sales Models </th>
                      <th [appSort]="dataSourceCampaign" data-order="desc" data-name="revenueTarget"class="col-1"scope="col">Revenue Target </th>
                      <th [appSort]="dataSourceCampaign" data-order="desc" data-name="deliveryTarget"class="col-1" scope="col">Delivery Target </th>
                      <th class="col-1"scope="col">Action</th>
                    </tr>
                  </thead>
                <tbody *ngIf="loadCampagne === true" class="bodyTable">
                  <tr   *ngFor = "let campaign of dataSourceCampaign | filter:term | paginate: { itemsPerPage: count, currentPage: p, totalItems: total }">
                    <td class=" text-center">
                      <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(campaign.status)}" > {{  fctBasic.getNameByStatus(campaign.status)  }}</label>
                    </td>
                    <td class="col-1 text-center">
                      {{campaign.startDate | date:'yyyy/MM/dd'}} - {{campaign.endDate | date:'yyyy/MM/dd'}}
                    </td>
                    <td class="col-1 text-center">{{campaign.campaignName | slice:0:50}}</td>
                    <td class="col-1 text-center">
                      <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(campaign.platformeType)}" style="color:white">
                        <i  *ngIf="campaign.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                        <i *ngIf="campaign.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                        <i *ngIf="campaign.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                        <i *ngIf="campaign.platformeType == 'Blog'" class="fa fa-bold "></i>
                        <i *ngIf="campaign.platformeType == 'multiNetwork'" class="fa fa-globe  "></i>
                        <mat-icon *ngIf="campaign.platformeType == 'TikTok'">tiktok</mat-icon>
                      </button>
                    </td>
                    <td class="col-1 text-center">
                      {{campaign.mission}}
                    </td>
                    <td class="col-1 text-center">
                      {{campaign.saleModels}}
                    </td>
                    <td class="col-1 text-center" >
                      {{campaign.revenueTarget | mask:"separator.2"}} €
                    </td>
                    <td class="col-1 text-center"  >
                      {{ campaign.deliveryTarget | mask:"separator.2"}} {{fctBasic.getsaleModels(campaign.saleModels)}}
                    </td>
                    <td class="col-1 col-md-1 col-sm-1 text-center">
                      <button (click)="openCampaignDetailInNewWindow(campaign._id)"  type="button" style="font-size: smaller;" class="btn btn-secondary"><span class="fa fa-search"></span></button>
                      <button (click)="openCampaignSelectorNewWindow(campaign._id)" type="button" style="font-size: smaller;" class="ml-2 btn btn-secondary button-top"><span class="fa fa-eye"></span></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination">
                <pagination-controls (responsive)="true" (pageChange)="paginateCampagne($event)" previousLabel = "" nextLabel = ""></pagination-controls>
              </div>
            </div>
          </div>
        </div>
    </body>
</html>