<!DOCTYPE html>
<html lang="en">

  <head>

    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="">
  </head>
    <body>
        <app-header></app-header>
      <section class="projects-section bg-light" style="margin-top:5%;">
        <div class="container">
        <div>
              <h1 class="text-center logo">POLITIQUE D’UTILISATION DES COOKIES D'INFLUENCELAB</h1>
              <p class="textcard mt-4"><b>Qu’est-ce qu’un cookie ?</b></p>
              <p class=" textcard  mt-4">Un cookie est un fichier qui est téléchargé sur votre terminal (ordinateur, smartphone, tablette…) lorsque vous consultez certains sites web. Les cookies permettent notamment à ces sites web de stocker et de récupérer des informations concernant les habitudes de navigation de l’utilisateur d'un site ou de son équipement puis, en fonction de ces informations, de le reconnaître.</p>
              <p class=" textcard  mt-4">Toutefois, les données obtenues sont restreintes. Elles concernent uniquement le nombre de pages visitées, la ville où est localisée l’adresse IP de connexion au site web, la fréquence et la récurrence des visites, la durée de la visite, le navigateur, l’opérateur ou le type de terminal à partir duquel la visite est effectuée. Des données telles que le nom, le prénom de l’utilisateur ou l'adresse postale de connexion ne sont, en aucun cas, obtenues.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
          <h1 class="text-center logo">Les cookies utilisés sur Influencelab.co</h1>
          <p class=" textcard  mt-4">Les cookies améliorent le confort de navigation sur un site internet et sont indispensables pour accéder à certains espaces sécurisés.
            Il existe différentes catégories de cookies, selon leurs finalités.</p>
          <p>Dans le cas d'Influencelab.co aucune information personnelle de l'utilisateur du site Influencelab.co n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers.</p>
          <p>La seule catégorie qu'Influencelab.co utilise est les cookies de mesures d'audience.</p>

          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
          <h1 class="text-center logo">Les cookies de mesures d'audience</h1>
          <p class=" textcard  mt-4">Influencelab.co utilise des cookies de mesure d’audience (« analytics ») pour collecter des informations relatives à la connexion
            et aux comportements des visiteurs à des fins statistiques (navigation, utilisation du site...), dans le but d'améliorer le
            contenu et les fonctionnalités du site, de l’adapter davantage à vos souhaits et d’en accroître la facilité d’utilisation.
          </p>
          <p>Ces cookies nous servent également à compter les visiteurs d'une page, le nombre de pages vues, ainsi que l’activité
            des visiteurs sur le site et leur fréquence de retour.</p>
          
            <div class="mb10 text-center">
              <button id="btn1"  onclick="setColor('btn1', '#101010')" type="button" align="center" class="center-block btn btn-secondary mr-2 mt-1">Je refuse</button>
            <button  id="btn2" onclick="setColor('btn2', '#101010')"  type="button" align="center" class="center-block btn btn-secondary  mt-1">J'accepte</button>
            </div>

          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">Cookies et traceurs tiers</h1>
              <li class="textcard mt-4">Il s’agit de cookies ou de traceurs téléchargés sur votre terminal par des entités tierces (partenaires, annonceurs…) lorsque vous naviguez sur certaines pages de notre site</li>
              <p class=" textcard  mt-4">Ces entités se sont engagées à respecter la législation en vigueur quant aux cookies/traceurs et garantissent la mise en œuvre de mesures de protection et de sécurisation des données recueillies.</p>
              <p class=" textcard  mt-4">Vous pouvez autoriser, bloquer ou supprimer les cookies installés sur votre poste en paramétrant les options de votre navigateur. Pour les navigateurs les plus courants, vous trouverez des indications via les liens suivants</p>
              <li class="textcard mt-4"><a href="https://support.mozilla.org/fr/kb/empecher-sites-enregistrer-preferences" target="_blank">Firefox</a></li>
              <li class="textcard mt-4"><a href="https://support.google.com/accounts/answer/61416?hl=en" target="_blank">Chrome</a></li>
              <li class="textcard mt-4"><a href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies" target="_blank">Internet Explorer</a></li>
              <li class="textcard mt-4"><a href="https://support.apple.com/kb/PH17191?locale=en_US&amp;viewlocale=en_US" target="_blank">Safari</a></li>
              <p class="textcard mt-4">Enfin, nous tenons à vous informer que la désactivation d’un cookie pourrait empêcher ou rendre difficile la navigation ou la prestation des services proposés sur notre site Internet.</p>
          </div>
        </div>
      </section>

      <!-- Footer -->
      <footer class="bg-black small text-center text-white-50">
        <div class="container-fluid">
          <span>Copyright &copy; Influence Lab</span>
          <a href="/legal">
            <span> Legal</span>
          </a>
        </div>
      </footer>
  </body>
</html>
