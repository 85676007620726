import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-show-youtube-contenue',
  templateUrl: './show-youtube-contenue.component.html',
  styleUrls: ['./show-youtube-contenue.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class ShowYoutubeContenueComponent implements OnInit {
  jsonContenu : any
  videoList : any
  selectedDevice : any
  contenueVideoPost : any = []
  constructor(
    private dialogRef: MatDialogRef<ShowYoutubeContenueComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cdRef:ChangeDetectorRef,
    private toastr: ToastrService,
    private apiService : ApiService,
  ) {
    this.jsonContenu = data;
   }

  ngOnInit(): void {
    this.videoList = this.jsonContenu.video
  }

  verifiedItem(arr : any, timestamp : any){
    return arr.some(function(el: any) {
      let date1 = new Date(el.currentDate);
      let date2 = new Date(timestamp);
      return date1.getTime() === date2.getTime();
    }); 
  }
  
  verifiedItemOnOffer(arr : any, timestamp : any){
    return arr.some(function(el: any) {
      let date1 = new Date(el.currentDate);
      let date2 = new Date(timestamp);
      return date1.getTime() === date2.getTime();
    }); 
  }

  async sortByLastModifiedDesc(arr : any) {
    return arr.sort((a: any, b: any) => {
      return <any>new Date(b.snippet.publishedAt) - <any>new Date(a.snippet.publishedAt);
    });
  }

  additemPosts(elem : any, index: number){
    let verif = this.verifiedItem(this.contenueVideoPost, elem.snippet.publishedAt)
    let verifOffer = this.verifiedItemOnOffer(this.jsonContenu.offer.posts, elem.snippet.publishedAt)
    if (verif == false && verifOffer == false){
      let newElem = elem
      this.contenueVideoPost.push(newElem)
      // this.videoList.splice(index, 1)
    } else {
      this.toastr.warning('This content has already been selected')
    }
  }
  
  async deleteItemPosts(elem: any, index: number){
    this.contenueVideoPost.splice(index, 1)
    // this.videoList.push(elem)
    await this.sortByLastModifiedDesc(this.videoList)
    this.cdRef.detectChanges();
  }

  onChange(type : string) {
    
    let newvideo = (element: any) => new Date(element.snippet.publishedAt).getTime() == new Date(this.selectedDevice.snippet.publishedAt).getTime()
    let indexPost = this.videoList.findIndex(newvideo)
    if (indexPost != -1){
      this.additemPosts(this.selectedDevice, indexPost)
    }
  }

  async createDasboardOnDb(){
    if (this.contenueVideoPost.length <= 0){
      this.toastr.error("You have to select posts or stories to be able to generate a dashboard")
      return
    }
    let newcontenueArray = {
      currentArray : this.contenueVideoPost,
      offer: this.jsonContenu.offer,
      contenueArray : this.jsonContenu.video,
      name: this.jsonContenu.offer.influencerObjects.name,
    }
    this.apiService.createYoutubeContenus(newcontenueArray).subscribe(res => {
      this.toastr.success('you Instagram Post are use for create Dashboard', 'Well done');
    })
  }
}