import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute} from '@angular/router';
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import {MatAccordion} from '@angular/material/expansion';
import {data} from 'src/app/model/apx-chartOfferDashboard.model'


@Component({
  selector: 'app-dashboard-admin-youtube',
  templateUrl: './dashboard-admin-youtube.component.html',
  styleUrls: ['./dashboard-admin-youtube.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dashboard.css']
})
export class DashboardAdminYoutubeComponent implements OnInit {

  @ViewChild(MatAccordion,{static:true}) accordion: MatAccordion;
  dashboardData: any = null;
  show = false
  chartOptions: any;
  selectedTabIndexContent = 0;
  videoSeleted = false
  showOffer = true
  overviewImpSeleted = true
  overviewReachSeleted = false
  overviewEngagementSeleted = false

  showVideoImp = true
  showVideoReach = false
  showVideoEngagement = false
  videoImpSeleted = true
  videoReachSeleted = false
  videoEngagementSeleted = false
  srcVideoImg = ""
  srcVideoCaption = ""
  expendedPosts = false
  videoSelected : any
  videochartDetailled : any

  constructor(
    private apiService : ApiService,
    private route: ActivatedRoute,
    public fctBasic: CampagneAndOfferFctService,
    private cdRef:ChangeDetectorRef,
    public router: Router,
  ) { 
    
  }

  ngOnInit(): void {
    this.apiService.getDashboardOffer(this.route.snapshot.params.id).subscribe(dashboardData => { 
      this.dashboardData = dashboardData
      this.show = true
      this.chartOptions = data
      this.showOffer = true
      this.cdRef.detectChanges();
      if (dashboardData.videoInfoArr.length > 0){
        this.srcVideoCaption = dashboardData.videoInfoArr[0].youtubeInfoVideo.caption
        this.srcVideoImg = dashboardData.videoInfoArr[0].youtubeInfoVideo.picture
        this.setPostValue(dashboardData.videoInfoArr[0])
        this.videoSeleted = true
        this.cdRef.detectChanges();
      }
      
    })
  }

  showOverviewChart(elem : any){
    switch (elem) {
      case "reach":
        this.overviewImpSeleted =false
        this.overviewReachSeleted = true
        this.overviewEngagementSeleted = false
        this.cdRef.detectChanges();
        break;
      case "impression":
        this.overviewImpSeleted = true
        this.overviewReachSeleted = false
        this.overviewEngagementSeleted = false
        this.cdRef.detectChanges();
        break;
        case "engagement":
          this.overviewImpSeleted = false
          this.overviewReachSeleted = false
          this.overviewEngagementSeleted = true
          this.cdRef.detectChanges();
      break;
      default:
        break;
    }
  }

  showVideoChartDetailed(elem : any){
    switch (elem) {
      case "reach":
        this.showVideoImp = false
        this.showVideoReach = true
        this.showVideoEngagement = false
        break;
      case "impression":
        this.showVideoImp = true
        this.showVideoReach = false
        this.showVideoEngagement = false
        break;
      case "engagement":
        this.showVideoImp = false
        this.showVideoReach = false
        this.showVideoEngagement = true
        break;
      default:
        break;
    }
  }

  showProfileInf(){
    try {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/detail-influencer/${this.dashboardData.Offer.influencerObjects.userId}`])
      );
      window.open(url, '_blank');
    } catch (error) {
      
    }  
  }

  setPostValue(elem : any){
    this.videoSelected = {}
    this.videoSelected.totalStatistics = {}
    this.videoSelected.totalStatistics.totalComment = elem.totalStatistics.totalComment
    this.videoSelected.totalStatistics.totalEngagement = elem.totalStatistics.totalEngagement
    this.videoSelected.totalStatistics.totalLike = elem.totalStatistics.totalLike
    this.videoSelected.totalStatistics.totalView = elem.totalStatistics.totalView
    this.videoSelected.totalStatistics.totalReach = elem.totalStatistics.totalReach
    this.videoSelected.totalStatistics.totalEstimatedMinutesWatched = elem.totalStatistics.totalEstimatedMinutesWatched
    this.videoSelected.totalStatistics.totalAverageViewDuration = elem.totalStatistics.totalAverageViewDuration
    this.videoSelected.chartPost = elem.chartPost
    this.srcVideoCaption = elem.youtubeInfoVideo.caption
    this.srcVideoImg = elem.youtubeInfoVideo.picture
    if (elem.chartPost.labelData.length == 1){
      this.videochartDetailled = {
        type: "bar",
        stacked: false,
        height: 350,
        toolbar: {
        show: false,
        }
      }
    }else {
      this.videochartDetailled = {
        type: "area",
        stacked: false,
        height: 350,
        toolbar: {
        show: false,
        }
      }
    }
    this.cdRef.detectChanges();
  }

}
