
<h4 class="dialogHeadlineGenerique" mat-dialog-title>Connection is incomplete 😲 </h4>
<mat-dialog-content class="custom-dialog-container mt-4">
    <p class="text-center">Hey Fellow, <br>
                 
    You need to select at least 1 account to register 😀</p>
</mat-dialog-content>
<mat-dialog-actions flex-center>
    <div >
        <button type="button" mat-dialog-close (click)="deleteAccount()" style="background-color: rgb(167, 167, 167);" class="btn  btn-secondary btn-template shadow-none">Cancel</button>
        <a href="{{FacebookUrl}}">
            <button type="button"  [mat-dialog-close]="true" class="btn  btn-secondary btn-template shadow-none ml-3"> Continue  </button>
        </a>
    </div>
    
    
</mat-dialog-actions>
