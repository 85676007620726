import { Component, OnInit } from '@angular/core';
import {UserLoginService} from 'src/app/service/user-login.service';
import { ApiService } from 'src/app/service/api.service';
import {Router} from '@angular/router'

@Component({
  selector: 'app-refuse-contrat',
  templateUrl: './refuse-contrat.component.html',
  styleUrls: ['./refuse-contrat.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class RefuseContratComponent implements OnInit {
  user : any
  constructor(
    private authService : UserLoginService,
    private apiService : ApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserValue()
  }

  deleteUser(){
    if (this.user.influencerInfo.picture != ""){
      this.apiService.deletePicture(this.user.influencerInfo.picture).subscribe(res => {
        this.apiService.deleteUser(this.user._id).subscribe(res => {
          this.router.navigate(['/listInfluencer'])
        })
      })
      
    } else {
      this.apiService.deleteUser(this.user._id).subscribe(res => {
        this.router.navigate(['/listInfluencer'])
      })
    }
  }

}
