<h4  class="dialogHeadlineSignup" mat-dialog-title>Votre compte a été creer avec facebook</h4>
<mat-dialog-content class="custom-dialog-container ">
    <p class="text-center">Voulez vous continuer avec facebook</p>
</mat-dialog-content>
<mat-dialog-actions>
        <div class="col-6 text-center">
            <button type="button" routerLink="/forgot-password" mat-dialog-close  style="background-color: rgb(167, 167, 167);" class="btn  btn-secondary btn-template ">Non</button>
        </div>
        <div class="col-6 text-center">
            <a href="{{urlFacebook}}">
                <button type="button" class="btn  btn-secondary btn-template" >Oui</button>
            </a>
        </div>
</mat-dialog-actions>