<body class="container-fluid">

  <app-header></app-header>
      <!-- Header -->
      <header class="masthead ">
        <div class="container d-flex h-100 align-items-center" >
          <div class="mx-auto col-12 text-center">
            <h1 style="font-size: 32px;"class="mx-auto mt-2 text-uppercase"><mark class="mx-auto my-0 text-uppercase" style="background-color:black; opacity:1;letter-spacing:2px;">Smart influence marketing solution</mark></h1>
            <h2 class=" mx-auto mt-2 text-uppercase"><b>WE CONNECT BRANDS AND GENUINE INFLUENCERS</b></h2>
            <h2 class=" mx-auto mt-2 text-uppercase"><b>THROUGH A POWERFUL TECHNOLOGY,</b></h2>
            <h2 class=" mx-auto mt-2 text-uppercase"><b>FUELED BY DATA.</b></h2>
          </div>
        </div>
      </header>
  
  <section id="About" class="projects-section bg-light">
    <div class="container">
    <div>
          <h1 class="text-center logo">ABOUT US</h1>
          <p class=" textcard text-center mt-4">Our mission is to help brands to leverage the power of recommandation of Everyday Influencers thanks to a powerful technology</p>
          <p class=" textcard text-center mt-4">We enable influencers to share their expertise and create engaging content for their favorite brands.</p>
          <p class=" textcard text-center mt-4">Ultimatly, we provide organic and authentic content for happier internet users.</p>
      </div>
    </div>
  </section>
      <!-- Projects Section -->
  
      <section id="Brand" class="projects-section bg-light">
        <div class="container">
  
          <!-- Featured Project Row -->
          <div class="row align-items-center no-gutters mb-4 mb-lg-5">
            <div class="col-xl-6 col-lg-6">
              <div class="hovereffect">
                <img class="img-fluid mb-3 mb-lg-0" src="../../../assets/images/edito/girl.jpg" alt="">
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="featured-text text-center text-lg-left">
                <h4>BRAND AND AGENCIES</h4>
                <p class="text-black mb-0">Leverage every-day influencers’ power thanks to a cutting edge technology.</p>
                <p class="text-black mb-0">First  <b>Managed-Services Influence Marketing Solution</b> : From automated campaign to tailor made project, we offer the largest influence marketing experience.</p>
                <p class="text-black mb-0"><b>Authenticity is our modjo</b> : Get every day Influencers posting engaging content about your brand in a brandsafe environment. Connect with your audience in a unique and authentic way.</p>
                <a routerLink="/brand">
                  <button type="button" align="center" class="center-block btn btn-secondary btn-lg mt-5">I AM A BRAND</button>
                </a>
              </div>
  
            </div>
          </div>
        </div>
      </section>
  
    <section id="Influencer" class="projects-section bg-light">
        <div class="container">
          <div class="row align-items-center no-gutters mb-4 mb-lg-5">
            <div class="col-xl-6 col-lg-6">
              <div class="featured-text2 text-center text-lg-right">
                <h4>INFLUENCERS</h4>
                <p class="text-black mb-0">Communicate around your favorite brands in a unique and authentic way...Engage your audience with fresh and attractive content</p>
                <a routerLink="/influencer">
                <button type="button" align="center" class="center-block btn btn-secondary btn-lg mt-5">I AM AN INFLUENCER</button>
                </a>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="hovereffect">
                <img class="img-fluid mb-3 mb-lg-0" src="../../../assets/images/edito/influencelab-photographer.jpg" alt="">
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Signup Section -->
  
      <section id="Influencer" class="projects-section bg-light">
        <div class="container">
          <div class="row text-center">
            <h1 class="text-center text-black d-block w-100"> <b>Ils nous font confiance</b> </h1>
          </div>
          <img class="img-fluid mb-3 mb-lg-0" src="../../../assets/images/edito/logoAd.png" alt="">
        </div>
      </section>
  
      <app-footer></app-footer>
      
      <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-146185347-1');
    </script>
    </body>
