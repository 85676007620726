import { Injectable } from '@angular/core';
import { userModel } from 'src/app/model/user-model.model'
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { FormBuilder} from '@angular/forms';

interface Path { 
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserLoginService {

  public myself:  userModel;
  roleAs: any;
  constructor(private router: Router,private service: ApiService) {}

  public isAuthenticated():Boolean {
    let userData : any = localStorage.getItem('userInfo')
    if(userData ){
      if (JSON.parse(userData)){
        return true;
      } else {
        return false
      }
    }
    return false;
  }

  getRole() {
    this.roleAs = localStorage.getItem('userType');
    return this.roleAs;
  }

  public loginAdmin(user: userModel): void{
    localStorage.setItem('userInfo', JSON.stringify(user))
    localStorage.setItem('userType', 'admin')
  }

  public loginContact(user: userModel): void{
    localStorage.setItem('userInfo', JSON.stringify(user))
    localStorage.setItem('userType', 'contact')
  }
 
  setItemForInfluencer(user: userModel){
    localStorage.setItem('userInfo', JSON.stringify(user))
    localStorage.setItem('userType', 'influencer')
  }

  public getUserValue(): any {
    var newuser = localStorage.getItem('userInfo');
    if (newuser) {
      return JSON.parse(newuser);
    }
    return null;
  }

  public getUserType(): string {
    const type = localStorage.getItem('userType');
    if (type) {
      return type;
    }
    return "";
  }
  
  public logout() {
    localStorage.clear();
    this.service.logout().subscribe(() => {
      this.router.navigate(['/login']);
    })
  }

  public logoutInfluencerAfterRegister() {
    localStorage.clear();
    this.router.navigate(['/success-registration']);
  }

  public update(id: string, params: userModel) {
  this.service.putUser(id, params).subscribe(user => {
  }) 
} 
}
