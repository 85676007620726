<!-- <p>instagram-insight-dialog works!</p>
<div class="vertical-alignment-helper">
    <div class="modal-dialog vertical-align-center" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background-color: black;" >
                <h5 class="text-center modal-title" style="color: white;" id="exampleModalLongTitle">Registration is incomplete ⚡</h5>
            </div>
            <div class="modal-body">
                <p>When you link your Facebook and/or Instagram, we need you to grant us permissions so we can secure our collaboration and access statistics for the work we will do togeteher</p>
                <p>DON’T WORRY, WE WILL NEVER :
                <li>Post to your page without your permission</li>
                <li>Comment on or like content on your behalf</li>
                <li>Access your direct messages</li>
                <li>Share or sell non campaign-related data with third parties</li>
                </p>
            <div class="modal-footer" style="text-align: center;">
                <button type="button" ng-click="deleteAccount()" style="background-color: rgb(167, 167, 167);" class="btn btn-lg btn-secondary btn-rounded mr10">Cancel</button>
                <a href="{{FacebookUrl}}">
                    <button type="button" class="btn btn-lg btn-secondary btn-rounded mr10"> <b>Continue</b> </button>
                    </a>
            </div>
            </div>
        </div>
    </div>
</div> -->


<h4 class="dialogHeadlineGenerique" mat-dialog-title>Registration is incomplete ⚡ </h4>

<mat-dialog-content >
    <div class="text-center mt-3 textDialog">When you link your Facebook and/or Instagram,</div>  
    <div class="text-center  textDialog">We need you to grant us permissions so we can secure our collaboration and access statistics for the work we will do togeteher,</div>  
    <div class="text-center mt-2 mb-2 textDialog">DON’T WORRY, WE WILL NEVER :
        <li class="text-center textDialog">Post to your page without your permission</li>
        <li class="text-center textDialog">Comment on or like content on your behalf</li>
        <li class="text-center textDialog">Access your direct messages</li>
        <li class="text-center textDialog">Share or sell non campaign-related data with third parties</li>
    </div> 
</mat-dialog-content>
<mat-dialog-actions flex-center>
    <div >
        <button type="button" mat-dialog-close (click)="deleteAccount()" style="background-color: rgb(167, 167, 167);" class="btn  btn-secondary btn-template shadow-none">Cancel</button>
        <a href="{{FacebookUrl}}">
            <button type="button"  [mat-dialog-close]="true" class="btn  btn-secondary btn-template shadow-none ml-3"> Continue  </button>
        </a>
    </div>
</mat-dialog-actions>