import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { MatPaginator } from '@angular/material/paginator';
import { Router} from '@angular/router';

@Component({
  selector: 'app-list-contact',
  templateUrl: './list-contact.component.html',
  styleUrls: ['./list-contact.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class ListContactComponent implements OnInit {
  dataSourceContact: any;
  public loadListContact = false
  p: number = 1;
  term: string;
  total: number
  count: number = 50;
  tmp: string;
  constructor(
    private apiService : ApiService, 
    private cdRef:ChangeDetectorRef,
    public router: Router,
    ) { }

  ngOnInit(): void {
    this.apiService.getContacts().subscribe(contact => { 
      if (contact != 400){
        this.dataSourceContact = contact;
        this.loadListContact = true
        this.cdRef.detectChanges();
      }
    })
  }

  findContact(){
    this.apiService.getContactsPaginateFilter(this.tmp, this.count).subscribe(res => {
      if (res.total > 0){
        this.total = res.total;
        this.dataSourceContact = res.contacts
        this.p = 1
        this.cdRef.detectChanges();
      }
    })
  }

  openContactDetailInNewWindow(id : any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/detail-contact/${id}`])
    );
    window.open(url, '_blank');
  }

}
