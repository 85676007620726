import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import {MediaCampagneService } from 'src/app/service/media-campagne.service';
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {toast} from 'src/app/model/toast'
@Component({
  selector: 'app-create-campagne',
  templateUrl: './create-campagne.component.html',
  styleUrls: ['./create-campagne.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css'],
  providers: [MediaCampagneService]

})

export class CreateCampagneComponent implements OnInit {
  buttonValue = ""
  init = false
  toast = toast
  campagnes: FormGroup;
  status = new FormControl("New Campaign")
  creationDate = new FormControl(new Date())
  startDate = new FormControl("", [Validators.required]);
  endDate = new FormControl("", [Validators.required]);
  agency = new FormControl("");
  advertiser = new FormControl("");
  agencyContact = new FormControl("");
  advertiserContact = new FormControl("");
  campaignName = new FormControl("", [Validators.required]);
  agencyReference = new FormControl("");
  objective = new FormControl("", [Validators.required]);
  mission = new FormControl("Content", [Validators.required]); 
  projectDescription = new FormControl("", [Validators.required]);
  platformeType = new FormControl("", [Validators.required]);
  saleModels = new FormControl("Quotation", [Validators.required]);
  mustInclude = new FormControl("", [Validators.required]);
  annonceurKey = new FormArray([]);
  deliveryTarget = new FormControl("0", [Validators.required])
  prixUnitaire = new FormControl("0", [Validators.required])
  revenueTarget = new FormControl("0", [Validators.required])
  currentDelivery = new FormControl(0)
  currentRevenue = new FormControl(0)
  pictures : any = new FormControl([]);
  files : any = new FormControl([]);
  keywordList : string[];
  keyword : any;
  listContacts : any = []
  listCompany : any = []


  constructor(
    private fb: FormBuilder, 
    public picService: MediaCampagneService,
    public fctBasic: CampagneAndOfferFctService,
    public api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private cdRef:ChangeDetectorRef
    ) {
    
   }

  ngOnInit(): void {
    this.api.getCompanies().subscribe(resnewFile => {
      this.listCompany = resnewFile
    })
    this.api.getContacts().subscribe(resnewFile => {
      this.listContacts = resnewFile
    })
    this.campagnes = this.fb.group({
      status: this.status,
      creationDate: this.creationDate,
      startDate: this.startDate,
      endDate: this.endDate,
      agency: this.agency,
      advertiser: this.advertiser,
      agencyContact: this.agencyContact,
      advertiserContact: this.advertiserContact,
      campaignName : this.campaignName,
      agencyReference: this.agencyReference,
      objective: this.objective,
      mission: this.mission,
      projectDescription: this.projectDescription,
      platformeType: this.platformeType,
      saleModels: this.saleModels,
      mustInclude: this.mustInclude,
      annonceurKey: this.annonceurKey,
      deliveryTarget: this.deliveryTarget,
      prixUnitaire: this.prixUnitaire,
      revenueTarget: this.revenueTarget,
      currentRevenue: this.currentRevenue,
      currentDelivery: this.currentDelivery,
      pictures: this.pictures,
      files: this.files,
    })
    this.init = true
    this.cdRef.detectChanges();
  }

  checkSpaceOnString(str : string) {
    let tmpstr = str.split(" ")
    tmpstr.forEach((elem) =>  {
      if (elem != " "){
        let verif = this.verifiedIndexOf(elem)
        if (verif == false){
          this.annonceurKey.push(new FormControl(elem))
          
        }
      }
    })
  }

  verifiedIndexOf(elem : string) : Boolean {
    let check = false
    this.annonceurKey.value.forEach((res : any) => {
     if (elem == res ){
       check = true
     }
    })
    return check
  }

  addKeyWords(){
    this.keyword = document.getElementById('keyword')
    if (this.keyword['value']){
      this.checkSpaceOnString(this.keyword['value'])
      this.keyword['value'] = ""
    }
  }

  selectFile(event: any) {	
    if(!event.target.files[0] || event.target.files.length == 0) {
        return ;
      }
      let check = this.picService.getIfElemExist(this.picService.selectedArrayFile, event.target.files[0])
      if (check == false){
      var reader = new FileReader();
      reader.onload = () => {
        let extention = this.picService.getExtentionFile(event.target.files[0])
        if (extention.extention != false){
          const fd = new FormData();
          fd.append('file', event.target.files[0], event.target.files[0].name);
          this.api.postFile(fd).subscribe(path => {
            this.campagnes.value['files'].push(path[0])
            this.cdRef.detectChanges()
          })
          this.cdRef.detectChanges()
        }
      }
        reader.readAsDataURL(event.target.files[0]); 
    }
  }

  addPicture(event : any){
  if(!event.target.files[0] || event.target.files.length == 0) {
    return false;
  }
  var reader = new FileReader();
  reader.onload = () => {
    let extention = this.picService.getExtentionImg(event.target.files[0])
    if (extention != false){
      
      const fd = new FormData();
      fd.append('file', event.target.files[0], event.target.files[0].name);
      this.api.postPicture(fd).subscribe(path => {
        this.campagnes.value['pictures'].push(path[0])
        this.cdRef.detectChanges()
      })
      this.cdRef.detectChanges()
      return event.target.files[0]
    }
  }
  reader.readAsDataURL(event.target.files[0]);
  return false
  }

  deleteImg(img : any, index: number){
    this.campagnes.value['pictures'].splice(index, 1)
    this.api.deletePicture(img).subscribe(res => {
      this.cdRef.detectChanges()
    })
  }

  deleteFile(file : any, index : number){
    this.campagnes.value['files'].splice(index, 1)
    this.api.deleteFile(file.name).subscribe(res => {
      this.cdRef.detectChanges()
    })
  }

  deleteKeyWords(index: number){
    this.annonceurKey.removeAt(index)
  }

  async createCampagne(){
    if (this.campagnes.valid === true){
      this.api.postCampaign(this.campagnes.value).subscribe(res => {
        this.toastr.success(this.toast.campaignCreate);
        this.router.navigate(['/listCampaign'])
      })
    } else {
      this.toastr.warning(this.toast.InvalidEntry);
    }
  }

  setplatform(type : any){
    this.campagnes.controls['platformeType'].setValue(type);
    this.buttonValue = type
  }

  calculateT(elem: any){
    if (elem.value['saleModels'] === "CPM"){  
      if (elem.value['deliveryTarget'] === 0){
        elem.controls['revenueTarget'].setValue(0);
        this.cdRef.detectChanges()
      } else {
        let tmpValue = elem.value['deliveryTarget'] * elem.value['prixUnitaire']
        elem.controls['revenueTarget'].setValue(tmpValue / 1000);
        this.cdRef.detectChanges()
      }
    } else {
      if (elem.value['deliveryTarget'] === 0){
        elem.controls['revenueTarget'].setValue(0);
        this.cdRef.detectChanges()
      } else {
        elem.controls['revenueTarget'].setValue(elem.value['deliveryTarget'] * elem.value['prixUnitaire']);
        this.cdRef.detectChanges()
      }
    }
  }

}
