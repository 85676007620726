import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {toast} from 'src/app/model/toast'

@Component({
  selector: 'app-show-offer-details',
  templateUrl: './show-offer-details.component.html',
  styleUrls: ['./show-offer-details.component.css','../../../assets/styles/dialog.css']
})
export class ShowOfferDetailsComponent implements OnInit {
  campagne : any
  contacts : any
  companys: any
  toast = toast
  selectedDevice : any
  arrayContacts : any = []
  constructor(
    private dialogRef: MatDialogRef<ShowOfferDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cdRef:ChangeDetectorRef,
    private toastr: ToastrService,
    private apiService : ApiService,
    public router: Router
  ) { 
    this.campagne = data
  }

  ngOnInit(): void {
  }
  onChange() {
    if (this.selectedDevice){
      this.openNewTabOfferDetails(this.selectedDevice)
    }
  }

  openNewTabOfferDetails(name : any){
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/offerDashboard/${name.id}`])
    );
    window.open(url, '_blank');
  }

}
