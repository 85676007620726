import { ApiService } from 'src/app/service/api.service';
import {CampagneModel} from 'src/app/model/campagne-model.model'
import { MatPaginator } from '@angular/material/paginator';
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './listCampagne.component.html',
  styleUrls: ['./listCampagne.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})

export class ListCampagneComponent implements OnInit {
  dataSourceCampaign: CampagneModel[];
  p: number = 1;
  total: number
  term: string;
  count: number = 50;
  tmp: string
  public loadCampagne = false
  constructor(private apiService : ApiService, public fctBasic: CampagneAndOfferFctService, private cdRef:ChangeDetectorRef,public router: Router) { 
    
  }

  ngOnInit(): void {
    this.apiService.getCampaignsPaginate(1, this.count).subscribe(res => { 
      if (res != 400){
        this.dataSourceCampaign = res.campagnes;
        this.total = res.total
        this.loadCampagne = true
        this.cdRef.detectChanges();
      }
    })
    
  }

  findCampaigns(){
    this.apiService.getCampaignsPaginateFilter(this.tmp, this.count).subscribe(res => {
      if (res.total > 0){
        this.total = res.total;
        this.dataSourceCampaign = res.campaigns
        this.p = 1
        this.cdRef.detectChanges();
      }
    })
  }

  paginateCampagne(event : any){
    this.p = event
    this.apiService.getCampaignsPaginate(this.p, this.count).subscribe(campaigns => { 
      if (campaigns != 400){
        this.dataSourceCampaign = campaigns.campagnes
        this.cdRef.detectChanges();
      }
    })

  }

  openCampaignDetailInNewWindow(id : any){
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/detailCampaign/${id}`])
    );
    window.open(url, '_blank');
  }

  openCampaignSelectorNewWindow(id : any){
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/selectorCampaign/${id}`])
    );
    window.open(url, '_blank');
  }

  

}
