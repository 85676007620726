<html>
    <body>
        <div  class="page container">
            <a *ngIf="campagneLoaded" [routerLink]="['/detailCampaign/', campaign._id]" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
                <mat-card class="mt-1" *ngIf="campagneLoaded">
                    <div class="title-card text-center">
                        <div class="col-lg-12  display-mid">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-3 text-center" >
                                <label class="col-12 labelFont text-center">Campaign Name<span class="ml-2 fa fa-angellist"></span></label>
                                <div  class=" text-center fileText">
                                    {{campaign.campaignName}}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-6 col-sm-3 col-3">
                                <label class="col-12 labelFont text-center">Campaign Date<span class="ml-2 fa fa-calendar"></span></label>
                                <div class="fileText text-center">
                                    {{campaign.startDate | date:'yyyy/MM/dd'}} - {{campaign.endDate | date:'yyyy/MM/dd'}}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                <label class="col-12 labelFont text-center">Mission<span class="ml-2 fa fa-rocket"></span></label>
                                <div class="fileText text-center">
                                    {{campaign.mission}}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                <label class="col-12 labelFont text-center">Social Network<span class="ml-2 fa fa-share-alt-square"></span></label>
                                <div class=" text-center">
                                    <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(campaign.platformeType)}" style="color:white">
                                        <i  *ngIf="campaign.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                                        <i *ngIf="campaign.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                                        <i *ngIf="campaign.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                                        <i *ngIf="campaign.platformeType == 'Blog'" class="fa fa-bold "></i>
                                        <i *ngIf="campaign.platformeType == 'multiNetwork'" class="fa fa-globe  "></i>
                                        <mat-icon *ngIf="campaign.platformeType == 'TikTok'" >tiktok</mat-icon>
                                      </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-divider ></mat-divider>
                    <mat-card-content class=" mt-4">
                        <div class="row mt-5">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <label class="col-lg-12 text-center labelFont col-md-12 "># of Influencers</label>
                                <div flex-center class="col-lg-12 ">
                                    <ngx-charts-pie-grid
                                    [view]="viewList"
                                    [scheme]="colorScheme"
                                    [results]="sizedata"
                                    >
                                    </ngx-charts-pie-grid>
                                    <!-- <div>
                                        <apx-chart 
                                        [chart]="chartOptions.chartdonut"
                                        [plotOptions]="chartOptions.plotOptions"
                                        [legend]="chartOptions.legendReach"
                                        [colors]="chartOptions.colors"
                                        [stroke]="chartOptions.stroke"
                                        [series]="sizedata"
                                    ></apx-chart>
                                    </div> -->
                                    
                                </div>
                                
                            </div>
                            <div class="col-md-6  col-lg-6   col-sm-12">
                                <label class="col-lg-12 text-center labelFont col-md-12 ">Social Reach</label>
                                <div flex-center class="col-lg-12 text-center">
                                    <ngx-charts-pie-grid
                                    [view]="viewList"
                                    [scheme]="colorScheme"
                                    [results]="sizedataReach"
                                    >
                                </ngx-charts-pie-grid>
                            </div>  
                        </div>  
                    </div>
                    <form [formGroup]="selector" >

                        <div class="row  input-row-selector " >
                            <div class="col-md-6 col-lg-4 col-sm-12  display-mid  ">
                                <div class="col-lg-6 col-6  col-md-4">
                                    <mat-label class="labelFont">Geotargeting</mat-label>
                                </div>
                                <div class="col-lg-6 col-6 col-md-8 ">
                                    <mat-form-field >
                                        <mat-select   formControlName="country"  multiple>
                                            <mat-option *ngFor="let country of countries; index as i "  [value]="country" >
                                                {{ country}}
                                            </mat-option>
                                            <mat-select-trigger>
                                            </mat-select-trigger>
                                        </mat-select>
                                    </mat-form-field>
                                        <span  *ngFor="let geotargetting of country.value; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                            {{ geotargetting }}
                                        </span>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4  col-sm-12  display-mid">
                                <div class="col-lg-6 col-6 text-center col-md-4 ">
                                    <mat-label class=" labelFont">City</mat-label>
                                </div>
                                <div class="col-lg-6 col-6 col-md-8">
                                    <mat-form-field >
                                        <mat-select formControlName="city" multiple>
                                            <mat-select-trigger>
                                            </mat-select-trigger>
                                            <mat-option  *ngFor="let user of userArray" [value]="user.influencerInfo.city">
                                                {{ user.influencerInfo.city }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span  *ngFor="let city of city.value; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                        {{ city }} 
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 display-mid col-sm-12   ">
                                <div class="col-lg-6 col-6 text-center col-md-4 ">
                                    <mat-label class="labelFont">ZipCode</mat-label>
                                </div>
                                <div class="col-lg-6 col-6 col-md-6">
                                    <mat-form-field  >
                                        <mat-select formControlName="zipcodes" multiple>
                                            <mat-select-trigger>
                                            </mat-select-trigger>
                                            <mat-option  *ngFor="let user of userArray" [value]="user.influencerInfo.postcode">
                                                {{ user.influencerInfo.postcode }}
                                            </mat-option>
                                        </mat-select>
                                        
                                    </mat-form-field>
                                    <div     *ngFor="let zipcode of zipcodes.value; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                        #{{ zipcode }} 
                                    </div>
                                </div>
                            </div>
                                
                        </div>                    
                        <div class="row input-row-selector  ">
                            <div class="col-md-6  col-lg-6 display-mid  col-sm-12">
                                <div class="col-lg-4 col-6  col-md-4">
                                    <mat-label class="labelFont">Primary Langage</mat-label>
                                </div>
                                <div class="col-lg-8 col-6 col-md-8">
                                    <mat-form-field >
                                        <mat-select formControlName="language"  multiple>
                                            <mat-select-trigger>
                                            </mat-select-trigger>
                                            <mat-option   *ngFor="let language of languages" [value]="language">
                                                {{ language }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                        <span   *ngFor="let primaryLanguage of language.value; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                            {{ primaryLanguage }} 
                                        </span>
                                </div>
                            </div>
                            <div class="col-md-6  col-lg-6 display-mid  col-sm-12">
                                <div class="col-lg-4 col-6  col-md-4">
                                    <mat-label class="labelFont">Secondary Language</mat-label>
                                </div>
                                <div class="col-lg-8 col-6 col-md-8">
                                    <mat-form-field>
                                        <mat-select formControlName="secondaryLanguage"  multiple>
                                            <mat-select-trigger>
                                            </mat-select-trigger>
                                            <mat-option  *ngFor="let language of languages" [value]="language">
                                                {{ language }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="col-lg-12 col-md-12">
                                        <span   *ngFor="let secondaryLanguage of secondaryLanguage.value; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                            {{ secondaryLanguage }} 
                                        </span>
                                    </div>
                                </div>
                                
                            </div>  
                        </div>
                        
                        <div class="row input-row-selector  ">
                            <div class="col-md-6 col-lg-6 display-mid col-sm-12   ">

                                <div  class="col-lg-4 col-6 textareapostionAdmin col-md-4">
                                    <div>
                                        <mat-label class="labelFont">Select Influencers</mat-label>

                                    </div>
                                    <div>
                                        <mat-label class="textsecondary">  Main topics (up to 6)</mat-label>

                                    </div>
                                </div>
                                <div class="col-lg-8 col-6 col-md-8">
                                    <mat-form-field >
                                        <mat-select  formControlName="topping"  multiple>
                                            <mat-select-trigger>
                                            </mat-select-trigger>
                                            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                        </mat-select>
                                        </mat-form-field>
                                        <div class="col-lg-12 col-md-12">
                                            <span    *ngFor="let topic of topping.value; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                                {{ topic }}
                                            </span>
                                        </div>
                                </div>
                            </div>
                            <div class="col-md-6  col-lg-6 display-mid   col-sm-12">
                                <div class="col-lg-4 col-6  col-md-4"> 
                                    <mat-label class="labelFont">Keywords</mat-label>

                                </div>
                                <div class="col-lg-8 col-6 col-md-8"> 
                                    <mat-form-field>
                                        <input        id="keywordTxt"   matInput  >
                                        <button  (click)="addKeyWords()" matSuffix mat-icon-button aria-label="Clear" >
                                          <mat-icon>add</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <div class="col-lg-12 col-md-12">
                                        <span  *ngFor="let keyword of Keywords.value; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                            #{{ keyword }} x 
                                        </span>
                                    </div>
                                </div>
                            </div>  
                        </div>
                       
                    </form>
                    <div *ngIf="currentUserNetworks.length > 0" flex-center class="row input-row-selector  ">
                        <button (click)="searchInfluenceur()" class="btn btn-secondaryWithBorder shadow-none" >Go !</button>
                    </div>
                    <!-- <div  class="row input-row-selector  ">
                            <div   class="col-md-12  col-lg-12   col-sm-12">
                                <div class="col-lg-12 col-12  col-md-12"> 
                                    <mat-form-field>
                                        <input matInput (change)="findInfs()" [(ngModel)]="tmp" type="text" placeholder="Search Influencers By First Name & Last Name..." style="font-family: Open Sans,Helvetica,Arial,sans-serif,FontAwesome;;font-size: 12px !important; line-height: 1.538461 !important;"/>
                                        <mat-icon matSuffix>search</mat-icon>
                                      </mat-form-field>
                                </div>
                                <div style="display:flex;flex-direction:row-reverse;">
                                    <div (click)="resetResearch()" ><i class="fa fa-trash mr-1"></i>Reset Research</div>
                                </div>
                            </div>
                    </div> -->
                    <div class="row input-row-selector  ">
                        <div class="col-md-12 col-lg-12 display-mid col-sm-12   ">
                            <div  class="col-lg-4 col-6 textareapostionAdmin col-md-4">
                                    <mat-label class="labelFont">Search Influencers By</mat-label>
                                    <mat-label class="textsecondary ">First Name & Last Name...</mat-label>
                            </div>
                            <div class="col-lg-8 col-6 col-md-8">
                                <mat-form-field>
                                    <input matInput (change)="findInfs()" [(ngModel)]="tmp" type="text" placeholder=" " style="font-family: Open Sans,Helvetica,Arial,sans-serif,FontAwesome;;font-size: 12px !important; line-height: 1.538461 !important;"/>
                                    <mat-icon matSuffix>search</mat-icon>
                                  </mat-form-field>
                                  <div style="display:flex;flex-direction:row-reverse;">
                                    <div (click)="resetResearch()" ><i class="fa fa-trash mr-1"></i>Reset Research</div>
                                </div>
                            </div>
                    </div>  
                    </div>
                         
                    </mat-card-content>
                    
                </mat-card>
                <mat-card *ngIf="!sendOffer && userReady" class="mt-4" >
                    <mat-card-content>
                        <div  class="tableResponsiveAnnex table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  ">
                          <div  class="panel-header-campaign ">
                              <div class="col-lg-12">
                                <div class="labelFont" >Select Network</div>
                              </div>
                          </div>
                          <div class="col-lg-12">
                            <mat-divider ></mat-divider>
                        </div>
                          <table  class="table table-hover  tableTitle  text-center">
                            <thead class="bodyTable">
                                <tr class="tableTitle col-lg-12">
                                  <th class="col-lg-1 tableTitle"scope="col">Select</th>
                                  <th class="col-lg-1"scope="col">Picture</th>
                                  <th class="col-lg-3"scope="col">Network  </th>
                                  <th class="col-lg-3" scope="col">Interest In  </th>
                                  <th class="col-lg-1"scope="col">Lieux principaux  </th>
                                  <th class="col-lg-1"scope="col">Tranche d'age  </th>
                                  <th class="col-lg-1"scope="col">Genre</th>
                                  <th class="col-lg-1"scope="col">Action</th>
                                </tr>
                              </thead>
                            <tbody class="bodyTable  col-lg-12">
                              <tr *ngFor = "let network of currentUserNetworks | filter:term | paginate: { itemsPerPage: count, currentPage: p, totalItems: total } ">
                                
                                <td  class="col-lg-1 col-md-1  text-center">
                                    <p><mat-checkbox (click)="addInfluenceurOnArray(network)" class=""></mat-checkbox></p>
                                </td>
                                <td class="col-lg-1 col-md-1  text-center">
                                    <img *ngIf="network.influencerInfo.picture" [defaultImage]="'assets/images/logo/profile-default.jpeg'" [errorImage]="'assets/images/logo/profile-default.jpeg'"  [lazyLoad]="fctBasic.checkUrlPath(network.influencerInfo.picture)"   style="height: 75px; width: 75px; border-radius: 50px;">
                                    <img  *ngIf="!network.influencerInfo.picture" src="assets/images/logo/profile-default.jpeg" style="height: 75px; width: 75px; border-radius: 50px;">
                                </td>
                                <td (click)="openOfferDetailInNewWindow(network)" class="col-lg-1 col-md-1  text-center">
                                    <div class="col-lg-12">
                                        <span>{{network.influencerInfo.firstname}} {{network.influencerInfo.lastname}}</span>
                                    </div>
                                    <div class="col-lg-12">
                                       <span (click)="fctBasic.checkUrlPathSocialNetwork(network, campaign.platformeType)">{{fctBasic.getNetworkNameForInfluenceur(network, campaign.platformeType) | slice:0:20}}</span>
                                    </div>
                                    <div class="col-lg-12">
                                        <p > 
                                            <i *ngIf="platformeType == 'Instagram'" class="fa fa-instagram"></i>
                                            <i *ngIf="platformeType == 'Facebook'" class="fa fa-facebook"></i>
                                            <i *ngIf="platformeType == 'Youtube'" class="fa fa-youtube"></i>
                                            <i *ngIf="platformeType == 'multiNetwork'" class="fa fa-globe"></i>
                                            <mat-icon *ngIf="platformeType == 'TikTok'">tiktok</mat-icon>
                                            {{fctBasic.getPageSizeForNetwork(network, campaign.platformeType) | number}} Subscribers</p>
                                    </div>
                                </td>
                                <td (click)="openOfferDetailInNewWindow(network)" class="col-lg-1 col-md-1  text-center">
                                    <div class="text-center">Interest in</div>
                                    <div  *ngIf="network.influencerInfo.topics.fashion == true">Fashion</div>
                                    <div *ngIf="network.influencerInfo.topics.homedeco == true">Home Deco</div>
                                    <div  *ngIf="network.influencerInfo.topics.beauty == true">Beauty</div>
                                    <div *ngIf="network.influencerInfo.topics.food == true">Food</div>
                                    <div  *ngIf="network.influencerInfo.topics.health == true">health</div>
                                    <div  *ngIf="network.influencerInfo.topics.travel == true">Travel</div>
                                    <div  *ngIf="network.influencerInfo.topics.automotive == true">Automotive</div>
                                    <div  *ngIf="network.influencerInfo.topics.tech == true">Tech</div>
                                    <div  *ngIf="network.influencerInfo.topics.lifestyle == true">LifeStyle</div>
                                    <div *ngIf="network.influencerInfo.topics.petcare == true">Pet Care</div>
                                </td>
                                <td (click)="openOfferDetailInNewWindow(network)" class="col-lg-1 col-md-1  text-center">
                                    <div *ngIf="!network.chart">
                                        <apx-chart 
                                        [series]="network.facebookPage.instagramObject.audienceCountry.series"
                                        [chart]="chartOptions.chart"
                                        [labels]="network.facebookPage.instagramObject.audienceCountry.labels"
                                        [legend]="chartOptions.legend"
                                        [dataLabels]="chartOptions.dataLabels"
                                        [colors]="chartOptions.colors"
                                        [stroke]="chartOptions.stroke"
                                    ></apx-chart>
                                    </div>
                                    <div *ngIf="network.chart">
                                        <apx-chart 
                                        [series]="network.pieTest.series"
                                        [chart]="chartOptions.chart"
                                        [labels]="network.pieTest.labels"
                                        [legend]="chartOptions.legend"
                                        [dataLabels]="chartOptions.dataLabels"
                                        [colors]="chartOptions.colorsNA"
                                        [stroke]="chartOptions.stroke"
                                        [states]="chartOptions.states"
                                    ></apx-chart>
                                    </div>
                                </td>
                                <td (click)="openOfferDetailInNewWindow(network)" class="col-lg-1 col-md-1  text-center">
                                    <div *ngIf="!network.chart">
                                        <apx-chart 
                                        [series]="network.facebookPage.instagramObject.audienceAge.series"
                                        [chart]="chartOptions.chart"
                                        [labels]="network.facebookPage.instagramObject.audienceAge.labels"
                                        [legend]="chartOptions.legend"
                                        [dataLabels]="chartOptions.dataLabels"
                                        [colors]="chartOptions.colors"
                                        [stroke]="chartOptions.stroke"
                                    ></apx-chart>
                                    </div>
                                    <div *ngIf="network.chart">
                                        <apx-chart 
                                        [series]="network.pieTest.series"
                                        [chart]="chartOptions.chart"
                                        [labels]="network.pieTest.labels"
                                        [legend]="chartOptions.legend"
                                        [dataLabels]="chartOptions.dataLabels"
                                        [colors]="chartOptions.colorsNA"
                                        [stroke]="chartOptions.stroke"
                                        [states]="chartOptions.states"
                                    ></apx-chart>
                                    </div>
                                </td>
                                <td (click)="openOfferDetailInNewWindow(network)" class="col-lg-1 col-md-1 text-center">
                                    <div *ngIf="network.chart">
                                        <apx-chart 
                                        [series]="network.pieTest.series"
                                        [chart]="chartOptions.chart"
                                        [labels]="network.pieTest.labels"
                                        [legend]="chartOptions.legend"
                                        [dataLabels]="chartOptions.dataLabels"
                                        [colors]="chartOptions.colorsNA"
                                        [stroke]="chartOptions.stroke"
                                        [states]="chartOptions.states"
                                    ></apx-chart>
                                    </div>
                                    <div *ngIf="!network.chart">
                                        <apx-chart 
                                        [series]="network.facebookPage.instagramObject.audienceGender.series"
                                        [chart]="chartOptions.chart"
                                        [labels]="network.facebookPage.instagramObject.audienceGender.labels"
                                        [legend]="chartOptions.legend"
                                        [dataLabels]="chartOptions.dataLabels"
                                        [colors]="chartOptions.colors"
                                        [stroke]="chartOptions.stroke"
                                    ></apx-chart>
                                    </div>
                                </td>
                                <td  class="col-lg-1 col-md-1  text-center">
                                    
                                    <button (click)="setIndividualOffer(network)" type="button" style="font-size: smaller;" class="mt-2 btn btn-secondary"><span class="fa fa-arrow-right"></span></button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="pagination">
                            <pagination-controls (responsive)="true" (pageChange)="p = $event" previousLabel = "" nextLabel = ""></pagination-controls>
                          </div>
                      </div>
                      
                      <div  *ngIf="sendOffer == false" flex-center class="col-md-12 col-lg-12 mt-2 mb-3 text-center">
                        <button (click)="showArrSelected()" class="btn btn-secondaryWithBorder shadow-none" >Save Selection</button>
                    </div>
                  </mat-card-content>
              </mat-card>
              <mat-card *ngIf="sendOffer && userReady" class=" mt-4" >
                <mat-card-content>
                  <div  class=" widgetContainer mt-2 mb-2">
                    <div class="widget" flex-center >
                        <div class="elemCenter">
                            <div> <b>Revenue - Total Sent</b> </div>
                            <div mask="separator.2">{{revenueTotalSent}} €</div>
                        </div>
                        <div class="lineVertical"></div>
                        <div class="elemCenter">
                            <div> <b> Revenue Target</b> </div>
                            <div mask="separator.2">{{campaign.revenueTarget}}€</div>
                        </div>
                    </div>
                    <div class="widget2" flex-center >
                        <div class="elemCenter">
                            <div> <b>Delivery  - Total Sent</b> </div>
                            <div mask="separator.2">{{deliveryTotalSent}} €</div>    
                        </div>
                        <div class="lineVertical"></div>
                        <div class="elemCenter">
                            <div> <b> Delivery Target</b> </div>
                            <div mask="separator.2">{{campaign.deliveryTarget}}€</div>
                        </div>
                  </div > 
                </div> 
                <div *ngIf="sendOffer" class="mt-5 tableResponsiveAnnex table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md">
                    <div  class="panel-header-campaign ">
                        <div class="col-lg-12">
                            <div class="labelFont" >Network Selected</div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <mat-divider ></mat-divider>
                    </div>
                    <table class="table table-hover  tableTitle  text-center">
                        <thead class="bodyTable">
                            <tr class="tableTitle col-lg-12">
                                <th class="col-lg-1 text-center" scope="col">Picture</th>
                                <th class=" col-lg-1 text-center" scope="col">Network </th>
                                <th class="col-lg-2  text-center  " scope="col">Delivery Target</th>
                                <th class="col-lg-2  text-center  " scope="col">Sales Models</th>
                                <th class="col-lg-2  text-center " scope="col">Prix Unitaire</th>
                                <th class=" col-lg-2 text-center  " scope="col">Revenue Target</th>
                                <th class="col-lg-3  text-center"scope="col">Action</th>
                            </tr>
                            </thead>
                        <tbody  *ngFor = "let network of arrayToSendOffer; let index = index;" class="bodyTable ">
                            
                            <td class="text-center">
                                <img *ngIf="network.influencerInfo.picture" [defaultImage]="'assets/images/logo/profile-default.jpeg'" [errorImage]="'assets/images/logo/profile-default.jpeg'" [lazyLoad]="fctBasic.checkUrlPath(network.influencerInfo.picture)"   style="height: 75px; width: 75px; border-radius: 50px;">
                                <img *ngIf="!network.influencerInfo.picture" src="assets/images/logo/profile-default.jpeg" style="height: 75px; width: 75px; border-radius: 50px;">
                            </td>
                            <td class=" text-center">
                                <div class="col-lg-12">
                                    <span>{{network.influencerInfo.firstname}} {{network.influencerInfo.lastname}}</span>
                                </div>
                                <div class="col-lg-12">
                                   <span (click)="fctBasic.checkUrlPathSocialNetwork(network, campaign.platformeType)">{{fctBasic.getNetworkNameForInfluenceur(network, campaign.platformeType) | slice:0:20}}</span>
                                </div>
                                <div class="col-lg-12">
                                    <p > 
                                        <i *ngIf="platformeType == 'Instagram'" class="fa fa-instagram"></i>
                                        <i *ngIf="platformeType == 'Facebook'" class="fa fa-facebook"></i>
                                        <i *ngIf="platformeType == 'Youtube'" class="fa fa-youtube"></i>
                                        {{fctBasic.getPageSizeForNetwork(network, campaign.platformeType) | number}} Subscribers</p>
                                </div>
                            </td>
                            
                            <td class="text-center ">
                                <input type="text" class="form-control txtSelector" mask="separator.2" (keyup)="calculateT(network)" [(ngModel)]="network.deliveryTarget" [ngModelOptions]="{standalone: true}"  >
                            </td>
                            <td class="text-center  ">
                                <input type="text" class="form-control txtSelector"  [(ngModel)]="campaign.saleModels" [ngModelOptions]="{standalone: true}" disabled>
                            </td>
                            <td class="text-center ">
                                <input type="text" (keyup)="calculateT(network)" class="form-control txtSelector" mask="separator.2" suffix=" €"[(ngModel)]="network.prixUnitaire" [ngModelOptions]="{standalone: true}">
                            </td>
                            <td class="text-center ">
                                <input type="text" disabled class="form-control txtSelector" mask="separator.2" suffix=" €" [(ngModel)]="network.revenueTarget" [ngModelOptions]="{standalone: true}">
                               
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="sendOfferForUser(network, index)"  class="btn btn-secondaryWithBorder shadow-none">Send Offer</button>
                            </td>
                        </tbody>
                    </table>
                </div>
              </mat-card-content>
          </mat-card>
        </div> 
    </body>
</html>