<html>

<body>
  <div class="page container-fluid ">
      <div class="row">
          <div class="col-md-6 col-lg-12 col-sm-12 col-xs-12">
              <div class=" tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
                <div  class="panel-header ">
                  <div class="top-lead">
                    <div class="group clearfix">
                        <i class="fa fa-bullhorn    icon mb-0 mt-0"></i>
                        <div  style="font-size:20px" class="labelFont text-normal">Campaigns</div>
                    </div>
                  </div>
                </div>
                <table class="table table-hover tableTitle text-center ">
                  <thead>
                      <tr>
                        <th class="col-3 text-center" scope="col">Status</th>
                        <th class="col-3 text-center" scope="col">Date</th>
                        <th class="col-3 text-center" scope="col">Campaign Name </th>
                        <th class="col-3 text-center" scope="col">Network</th>
                      </tr>
                    </thead>
                  <tbody class="bodyTable">
                    <tr [routerLink]="['/dashboard-contact/', campaign._id]" *ngFor = "let campaign of dataSourceCampaign; let i=index">
                      <td class="center-text">
                        <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(campaign.status)}" > {{fctBasic.getNameByStatus(campaign.status)}}</label>
                      </td>
                      <td class="center-text">
                        {{campaign.startDate | date:'yyyy/MM/dd'}} - {{campaign.endDate | date:'yyyy/MM/dd'}}
                      </td>
                      <td class="center-text">
                        {{campaign.campaignName | slice:0:40}}
                      </td>
                      <td class="center-text">
                        <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(campaign.platformeType)}" style="color:white">
                          <i  *ngIf="campaign.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                          <i *ngIf="campaign.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                          <i *ngIf="campaign.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                          <i *ngIf="campaign.platformeType == 'Blog'" class="fa fa-bold "></i>
                          <i *ngIf="campaign.platformeType == 'multiNetwork'" class="fa fa-globe"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
      </div>
  </div>
</body>

</html>