
 <mat-toolbar style="background: black;color: #babcc4;" *ngIf="sidenav.mode === 'over' && (boolInfluencer ||boolAdmin)" class="mat-elevation-z8">
    <button  mat-icon-button *ngIf="sidenav.mode === 'over' && (boolInfluencer ||boolAdmin)" (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened">
            menu
        </mat-icon>
        <mat-icon *ngIf="sidenav.opened">
            close
        </mat-icon>
    </button>
    
</mat-toolbar>
<mat-sidenav-container >
   
    <mat-sidenav  #sidenav="matSidenav" class="mat-elevation-z8" >

        <img class="mat-elevation-z8" routerLink="/home-admin" style="max-width: 150px; max-height: 61px; width: auto; padding-top: 10px;"  src="/assets/images/logo/influenceLabLogo.png"  />
        <button *ngIf="boolAdmin" routerLink="/home-admin" mat-button class="menu-button mt-2 center-text">
            <mat-icon>home</mat-icon>
            <span>Home</span>
        </button>
        <button *ngIf="boolInfluencer" routerLink="/home" mat-button class=" mt-2 menu-button">
            <mat-icon>home</mat-icon>
            <span>Home</span>
        </button>
        <button *ngIf="boolContact" routerLink="/home-contact" mat-button class=" mt-2 menu-button">
            <mat-icon>home</mat-icon>
            <span>Home</span>
        </button>
        <button  *ngIf="boolAdmin" routerLink="/listInfluencer" mat-button class=" mt-2 menu-button">
            <!-- <mat-icon>person</mat-icon> -->
            <mat-icon class="mat-iconFa"><i class="fa fa-child"></i></mat-icon>
            <span>Influencers</span>
        </button>
        <button *ngIf="boolAdmin" routerLink="/listOffers" mat-button class=" mt-2 menu-button">
            <mat-icon class="mat-iconFa"><i class="fa fa-gift"></i></mat-icon>
            <span>Offers</span>
        </button>
        <button *ngIf="boolInfluencer" routerLink="/myOffers" mat-button class=" mt-2 menu-button">
            <mat-icon>card_giftcard</mat-icon>
            <span>Offers</span>
        </button>
        <button  *ngIf="boolAdmin" routerLink="/listCampaign"  mat-button class="mt-2 menu-button">
            <mat-icon class="mat-iconFa"><i class="fa fa-bullhorn"></i></mat-icon>
            <span>Campaigns</span>
        </button>
        <button  *ngIf="boolInfluencer" routerLink="/profile" mat-button class="mt-2 menu-button">
            <mat-icon>person</mat-icon>
            <span>Account</span>
        </button>
        <button  *ngIf="boolAdmin"  routerLink="/list-company" mat-button class="mt-2 menu-button">
            <mat-icon>assessment</mat-icon>
            <span>Company</span>
        </button>
        <button *ngIf="boolAdmin"  routerLink="/list-contact"  mat-button class="mt-2 menu-button">
            <mat-icon>assessment</mat-icon>
            <span>Contact</span>
        </button>
        <!-- <button *ngIf="boolAdmin"  routerLink="/pigeExternal"  mat-button class="mt-2 menu-button">
            <mat-icon>note_add</mat-icon>
            <span>Pige</span>
        </button> -->
        <button (click)="logoutAdmin()" mat-button class="mt-2 menu-button inner-content">
            <mat-icon>power_settings_new</mat-icon>
            <span>Logout</span>
        </button>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet>
            
        </router-outlet>
         
        <!-- <div class="footerMat " fxFlexOffset="auto">
            <p class="left">&copy; Copyright 2019 <strong>Influence Lab</strong>, All rights reserved. <a href="/legal" target="_blank">Legal</a></p>
        </div>   -->
    </mat-sidenav-content>
    
    
</mat-sidenav-container>
