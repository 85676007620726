import { ApiService } from 'src/app/service/api.service';
import {CampagneModel} from 'src/app/model/campagne-model.model'
import {OfferModel} from 'src/app/model/offer-model.model'
import {userModel} from 'src/app/model/user-model.model'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})

export class HomeAdminComponent implements OnInit {
  dataSourceCampaign : any[]
  dataSourceOffer : any[]
  dataSourceInfluencer : userModel[]
  newMessage : string = ""
  receiverName : any = ""
  arrayMessage : any[]
  user : any
  userArraytosendEmmail: any[]
  constructor(private service: ApiService, public fctBasic: CampagneAndOfferFctService, private cdRef:ChangeDetectorRef, private toastr: ToastrService,) { 
    this.service = service
    this.service.getCampaignsHome().subscribe(campaigns => { 
      if (campaigns != 400){
        this.dataSourceCampaign = campaigns;
        this.cdRef.detectChanges();
      }
    })
    this.service.getInfluencersHome().subscribe(influencers => {
      if (influencers != 400){
        this.dataSourceInfluencer = influencers;
        this.cdRef.detectChanges();
      } 
    })
    this.service.getOffresHome().subscribe(offers => { 
      this.dataSourceOffer = offers;
      this.cdRef.detectChanges();
    })

    
  }
  ngOnInit(): void {
    this.service.getUser().subscribe(user => {
      this.user = user
    })
    

    
  }

  // sendNewMessage(){
  //   if (!this.receiverName){
  //     this.toastr.error("Please select one influenceur for send message.")
  //     return 
  //   }
  //   if (this.newMessage == ""){
  //     this.toastr.error("Your message is empty")
  //     return
  //   }
  //   let newmessage = {
  //     "receiverId" : this.receiverName._id,
  //     "senderName" : "admin@influencelab.co",
  //     "senderId" : this.user._id,
  //     "senderType": "admin",
  //     "receiverName": this.receiverName.email,
  //     "body": this.newMessage,
  //     "creationDate": new Date(),
  //   }
  //   this.service.sendMessage(newmessage).subscribe(res => {
  //     this.toastr.success('Message sent to Influencer !', 'Message sent');
	// 		this.newMessage = ""
  //     this.cdRef.detectChanges();
  //   })
  // }

  // addMessageBox(user : any){
    
    
  // }
}
