import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import {toast} from 'src/app/model/toast'
@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css']
})
export class CreateCompanyComponent implements OnInit {
  company: FormGroup;
  companyName = new FormControl("", [Validators.required])
  creationDate = new FormControl(new Date())
  siren = new FormControl("");
  toast = toast
  siret = new FormControl("");
  filiale = new FormControl("");
  type = new FormControl("agence");
  mandatFiles = new FormControl({});
  email = new FormControl("");
  phone = new FormControl("");
  website = new FormControl("");
  fax = new FormControl("");
  prelevement = new FormControl(true);
  tvaIntracommunautaire = new FormControl("");
  apeCode = new FormControl("");
  secteur = new FormControl("");
  files = new FormControl("");
  listContact : any
  constructor(
    private fb: FormBuilder, 
    public api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private cdRef:ChangeDetectorRef
    ) {
    
   }

  ngOnInit(): void {

    this.company = this.fb.group({
      companyName: this.companyName,
      creationDate: this.creationDate,
      siren: this.siren,
      siret: this.siren,
      filiale: this.filiale,
      type: this.type,
      mandatFiles: this.mandatFiles,
      email: this.email,
      phone: this.phone,
      website: this.website,
      fax: this.fax,
      prelevement: this.prelevement,
      tvaIntracommunautaire: this.tvaIntracommunautaire,
      apeCode: this.apeCode,
      secteur: this.secteur,
      files: this.files,
    })
    this.api.getCompanies().subscribe(res => {
      this.listContact = res
    })
  }

  createCompany(){
    if (this.company.valid === true){
      this.api.createCompany(this.company.value).subscribe(res => {
        this.toastr.success(this.toast.companyCardCreate)
        this.router.navigate(['/list-company'])
      })
    }
  }

}
