import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import {Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { ToastrService } from 'ngx-toastr';
import {MediaService } from 'src/app/service/media.service';
import {toast} from 'src/app/model/toast'

@Component({
  selector: 'app-influenceur-billing',
  templateUrl: './influenceur-billing.component.html',
  styleUrls: ['./influenceur-billing.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css'],
  providers: [MediaService]
})
export class InfluenceurBillingComponent implements OnInit {
  
  offer :any
  modifContent = false
  toast = toast
  modifNewElement = false
  createContent = true
  offerShow = false
  invoiceTxt = "Destinataire Influencelab. Service gestion: 90 rue Raynouard - 75016 Paris \n RCS Paris No 842 653 602." 
  constructor(
    private fb: FormBuilder,
    public picService: MediaService,
    private route: ActivatedRoute,
    private apiService : ApiService,
    private toastr: ToastrService,
    private router: Router,
    private cdRef:ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.apiService.getOffre(this.route.snapshot.params.id).subscribe(offer => { 
      this.offer = offer
      this.offerShow = true
      this.cdRef.detectChanges()
    })
  }

  addFile(event : any, type: string){
    if(!event.target.files[0] || event.target.files.length == 0) {
      return false;
    }
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.picService.getExtentionFile(event.target.files[0])
      if (extention.extention != false){
        
        const fd = new FormData();
        fd.append('file', event.target.files[0], event.target.files[0].name);
        this.apiService.postFile(fd).subscribe(path => {
          if (!this.offer.billing){
            this.offer.billing = {}
          }
          switch (type) {
            case "rib":
              this.offer.billing.rib = path[0]
              this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
                this.cdRef.detectChanges()
              })
              break;
            case "attestation":
              this.offer.billing.attestation = path[0]
              this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
                this.cdRef.detectChanges()
              })
              break;
            case "invoice":
                this.offer.billing.invoice = path[0]
                this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
                  this.cdRef.detectChanges()
                })
                break;
            default:
              break;
          }
        })
        this.cdRef.detectChanges()
        return event.target.files[0]
      } else {
        this.toastr.warning(this.toast.invoiceFileType)
      }
    }
    reader.readAsDataURL(event.target.files[0]);
    return false
    }
  
    deleteFile(file : any, type: any){
      switch (type) {
        case "invoice":
          this.offer.billing.invoice = null
          this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
            this.cdRef.detectChanges()
  
          })
          break;
        case "rib":
          this.offer.billing.rib = null
          this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
            this.cdRef.detectChanges()
  
          })
          break;
        case "attestation":
          this.offer.billing.attestation = null
          this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
            this.cdRef.detectChanges()
          })
          break;
        default:
          break;
      }
      this.apiService.deletePicture(file).subscribe(res => {
        this.cdRef.detectChanges()
      })
    }
  
    createNewContent(){
      if (!this.offer.billing || !this.offer.billing.invoice){
        this.toastr.warning(this.toast.invoiceNot)
        return
      }
      if (!this.offer.billing || !this.offer.billing.rib){
        this.toastr.warning(this.toast.RibNot)
        return
      }
     this.offer.billing.creationDate = new Date()
      this.offer.status = "Payment Pending"
      this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
          this.toastr.success(this.toast.invoiceFileReceive);
          this.sendMailtoAdmin()
          this.router.navigate(['/myOffers'])
      })
    }

    sendMailtoAdmin(){
      
      this.apiService.userSendHerInvoice(this.offer.influencerObjects.name, this.offer.campaignName).subscribe(res =>{
        console.log("reee")
      })
    }

}
