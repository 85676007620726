import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: 'app-ratio-picture',
  templateUrl: './ratio-picture.component.html',
  styleUrls: ['./ratio-picture.component.css']
})
export class RatioPictureComponent implements OnInit {
  @Input()
  public src: string;

  @Input()
  public minAspectRatio: number = 4 / 3;

  @Input()
  public maxAspectRatio: number = 1.77; // (16:9)

  public minHeight = 1000;
  public maxHeight = "auto";

  constructor() {}

  public onLoad(event: Event) {
    const el: HTMLImageElement = event.target as HTMLImageElement;
    const width = el.naturalWidth;
    const height = el.naturalHeight;
    const aspectRatio = width / height;

    if (aspectRatio >= this.maxAspectRatio) {
      this.maxHeight = "auto";
    } else {
      // ideally instead of 300 this would be something more dynamic
      this.maxHeight = Math.min(height, 300) + "px";
    }

  }

  ngOnInit() {}

}
