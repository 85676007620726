<mat-nav-list>
    <div class="logo py-4">
    </div>
    <mat-divider></mat-divider>
    <h2 matSubheader class="mt-2">APPLICATIONS</h2>
  
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/home">
      <mat-icon>home</mat-icon> Home
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard">
      <mat-icon>dashboard</mat-icon> Dashboard
    </a>
    <a mat-list-item> <mat-icon>edit</mat-icon> Link 3 </a>
    <a mat-list-item> <mat-icon>edit</mat-icon> Link 4 </a>
    <a mat-list-item> <mat-icon>edit</mat-icon> Link 5 </a>
    <a mat-list-item> <mat-icon>edit</mat-icon> Link 6 </a>
  </mat-nav-list>


