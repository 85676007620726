<html>
    <body>
        <div class="container page">
            <mat-card class="mt-3 mb-5" >
                <div class="title-card" text-center>
                    <div>
                        <mat-label class="title">🤗 Pige Externe 🚀</mat-label>
                    </div>
                </div>
                <div *ngIf="exportFile == true" flex-center class="mt-2 mb-3">
                    <div *ngFor="let exportFile of exportArrayFiles">
                        <a *ngIf="exportFileUP == true" href="{{exportFile}}">
                            <button  mat-button   class="btn btn-secondaryWithBorder shadow-none md-2" >Download</button>
                        </a>
                    </div>
                </div>
                <mat-divider ></mat-divider>
                <mat-card-content class=" mt-4">
                    
                            <div class="row input-row  ">
                            <div class="col-md-6 col-lg-6 col-sm-12  display-mid ">
                                    <div class="col-lg-4 col-6  col-md-4">
                                        <mat-label class="labelFont">Ig Account *</mat-label>
                                        <i  class="fa fa-info ml-1" #tooltip="matTooltip"
                                        matTooltip="Mettre le nom du compte exemple hougoulson"
                                        aria-label="Button that displays a tooltip when focused or hovered over"
                                        matTooltipPosition="right"
                                        matTooltipClass="allow-cr"
                                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                        </i>
                                    </div>
                                    
                                    <div class="col-lg-8   col-md-8 ">
                                        
                                        <mat-form-field>
                                            <input (keyup.enter)="addElement('accounts')"  [(ngModel)]="accountsInput" matInput>
                                            <button  matSuffix mat-icon-button aria-label="Clear" (click)="addElement('accounts')">
                                              <mat-icon class="iconOffer">add</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <div  class="row col-lg-12 col-md-12">
                                            <div   (click)="deleteElement(i, 'accounts')"  *ngFor="let keyword of accounts; index as i" class="badge mt-1  mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px!important; line-height: 1.538461 !important;">
                                                {{ keyword }} x
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="col-md-6  col-lg-6 display-mid  col-sm-12">
                                    <div class="col-lg-4   col-md-4 ">
                                        <mat-label class="labelFont">Keyword  *</mat-label>
                                        <i  class="fa fa-info ml-1" #tooltip="matTooltip"
                                        matTooltip="Mettre le nom tag qui est dans le post ou reel qu'on souhaite recupérer exemple test"
                                        aria-label="Button that displays a tooltip when focused or hovered over"
                                        matTooltipPosition="right"
                                        matTooltipClass="allow-cr"
                                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                        </i>
                                    </div>
                                    <div class="col-lg-8  col-md-8 ">
                                            <mat-form-field>
                                                <input  (keyup.enter)="addElement('keywords')" [(ngModel)]="keywordsInput" matInput>
                                                <button  matSuffix mat-icon-button aria-label="Clear" (click)="addElement('keywords')">
                                                  <mat-icon class="iconOffer">add</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        <div  class="row col-lg-12 col-md-12">
                                            <div   (click)="deleteElement(i, 'keywords')"  *ngFor="let keyword of keywords; index as i" class="badge mt-1  mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px!important; line-height: 1.538461 !important;">
                                                {{ keyword }} x
                                            </div>
                                        </div>
                                    </div>
                            </div>  
                        </div>
                        <form  class="align-items-center"  [formGroup]="pigeExtern">
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-6 col-sm-12  display-mid  ">
                                    <div class="col-lg-4 col-6  col-md-4">
                                        <mat-label class="labelFont">Start Date</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field class="campagneselect2byline">
                                            <input matInput [matDatepicker]="picker1" formControlName="dateStart">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12  display-mid">
                                    <div class="col-lg-4 col-6  col-md-4 ">
                                        <mat-label class="labelFont">End Date</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field  class="campagneselect2byline">
                                            <input matInput [matDatepicker]="picker"  formControlName="dateEnd">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div *ngIf="exportFile == false && loading == false" flex-center class="mt-2 mb-3">
                            <button   (click)="getExternPige()" class="btn btn-secondaryWithBorder shadow-none " >Go</button>
                        </div>
                        <div *ngIf="exportFile == false && loading == true" flex-center class="mt-2 mb-3">
                            <div class="row">
                                <div class="bar"></div>
                            </div>
                        </div>
                        
                    </form>
                </mat-card-content>
            </mat-card>
            <mat-divider style="color: black;"></mat-divider>

            <mat-card class="mt-5" >
                <div class="title-card" text-center>
                    <div>
                        <mat-label class="title">🤗 Pige Express 🚀</mat-label>
                    </div>
                </div>
                
                <mat-divider ></mat-divider>
                <mat-card-content class=" mt-4">
                    <div class="row input-row  ">
                        <div class="col-md-6 col-lg-6 col-sm-12  display-mid ">
                                <div class="col-lg-4 col-6  col-md-4">
                                    <mat-label class="labelFont">Upload Picture</mat-label>
                                    <i  class="fa fa-info ml-1" #tooltip="matTooltip"
                                    matTooltip="Mettre le nom du compte exemple hougoulson"
                                    aria-label="Button that displays a tooltip when focused or hovered over"
                                    matTooltipPosition="right"
                                    matTooltipClass="allow-cr"
                                    aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                    </i>
                                </div>
                                
                                <div class="col-lg-8   col-md-8 ">
                                    <mat-form-field>
                                        <ngx-mat-file-input [multiple]="true" [autofilled]="false" (change)="modifPicture($event)"></ngx-mat-file-input>
                                        <mat-icon matSuffix>attach_file</mat-icon>
                                    </mat-form-field>
                                </div>
                        </div>
                        <div class="col-md-6 col-lg-6  col-sm-12">
                            <div class="col-md-12 col-lg-12 display-mid">
                                <div class="col-lg-4 col-12 col-md-4">
                                    <mat-label class="labelFont">Influenceur Name </mat-label>
                                </div>
                                <div class="col-lg-8 col-12 col-md-8">
                                    <mat-form-field >
                                        <input matInput  placeholder="" [email]="true" formControlName="secondarymail">
                                        <!-- <mat-error *ngIf="influenceur.value['email'].invalid">{{getErrorMessage()}}</mat-error> -->
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 col-sm-12  display-mid " *ngIf="picPige && picPige.post.length > 0">
                            <div class="imgrow" >
                                <div  *ngFor = "let x of picPige.post">
                                    <div class="img_wrapper_multi" *ngIf="x && x.picture">
                                        <span (click)="deleteImgForModif(x.picture, x)"  class="close">&times;</span>
                                        <img  [src]="fctBasic.checkUrlPath(x.picture)"    class="img-responsive center-block imgFeedMulti">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div *ngIf="picPige" flex-center class="mt-2 mb-3">
                        <button   (click)="generateExpress()" class="btn btn-secondaryWithBorder shadow-none " >Create Pige Pttx</button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </body>
</html>