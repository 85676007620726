import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute} from '@angular/router'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { ShareCampagneOnContactUserComponent } from 'src/app/dialog/share-campagne-on-contact-user/share-campagne-on-contact-user.component';
import { ShowOfferDetailsComponent } from 'src/app/dialog/show-offer-details/show-offer-details.component';
import {data} from 'src/app/model/apx-chartCampagneDashboard.model'

@Component({
  selector: 'app-dashboard-campagne',
  templateUrl: './dashboard-campagne.component.html',
  styleUrls: ['./dashboard-campagne.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dashboard.css'],
})
export class DashboardCampagneComponent implements OnInit {
  chartOptions : any = {}
  selectedTabIndex = 0;
  selectedTabIndexContent = 0;
  showOffer = true
  showPost = false
  showReel = false
  showStory = false

  overviewImpSeleted = true
  overviewReachSeleted = false
  overviewEngagementSeleted = false
  overviewPlaysSeleted = false
  expendedOverviewStories = false
  expendedOverViewPosts = false
  expendedOverViewReels = false

  storiesTxtOverviewSeleted = ""
  storiesPicOverviewSeleted = ""
  storieImpSeleted = true
  storieReachSeleted = false
  storieEngagementSeleted = false
  expendedStories = false
  textSelectedStory = ""
  srcSelectedStory = ""


  postTxtOverviewSeleted = ""
  postPicOverviewSeleted = ""
  expendedPosts = false
  postImpSeleted = true
  postReachSeleted = false
  postEngagementSeleted = false
  srcPostImg = ""
  srcPostCaption = ""

  reelTxtOverviewSeleted = ""
  reelPicOverviewSeleted = ""
  textSelectedReel = ""
  srcSelectedReel = ""
  reelImpSeleted = true
  reelReachSeleted = false
  reelEngagementSeleted = false
  expendedReels = false
  srcReelImg = ""
  srcReelCaption = ""




  chartImpressionSelected = false
  chartReachSelected = false
  selectedTabPostIndex = 0;
  selectedTabStoryIndex = 0;

  dashboardData: any = null;
  readyToShow = false
  excelOverviewUrl : any = ""
  excelUrl : any = ""
  pptxUrl : any = ""
  pigeReady = false

  globalEngagement : 0
  globalEngagementPost : 0
  globalEngagementReel : 0
  
  constructor(
    private apiService : ApiService,
    private route: ActivatedRoute,
    public fctBasic: CampagneAndOfferFctService,
    public dialog: MatDialog,
    private cdRef:ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.apiService.getDashboardCampagne(this.route.snapshot.params.id).subscribe(dashboardData => {
      this.dashboardData = dashboardData
      this.chartOptions = data
      this.chartImpressionSelected = true
      this.readyToShow = true
      if (dashboardData.dashboardCampaign.labelData.length == 1){
        this.chartOptions.chart.type = "bar"
        this.cdRef.detectChanges();
      }
      if (dashboardData.contenuCampaignStory.length > 0){
        this.textSelectedStory = this.dashboardData.contenuCampaignStory[0].caption
        this.srcSelectedStory = this.dashboardData.contenuCampaignStory[0].picture
        this.storiesTxtOverviewSeleted = dashboardData.contenuCampaignStory[0].caption
        this.storiesPicOverviewSeleted = dashboardData.contenuCampaignStory[0].picture
        this.cdRef.detectChanges();
      }
      if (dashboardData.contenuCampaignPost.length > 0){
        this.srcPostImg = this.dashboardData.contenuCampaignPost[0].picture
        this.srcPostCaption = this.dashboardData.contenuCampaignPost[0].caption
        this.postTxtOverviewSeleted = dashboardData.contenuCampaignPost[0].caption
        this.postPicOverviewSeleted = dashboardData.contenuCampaignPost[0].picture
        this.cdRef.detectChanges();
      }
      if (dashboardData.contenuCampaignReel.length > 0){
        this.srcReelImg = this.dashboardData.contenuCampaignReel[0].picture
        this.srcReelCaption = this.dashboardData.contenuCampaignReel[0].caption
        this.reelTxtOverviewSeleted = dashboardData.contenuCampaignReel[0].caption
        this.reelPicOverviewSeleted = dashboardData.contenuCampaignReel[0].picture
        this.cdRef.detectChanges();
      }
      dashboardData.campagneTotalStats.totalEngagement ? 
      this.globalEngagement = dashboardData.campagneTotalStats.totalEngagement : 
      this.globalEngagement =  dashboardData.campagneTotalStats.totalLike + dashboardData.campagneTotalStats.totalComment
      dashboardData.campagneTotalStatsPost.totalEngagement ? 
      this.globalEngagementPost = dashboardData.campagneTotalStatsPost.totalEngagement : 
      this.globalEngagementPost =  dashboardData.campagneTotalStatsPost.totalLike + dashboardData.campagneTotalStatsPost.totalComment
      dashboardData.campagneTotalStatsReel.totalEngagement ? 
      this.globalEngagementReel = dashboardData.campagneTotalStatsReel.totalEngagement : 
      this.globalEngagementReel =  dashboardData.campagneTotalStatsReel.totalLike + dashboardData.campagneTotalStatsReel.totalComment
      this.cdRef.detectChanges();
      this.apiService.exportFile(dashboardData.campagne._id, dashboardData.campagne.startDate, dashboardData.campagne.endDate).subscribe(exportFile => { 
        this.excelOverviewUrl =  "/api/export/overview/" + exportFile.urlOverview
        this.excelUrl =  "/api/export/excel/" + exportFile.urlExcel
        this.pptxUrl =  "/api/export/pptx/" + exportFile.urlPptx
        this.pigeReady = true
        this.cdRef.detectChanges();
      })
    })

  }

  clickDialogOffersDetails(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.dashboardData.alias
    let option ={
     data: this.dashboardData.alias
    }
    const dialogRef = this.dialog.open(ShowOfferDetailsComponent, option);
    dialogRef.afterClosed().subscribe(result => { 
      });
  }

clickDialogContact(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.dashboardData.campagne
    let option ={
     data: this.dashboardData.campagne
    }
    const dialogRef = this.dialog.open(ShareCampagneOnContactUserComponent, option);
    dialogRef.afterClosed().subscribe(result => {
    //console.log(`Dialog result: ${result}`);
      });
  }

  showPic(elem : any, type: string, typeArr: string) {
    switch (type) {
      case "Overview":
        switch (typeArr) {
          case "Post":
            this.postPicOverviewSeleted = elem.picture
            this.postTxtOverviewSeleted = elem.caption
            this.cdRef.detectChanges();
            break;
          case "Reel":
            this.reelPicOverviewSeleted = elem.picture
            this.reelTxtOverviewSeleted = elem.caption
            this.cdRef.detectChanges();
            break;
          case "Storie":
            this.storiesPicOverviewSeleted = elem.picture
            this.storiesTxtOverviewSeleted = elem.caption
            this.cdRef.detectChanges();
            break;
          default:
            break;
        }
        break;
      case  "Post":
        this.srcSelectedStory = elem.picture
        this.textSelectedStory = elem.caption
        this.cdRef.detectChanges();
        break
      case  "Storie":
        this.srcSelectedStory = elem.picture
        this.textSelectedStory = elem.caption
        this.cdRef.detectChanges();
        break
      case "Reel":
        this.textSelectedReel = elem.caption
        this.srcSelectedReel = elem.picture
        this.cdRef.detectChanges();
        break;
      default:
        break;
    }

  }

  showPicPost(elem : any) {
    this.srcPostImg = elem.picture
    this.srcPostCaption = elem.caption
  }

  showPicReel(elem : any) {
    this.srcReelImg = elem.picture
    this.srcReelCaption = elem.caption
  }

  showPicStory(elem : any) {
    this.textSelectedStory = elem.caption
    this.srcSelectedStory = elem.picture
  }

  showReach() {
    this.chartReachSelected = true
    this.chartImpressionSelected = false
  }

  showImp() {
    this.chartImpressionSelected = true
    this.chartReachSelected = false
  }

  setTabIndex(index: any) {
    this.selectedTabIndex = index
  }

  setTabPostIndex(index: any) {
    this.selectedTabPostIndex = index;
  }

  setTabStoryIndex(index: any) {
    this.selectedTabStoryIndex = index;
  }

  onTabChanged(event : any){
    switch (event.tab.textLabel) {
      case "Overview":
        if (this.dashboardData.dashboardCampaign.labelData.length == 1){
          this.chartOptions.chart.type = "bar"
          this.cdRef.detectChanges();
        } else {
          this.chartOptions.chart.type = "area"
          this.cdRef.detectChanges();
        }
        this.showOffer = true
        this.showPost = false
        this.showStory = false
        this.showReel = false
        break;
      case "Reels":
        if (this.dashboardData.dashboardCampaignReel.labelData.length == 1){
          this.chartOptions.chart.type = "bar"
          this.cdRef.detectChanges();
        } else {
          this.chartOptions.chart.type = "area"
          this.cdRef.detectChanges();
        }
        this.showOffer = false
        this.showPost = false
        this.showStory = false
        this.showReel = true
        this.cdRef.detectChanges();
        break;
      case "Posts":
        if (this.dashboardData.dashboardCampaignPost.labelData.length == 1){
          this.chartOptions.chart.type = "bar"
          this.cdRef.detectChanges();
        } else {
          this.chartOptions.chart.type = "area"
          this.cdRef.detectChanges();
        }
        this.showOffer = false
        this.showPost = true
        this.showStory = false
        this.showReel = false
        this.cdRef.detectChanges();
        break;
      case "Stories":
        if (this.dashboardData.dashboardCampaignStory.labelData.length == 1){
          this.chartOptions.chart.type = "bar"
          this.cdRef.detectChanges();
        } else {
          this.chartOptions.chart.type = "area"
          this.cdRef.detectChanges();
        }
        this.showOffer = false
        this.showPost = false
        this.showStory = true
        this.showReel = false
      break;

      default:
        break;
    }
  }

  showOverviewChart(elem : any){
    switch (elem) {
      case "reach":
        this.overviewImpSeleted =false
        this.overviewReachSeleted = true
        this.overviewEngagementSeleted = false
        this.overviewPlaysSeleted = false
        this.cdRef.detectChanges();
        break;
      case "plays":
        this.overviewImpSeleted =false
        this.overviewReachSeleted = false
        this.overviewEngagementSeleted = false
        this.overviewPlaysSeleted = true

        this.cdRef.detectChanges();
        break;
      case "impression":
        this.overviewImpSeleted =true
        this.overviewReachSeleted = false
        this.overviewEngagementSeleted = false
        this.overviewPlaysSeleted = false
        this.cdRef.detectChanges();
        break;
        case "engagement":
          this.overviewImpSeleted = false
          this.overviewReachSeleted = false
          this.overviewEngagementSeleted = true
          this.overviewPlaysSeleted = false
          this.cdRef.detectChanges();
      break;
      default:
        break;
    }
  }

  showReelChart(elem : any){
    switch (elem) {
      case "reach":
        this.reelImpSeleted = false;
        this.reelReachSeleted = true
        this.reelEngagementSeleted = false
        break;
      case "impression":
        this.reelImpSeleted =true
        this.reelReachSeleted = false
        this.reelEngagementSeleted = false
        break;
        case "engagement":
          this.reelImpSeleted = false
          this.reelReachSeleted = false
          this.reelEngagementSeleted = true
      break;
      default:
        break;
    }
  }

  showPostChart(elem : any){
    switch (elem) {
      case "reach":
        this.postImpSeleted = false;
        this.postReachSeleted = true
        this.postEngagementSeleted = false
        break;
      case "impression":
        this.postImpSeleted =true
        this.postReachSeleted = false
        this.postEngagementSeleted = false
        break;
        case "engagement":
          this.postImpSeleted = false
          this.postReachSeleted = false
          this.postEngagementSeleted = true
      break;
      default:
        break;
    }
  }

  showStoriesChart(elem : any){
    switch (elem) {
      case "reach":
        this.storieImpSeleted =false
        this.storieReachSeleted = true
        this.storieEngagementSeleted = false
        this.cdRef.detectChanges();
        break;
      case "impression":
        this.storieImpSeleted =true
        this.storieReachSeleted = false
        this.storieEngagementSeleted = false
        this.cdRef.detectChanges();
        break;
        case "engagement":
          this.storieImpSeleted = false
          this.storieReachSeleted = false
          this.storieEngagementSeleted = true
          this.cdRef.detectChanges();
      break;
      default:
        break;
    }
  }

}
