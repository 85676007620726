import { ApiService } from 'src/app/service/api.service';
import {CampagneModel} from 'src/app/model/campagne-model.model'
import {OfferModel} from 'src/app/model/offer-model.model'
import {userModel} from 'src/app/model/user-model.model'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import {UserLoginService} from 'src/app/service/user-login.service';

@Component({
  selector: 'app-home-contact',
  templateUrl: './home-contact.component.html',
  styleUrls: ['./home-contact.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class HomeContactComponent implements OnInit {
  dataSourceCampaign : any[]
  userContact : any
  constructor(
    private service: ApiService, 
    private authService : UserLoginService,
    public fctBasic: CampagneAndOfferFctService, 
    private cdRef:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.userContact = this.authService.getUserValue()
    this.service.getCampaignsForContact(this.userContact.contactObject).subscribe(campaigns => { 
      if (campaigns != 400){
        this.dataSourceCampaign = campaigns;
        this.cdRef.detectChanges();
      }
    })
  }

}
