<h4  class="dialog-headline-facebook" mat-dialog-title>🤗 One moment please 🤗</h4>
<mat-dialog-content style="width: auto;height: auto;" class=" mt-3">
    <p  class="mt-1 text-center">Are you sure you don't want to connect your Facebook & Instagram accounts ?</p>
    <p  class="mt-1 text-center">It will simplify greatly our collaboration in the future.</p>
</mat-dialog-content>
<mat-dialog-actions >
    <div class="col-12 text-center">
        <button [routerLink]="['/register']" type="button"  mat-dialog-close  style="background-color: rgb(167, 167, 167);border-color: rgb(167, 167, 167);" class="btn  btn-secondary btn-template ">No, thanks</button>
        <a href="{{urlFacebook}}">
            <button type="button" class="btn  btn-secondary button-top btn-template ml-3" >Yes, I want to connect</button>
        </a>
    </div>
        
</mat-dialog-actions>