import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import {Router, ActivatedRoute } from '@angular/router';
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { ToastrService } from 'ngx-toastr';
import { DeletepageComponent } from 'src/app/dialog/deletepage/deletepage.component';
import { MatDialog} from '@angular/material/dialog';
import {UserLoginService} from 'src/app/service/user-login.service';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import {MediaInfluenceurService } from 'src/app/service/media-influenceur.service';
import {data} from 'src/app/model/apx-chartPieNetwork.model'
import {toast} from 'src/app/model/toast'
import { AddMoreSocialNetworkComponent } from 'src/app/dialog/add-more-social-network/add-more-social-network.component';

@Component({
  selector: 'app-detail-profil',
  templateUrl: './detail-profil.component.html',
  styleUrls: ['./detail-profil.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css'],
  providers: [MediaInfluenceurService]
})
export class DetailProfilComponent implements OnInit {
  currentUser : any
  toast = toast
  toppingList: string[] = ['fashion', 'beauty', 'health', 'food', 'homedeco', 'travel', 'automotive', 'tech', 'lifestyle', 'petcare'];
  init = false
  fbInit = false
  ytInit = false
  isDisabled = true
  topics : any
  ytUrl : string;
  fbUrl : string;
  keywords : any
  keyword : any;
  chartOptions : any = data;
  countries: Array<string> = ["France", "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Anguilla", "Antigua & Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas"
    , "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia & Herzegovina", "Botswana", "Brazil", "British Virgin Islands"
    , "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Chad", "Chile", "China", "Colombia", "Congo", "Cook Islands", "Costa Rica"
    , "Cote D Ivoire", "Croatia", "Cruise Ship", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea"
    , "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", "France", "French Polynesia", "French West Indies", "Gabon", "Gambia", "Georgia", "Germany", "Ghana"
    , "Gibraltar", "Greece", "Greenland", "Grenada", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong", "Hungary", "Iceland", "India"
    , "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Kyrgyz Republic", "Laos", "Latvia"
    , "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Mauritania"
    , "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Namibia", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia"
    , "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal"
    , "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia", "Rwanda", "Saint Pierre & Miquelon", "Samoa", "San Marino", "Satellite", "Saudi Arabia", "Senegal", "Serbia", "Seychelles"
    , "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "South Africa", "South Korea"
    , "Spain", "Sri Lanka", "St Kitts & Nevis", "St Lucia", "St Vincent", "St. Lucia", "Sudan"
    , "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor L'Este", "Togo", "Tonga", "Trinidad & Tobago", "Tunisia"
    , "Turkey", "Turkmenistan", "Turks & Caicos", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay"
    , "Uzbekistan", "Venezuela", "Vietnam", "Virgin Islands (US)", "Yemen", "Zambia", "Zimbabwe"];

  languages: Array<string> = ["French", "Afrikaans", "Albanian", "Amharic", "Arabic", "Aramaic", "Armenian", "Assamese", "Aymara", "Azerbaijani", "Balochi",
    "Bamanankan", "Bashkort (Bashkir)", "Basque", "Belarusan", "Bengali", "Bhojpuri", "Bislama", "Bosnian", "Brahui", "Bulgarian", "Burmese",
    "Cantonese", "Catalan", "Cebuano", "Chechen", "Cherokee", "Croatian", "Czech", "Dakota", "Danish", "Dari", "Dholuo", "Dutch", "English",
    "Esperanto", "Estonian", "Éwé", "Finnish", "French", "Georgian", "German", "Gikuyu", "Greek", "Guarani", "Gujarati", "Haitian Creole",
    "Hausa", "Hawaiian", "Hawaiian Creole", "Hebrew", "Hiligaynon", "Hindi", "Hungarian", "Icelandic", "Igbo", "Ilocano", "Indonesian (Bahasa Indonesia)",
    "Inuit/Inupiaq", "Irish Gaelic", "Italian", "Japanese", "Jarai", "Javanese", "K’iche’", "Kabyle", "Kannada", "Kashmiri", "Kazakh", "Khmer", "Khoekhoe",
    "Korean", "Kurdish", "Kyrgyz", "Lao", "Latin", "Latvian", "Lingala", "Lithuanian", "Macedonian", "Maithili", "Malagasy", "Malay (Bahasa Melayu)", "Malayalam",
    "Mandarin (Chinese)", "Marathi", "Mende", "Mongolian", "Nahuatl", "Navajo", "Nepali", "Norwegian", "Ojibwa", "Oriya", "Oromo", "Pashto", "Persian",
    "Polish", "Portuguese", "Punjabi", "Quechua", "Romani", "Romanian", "Russian", "Rwanda", "Samoan", "Sanskrit", "Serbian", "Shona", "Sindhi", "Sinhala",
    "Slovak", "Slovene", "Somali", "Spanish", "Swahili", "Swedish", "Tachelhit", "Tagalog", "Tajiki", "Tamil", "Tatar", "Telugu", "Thai", "Tibetic languages",
    "Turkish", "Turkmen", "Ukrainian", "Urdu", "Uyghur", "Uzbek", "Vietnamese", "Warlpiri", "Welsh", "Wolof", "Xhosa", "Yakut", "Yiddish", "Yoruba", "Yucatec",
    "Zapotec", "Zulu"]
  //////////////////////////////
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showGridLines: boolean = false
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Statistiques';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Population';
  legendTitle: string = 'Date';
  noBarWhenZero: boolean = false;
  legendPosition: any = 'below';
  view: [number, number] = [200, 150];
  isReadMore: boolean = true
  colorScheme = {
    domain: ['#006579', '#4bdbaa', "#cce386", "#e0de3d", "#f1b306", "#fc7304", "#ff3300", "#8d0000","#aa0033","#ff004c","#58013b","#7d008d", "#26008d"]
  };
  //////////////////////////////
  
  influenceur: FormGroup;
  constructor(
    private apiService : ApiService, 
    private route: ActivatedRoute,
    private cdRef:ChangeDetectorRef,
    public fctBasic:CampagneAndOfferFctService,
    public router: Router,
    public picService: MediaInfluenceurService,
    public toastr: ToastrService,
    public dialog: MatDialog,
    private authService : UserLoginService,
    private fb: FormBuilder,
  ) { 
    
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getUserValue()
    this.apiService.getUser().subscribe(res => {
      if (!res){
        this.authService.logout()
      } else {
        this.getYtToken()
        this.getFbToken()
        this.currentUser = res
        this.influenceur = this.fb.group({
          creationDate: new FormControl(this.currentUser.influencerInfo.creationDate),
          firstname: new FormControl({value:this.currentUser.influencerInfo.firstname, disabled: this.isDisabled}, Validators.required),
          lastname: new FormControl({value:this.currentUser.influencerInfo.lastname, disabled: this.isDisabled}, Validators.required),
          secondarymail: new FormControl({value:this.currentUser.influencerInfo.secondarymail, disabled: this.isDisabled}, Validators.required),
          phone: new FormControl({value:this.currentUser.influencerInfo.phone, disabled: this.isDisabled}, Validators.required),
          adress: new FormControl({value:this.currentUser.influencerInfo.adress, disabled: this.isDisabled}, Validators.required),
          postcode: new FormControl({value:this.currentUser.influencerInfo.postcode, disabled: this.isDisabled}, Validators.required),
          city: new FormControl({value:this.currentUser.influencerInfo.city, disabled: this.isDisabled}, Validators.required),
          country: new FormControl({value:this.currentUser.influencerInfo.country, disabled: this.isDisabled}, Validators.required),
          gender: new FormControl({value:this.currentUser.influencerInfo.gender, disabled: this.isDisabled}),
          birthday: new FormControl({value:this.currentUser.influencerInfo.birthday, disabled: this.isDisabled}),
          language: new FormControl({value:this.currentUser.influencerInfo.language, disabled: this.isDisabled}, Validators.required),
          secondaryLanguage: new FormControl({value:this.currentUser.influencerInfo.secondaryLanguage, disabled: this.isDisabled}),
          topics: new FormControl(this.tranformTopicsToArray(this.currentUser.influencerInfo.topics), Validators.required),
          keywords: new FormControl(this.currentUser.influencerInfo.keywords, Validators.required),
          picture: new FormControl(this.currentUser.influencerInfo.picture),
          valueArrTmp: new FormControl({value:"", disabled: this.isDisabled})
        })
        this.init = true
      }
    })
    
  }



  modify(){
    if (this.isDisabled === true){
      this.isDisabled = false
    }
    this.toastr.success(this.toast.editProfil)
    this.influenceur.enable()
    this.cdRef.detectChanges();
  }
  
  saveModification(){
    if (this.influenceur.valid === true){
      this.setterInfluencerFormOnJson(this.influenceur)
    }
  }

  public setterInfluencerFormOnJson(json: any){
    let check = this.authService.isAuthenticated()
    if (check == true){
      this.currentUser.influencerInfo.firstname = json.value['firstname']
      this.currentUser.influencerInfo.lastname = json.value['lastname']
      this.currentUser.influencerInfo.secondarymail = json.value['secondarymail']
      this.currentUser.influencerInfo.phone = json.value['phone']
      this.currentUser.influencerInfo.adress = json.value['adress']
      this.currentUser.influencerInfo.postcode = json.value['postcode']
      this.currentUser.influencerInfo.city = json.value['city']
      this.currentUser.influencerInfo.country = json.value['country']
      this.currentUser.influencerInfo.gender = json.value['gender']
      this.currentUser.influencerInfo.birthday = json.value['birth']
      this.currentUser.influencerInfo.language = json.value['language']
      this.currentUser.influencerInfo.secondaryLanguage = json.value['secondaryLanguage']
      this.currentUser.influencerInfo.keywords = json.value['keywords']
      this.currentUser.influencerInfo.picture = json.value['picture']
      let newtopicsJson = this.setTopics(json.value['topics'])
      this.currentUser.influencerInfo.topics = newtopicsJson
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.apiService.putUserInfluenceurInfo(this.currentUser._id, this.currentUser.influencerInfo).subscribe(res => {
        this.toastr.success(this.toast.compteModified)
        this.cdRef.detectChanges();
      })
    } else {
      console.log("check false")
    }
  }

  tranformTopicsToArray(elem: any){
    var arr = []
    if (elem.fashion == true){
      arr.push("fashion")
    }  if (elem.health == true){
      arr.push("health")

    }  if (elem.food == true) {
      arr.push("food")

    } if (elem.homedeco == true) {
      arr.push("homedeco")

    } if (elem.travel == true) {
      arr.push("travel")

    } if (elem.automotive == true) {
      arr.push("automotive")

    } if (elem.tech == true) {
      arr.push("tech")

    }  if (elem.lifestyle == true) {
      arr.push("lifestyle")

    }  if (elem.petcare == true) {
      arr.push("petcare")
    }
    return arr
  }

  setTopics (array : []){
    var newjsonTopics = {
      fashion: false,
      beauty: false,
      health: false,
      food: false,
      homedeco: false,
      travel: false,
      automotive: false,
      tech: false,
      lifestyle: false,
      petcare: false,
    }
    if (array){
      array.forEach(elem => {
        switch (elem) {
          case 'fashion':
            newjsonTopics.fashion = true
            break;
          case 'beauty':
            newjsonTopics.beauty = true
            break;
          case 'health':
            newjsonTopics.health = true
  
            break;
          case 'food':
            newjsonTopics.food = true
  
            break;
          case 'homedeco':
            newjsonTopics.homedeco = true
  
            break;
          case 'travel':
            newjsonTopics.travel = true
  
            break;
          case 'automotive':
            newjsonTopics.automotive = true
  
            break;
          case 'tech':
            newjsonTopics.tech = true
            break;
          case 'lifestyle':
            newjsonTopics.lifestyle = true
            break;
          case 'petcare':
            newjsonTopics.petcare = true
        
          break;
          default:
            break;
        }
      })
        return newjsonTopics
      } else {
        return newjsonTopics
      }
    
    }

  verifiedIndexOf(elem : string) : Boolean {
    let check = false
    this.influenceur.value.keywords.forEach((res : any) => {
     if (elem == res ){
       check = true
     }
    })
    return check
  }

  checkSpaceOnString(str : string) {
    let tmpstr = str.split(" ")
    tmpstr.forEach((elem) =>  {
      if (elem != " "){
        let verif = this.verifiedIndexOf(elem)
        if (verif == false){
          this.influenceur.value.keywords.push(elem)
        }
      }
    })
  }

  addKeyWords(){
    if (this.isDisabled){
      return
    }
    if (this.influenceur.value['valueArrTmp']){
      this.checkSpaceOnString(this.influenceur.value['valueArrTmp'])
      this.influenceur.controls['valueArrTmp'].setValue("")
    }
  }

  deleteKeyWords(index: number){
    if (this.isDisabled === true){
      return 
    }
    this.influenceur.value['keywords'].splice(index, 1)
  }

  addPicture(event : any){
    if (this.isDisabled){
      return false
    }
    if(!event.target.files[0] || event.target.files.length == 0) {
      return false;
    }
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.picService.getExtentionImg(event.target.files[0])
      if (extention != false){
        
        const fd = new FormData();
        fd.append('file', event.target.files[0], event.target.files[0].name);
        this.apiService.postPicture(fd).subscribe(path => {
          this.influenceur.controls['picture'].setValue(path[0]);
          this.cdRef.detectChanges()
        })
        this.cdRef.detectChanges()
        return event.target.files[0]
      }
    }
    reader.readAsDataURL(event.target.files[0]);
    return false
    }
  
    deleteImg(img : any){
      if (this.isDisabled){
        return
      }
      this.influenceur.controls['picture'].setValue("");
      this.apiService.deletePicture(img).subscribe(res => {
        this.cdRef.detectChanges()
      })
    }

    getYtToken(){
      this.apiService.getYoutubeToAccount().subscribe(res => {
        this.ytUrl = res.redirect
        this.ytInit = true
        this.cdRef.detectChanges()
      })
    }
    getFbToken(){
      this.apiService.getFacebookTokentoAccount().subscribe(res => {
        this.fbUrl = res.redirect
        this.fbInit = true;
        this.cdRef.detectChanges()
        
      })
    }

    openDialogError(compenentName: any, option : any) {
      const dialogRef = this.dialog.open(compenentName, option);
      dialogRef.afterClosed().subscribe(result => {
        
        console.log(`Dialog result: ${result}`);
      });
  }

  moreSocialNetwork(){
    
    this.openDialogError(AddMoreSocialNetworkComponent, {
      panelClass: 'dialog-container-custom',
      data: this.currentUser
    })
  }

}
