<html>
    <body>
        <div  class="page container-fluid  ">
          <div class="row">
            <div class="tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1" >
              <table *ngIf="loadOffer" class="table table-hover  tableTitle  text-center">
                <thead>
                    <tr class="tableTitle ">
                      <th class="col-1"scope="col">Status</th>
                      <th class="col-1" scope="col">Dates</th>
                      <th class="col-1"scope="col">Offer Name</th>
                      <th class="col-1"scope="col">Network</th>
                      <th class="col-1"scope="col">Your Mission</th>
                      <th class="col-1"scope="col">Curent Revenue</th>
                      <th class="col-1"scope="col">Revenue Goal</th>
                      <th class="col-1"scope="col">Page Name</th>
                    </tr>
                  </thead>
                <tbody class="bodyTable">
                  <tr *ngFor = "let offer of dataSourceOffer | paginate: { itemsPerPage: count, currentPage: p }" [routerLink]="['/detailOffer/', offer._id]">
                    <td class=" text-center">
                      <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(offer.status)}" > {{fctBasic.getNameByStatus(offer.status)}}</label>
                    </td>
                    <td class=" text-center">
                      {{offer.startDate | date:'yyyy/MM/dd'}} - {{offer.endDate | date:'yyyy/MM/dd'}}
                    </td>
                    <td class="text-center">
                      {{offer.campaignName}}
                    </td>
                    <td class=" text-center">
                      <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(offer.platformeType)}" style="color:white">
                        <i  *ngIf="offer.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                        <i *ngIf="offer.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                        <i *ngIf="offer.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                        <i *ngIf="offer.platformeType == 'Blog'" class="fa fa-bold "></i>
                        <i *ngIf="offer.platformeType == 'multiNetwork'" class="fa fa-globe "></i>
                      </button>
                    </td>
                    <td class="text-center">
                      {{offer.mission}}
                    </td>
                    <td class="text-center">
                      {{offer.currentRevenue}}
                    </td>
                    <td class="text-center">
                      {{offer.revenueTarget}}
                    </td>
                    <td class=" text-center">
                        {{fctBasic.getNetworkNameForInfluenceur(offer.influencerObjects, offer.platformeType)}}    
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination">
                <pagination-controls  (pageChange)="p = $event" previousLabel = "" nextLabel = ""></pagination-controls>
              </div>
          </div>
        </div>
      </div>
    </body>
</html>


