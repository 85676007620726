import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duplicata',
  pure: false
})
export class DuplicataPipe implements PipeTransform {

  transform(arr: [any], arg1: any): any {
    var newArray:  any = this.filterByValue(arr, arg1)
    return newArray
 }

 filterByValue(array: any[], info: string) {
  var arr : any[] = []
  array.forEach(item => {
    if (item && item.influencerInfo && item.influencerInfo[info]){
      var index = arr.indexOf(item.influencerInfo[info])
      if (index == -1){
        arr.push( item.influencerInfo[info])
      }
    }   
  });
  return arr
}

}
