import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute} from '@angular/router';
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import {MatAccordion} from '@angular/material/expansion';
import {data} from 'src/app/model/apx-chartOfferDashboard.model'
@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dashboard.css']
})
export class DashboardAdminComponent implements OnInit {

  @ViewChild(MatAccordion,{static:true}) accordion: MatAccordion;
  dashboardData: any = null;
  show = false
  chartOptions: any;
  selectedTabIndexContent = 0;
  
  showOffer = true
  overviewImpSeleted = true
  overviewReachSeleted = false
  overviewEngagementSeleted = false
  overviewPlaysSeleted = false
  expendedOverviewStories = false
  expendedOverViewPosts = false
  storiesTxtOverviewSeleted = ""
  storiesPicOverviewSeleted = ""
  postTxtOverviewSeleted = ""
  postPicOverviewSeleted = ""
  reelPicOverviewSeleted = ""
  reelTxtOverviewSeleted = ""

  showReel = false
  reelImpSeleted = true
  reelReachSeleted = false
  reelEngagementSeleted = false
  showReelImp = true
  showReelReach = false
  showReelEngagement = false
  reelSeleted : any
  srcReelImg = ""
  srcReelCaption = ""
  expendedReel = false
  reelchartDetailled : any

  showPost = false
  postImpSeleted = true
  postReachSeleted = false
  postEngagementSeleted = false
  showPostImp = true
  showPostReach = false
  showPostEngagement = false
  postSeleted : any
  srcPostImg = ""
  srcPostCaption = ""
  expendedPosts = false
  postchartDetailled : any

  expendedStories = false
  showStory = false
  storieImpSeleted = true
  storieReachSeleted = false
  storieEngagementSeleted = false
  showStorieImp = true
  showStorieReach = false
  textSelectedStory = ""
  srcSelectedStory = ""
  storieSeleted : any
  storychartDetailled : any
  globalEngagement : 0
  globalEngagementPost : 0
  globalEngagementReel : 0

  constructor(
    private apiService : ApiService,
    private route: ActivatedRoute,
    public fctBasic: CampagneAndOfferFctService,
    private cdRef:ChangeDetectorRef,
    public router: Router,
  ) { 
    
  }

  ngOnInit(): void {
    this.apiService.getDashboardOffer(this.route.snapshot.params.id).subscribe(dashboardData => { 
      this.dashboardData = dashboardData
      this.show = true
      this.chartOptions = data
      this.storychartDetailled = this.chartOptions
      if (dashboardData.infoStory.length > 0){
        this.textSelectedStory = dashboardData.infoStory[0].caption
        this.srcSelectedStory = dashboardData.infoStory[0].picture
        this.storiesTxtOverviewSeleted = dashboardData.infoStory[0].caption
        this.storiesPicOverviewSeleted = dashboardData.infoStory[0].picture
      }
      if (dashboardData.infoPost.length > 0){
        this.srcPostCaption = dashboardData.infoPost[0].caption
        this.srcPostImg = dashboardData.infoPost[0].picture
        this.postTxtOverviewSeleted = dashboardData.infoPost[0].caption
        this.postPicOverviewSeleted = dashboardData.infoPost[0].picture
      }
      if (dashboardData.infoReel && dashboardData.infoReel.length > 0){
        this.srcReelCaption = dashboardData.arrReel[0].infoPost.caption
        this.srcReelImg = dashboardData.arrReel[0].infoPost.picture
        this.reelTxtOverviewSeleted = dashboardData.infoReel[0].caption
        this.reelPicOverviewSeleted = dashboardData.infoReel[0].picture
      }
      
      if (dashboardData.offerJson.labelData.length == 1){
        this.chartOptions.chart.type = "bar"
        this.chartOptions.plotOptions.bar.columnWidth = 20 + (60 / (1 + 30*Math.exp(-1 /3)));
        this.cdRef.detectChanges();
      }
      this.chartOptions.plotOptions.bar.columnWidth = 20 + (60 / (1 + 30*Math.exp(-1 /3)));
      this.cdRef.detectChanges();
      if (dashboardData.arrPort.length > 0){
        this.setPostValue(dashboardData.arrPort[0])
      }
      if (dashboardData.arrReel.length > 0){
        this.setReelValue(dashboardData.arrReel[0])
      }
      if (dashboardData.arrStory.length > 0){
        this.setStoriesValue(dashboardData.arrStory[0])
      }
      dashboardData.globalStatistics.totalEngagement ? 
      this.globalEngagement = dashboardData.globalStatistics.totalEngagement : 
      this.globalEngagement =  dashboardData.globalStatistics.totalLike + dashboardData.globalStatistics.totalComment
      dashboardData.postGlobal.totalEngagement ? 
      this.globalEngagementPost = dashboardData.postGlobal.totalEngagement : 
      this.globalEngagementPost =  dashboardData.postGlobal.totalLike + dashboardData.postGlobal.totalComment
      dashboardData.reelGlobal.totalEngagement ? 
      this.globalEngagementReel = dashboardData.reelGlobal.totalEngagement : 
      this.globalEngagementReel =  dashboardData.reelGlobal.totalLike + dashboardData.reelGlobal.totalComment
      
    })
  }

  showOverviewChart(elem : any){
    switch (elem) {
      case "reach":
        this.overviewImpSeleted =false
        this.overviewReachSeleted = true
        this.overviewEngagementSeleted = false
        this.overviewPlaysSeleted = false

        this.cdRef.detectChanges();
        break;
      case "plays":
        this.overviewImpSeleted =false
        this.overviewReachSeleted = false
        this.overviewEngagementSeleted = false
        this.overviewPlaysSeleted = true
        this.cdRef.detectChanges();
        break;
      case "impression":
        this.overviewImpSeleted = true
        this.overviewReachSeleted = false
        this.overviewEngagementSeleted = false
        this.overviewPlaysSeleted = false

        this.cdRef.detectChanges();
        break;
        case "engagement":
          this.overviewImpSeleted = false
          this.overviewReachSeleted = false
          this.overviewEngagementSeleted = true
          this.overviewPlaysSeleted = false
          this.cdRef.detectChanges();
      break;
      default:
        break;
    }
  }

  showPostChart(elem : any){
    switch (elem) {
      case "reach":
        this.postImpSeleted = false
        this.postReachSeleted = true
        this.postEngagementSeleted = false
        break;
      case "impression":
        this.postImpSeleted = true
        this.postReachSeleted = false
        this.postEngagementSeleted = false
        break;
        case "engagement":
          this.postImpSeleted = false
          this.postReachSeleted = false
          this.postEngagementSeleted = true
      break;
      default:
        break;
    }
  }

  showPostChartDetailed(elem : any){
    switch (elem) {
      case "reach":
        this.showPostImp = false
        this.showPostReach = true
        this.showPostEngagement = false
        break;
      case "impression":
        this.showPostImp = true
        this.showPostReach = false
        this.showPostEngagement = false
        break;
      case "engagement":
        this.showPostImp = false
        this.showPostReach = false
        this.showPostEngagement = true
        break;
      default:
        break;
    }
  }

  showStoriesChartDetailled(elem : any){
    switch (elem) {
      case "reach":
        this.showStorieImp = false
        this.showStorieReach = true
        this.cdRef.detectChanges();
        break;
      case "impression":
        this.showStorieImp = true
        this.showStorieReach = false
        this.cdRef.detectChanges();
        break;
        
      default:
        break;
    }
  }

  showStoriesChart(elem : any){
    switch (elem) {
      case "reach":
        this.storieImpSeleted =false
        this.storieReachSeleted = true
        this.storieEngagementSeleted = false
        this.cdRef.detectChanges();
        break;
      case "impression":
        this.storieImpSeleted =true
        this.storieReachSeleted = false
        this.storieEngagementSeleted = false
        this.cdRef.detectChanges();
        break;
        
      default:
        break;
    }
  }

  showReelChart(elem : any){
    switch (elem) {
      case "reach":
        this.reelImpSeleted = false
        this.reelReachSeleted = true
        this.reelEngagementSeleted = false
        break;
      case "impression":
        this.reelImpSeleted = true
        this.reelReachSeleted = false
        this.reelEngagementSeleted = false
        break;
        case "engagement":
          this.reelImpSeleted = false
          this.reelReachSeleted = false
          this.reelEngagementSeleted = true
      break;
      default:
        break;
    }
  }

  showReelChartDetailed(elem : any){
    switch (elem) {
      case "reach":
        this.showReelImp = false
        this.showReelReach = true
        this.showReelEngagement = false
        break;
      case "impression":
        this.showReelImp = true
        this.showReelReach = false
        this.showReelEngagement = false
        break;
      case "engagement":
        this.showReelImp = false
        this.showReelReach = false
        this.showReelEngagement = true
        break;
      default:
        break;
    }
  }

  checkBarValue(arr : any){
    if (arr.length == 1){
      this.chartOptions.chart.type = "bar"
      this.chartOptions.plotOptions.bar.columnWidth = 20 + (60 / (1 + 30*Math.exp(-1 /3)));
      this.cdRef.detectChanges();
    } else {
      this.chartOptions.chart.type = "area"
      this.cdRef.detectChanges();
    }
  }
  
  onTabChanged(event : any){
    switch (event.tab.textLabel) {
      case "Overview":
        this.showOffer = true
        this.showPost = false
        this.showStory = false
        this.showReel = false
        this.overviewImpSeleted = true
        this.overviewReachSeleted = false
        this.overviewEngagementSeleted = false
        this.checkBarValue(this.dashboardData.offerJson.labelData)
        this.cdRef.detectChanges();
        break;
      case "Posts":
        this.showOffer = false
        this.showPost = true
        this.showStory = false
        this.showReel = false
        this.postImpSeleted = true
        this.postReachSeleted = false
        this.postEngagementSeleted = false
        this.checkBarValue(this.dashboardData.postjson.labelData)
        this.cdRef.detectChanges();
        
        break;
      case "Stories":
        this.showOffer = false
        this.showPost = false
        this.showStory = true
        this.showReel = false
        this.storieImpSeleted =true
        this.storieReachSeleted = false
        this.storieEngagementSeleted = false
        this.checkBarValue(this.dashboardData.storyJson.labelData)
        this.cdRef.detectChanges();
      break;
      case "Reels":
        this.showOffer = false
        this.showPost = false
        this.showStory = false
        this.showReel = true
        this.reelImpSeleted =true
        this.reelReachSeleted = false
        this.reelEngagementSeleted = false
        this.checkBarValue(this.dashboardData.reelJson.labelData)
        this.cdRef.detectChanges();
      break;
    
      default:
        break;
    }
  }

  setReelValue(elem: any){
    this.reelSeleted = {}
    this.reelSeleted.totalStatistics = {}
    //this.reelSeleted.totalStatistics.totalComment = elem.totalStatistics.totalComment
    this.reelSeleted.totalStatistics.totalEngagement = (elem.totalStatistics.totalComment + elem.totalStatistics.totalLike) || elem.totalStatistics.totalEngagement
    //this.reelSeleted.totalStatistics.totalLike = elem.totalStatistics.totalLike
    this.reelSeleted.totalStatistics.totalView = elem.totalStatistics.totalView
    this.reelSeleted.totalStatistics.totalReach = elem.totalStatistics.totalReach
    this.reelSeleted.totalStatistics.totalPlays = elem.totalStatistics.totalPlays
    this.reelSeleted.chartReel = elem.chartReel
    this.srcReelCaption = elem.infoPost.caption
    this.srcReelImg = elem.infoPost.picture
    if (elem.chartReel.labelData.length == 1){
      this.reelchartDetailled = {
        type: "bar",
        stacked: false,
        height: 350,
        toolbar: {
        show: false,
        }
      }
    }else {
      this.reelchartDetailled = {
        type: "area",
        stacked: false,
        height: 350,
        toolbar: {
        show: false,
        }
      }
    }
    this.cdRef.detectChanges();
  }

  setPostValue(elem : any){
    this.postSeleted = {}
    this.postSeleted.totalStatistics = {}
    this.postSeleted.totalStatistics.totalEngagement = (elem.totalStatistics.totalComment + elem.totalStatistics.totalLike) || elem.totalStatistics.totalEngagement
    this.postSeleted.totalStatistics.totalView = elem.totalStatistics.totalView
    this.postSeleted.totalStatistics.totalReach = elem.totalStatistics.totalReach
    this.postSeleted.chartPost = elem.chartPost
    this.srcPostCaption = elem.infoPost.caption
    this.srcPostImg = elem.infoPost.picture
    if (elem.chartPost.labelData.length == 1){
      this.postchartDetailled = {
        type: "bar",
        stacked: false,
        height: 350,
        toolbar: {
        show: false,
        }
      }
    }else {
      this.postchartDetailled = {
        type: "area",
        stacked: false,
        height: 350,
        toolbar: {
        show: false,
        }
      }
    }
    this.cdRef.detectChanges();
  }

  setStoriesValue(elem : any){
    this.storieSeleted = {}
    this.storieSeleted.totalStatistics = {}
    this.storieSeleted.totalStatistics.totalComment = elem.totalStatistics.totalComment
    this.storieSeleted.totalStatistics.totalEngagement = elem.totalStatistics.totalEngagement
    this.storieSeleted.totalStatistics.totalLike = elem.totalStatistics.totalLike
    this.storieSeleted.totalStatistics.totalView = elem.totalStatistics.totalView
    this.storieSeleted.totalStatistics.totalReach = elem.totalStatistics.totalReach
    this.storieSeleted.chartPost = elem.chartPost
    this.textSelectedStory = elem.infoPost.caption
    this.srcSelectedStory = elem.infoPost.picture
    if (elem.chartPost.labelData.length == 1){
      this.storychartDetailled = {
        type: "bar",
        stacked: false,
        height: 350,
        toolbar: {
        show: false,
        }
      }
    }else {
      this.storychartDetailled = {
        type: "area",
        stacked: false,
        height: 350,
        toolbar: {
        show: false,
        }
      }
    }
  }



  showProfileInf(){
    try {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/detail-influencer/${this.dashboardData.Offer.influencerObjects.userId}`])
      );
      window.open(url, '_blank');
    } catch (error) {
      
    }  
  }

  showPic(elem : any, type: string, typeArr: string) {
    switch (type) {
      case "Overview":
        switch (typeArr) {
          case "Post":
              this.postPicOverviewSeleted = elem.picture
              this.postTxtOverviewSeleted = elem.caption
              this.cdRef.detectChanges();
            break;
          case "Reel":
              this.reelPicOverviewSeleted = elem.picture
              this.reelTxtOverviewSeleted = elem.caption
              this.cdRef.detectChanges();
            
            break;
          case "Storie":
              this.storiesPicOverviewSeleted = elem.picture
              this.storiesTxtOverviewSeleted = elem.caption
              this.cdRef.detectChanges();
            break;
          default:
            break;
        }
        break;
      
      default:
        break;
    }
    
  }

}
