import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import {toast} from 'src/app/model/toast'

@Component({
  selector: 'app-add-more-social-network',
  templateUrl: './add-more-social-network.component.html',
  styleUrls: ['./add-more-social-network.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class AddMoreSocialNetworkComponent implements OnInit {
  currentUser : any
  urlInsta = ""
  urlYt = ""
  urlBlog = ""
  urlTikTok = ""
  instagramArray : any = []
  ytArray  : any  = []
  blogArray : any  = []
  tiktokArray : any  = []
  toast = toast
  urlYtReady = false
  constructor(
    private dialogRef: MatDialogRef<AddMoreSocialNetworkComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cdRef:ChangeDetectorRef,
    private apiService : ApiService,
    private toastr: ToastrService,
  ) { 
    this.currentUser = data;
    try {
      if (this.currentUser.valid === true && this.currentUser.confirm === true){
        this.getYtManage()
      } else {
        this.getYt()
      }
    } catch (error) {
      this.getYt()
    }
    
  }

  ngOnInit(): void {
    this.apiService.getUser().subscribe(user => {
      this.currentUser = user
    })
  }

  addBlogAccount(){
    if (this.urlBlog == ""){
      return 
    }
    let newPageInf : any = {};
    newPageInf.blogToken = {};
    newPageInf.blogToken.url = this.urlBlog;
    newPageInf.creationDate = new Date()
    newPageInf.accountStatus = "Awaiting for Approval"
    this.currentUser.userObject.push(newPageInf)
    this.apiService.putUser(this.currentUser._id, this.currentUser).subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.toastr.success(this.toast.urlNetworkBlog)
      this.urlBlog = "";
      this.cdRef.detectChanges();
    })
  }

  addTiktokAccount(){
    if (this.urlTikTok == ""){
      return 
    }
    let nameAccount = this.urlTikTok.search("https://www.tiktok.com/@")
    if (nameAccount === -1 ){
      this.urlTikTok = "https://www.tiktok.com/@" + this.urlTikTok
    }
    let newPageInf : any = {};
    newPageInf.tiktokjson = {};
    newPageInf.tiktokjson.url = this.urlTikTok;
    newPageInf.creationDate = new Date()
    newPageInf.accountStatus = "Awaiting for Approval"
    this.currentUser.userObject.push(newPageInf)
    this.apiService.putUser(this.currentUser._id, this.currentUser).subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.toastr.success(this.toast.urlNetworkTikTok)
      this.urlTikTok = "";
      this.cdRef.detectChanges();
    })
  }


  addNetworkInstagram(){
    if (this.urlInsta == ""){
      return 
    }
    let nameAccount = this.urlInsta.search("https://www.instagram.com/")
    if (nameAccount === -1 ){
      this.urlInsta = "https://www.instagram.com/" + this.urlInsta
    }
    let newPageInf : any = {};
    newPageInf.instagramUrl = this.urlInsta;
    newPageInf.creationDate = new Date()
    newPageInf.accountStatus = "Awaiting for Approval"
    this.currentUser.userObject.push(newPageInf)
    this.apiService.putUser(this.currentUser._id, this.currentUser).subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.toastr.success(this.toast.urlNetworkIg)
      this.urlInsta = "";
      this.cdRef.detectChanges();
    })
  }

  getYt(){
    this.apiService.getYoutubeApi().subscribe(res =>{
      this.urlYt = res.redirect
      this.urlYtReady = true
      this.cdRef.detectChanges();
    })
  }

  getYtManage(){
    this.apiService.getYoutubeToAccount().subscribe(res =>{
      this.urlYt = res.redirect
      this.urlYtReady = true
      this.cdRef.detectChanges();
    })
  }


  deletepage(elem: any, index: number){
    this.currentUser.userObject.splice(index, 1)
    this.apiService.putUser(this.currentUser._id, this.currentUser).subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.toastr.success(this.toast.deleteNetwork)
      this.cdRef.detectChanges();
    })
  }
}
