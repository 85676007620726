<h4 class="dialogHeadlineGenerique" mat-dialog-title>Manage Followers</h4>

<mat-dialog-content style="width: auto;height: auto;" class=" mt-2">
    <div class="row input-row " *ngFor="let net of userNetwork.userObject">
        <div class="col-md-12 col-12 col-lg-12 col-sm-12  display-mid ">
            <div class="col-lg-4 col-md-4 col-sm-12">
                <button mat-mini-fab  [style.background-color]="fctBasic.getNetworkColorForInfluenceur(net)" style="color: white">
                    <i *ngIf="!net.tiktokjson" [ngClass]="fctBasic.getNetworkIconForInfluenceur(net)"></i>
                    <mat-icon *ngIf="net.tiktokjson && net.tiktokjson.url" >tiktok</mat-icon>
                  </button>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                {{fctBasic.getNetwork(net)}}
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <mat-form-field  >
                    <input  matInput   [(ngModel)]="followers" [ngModelOptions]="{standalone: true}"   type="text" >
                    <button (onclick)="updateFollowers(net)"  matSuffix mat-icon-button aria-label="Clear" >
                      <mat-icon>add</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>
        
    </div>
    
</mat-dialog-content>
<mat-dialog-actions>
        
</mat-dialog-actions>