<body>

    <app-header></app-header>

      <!-- Header -->
      <header class="brandhead">
        <div class="container d-flex h-100 align-items-center">
          <div class="mx-auto text-center">
            <h1 class="mx-auto my-0 text-uppercase"><mark style="background-color: black;opacity:1;letter-spacing:2px;font-size: 32px;">Leverage the power of influence</mark></h1>
            <h2 class="text-white-50 mx-auto mt-2 text-uppercase"></h2>
          </div>
        </div>
      </header>
  
      <section id="Brand" class="projects-section bg-light">
        <div class="container">
        <div>
              <p class=" textcard text-center mt-4">You have a goal, we help you to over achieve it through influence marketing.</p>
              <p class=" textcard text-center mt-4">From fully automated campaign to tailor made project, we offer the largest influence marketing experience.</p>
              <p class=" textcard text-center mt-4">Our technical and social media expert teams work hand in hand to guide you along the influence marketing journey.</p>
          </div>
        </div>
      </section>
      <!-- Projects Section -->
  
      <section id="Brand" class="projects-section bg-light">
        <div class="container">
  
          <!-- Featured Project Row -->
          <div class="row align-items-center no-gutters mb-4 mb-lg-5">
            <div class="col-xl-4 col-lg-5">
              <div class="hovereffect">
                <img class="img-fluid mb-3 mb-lg-0" src="../../../assets/images/edito/hip-hop.jpg" alt="">
              </div>
            </div>
            <div class="col-xl-8 col-lg-7">
              <div class="featured-text text-center text-lg-left">
                <h4>DETERMINE THE RIGHT STRATEGY</h4>
                <p class="text-black mb-0">The starting point of influence marketing plan is to define what objectives your brand want to achieve.</p>
                <p class="text-black mb-0">We help you set the right influence marketing approach to find and engage your audience in an authentic way.</p>
            </div>
          </div>
      </div>
  </div>
</section>
  
    <section id="Brand" class="projects-section bg-light">
        <div class="container">
          <div class="row align-items-center no-gutters mb-4 mb-lg-5">
            <div class="col-xl-8 col-lg-7">
              <div class="featured-text2 text-center text-lg-left">
                <h4>FIND THE RIGHT INFLUENCERS</h4>
                <p class="text-black mb-0">That’s were technology helps us find our way.</p>
                <p class="text-black mb-0">Our Proprietary Algorithm finds among millions of data the best Every-Day Influencers to endorse your communications through :</p>
                <p class="text-black mb-0">&nbsp;&nbsp;  - Affitinity to the brand and the topic</p>
                <p class="text-black mb-0">&nbsp;&nbsp;  - Potential reach</p>
                <p class="text-black mb-0">&nbsp;&nbsp;  - Influencers Score ™</p>
              </div>
            </div>
            <div class="col-xl-4 col-lg-5">
              <div class="hovereffect">
                <img class="img-fluid mb-3 mb-lg-0" src="../../../assets/images/edito/influencelab-hike.jpg" alt="">
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <section id="Brand" class="projects-section bg-light">
        <div class="container">
          <div class="row align-items-center no-gutters mb-4 mb-lg-5">
            <div class="col-xl-4 col-lg-5">
              <div class="hovereffect">
                <img class="img-fluid mb-3 mb-lg-0" src="../../../assets/images/edito/influence-lab-pug.jpg" alt="">
              </div>
            </div>
            <div class="col-xl-8 col-lg-7">
              <div class="featured-text text-center text-lg-left">
                <h4>DELIVER A SEEMLESS EXECUTION</h4>
                <p class="text-black mb-0">We keep at heart the authentic tone of influencers : his voice is what will engage the community.</p>
                <p class="text-black mb-0">At the same time, we help influencers create meaningful content respecting brand stewardship and brief guidance.</p>
                <p class="text-black mb-0">We monitore the campaign along the way to ensure delivery and brand safety</p>
              </div>
              <a href="mailto:hello@influencelab.co?subject=Hello%20Influence%20Lab">
                <button type="button" align="center" class="center-block btn btn-secondary btn-lg mt-4 ml-4">START NOW !</button>
              </a>
            </div>
          </div>
        </div>
      </section>
  
      <app-footer></app-footer>
  
  </body>



