<!-- <!DOCTYPE html>
<html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main">

		<div class="auth-container">
			
			<div flex-center>
				<a href="/"><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
						class="img-fluid" /></a>
			</div>
			<div flex-center>
				<h3 class="text-normal text-center mt-4 mb-4" style="color: white;">Sign in to your account</h3>
			</div>
			<div flex-center>
				<button (click)="facebookConnect()" type="button" style="background-color:#3b5998; color:white;"
					class="form-control btn btn-lg mb5"><i class="fa fa-facebook"
					style="margin-right: 15px;"></i>Login With Facebook</button>
			</div>
			<p class="text-account text-center mt-2" style="color: white;">Don't have an account? <a style="color: #a7a7a7;"
				href="/signup">Register Now</a></p>
			<div flex-center>
					<form  [formGroup]="captchaGroup">
						<ngx-recaptcha2 class="text-center" #captchaElem [siteKey]="sitekey" formControlName="captchaForm"
							(success)="handleSuccess($event)" (reset)="handleReset()" (expire)="handleExpire()">
						</ngx-recaptcha2>
					</form>
			</div>
			
			</div>
		</div>


</body>

</html> -->

<!DOCTYPE html>
<html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main mastheadd">
		<div flex-center class="container-fluid">
			<div class="widht-size">
				<div flex-center>
					<a><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
							class="img-fluid" /></a>
				</div>
				<div flex-center>
					<h3 class="text-normal text-center mt-3 mb-2" style="color: white;">Sign in to your account</h3>
				</div>
				<form >
					<div class="mt-3 row input-row">
						<div class="col-md-12 col-lg-12 col-12 col-sm-12">
							<div class="col-md-12 col-lg-12 col-12 col-sm-12">
								<button (click)="facebookConnect()" type="button" style="background-color:#3b5998; color:white;"
								class="form-control btn btn-lg"><i class="fa fa-facebook"
								style="margin-right: 15px;"></i>Login With Facebook</button>
							</div>
						</div>
					</div>
				</form>
				<p class="text-account text-center mt-4" style="color: white;">Or continue with</p>
				<div class="form-container">
					<form [formGroup]="login">
						<div class="row input-row  " >
							<div class="col-md-12 col-lg-12 ">
								<div class=" display-mid">
									<div class="col-lg-4  col-12 col-sm-4 col-md-4 ">
										<mat-label style="color: white;" >Email *</mat-label>
									</div>
									<div class="col-lg-8 col-12 col-sm-8 col-md-8 ">
										<mat-form-field >
											<input type="email" matInput placeholder=""  formControlName="username" >
										</mat-form-field>
									</div>
								</div>
							</div>
						</div>
					<div class="row input-row  " >
						<div class="col-md-12 col-lg-12 ">
							<div class="display-mid">
								<div class="col-lg-4  col-12 col-md-4 ">
									<mat-label style="color: white;">Password *</mat-label>
								</div>
								<div class="col-lg-8 col-12  col-md-8 ">
									<mat-form-field style="color: white!important;">
										<input  matInput type="password" placeholder="" formControlName="password" [type]="hide ? 'password' : 'text'" >
										<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
									</mat-form-field>
								</div>
							
						</div>
					</div>
				</div>
				<div class="row input-row  " >
					<div class="col-md-12 col-lg-12 ">
						<div class="text-account text-right col-md-12 col-lg-12" style="color: white;">
							<p routerLink="/forgot-password" class="text-account text-right " style="color: white;">Forgot password ?</p>
						</div>
					</div>
				</div>
					
					<div flex-center class="mt-2">
						<form [formGroup]="captchaGroup">
							<ngx-invisible-recaptcha #captchaElem
							  [siteKey]="sitekey"
							  (reset)="handleReset()"
							  (success)="handleSuccess($event)"
							  [useGlobalDomain]="false"
							  [ngModel]="succesCaptcha"
							  [ngModelOptions]="{ standalone: true }">
							</ngx-invisible-recaptcha>
						  </form>
					</div>
					<div flex-center class="center-block">
						<button type="submit" (click)="submit()" class="btn btncustom btn-lg btn-light text-uppercase">Sign in</button>
					</div>
					</form>
				</div>
				<p class="text-account text-center mt-4" style="color: white;">Don't have an account? <a style="color: #a7a7a7;"
					href="/signup">Register Now</a></p>
			</div>
		</div>
	</div>
</body>

</html>

<!-- <!DOCTYPE html>
<html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main mastheadd">

		<div class="auth-container">
			<div flex-center>
				<a href="/"><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
						class="img-fluid" /></a>
			</div>
			<div flex-center>
				<h3 class="text-normal text-center mt-3 mb-2" style="color: white;">Sign in to your account</h3>
			</div>
			<div flex-center class="mt-3">
				<button (click)="facebookConnect()" type="button" style="size:30px;background-color:#3b5998; color:white;"
					class="form-control btn btn-lg"><i class="fa fa-facebook"
					style="margin-right: 15px;"></i>Login With Facebook</button>
			</div>
			<p class="text-account text-center mt-4" style="color: white;">Or continue with</p>
			<mat-divider style="color:white;"></mat-divider>
			<div class="form-container">
				<form [formGroup]="login">
					<div class="row input-row  " >
						<div class="col-lg-12  col-12 col-md-4 ">
							<mat-label style="color: white;" >Email *</mat-label>
						</div>
						<div class="col-lg-12 col-12  col-md-8 ">
							<mat-form-field >
								<input type="email" matInput placeholder=""  formControlName="username" >
							</mat-form-field>
						</div>
				</div>
				<div class="row input-row  " >
						<div class="col-lg-12  col-12 col-md-4 ">
							<mat-label style="color: white;">Password *</mat-label>
						</div>
						<div class="col-lg-12 col-12  col-md-8 ">
							<mat-form-field style="color: white!important;">
								<input  matInput type="password" placeholder="" formControlName="password" [type]="hide ? 'password' : 'text'" >
								<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
							</mat-form-field>
						</div>
				</div>
				<p routerLink="/forgot-password" class="text-account text-right " style="color: white;">Forgot password ?</p>
				<div flex-center class="mt-2">
					<form [formGroup]="captchaGroup">
						<ngx-invisible-recaptcha #captchaElem
						  [siteKey]="sitekey"
						  (reset)="handleReset()"
						  (success)="handleSuccess($event)"
						  [useGlobalDomain]="false"
						  [ngModel]="succesCaptcha"
						  [ngModelOptions]="{ standalone: true }">
						</ngx-invisible-recaptcha>
					  </form>
				</div>
				<div flex-center class="center-block">
					<button type="submit" (click)="submit()" class="btn btncustom btn-lg btn-light text-uppercase">Sign in</button>
				</div>
                </form>
			</div>

			
			
			<p class="text-account text-center mt-4" style="color: white;">Don't have an account? <a style="color: #a7a7a7;"
				href="/signup">Register Now</a></p>
			
			
			</div>
		</div>
</body>

</html> -->