import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import {Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { ToastrService } from 'ngx-toastr';
import {MediaService } from 'src/app/service/media.service';

@Component({
  selector: 'app-invoice-admin',
  templateUrl: './invoice-admin.component.html',
  styleUrls: ['./invoice-admin.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css'],
  providers: [MediaService]
})
export class InvoiceAdminComponent implements OnInit {

  offer :any
  mydisabled = true
  modifContent = false
  modifNewElement = false
  createContent = true
  offerShow = false
  constructor(
    private fb: FormBuilder,
    public picService: MediaService,
    private route: ActivatedRoute,
    private apiService : ApiService,
    private toastr: ToastrService,
    private router: Router,
    private cdRef:ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.apiService.getOffre(this.route.snapshot.params.id).subscribe(offer => { 
      this.offer = offer
      this.offerShow = true
      this.cdRef.detectChanges()
    })
  }

  addFile(event : any, type: string){
    if(!event.target.files[0] || event.target.files.length == 0) {
      return false;
    }
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.picService.getExtentionFile(event.target.files[0])
      if (extention.extention != false){
        
        const fd = new FormData();
        fd.append('file', event.target.files[0], event.target.files[0].name);
        this.apiService.postFile(fd).subscribe(path => {
          if (!this.offer.billing){
            this.offer.billing = {}
          }
          switch (type) {
            case "rib":
              this.offer.billing.rib = path[0]
              this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
                this.cdRef.detectChanges()
              })
              break;
            case "attestation":
              this.offer.billing.attestation = path[0]
              this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
                this.cdRef.detectChanges()
              })
              break;
            case "invoice":
                this.offer.billing.invoice = path[0]
                this.apiService.putOffre(this.offer._id, this.offer).subscribe(res => {
                  this.cdRef.detectChanges()
                })
                break;
            default:
              break;
          }
        })
        this.cdRef.detectChanges()
        return event.target.files[0]
      }
    }
    reader.readAsDataURL(event.target.files[0]);
    return false
    }
    
}
