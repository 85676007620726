import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import {UserLoginService } from 'src/app/service/user-login.service';
import { Validators } from '@angular/forms';
import {Router} from '@angular/router'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signin-admin',
  templateUrl: './signin-admin.component.html',
  styleUrls: ['./signin-admin.component.css']
})
export class SigninAdminComponent implements OnInit {
  inputs: FormGroup;
  
  username = new FormControl("", Validators.required);
  password = new FormControl("", Validators.required);
  constructor(private service: ApiService, fb: FormBuilder, private router: Router, private userService: UserLoginService,private toastr: ToastrService,) {
    this.inputs = fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    let auth = this.userService.isAuthenticated()
    
  }

  // $scope.sendLink = function() {
  //   if ($scope.email !== "") {
  //     $http.get('/forget/' + $scope.email).then(function(res) {
  //       console.log("forgotten pass", res);
  //       toastr.success("Email was successfully sent to " + $scope.email + " (Don't forget to check your spams, just in case 😉)"  , 'Success ');
  //       $scope.email = "";
  //     })
  //   } else {
  //     toastr.error('User '+ $scope.email +' not found', 'Error');
  //   }
  // }


  submit(): void {   
    this.service.postLogin(this.inputs.value).subscribe(passportResp => {
      if (passportResp.err){
        this.toastr.error(passportResp.err)
      } else if (passportResp.resp && passportResp.resp === "admin"){
        this.userService.loginAdmin(passportResp.user)
        this.router.navigate(['/home-admin']);
      } else if (passportResp.resp && passportResp.resp === "influencer"){
        this.router.navigate([passportResp.redirect]);
      } else if (passportResp.resp && passportResp.resp === "contact"){
        this.userService.loginContact(passportResp.user)
        this.router.navigate(['/home-contact']);
      }
    })
  };
}