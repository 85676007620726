<html>
    <body>
      <div class="page container">
        <a *ngIf="show" [routerLink]="['/detailOfferAdmin/', dashboardData.Offer._id]" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
        <mat-card *ngIf="show">
          <div class="title-cardDashboard" >
            <div>
                <mat-label class="title"><i>Dashboard</i></mat-label>
            </div>
            <div>
              <mat-label class="title">🤗  {{dashboardData.Offer.campaignName}} 🚀</mat-label>
          </div>
        </div>
        <mat-divider ></mat-divider>
        <div class="title-card text-center mt-5 mb-3">
          <div class="col-lg-12  display-mid">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3 text-center" >
                  <label class="col-12 labelFont text-center">Revenue Target<span class="ml-2 fa fa-bullseye"></span></label>
                  <div  class=" text-center fileText">
                      {{dashboardData.Offer.revenueTarget}}
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-6 col-sm-3 col-3">
                  <label class="col-12 labelFont text-center">Campaign Date<span class="ml-2 fa fa-calendar"></span></label>
                  <div class="fileText text-center">
                      {{dashboardData.Offer.startDate | date:'yyyy/MM/dd'}} - {{dashboardData.Offer.endDate | date:'yyyy/MM/dd'}}
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                  <label class="col-12 labelFont text-center">Mission<span class="ml-2 fa fa-rocket"></span></label>
                  <div class="fileText text-center">
                      {{dashboardData.Offer.mission}}
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                  <label class="col-12 labelFont text-center">Social Network<span class="ml-2 fa fa-share-alt-square"></span></label>
                  <div class=" text-center">
                      <!-- <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(dashboardData.Offer.platformeType)}" style="color:white">
                          <i  *ngIf="dashboardData.Offer.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                          <i *ngIf="dashboardData.Offer.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                          <i *ngIf="dashboardData.Offer.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                          <i *ngIf="dashboardData.Offer.platformeType == 'Blog'" class="fa fa-bold "></i>
                          <i *ngIf="dashboardData.Offer.platformeType == 'multiNetwork'" class="fa fa-globe  "></i>
                        </button> -->
                      <button (click)="showProfileInf()" type="button" [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(dashboardData.Offer.platformeType)}" style="color:white" class="btn btn-sm shadow-none ">
                      <i  *ngIf="dashboardData.Offer.platformeType == 'Facebook'" class="fa mr-2 left fa-facebook "></i>
                      <i *ngIf="dashboardData.Offer.platformeType == 'Instagram'" class="fa mr-2 left fa-instagram "></i>
                      <i *ngIf="dashboardData.Offer.platformeType == 'Youtube'" class="fa mr-2 left fa-youtube "></i>
                      <i *ngIf="dashboardData.Offer.platformeType == 'Blog'" class="fa fa-bold mr-2 left"></i>
                      <i *ngIf="dashboardData.Offer.platformeType == 'multiNetwork'" class="fa fa-globe  mr-2 left"></i>
                      {{fctBasic.getNetworkNameForInfluenceur(dashboardData.Offer.influencerObjects, dashboardData.Offer.platformeType)}}</button>
                  </div>
              </div>
          </div>
      </div>
      <mat-divider ></mat-divider>
  
          <mat-card-content>
            <div class="container" >
              <div class="cardMetrics mt-4">
                <mat-card>
                  <mat-card-content *ngIf="showOffer" (click)="showOverviewChart('impression')">
                    <div class="fa-content">
                      <div style="color: #4bdbaa;">
                        <i style="font-size:50px;" class="fa fa-eye"></i>
                      </div>
                      <div  class="cardMetricsContent ">
                        <div class="labelFont">
                         Impressions
                        </div>
                        <div  class="numberCard">
                          <span>{{dashboardData.Offer.globalStatistics.View | mask: "separator.2"}}</span>
                          
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card (click)="showOverviewChart('reach')">
                  <mat-card-content>
                    <div class="fa-content">
                      <div style="color: #5bb2ee;">
                        <i style="font-size:50px;display:inline-block;" class="fa fa-users"></i>
                      </div>
                      <div  class="cardMetricsContent ">
                        <div class="labelFont">
                          Average Duration
                        </div>
                        <div class="numberCard ">
                          <span>5min</span>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card (click)="showOverviewChart('engagement')">
                  <mat-card-content>
                    <div class="fa-content">
                      <div style="color: #dc2626;">
                        <i style="font-size:50px;" class="fa fa-heart"></i>
                      </div>
                      <div  class="cardMetricsContent ">
                        <div class="labelFont">
                          Engagement
                        </div>
                        <div class="numberCard ">
                          <span>{{dashboardData.Offer.globalStatistics.Like + dashboardData.Offer.globalStatistics.Comment | mask: "separator.2"}}</span>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
               <div class="chartPositionCard mt-4">
                <div  class="cardcharttitle ">
                    <div class="labelFont" *ngIf="overviewImpSeleted">Impressions Delivered (Daily)</div> 
                    <div  class="labelFont" *ngIf="overviewReachSeleted">Average (Daily)</div> 
                    <div class="labelFont" *ngIf="overviewEngagementSeleted">Engagement (Daily)</div> 
                </div>
                <apx-chart *ngIf="overviewImpSeleted"
                [series]="dashboardData.offerJson.impressionviewsdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.offerJson.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [colors]="chartOptions.colors"
              ></apx-chart>
              <apx-chart *ngIf="overviewReachSeleted"
                [series]="dashboardData.offerJson.impressionviewsdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.offerJson.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fillReach"
                [plotOptions]="chartOptions.plotOptions"
                [colors]="chartOptions.colorsReach"
              ></apx-chart>
              <apx-chart *ngIf="overviewEngagementSeleted"
                [series]="dashboardData.offerJson.impressionengagementdata"
                [chart]="chartOptions.chartEngagement"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [dataLabels]="chartOptions.dataLabels"
                [labels]="dashboardData.offerJson.labelData"
                [fill]="chartOptions.fillEngagement"
                [colors]="chartOptions.colorsEngagement"
                [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>   
              </div>
            </div>
        </mat-card-content>
        </mat-card>
        <mat-card class="mt-3" *ngIf="show && dashboardData.videoInfoArr.length > 0">
          <mat-card-content>
            <div  class="cardcharttitle ">
              <div class="labelFont" >Detailed Statistics</div> 
          </div>
            <div class="row col-lg-12 col-12" *ngIf="videoSeleted">
              <div class="col-lg-9">
                <apx-chart *ngIf="showVideoImp"
                [series]="videoSelected.chartPost.impressionviewsdata"
                [chart]="videochartDetailled"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [dataLabels]="chartOptions.dataLabels"
                [labels]="videoSelected.chartPost.labelData"
                [fill]="chartOptions.fill"
                [colors]="chartOptions.colors"
                [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <apx-chart *ngIf="showVideoReach"
                    [series]="videoSelected.chartPost.impressionaverageduration"
                    [chart]="videochartDetailled"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="videoSelected.chartPost.labelData"
                    [fill]="chartOptions.fillReach"
                    [colors]="chartOptions.colorsReach"
                    [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <apx-chart *ngIf="showVideoEngagement"
                    [series]="videoSelected.chartPost.impressionengagementdata"
                    [chart]="videochartDetailled"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="videoSelected.chartPost.labelData"
                    [fill]="chartOptions.fillEngagement"
                    [colors]="chartOptions.colorsEngagement"
                    [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <div flex-center>
                <div  class="cardMetricsDetailled  mt-4">
                  <div class="contentMetrics">
                    <mat-card (click)="showVideoChartDetailed('impression')">
                      <mat-card-content >
                        <div class="fa-content">
                          <div style="color: #4bdbaa;">
                            <i style="font-size:25px;" class="fa fa-eye"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                             Impressions
                            </div>
                            <div  class="numberCard">
                              <span>{{videoSelected.totalStatistics.totalView}}</span>
                              
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="contentMetrics">
                    <mat-card (click)="showVideoChartDetailed('reach')">
                      <mat-card-content>
                        <div class="fa-content">
                          <div style="color: #5bb2ee;">
                            <i style="font-size:25px;" class="fa fa-users"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                              Average Duration 
                            </div>
                            <div class="numberCard">
                              <span>{{videoSelected.totalStatistics.totalAverageViewDuration}} min</span>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="contentMetrics">
                    <mat-card (click)="showVideoChartDetailed('engagement')">
                      <mat-card-content>
                        <div class="fa-content">
                          <div style="color: #dc2626;">
                            <i style="font-size:25px;" class="fa fa-heart"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                              Engagement
                            </div>
                            <div class="numberCard ">
                              <span>{{videoSelected.totalStatistics.totalLike + videoSelected.totalStatistics.totalComment}}</span>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
              </div>
              <div  class="col-lg-3">
                <div flex-center class="cardPictureDetailed ">
                  <div flex-center class="card">
                    <div  class="profileIf ">
                      <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
                    </div>
                    <img  [src]="srcVideoImg" class="post-image" alt="">
                    
                    <div class="card-content" (click)="expendedPosts = !expendedPosts">
                      <div>{{(expendedPosts) ? srcVideoCaption : srcVideoCaption | slice:0:150}} </div>
                      <div  (click)="expendedPosts=true" *ngIf="!expendedPosts">...</div>
                        
                        <div  (click)="expendedPosts=false" *ngIf="expendedPosts">...</div>
                    </div>
                  </div>
                </div>
                
              </div>
          </div>
          <div class="col-lg-12 mt-4">
            <div  class="cardcharttitle ">
              <div class="labelFont text-center">
                Video(s) ({{dashboardData.videoInfoArr.length}})
              </div>
            </div>
            <div flex-center class="thumbnails mt-4">
              <div *ngFor="let video of dashboardData.videoInfoArr;let index = index;" >
                <div class="img_wrapper ">
                  
                  <img (click)="setPostValue(video)" mat-card-image class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="video.youtubeInfoVideo.picture" >
                </div>
              </div>
            </div>
          </div>
          </mat-card-content>
        </mat-card>
      </div>
    </body>
  </html>