<section id="Contact" class="contact-section bg-black">
    <div class="container">
      <div class="row">
        <div class="col-md-4 mb-3 mb-md-0">
          <div class="card py-4 h-100">
            <div class="card-body text-center">
              <i class="fa fa-map-marker text-primary mb-2"></i>
              <h4 class="text-uppercase m-0">Address</h4>
              <hr class="my-4">
              <div class="small text-black">90 Rue Raynouard, 75016 Paris</div>
            </div>
          </div>
        </div>

        <div class="col-md-4 mb-3 mb-md-0">
          <div class="card py-4 h-100">
            <div class="card-body text-center">
              <i class="fa fa-envelope text-primary mb-2"></i>
              <h4 class="text-uppercase m-0">Email</h4>
              <hr class="my-4">
              <div class="small text-black">
                <a style="color:black;"href="mailto:hello@influencelab.co?subject=Hello%20Influence%20Lab">hello@influencelab.co</a>
              </div>
            </div>

          </div>
        </div>

        <div class="col-md-4 mb-3 mb-md-0">
          <div class="card py-4 h-100">
            <div class="card-body text-center">
              <i class="fa fa-mobile text-primary mb-2"></i>
              <h4 class="text-uppercase m-0">Phone</h4>
              <hr class="my-4">
              <div class="small text-black">+33 6 11 62 92 03</div>
            </div>
          </div>
        </div>
      </div>

      <div class="social d-flex justify-content-center">
        <a href="https://www.instagram.com/influence.lab/" target="_blank" class="mx-2">
          <i class="fa fa-instagram"></i>
        </a>
        <a href="https://www.facebook.com/influencelab.co/"  target="_blank" class="mx-2">
          <i class="fa fa-facebook"></i>
        </a>
      </div>

    </div>
  </section>
  <footer class="bg-black small text-center text-white-50">
    <div class="container-fluid">
      <span>Copyright &copy; Influence Lab</span>
      <a href="/legal">
        <span> Legal</span>
      </a>
    </div>
  </footer>
