import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import {CampagneModel} from 'src/app/model/campagne-model.model'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router'
import {MediaCampagneService } from 'src/app/service/media-campagne.service';
import {toast} from 'src/app/model/toast'

@Component({
  selector: 'app-detail-campagne',
  templateUrl: './detail-campagne.component.html',
  styleUrls: ['./detail-campagne.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css'],
  providers: [MediaCampagneService]
})
export class DetailCampagneComponent implements OnInit {
  dataSourceCampagne : any
  init = false
  toast = toast
  initAgency = false
  initAdvertise = false
  makeDuplicata = false
  makeModify = false
  campagneLive = false
  isDisabled = true
  buttonValue = ""
  offerTotalCurrentRevenue = 0;
  OfferTotalCurrentDelivery = 0;
  OfferTotalDeliveryTarget = 0;
  OfferTotalRevenueTarget = 0;
  campagnes: FormGroup;
  //annonceurKey = new FormArray([]);
  keywordList : string[];
  keyword : any;
  statusChanged : any
  listContacts : any = []
  listCompany : any = []
  constructor(private service: ApiService, 
    private route: ActivatedRoute, 
    public picService: MediaCampagneService,
    private fb: FormBuilder,
    private router: Router,
    public fctBasic: CampagneAndOfferFctService, 
    private cdRef:ChangeDetectorRef,
    private toastr: ToastrService) 
    { }

  ngOnInit(): void {
    this.service.getCampaign(this.route.snapshot.params.id).subscribe(campagne => {
      if (campagne != 400){
        this.dataSourceCampagne = campagne;
        if (this.dataSourceCampagne.offerObject.length > 0){
          this.campagneLive = true
          this.getTotalOnCampagne(this.dataSourceCampagne.offerObject)
        }
        this.service.getCompanies().subscribe(resnewFile => {
          this.listCompany = resnewFile
          this.initAgency = true
          this.cdRef.detectChanges();
        })
        this.service.getContacts().subscribe(resnewFile => {
          this.listContacts = resnewFile
          this.cdRef.detectChanges();
        })
        this.campagnes = this.fb.group({
          annonceurKey: new FormControl(this.dataSourceCampagne.annonceurKey),
          creationDate: new FormControl(this.dataSourceCampagne.creationDate),
          startDate: [{value:this.dataSourceCampagne.startDate,disabled: this.isDisabled}, Validators.required],
          endDate: [{value:  this.dataSourceCampagne.endDate, disabled: this.isDisabled}, Validators.required],
          agency: [{value:  this.dataSourceCampagne.agency, disabled: this.isDisabled}],
          advertiser: [{value:  this.dataSourceCampagne.advertiser, disabled: this.isDisabled}],
          agencyContact: [{value:  this.dataSourceCampagne.agencyContact, disabled: this.isDisabled}],
          advertiserContact: [{value:  this.dataSourceCampagne.advertiserContact, disabled: this.isDisabled}],
          campaignName : [{value: this.dataSourceCampagne.campaignName, disabled: this.isDisabled}, Validators.required],
          agencyReference: [{value: this.dataSourceCampagne.agencyReference, disabled: this.isDisabled}],
          mission: [{value: this.dataSourceCampagne.mission, disabled: this.isDisabled}, Validators.required],
          saleModels:[{value: this.dataSourceCampagne.saleModels, disabled: this.isDisabled}, Validators.required],
          objective: [{value: this.dataSourceCampagne.objective, disabled: this.isDisabled}, Validators.required],
          mustInclude: [{value: this.dataSourceCampagne.mustInclude, disabled: this.isDisabled}, Validators.required],
          platformeType: [{value: this.dataSourceCampagne.platformeType, disabled: this.isDisabled}, Validators.required],
          projectDescription: [{value: this.dataSourceCampagne.projectDescription, disabled: this.isDisabled}, Validators.required],
          pictures: new FormControl(this.dataSourceCampagne.pictures),
          files: new FormControl(this.dataSourceCampagne.files),
          prixUnitaire: [{value: this.dataSourceCampagne.prixUnitaire, disabled: this.isDisabled}, Validators.required],
          deliveryTarget:[{value:  this.dataSourceCampagne.deliveryTarget, disabled: this.isDisabled}, Validators.required],
          revenueTarget: [{value: this.dataSourceCampagne.revenueTarget, disabled: this.isDisabled}, Validators.required],
          valueArrTmp: new FormControl("")
        })
        this.init = true
        this.buttonValue = campagne.platformeType
        this.cdRef.detectChanges();
      }  else {
        this.router.navigate(['/listCampaign']);
      }
    })
  }



changeStatusCampaign(option : any){
    if (!option.value){
      this.toastr.warning(this.toast.campaignNoStatus, "Warning")
      return
    } else {
      this.service.getChangeStatusCampaign(option.value, this.dataSourceCampagne._id).subscribe(resCampagne => {
        this.dataSourceCampagne = resCampagne;
        this.toastr.success(this.toast.campaignStatusModified)
        if (resCampagne.status == "CampaignLive" || resCampagne.status == "Past"){
          this.campagneLive = true
          this.getTotalOnCampagne(this.dataSourceCampagne.offerObject)
          this.cdRef.detectChanges();
        } else {
          this.campagneLive = false
          this.cdRef.detectChanges();
        }
      })
    }
  }

  checkSpaceOnString(str : string) {
    let tmpstr = str.split(" ")
    tmpstr.forEach((elem) =>  {
      if (elem != " "){
        let verif = this.verifiedIndexOf(elem)
        if (verif == false){
          this.campagnes.value.annonceurKey.push(elem)
        }
      }
    })
  }

  verifiedIndexOf(elem : string) : Boolean {
    let check = false
    if (this.campagnes.value.annonceurKey.length > 0){
      this.campagnes.value.annonceurKey.forEach((res : any) => {
        if (elem == res ){
          check = true
        }
       })
    }
    return check
  }

  addKeyWords(){
    if (this.campagnes.value['valueArrTmp']){
      this.checkSpaceOnString(this.campagnes.value['valueArrTmp'])
      this.campagnes.controls['valueArrTmp'].setValue("")
    }
  }

  deleteKeyWords(index: number){
    if (this.isDisabled){
      return
    }
    this.campagnes.value.annonceurKey.splice(index, 1)
  }

  modifyCampagne(){
    this.toastr.success(this.toast.campaignEdit)
    if (this.isDisabled === true){
      this.isDisabled = false
    }
    this.campagnes.enable()
    this.makeDuplicata = false
    this.makeModify = true
  }

  makedupicataCampagne(){
    this.toastr.success(this.toast.campaignDuplic)
    if (this.isDisabled === true){
      this.isDisabled = false
    }
    this.makeDuplicata = true
    this.makeModify = false
    this.campagnes.enable()
    this.campagnes.controls['pictures'].setValue([])
    this.campagnes.controls['files'].setValue([])
    this.cdRef.detectChanges();
  }

  async saveModifCampagne(){
    if (this.campagnes.valid === true){
      this.service.putCampaign(this.route.snapshot.params.id, this.campagnes.value).subscribe(campagne => {
        this.toastr.success(this.toast.campaignModified)
        this.router.navigate(['/listCampaign']);
      })
    }
  }

  openOfferDetailInNewWindow(id : any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/detailOfferAdmin/${id}`])
    );
    window.open(url, '_blank');
  }

  openCampagneDashboardInNewWindow(id : any) {
    switch (this.dataSourceCampagne.platformeType) {
      case "Youtube":
        const urlyt = this.router.serializeUrl(
          this.router.createUrlTree([`/campaignDashYoutube/${id}`])
        );
        window.open(urlyt, '_blank');
        break;
      
      default:
        const url = this.router.serializeUrl(
        this.router.createUrlTree([`/campaignDash/${id}`])
      );
      window.open(url, '_blank');
      break;
    }
    
  }

  openInfluenceurSelectorInNewWindow(id : any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/selectorCampaign/${id}`])
    );
    window.open(url, '_blank');
  }

  createNewCampagneWithDuplicata(){
    
    if (this.campagnes.value['platformeType'] && this.campagnes.value['platformeType'].value) {
      this.campagnes.value['platformeType'] = this.campagnes.value['platformeType'].value
    }
    if (this.campagnes.valid === true){
      this.campagnes.value.status = "New Campaign"
      this.campagnes.value.creationDate = new Date()
      this.service.postCampaign(this.campagnes.value).subscribe(res => {
        this.toastr.success(this.toast.campaignModified);
        this.router.navigate(['/listCampaign'])
        .then(() => {
          window.location.reload();
        });
      })
    }
  }
  
setplatform(type : any){
  this.campagnes.controls['platformeType'].setValue(type);
  this.buttonValue = type
}

affiliedFormOnOffer(offer:any, event : any){
  offer.putManually = event.checked
  this.service.putOffre(offer._id, offer).subscribe(offre => {
    if (event.checked == true){
      this.toastr.success(this.toast.offerManualInput)
    } else {
      this.toastr.success(this.toast.offerManualInputOff)
    }
  })
}

getTotal(arr: []){
  return arr.reduce( (sum, curr:any) => sum + curr.Total,0 );
}

addFile(event: any) {	
  if (this.isDisabled){
    return
  }
  if(!event.target.files[0] || event.target.files.length == 0) {
      return ;
    }
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.picService.getExtentionFile(event.target.files[0])
      if (extention.extention != false){
        const fd = new FormData();
        fd.append('file', event.target.files[0], event.target.files[0].name);
        this.service.postFile(fd).subscribe(path => {
          this.campagnes.value['files'].push(path[0])
          this.cdRef.detectChanges()
        })
        this.cdRef.detectChanges()
      }
    }
      reader.readAsDataURL(event.target.files[0]); 
}

addPicture(event : any){
  if (this.isDisabled){
    return false
  }
  if(!event.target.files[0] || event.target.files.length == 0) {
    return false;
  }
  var reader = new FileReader();
  reader.onload = () => {
    let extention = this.picService.getExtentionImg(event.target.files[0])
    if (extention != false){
      
      const fd = new FormData();
      fd.append('file', event.target.files[0], event.target.files[0].name);
      this.service.postPicture(fd).subscribe(path => {
        this.campagnes.value['pictures'].push(path[0])
        this.cdRef.detectChanges()
      })
      this.cdRef.detectChanges()
      return event.target.files[0]
    }
  }
  reader.readAsDataURL(event.target.files[0]);
  return false
  }

  deleteImg(img : any, index: number){
    if (this.isDisabled){
      return
    }
    this.campagnes.value['pictures'].splice(index, 1)
    this.service.deletePicture(img).subscribe(res => {
      this.cdRef.detectChanges()
    })
  }

  deleteFile(file : any, index : number){
    if (this.isDisabled){
      return
    }
    this.campagnes.value['files'].splice(index, 1)
    this.service.deleteFile(file.name).subscribe(res => {
      this.cdRef.detectChanges()
    })
  }

  calculateT(elem: any){
    if (elem.value['saleModels'] === "CPM"){  
      if (elem.value['deliveryTarget'] === 0){
        elem.controls['revenueTarget'].setValue(0);
        this.cdRef.detectChanges()
      } else {
        let tmpValue = elem.value['deliveryTarget'] * elem.value['prixUnitaire']
        elem.controls['revenueTarget'].setValue(tmpValue / 1000);
        this.cdRef.detectChanges()
      }
    } else {
      if (elem.value['deliveryTarget'] === 0){
        elem.controls['revenueTarget'].setValue(0);
        this.cdRef.detectChanges()
      } else {
        elem.controls['revenueTarget'].setValue(elem.value['deliveryTarget'] * elem.value['prixUnitaire']);
        this.cdRef.detectChanges()
      }
    }
  }

  getTotalOnCampagne(offers: any){
    offers.map((offer: any) => {
      this.offerTotalCurrentRevenue += offer.currentRevenue;
      this.OfferTotalCurrentDelivery += offer.currentDelivery;
      this.OfferTotalDeliveryTarget += offer.deliveryTarget;
      this.OfferTotalRevenueTarget += offer.revenueTarget;
    })
  }

}
