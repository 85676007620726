import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-nopassword',
  templateUrl: './nopassword.component.html',
  styleUrls: ['./nopassword.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class NopasswordComponent implements OnInit {
  urlFacebook: string = "/api/facebook";
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

}
