import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import {toast} from 'src/app/model/toast'
@Component({
  selector: 'app-share-campagne-on-contact-user',
  templateUrl: './share-campagne-on-contact-user.component.html',
  styleUrls: ['./share-campagne-on-contact-user.component.css','../../../assets/styles/dialog.css']
})
export class ShareCampagneOnContactUserComponent implements OnInit {
  campagne : any
  contacts : any
  companys: any
  toast = toast
  selectedDevice : any
  arrayContacts : any = []
  constructor(
    private dialogRef: MatDialogRef<ShareCampagneOnContactUserComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cdRef:ChangeDetectorRef,
    private toastr: ToastrService,
    private apiService : ApiService,
  ) { 
    this.campagne = data
  }

  ngOnInit(): void {
    this.apiService.getContactsForAfilliedOnCampagne(this.campagne._id).subscribe(companies => {
      this.contacts = companies
      this.loopOnAdvertiser()
    })
  }

  loopOnAdvertiser(){
    this.apiService.getContactsUser(this.campagne._id).subscribe((res) =>{
      if (res != 400 || res != null){
        this.arrayContacts = res
        this.cdRef.detectChanges()
      } else {
        this.arrayContacts = []
        this.cdRef.detectChanges()
      }
    })
  }
  
  onChange() {
    if (this.selectedDevice){
      let index = this.selectedDevice.contactUserId.contactObject.campaignAffiliated.indexOf(this.campagne._id)
      if (index == -1){
        this.addContact(this.selectedDevice)
      }
    }
  }

  addContact(companies : any){
    let index = companies.contactUserId.contactObject.campaignAffiliated.indexOf(this.campagne._id)
    if (index == -1){
      companies.contactUserId.contactObject.campaignAffiliated.push(this.campagne._id)
      this.apiService.putUser(companies.contactUserId._id, companies.contactUserId).subscribe(res => {
        this.toastr.success(this.toast.contactaffilied)
        this.arrayContacts.push(companies.contactUserId)
        this.selectedDevice = null
        this.cdRef.detectChanges();
      })
    }
  }

  deleteContact(elem : any, indexOnarray : number){
    let index = elem.contactObject.campaignAffiliated.indexOf(this.campagne._id)
    if (index != -1){
      elem.contactObject.campaignAffiliated.splice(index, 1)
      this.apiService.putUser(elem._id, elem).subscribe(res => {
        this.toastr.success(this.toast.contactNotaffilied)
        this.arrayContacts.splice(indexOnarray, 1)
        this.selectedDevice = null
        this.cdRef.detectChanges();
      })
    }
  }

}
