import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import {toast} from 'src/app/model/toast'

@Component({
  selector: 'app-modif-contact',
  templateUrl: './modif-contact.component.html',
  styleUrls: ['./modif-contact.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css']
})
export class ModifContactComponent implements OnInit {
  contact: FormGroup;
  contactData : any;
  listAdvertiser : any;
  showContact = false
  createNewUser = false
  isDisabled = true
  toast = toast
  passwordClip : string = ""
  newCompany : any
  constructor(
    private route: ActivatedRoute, 
    private fb: FormBuilder, 
    public api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private cdRef:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.api.getContact(this.route.snapshot.params.id).subscribe(contact => {
      this.contactData = contact
      this.contact = this.fb.group({
        firstname: this.contactData.firstname,
        lastname: [this.contactData.lastname, Validators.required],
        fonction: this.contactData.fonction,
        email: [this.contactData.email, Validators.required],
        company: [this.contactData.company , Validators.required],
        phone: this.contactData.phone,
        phone2: this.contactData.phone2,
        fax: this.contactData.fax,
        adress: this.contactData.adress,
        zipCode: this.contactData.zipCode,
        town: this.contactData.town,
        country: this.contactData.country,
        aditionalInformation: this.contactData.aditionalInformation,
        creationDate: this.contactData.creationDate,
        contactUserId: this.contactData.contactUserId,
        password: this.contactData.password,
      })
      this.passwordClip = this.contactData.password
      this.api.getCompanies().subscribe(company => {
        this.listAdvertiser = company
        this.cdRef.detectChanges();
      })
      this.showContact = true
      if (this.contactData.email && this.contactData.email != null){
        this.createNewUser = true
      }
      this.cdRef.detectChanges();
    })
  }

  checkIfContactUserExist(){
    if (this.contactData && !this.contactData.contactUserId){
      if (!this.contact.value["password"]){
        this.contact.value["password"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        let newuserContact = {
          "username": this.contact.value["email"],
          "password": this.contact.value["password"],
        };
        this.api.createUserContact(newuserContact).subscribe(res => {
          if (res && res.err){
            this.toastr.warning(res.err)
            return
          }
          this.contact.value["contactUserId"] = res._id
          this.api.putContact(this.route.snapshot.params.id, this.contact.value).subscribe(campagne => {
            this.toastr.success(this.toast.modfiContact)
            this.router.navigate(['/list-contact']);
          })
        })
      } else {
        let newuserContact = {
          "username": this.contact.value["email"],
          "password": this.contact.value["password"],
        };
        this.api.createUserContact(newuserContact).subscribe(res => {
          if (res && res.err){
            this.toastr.warning(res.err)
            return
          }
          this.contact.value["contactUserId"] = res._id
          this.api.putContact(this.route.snapshot.params.id, this.contact.value).subscribe(campagne => {
            this.toastr.success(this.toast.modfiContact)
            this.router.navigate(['/list-contact']);
          })
        })
      }
      
    } else {
      this.api.getContactsUserForCheck(this.contactData.contactUserId).subscribe(res => {
        if (res == null ){
          let newuserContact = {
            "username": this.contact.value["email"],
            "password": this.contact.value["password"],
          };
          
          this.api.createUserContact(newuserContact).subscribe(res => {
            if (res && res.err){
              this.toastr.warning(res.err)
              return
            }
            this.contact.value["contactUserId"] = res._id
            this.api.putContact(this.route.snapshot.params.id, this.contact.value).subscribe(campagne => {
              this.toastr.success(this.toast.modfiContact)
              this.router.navigate(['/list-contact']);
            })
          })
        } else {
          this.api.putContact(this.route.snapshot.params.id, this.contact.value).subscribe(campagne => {
            this.toastr.success(this.toast.modfiContact)
            this.router.navigate(['/list-contact']);
          })
        }
      })
    }
  }

  modifContact(){
    if (this.contact.valid === true){
      this.checkUserContact()
    } else {
      this.toastr.warning(this.toast.InvalidEntry)
    }
  }

  checkUserContact(){
    if (!this.contactData.email  && this.contact.value['email']){
      let newuserContact = {
        "username": this.contact.value["email"],
        "password": this.contact.value["password"],
      };
      this.api.createUserContact(newuserContact).subscribe(res => {
        if (res && res.err){
          this.toastr.warning(res.err)
          return
        }
        this.contact.value["contactUserId"] = res._id
        this.api.putContact(this.route.snapshot.params.id, this.contact.value).subscribe(campagne => {
          this.toastr.success(this.toast.modfiContact)
          this.router.navigate(['/list-contact']);
        })
        
      })
      
    }
    else if (this.contactData.email && this.contact.value['email']){
      if (this.contactData.email == this.contact.value['email']){
        this.checkIfContactUserExist()
      } else {
        this.api.putUserTypeContact(this.route.snapshot.params.id, this.contact.value, this.contactData.contactUserId).subscribe(res => {
          if (res && res.err){
            this.toastr.warning(res.err)
            return
          }
          this.toastr.success(this.toast.modfiContact)
          this.router.navigate(['/list-contact']); 
        })
      }
    } else {
      // this.api.putContact(this.route.snapshot.params.id, this.contact.value).subscribe(campagne => {
      //   this.toastr.success("Successfully Modified Contact")
      //   this.router.navigate(['/list-contact']);
      // })
    }
  }

  clipboard(){
    this.toastr.success(this.toast.textCopied)
  }

  setCompany(elem : any){
    this.newCompany = elem
  }

}
