import { ApiService } from 'src/app/service/api.service';
import {OfferModel} from 'src/app/model/offer-model.model'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { Component, OnInit,ChangeDetectorRef  } from '@angular/core';
import { Router} from '@angular/router';
@Component({
  selector: 'app-list-offer',
  templateUrl: './list-offer.component.html',
  styleUrls: ['./list-offer.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})

export class ListOfferComponent implements OnInit {
  dataSourceOffer: any;
  p: number = 1;
  total: number;
  term: string;
  tmp: string;
  count: number = 50;
  loadOffer = false
  constructor(
    private apiService : ApiService,
    public fctBasic: CampagneAndOfferFctService,
    private cdRef:ChangeDetectorRef,
    public router: Router
    ) {
      
      
     }

  ngOnInit(): void {
    this.apiService.getOffresPaginate(1, this.count).subscribe(res => { 
      if (res != 400){
        this.dataSourceOffer = res.offers;
        this.total = res.total;
        this.loadOffer = true
        this.cdRef.detectChanges()
      }
    })
  }

paginateOffer(event : any){
  this.p = event
  this.apiService.getOffresPaginate(this.p, this.count).subscribe(res => { 
    if (res != 400){
      this.dataSourceOffer = res.offers
      this.cdRef.detectChanges();
    }
  })

}

  openOfferDetailInNewWindow(id : any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/detailOfferAdmin/${id}`])
    );
    window.open(url, '_blank');
  }

  findOffer(){
    this.apiService.getOffresPaginateFilter(this.tmp, this.count).subscribe(res => {
      if (res.total > 0){
        this.total = res.total;
        this.dataSourceOffer = res.offers
        this.p = 1
        this.cdRef.detectChanges();
      }
    })
  }

  showProfileInf(offer : any){
    try {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/detail-influencer/${offer.influencerObjects.userId}`])
      );
      window.open(url, '_blank');
    } catch (error) {
      
    }  
  }

}
