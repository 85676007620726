<!-- <html>
    <body>
        <div *ngIf="showContact" class="container-fluid">
            <mat-card >
                <mat-card-content class=" mt-1">
                    <form [formGroup]="contact" >
                        
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-6 col-sm-12   ">
                                    <div class="col-lg-12   col-md-12">
                                        <mat-label>First Name</mat-label>
                                    </div>
                                    <div class="col-lg-12   col-md-12 ">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="firstname">
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6  col-lg-6   col-sm-12">
                                    <div class="col-lg-2 col-md-2 ">
                                        <mat-label>Last Name</mat-label>
                                    </div>
                                    <div class="col-lg-12  col-md-12 ">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="lastname">
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label>Fonction</mat-label>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <mat-form-field >
                                        <input matInput placeholder=""  formControlName="fonction">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-6 col-sm-12   ">
                                    <div class="col-lg-2   col-md-2 ">
                                        <mat-label>Email</mat-label>
                                    </div>
                                    <div class="col-lg-12   col-md-12 ">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="email">
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6  col-lg-6   col-sm-12">
                                    <div class="col-lg-2 col-md-2 ">
                                        <mat-label>Company Name</mat-label>
                                    </div>
                                    <div class="col-lg-12  col-md-12">
                                        <mat-form-field>
                                            <mat-select formControlName="company">
                                                <mat-option value="company"></mat-option>
                                                    <mat-option (click)="setCompany(x)" *ngFor="let x of listAdvertiser ; index as i" value="{{x.companyName}}">{{x.companyName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 col-sm-12   ">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label>Phone </mat-label>
                                </div>
                                <div class="col-lg-12  col-md-12 ">
                                    <mat-form-field >
                                        <input matInput mask="00 00 00 00 00" placeholder=""  formControlName="phone">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6  col-lg-6   col-sm-12">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label>Phone 2</mat-label>
                                </div>
                                <div class="col-lg-12  col-md-12 ">
                                    <mat-form-field >
                                        <input  mask="00 00 00 00 00" matInput placeholder=""  formControlName="phone2">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 col-sm-12   ">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label>Fax</mat-label>
                                </div>
                                <div class="col-lg-12  col-md-12 ">
                                    <mat-form-field >
                                        <input  mask="00 00 00 00 00" matInput placeholder=""  formControlName="fax">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6  col-lg-6   col-sm-12">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label>Adress</mat-label>
                                </div>
                                <div class="col-lg-12  col-md-12 ">
                                    <mat-form-field >
                                        <input matInput placeholder=""  formControlName="adress">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-6 col-sm-12   ">
                                    <div class="col-lg-2   col-md-2 ">
                                        <mat-label>Zip Code</mat-label>
                                    </div>
                                    <div class="col-lg-12   col-md-12 ">
                                        <mat-form-field>
                                            <mat-select formControlName="zipCode">
                                                <mat-option [value]="'true'">
                                                    Oui
                                                </mat-option>
                                                <mat-option [value]="'false'">
                                                    Non
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6  col-lg-6   col-sm-12">
                                    <div class="col-lg-2 col-md-2 ">
                                        <mat-label>Town</mat-label>
                                    </div>
                                    <div class="col-lg-12  col-md-12">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="town">
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-6 col-sm-12   ">
                                    <div class="col-lg-2   col-md-2 ">
                                        <mat-label>Country</mat-label>
                                    </div>
                                    <div class="col-lg-12   col-md-12 ">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="country">
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6  col-lg-6   col-sm-12">
                                    <div class="col-lg-12 col-md-2 ">
                                        <mat-label>Additional Information</mat-label>
                                    </div>
                                    <div class="col-lg-12  col-md-12">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="aditionalInformation">
                                        </mat-form-field>
                                    </div>
                            </div>
                             
                        </div>
                        <div class="row input-row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label>Password</mat-label>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <mat-form-field >
                                        <input  matInput placeholder=""   formControlName="password">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="container mt-5">
                            <div class="row">
                              <div class="col-md-12 col-lg-12 text-center">
                                <button (click)="modifContact()"  mat-button  class="btn btn-secondaryWithBorder" >Save Modifications</button>
                            </div>
                            </div>
                          </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </body>
</html> -->

<html>
    <body>
        <div *ngIf="showContact" class="page container">
            <a [routerLink]="['/list-contact']" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
            <mat-card class="mt-1">
                <mat-card-content class=" mt-1">
                    <form [formGroup]="contact" >
                        
                        <div class="row input-row  ">
                            <div class="col-md-12 col-lg-6 display-mid   ">
                                    <div class="col-lg-4  col-6 col-md-4">
                                        <mat-label class="labelFont">First Name</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="firstname">
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-12 col-lg-6 display-mid">
                                <div class="col-lg-4 col-md-2 ">
                                    <mat-label class="labelFont">Last Name</mat-label>
                                </div>
                                <div class="col-lg-8  col-md-12 ">
                                    <mat-form-field >
                                        <input matInput placeholder=""  formControlName="lastname">
                                    </mat-form-field>
                                </div>
                        </div> 
                             
                        </div>
                        <div class="row input-row">
                            <div class="col-lg-12 col-md-12 col-sm-12 display-mid">
                                <div class="col-lg-2 col-md-4">
                                    <mat-label class="labelFont">Fonction</mat-label>
                                </div>
                                <div class="col-lg-10 col-md-12">
                                    <mat-form-field >
                                        <input matInput placeholder=""  formControlName="fonction">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row  ">
                            <div class="col-lg-12 col-md-12 col-sm-12 display-mid  ">
                                    <div class="col-lg-2   col-md-2 ">
                                        <mat-label class="labelFont">Email</mat-label>
                                    </div>
                                    <div class="col-lg-10   col-md-10">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="email">
                                        </mat-form-field>
                                    </div>
                            </div>
                              
                        </div>
                        <div class="row input-row  ">
                        
                            <div class="col-lg-12 col-md-12 col-sm-12 display-mid">
                                    <div class="col-lg-2 col-md-2 ">
                                        <mat-label class="labelFont">Company</mat-label>
                                    </div>
                                    <div class="col-lg-10  col-md-10">
                                        <mat-form-field>
                                            <mat-select formControlName="company">
                                                <mat-option value=""></mat-option>
                                                    <mat-option (click)="setCompany(x)" *ngFor="let x of listAdvertiser ; index as i" value="{{x.companyName}}">{{x.companyName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 col-sm-12 display-mid  ">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label class="labelFont">Phone </mat-label>
                                </div>
                                <div class="col-lg-8  col-md-12 ">
                                    <mat-form-field >
                                        <input matInput placeholder="" mask="00 00 00 00 00 00" formControlName="phone">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6  col-lg-6 display-mid  col-sm-12">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label class="labelFont">Phone 2</mat-label>
                                </div>
                                <div class="col-lg-8  col-md-12 ">
                                    <mat-form-field >
                                        <input matInput placeholder="" mask="00 00 00 00 00 00" formControlName="phone2">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-12 col-sm-12 display-mid  ">
                                <div class="col-lg-2 col-md-4">
                                    <mat-label class="labelFont">Fax</mat-label>
                                </div>
                                <div class="col-lg-10  col-md-12 ">
                                    <mat-form-field >
                                        <input matInput placeholder=""  formControlName="fax">
                                    </mat-form-field>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row input-row">
                            
                            <div class="col-md-6 col-lg-12 col-lg-6 display-mid  col-sm-12">
                                <div class="col-lg-2 col-md-4">
                                    <mat-label class="labelFont">Address</mat-label>
                                </div>
                                <div class="col-lg-10  col-md-12 ">
                                    <mat-form-field >
                                        <input matInput placeholder=""  formControlName="adress">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-12 col-lg-6 display-mid  col-sm-12   ">
                                    <div class="col-lg-2   col-md-2 ">
                                        <mat-label class="labelFont">Zip Code</mat-label>
                                    </div>
                                    <div class="col-lg-10   col-md-10 ">
                                        <mat-form-field >
                                            <input matInput placeholder="" mask="separator.3" formControlName="zipCode">
                                        </mat-form-field>
                                    </div>
                            </div>
                            
                        </div>
                        <div class="row input-row  ">
                            
                            <div class="col-md-6 col-lg-12 col-lg-6 display-mid  col-sm-12">
                                    <div class="col-lg-2 col-md-2 ">
                                        <mat-label class="labelFont">Town</mat-label>
                                    </div>
                                    <div class="col-lg-10  col-md-10">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="town">
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-12 col-lg-6 display-mid  col-sm-12   ">
                                    <div class="col-lg-2   col-md-2 ">
                                        <mat-label class="labelFont">Country</mat-label>
                                    </div>
                                    <div class="col-lg-10   col-md-12 ">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="country">
                                        </mat-form-field>
                                    </div>
                            </div>
                            
                             
                        </div>
                        <div class="row input-row  ">
                            
                            <div class="col-md-6 col-lg-12 col-lg-6 display-mid  col-sm-12">
                                    <div class="col-lg-2 col-md-2 ">
                                        <mat-label class="labelFont">Additional Information</mat-label>
                                    </div>
                                    <div class="col-lg-10  col-md-12">
                                        <mat-form-field >
                                            <input matInput placeholder=""  formControlName="aditionalInformation">
                                        </mat-form-field>
                                    </div>
                            </div>
                             
                        </div>
                        <div class="row input-row">
                            <div class="col-lg-12 display-mid col-md-12 col-sm-12">
                                <div class="col-lg-2 col-md-4">
                                    <mat-label class="labelFont">Dashboard access password</mat-label>
                                </div>
                                <div class="col-lg-10 col-md-12">
                                    <mat-form-field (click)="clipboard()"  [cdkCopyToClipboard]="passwordClip" >
                                            <div (click)="clipboard()">
                                                <input    matInput disabled [(ngModel)]="passwordClip" [ngModelOptions]="{standalone: true}"  >
                                            </div>
                                            <button   matSuffix mat-icon-button aria-label="Clear" >
                                                <mat-icon>file_copy</mat-icon>
                                              </button>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 mb-3">
                            <div flex-center>
                                <button (click)="modifContact()"   class="btn  btn-secondaryWithBorder  shadow-none" >Save Modifications</button>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
                
            </mat-card>
        </div>
    </body>
</html>
