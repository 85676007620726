<!DOCTYPE html>
<html lang="en">
  <header>
  </header>
  <body>
    <div *ngIf="init" class="page container-fluid ">
        <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <div class="tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
                  <div  class="panel-header ">
                    <div class="top-lead">
                      <div class="group clearfix">
                          <i class="fa fa-gift    icon mb-0 mt-0"></i>
                          <div  style="font-size:20px" class="labelFont text-normal">Offers</div>
                      </div>
                    </div>
                  </div>
                  <table class="table  table-hover  text-center  ">
                    <thead >
                        <tr class="tableTitle ">
                          <th class="col-2 text-center"scope="col">Status</th>
                          <th class="col-3 text-center" scope="col">Date</th>
                          <th class="col-4 text-center"scope="col">Offer Name</th>
                          <th class="col-3 text-center" scope="col">Network</th>
                        </tr>
                      </thead>
                    <tbody class="bodyTable">
                      <tr *ngFor = "let offer of dataSourceOffer" [routerLink]="['/detailOffer/', offer._id]">
                        <td class="center-text">
                          <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(offer.status)}" > {{fctBasic.getNameByStatus(offer.status)}}</label>
                        </td>
                        <td>
                          {{offer.startDate | date:'yyyy/MM/dd'}} - {{offer.endDate | date:'yyyy/MM/dd'}}
                        </td>
                        <td>{{offer.campaignName}}</td>
                        <td>
                          <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(offer.platformeType)}" style="color:white">
                            <i  *ngIf="offer.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                            <i *ngIf="offer.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                            <i *ngIf="offer.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                            <i *ngIf="offer.platformeType == 'Blog'" class="fa fa-bold "></i>
                            <i *ngIf="offer.platformeType == 'multiNetwork'" class="fa fa-globe"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>     
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <!-- <div class=" tableResponsiveMessage table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1 " style="background-color:white;">
                  <div  class="panel-header ">
                    <div class="top-lead">
                      <div class="group clearfix">
                          <i class="fa fa-envelope     icon mb-0 mt-0"></i>
                          <div  style="font-size:20px" class="labelFont text-normal">Message(s)</div>
                      </div>
                    </div>
                  </div>
                  <mat-accordion >
                    <div *ngIf="arrayMessage && arrayMessage.length > 0" class="mt-3">
                      <li *ngFor="let m of arrayMessage; let i=index" class="list-group-item ">
                        <div class="media">
                          <div class="media-body">
                            <p>
                              <strong>{{m.senderName}}</strong>
                              <time class="text-muted float-right">{{ m.creationDate | date:'yyyy/MM/dd'}}</time>
                            </p>
                            <p class="card-text fileTextMessage">{{ m.body }}</p>
                          </div>
                        </div>
                      </li>
                      
                    </div>
                    <mat-expansion-panel  hideToggle >
                      <mat-expansion-panel-header >
                        <mat-panel-title >
                          Send New Message
                        </mat-panel-title>
                        
                      </mat-expansion-panel-header>
                      
                      <div class="col-md-12 col-lg-12 mt-2">
                        <label  class="control-label">Message</label>
                        <mat-form-field  appearance="outline">
                          <textarea rows="5" [(ngModel)]="newMessage"  matInput ></textarea>
                        </mat-form-field>
                    </div>
                      <div flex-center>
                        <button type="button" style="font-size: smaller;" class="btn btn-secondary mb-2" (click)="sendNewMessage()">Send !</button>
                      </div>
                    </mat-expansion-panel>
                </mat-accordion>    
              </div> -->
                <div class="tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
                  <div  class="panel-header ">
                    <div class="top-lead">
                      <div class="group clearfix">
                          <i class="fa fa-child    icon mb-0 mt-0"></i>
                          <div  style="font-size:20px" class="labelFont text-normal">My Account</div>
                      </div>
                    </div>
                  </div>
                  <table class="table  table-hover  text-center  ">
                    <thead >
                        <tr class="tableTitle ">
                          <th class="col-3 text-center"scope="col">Last Name</th>
                          <th class="col-3 text-center" scope="col">First Name</th>
                          <th class="col-3 text-center"scope="col">Email</th>
                          <th class="col-3 text-center" scope="col">Action</th>
                        </tr>
                      </thead>
                    <tbody class="bodyTable">
                      <tr >
                        <td class="center-text">
                          {{currentUser.influencerInfo.firstname}}
                        </td>
                        <td>
                          {{currentUser.influencerInfo.lastname}}
                        </td>
                        <td>{{currentUser.influencerInfo.secondarymail}}</td>
                        <td>
                          <button [routerLink]="['/profile']" type="button" style="font-size: smaller;" class="btn btn-secondary"><span class="fa fa-search"></span></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>     
              </div>
              
              <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <div class="mb-2 tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
                  <div  class="panel-header ">
                    <div class="hearder-Container-flex">
                      <div style="font-size:20px">Manage Networks</div>
                      <div style="font-size:12px">Numbers of networks {{dataSourceOffer.length}}</div>
                    </div>
                    <div  class="hearder-Content-flex ">
                      <div>
                        <i style="font-size:28px;" class="fa fa-gift"></i>
                      </div>
                    </div>
                     
                  </div>
                  <table class="table  table-hover  text-center  ">
                    <thead >
                        <tr class="tableTitle ">
                          <th scope="col">Platforme Type</th>
                          <th  scope="col">Name</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                    <tbody class="bodyTable">
                      <tr *ngFor = "let offer of dataSourceOffer">
                        <td>
                          <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(offer.platformeType)}" style="color:white">
                            <i  *ngIf="offer.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                            <i *ngIf="offer.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                            <i *ngIf="offer.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                            <i *ngIf="offer.platformeType == 'Blog'" class="fa fa-bold "></i>
                          </button>
                        </td>
                        <td class="center-text">
                          Network Name
                        </td>
                        <td>
                          <div class="form-group center-text form-group ">
                            <button  type="button" [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(offer.platformeType)}" style="color:white" class="btn btn-default btn-template rounded"><i class=" fa mr-2 fa-instagram"></i>Manage</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>     
              </div> -->
        </div>
    </div>
  </body>
</html>
