
<body>
    <app-header></app-header>
      <header class="influencerhead">
          <div class="container d-flex h-100 align-items-center">
            <div class="mx-auto text-center">
              <h1 class="mx-auto my-0 text-uppercase"><mark style="background-color: black;opacity:1;letter-spacing:2px;font-size: 32px;">Turn your passion into success</mark></h1>
              <h2 class="text-white-50 mx-auto mt-2 text-uppercase"></h2>
            </div>
          </div>
        </header>
      
        <section id="Influencer" class="projects-section bg-light">
          <div class="container">
          <div>
                <p class=" textcard text-center mt-4">Join the Influence Lab community and develop new opportunities.</p>
                <p class=" textcard text-center mt-4">Test news products for free, create authentic content, engage your community with exclusive news.</p>
                <p class=" textcard text-center mt-4">Collaborate with your favorite brands and get paid for it !</p>
            </div>
          </div>
        </section>
        <!-- Projects Section -->
        <section id="Influencer" class="projects-section bg-light">
            <div class="container">
              <div class="row align-items-center no-gutters mb-4 mb-lg-5">
                <div class="col-xl-8 col-lg-7">
                  <div class="featured-text2 text-center text-lg-left">
                    <h4>1-SIGN UP AND BROWSE</h4>
                    <p class="text-black mb-0">Browse the campagn and receive collaboration request from your favorite brands</p>
                    <p></p>
                    <h4>2-CREATE AUTHENTIC CONTENT</h4>
                    <p class="text-black mb-0">Create authentic and exclusive content to engage your community with the hottest trends</p>
                    <p></p>
                    <!--h4>3-POST AND GET PAID</h4>
                    <p class="text-black mb-0">Post you creative content and get fair paid based on your influence power</p-->
                  </div>
                </div>
                <div class="col-xl-4 col-lg-5">
                  <div class="hovereffect">
                    <img class="img-fluid mb-3 mb-lg-0" src="../../../assets/images/edito/influencelab-chill.jpg" alt="">
                  </div>
      
                </div>
              </div>
            </div>
            <div class="mx-auto text-center mt-5">
              <a href="/signup">
              <button type="button" align="center" class="center-block btn btn-secondary btn-lg mt-4">START NOW !</button>
              </a>
      
              <p></p>
            </div>
          </section> 
      <app-footer></app-footer>

  </body>
  
  
