
<html>
    <body >
        <div class="container page">
            <a *ngIf="offerShow" [routerLink]="['/detailOffer/', offer._id]" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
                <mat-card  class="mt-1">
                    <mat-card-content *ngIf="offerShow">
                        <div class="containerBox">
                            <div class="box">
                                <div class="text-center">
                                    <mat-label class="title">UPLOAD YOUR INVOICE 🤓</mat-label>
                                </div>
                                
                            </div>
                        </div>
                        <div flex-center>
                            <div class="text-center labelFont mt-3 col-12 col-lg-12 col-md-12 col-sm-12">
                                <p>Reference : {{offer.campaignName}} </p>
                                <div>Le règlement aura lieu par virement sous 30 jours à réception.</div>
                            </div>
                        </div>
                        
                        <form class="align-items-center container">
                            <div class="mt-3 ">
                                <div class="col-12 col-lg-12 col-md-12 ">
                                    <div class="row">
                                        <div  class="col-12 col-lg-9 col-md-12 col-sm-12">
                                            <div class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-5   col-md-6 ">
                                                            <mat-label  class="labelFont">Invoice*</mat-label>
                                                            <i [cdkCopyToClipboard]="invoiceTxt" class="fa fa-info ml-2" #tooltip="matTooltip"  
                                                            matTooltip="Destinataire Influencelab. 
                                                            Service gestion: 90 rue Raynouard - 75016 Paris
                                                            RCS Paris No 842 653 602." 
                                                            aria-label="Button that displays a tooltip when focused or hovered over"           
                                                            matTooltipPosition="right"
                                                            matTooltipClass="allow-cr"
                                                            aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                                            
                                                        </i>
                                                        
                                                        </div>
                                                        <div class="col-lg-7 col-md-6 ">
                                                            <mat-form-field>
                                                                <ngx-mat-file-input (change)="addFile($event, 'invoice')"></ngx-mat-file-input>
                                                                <mat-icon matSuffix>attach_file</mat-icon>
                                                              </mat-form-field>
                                                              <div  class="row col-lg-12 col-md-12" *ngIf="offer.billing && offer.billing.invoice">
                                                                <div class="img_wrapper_Invoice">
                                                                    <span (click)="deleteFile(offer.billing.invoice, 'invoice')" class="close">&times;</span>
                                                                    <img  [src]="picService.getExtentionFileToShow(offer.billing.invoice)" class="img-responsive  imgFeedMulti">
                                                                    <div class="fileText">{{offer.billing.invoice.name}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-5  col-md-6 ">
                                                            <mat-label  class="labelFont">Upload RIB*</mat-label>
                                                            <i class="fa fa-info ml-2" #tooltip="matTooltip"  
                                                            matTooltip="RIB  (obligatoire pour chaque nouvelle facture)" 
                                                            aria-label="Button that displays a tooltip when focused or hovered over"           
                                                            matTooltipPosition="right"
                                                            matTooltipClass="allow-cr"
                                                            aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                                            
                                                        </i>

                                                        </div>
                                                        <div class="col-lg-7  col-md-6">
                                                            <mat-form-field>
                                                                <ngx-mat-file-input (change)="addFile($event, 'rib')"></ngx-mat-file-input>
                                                                <mat-icon matSuffix>attach_file</mat-icon>
                                                            </mat-form-field>
                                                            <div  class="row col-lg-12 col-md-12" *ngIf="offer.billing && offer.billing.rib">
                                                                <div class="img_wrapper_Invoice">
                                                                    <span (click)="deleteFile(offer.billing.rib, 'rib')" class="close">&times;</span>
                                                                    <img  [src]="picService.getExtentionFileToShow(offer.billing.rib)"   class="img-responsive center-block imgFeedMulti">
                                                                    <div class="fileText">{{offer.billing.rib.name}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div  class="row input-row  ">
                                                <div class="col-md-12 col-lg-12 col-sm-12   ">
                                                    <div class="col-md-12 col-lg-12  display-mid">
                                                        <div flex-center class="col-lg-5   col-md-4 ">
                                                            <mat-label  class="labelFont">Upload Attestation de vigilance </mat-label>
                                                            <i class="fa fa-info ml-2" #tooltip="matTooltip"  
                                                            matTooltip="Pour les auto-entrepreneurs,
                                                            &#x0A; attestation de vigilance de moins de 3 mois,
                                                            &#013; disponible depuis le site de l'URSSAF" 
                                                            matTooltipPosition="right"
                                                            matTooltipClass="allow-cr"
                                                            aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                                            
                                                        </i>
                                                        </div>
                                                        <div class="col-lg-7   col-md-8 ">
                                                            <mat-form-field>
                                                                <ngx-mat-file-input (change)="addFile($event, 'attestation')"></ngx-mat-file-input>
                                                                <mat-icon matSuffix>attach_file</mat-icon>
                                                              </mat-form-field>
                                                              <div  class="row col-lg-12 col-md-12" *ngIf="offer.billing && offer.billing.attestation">
                                                                <div class="img_wrapper_Invoice">
                                                                    <span (click)="deleteFile(offer.billing.attestation, 'attestation')" class="close">&times;</span>
                                                                    <img  [src]="picService.getExtentionFileToShow(offer.billing.attestation)"   class="img-responsive center-block imgFeedMulti">
                                                                    <div class="fileText">{{offer.billing.attestation.name}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="containerBox mt-3" >
                            <div class="box">
                                <div class="boxtxt text-center">
                                    <mat-label > <i>Thank you very much for your great work on this campaign !</i> </mat-label>
                                    
                                </div>
                                <div class="boxtxt text-center">
                                    <mat-label ><i>Your payment will be processed within 30 days. Feel free to contact gestion@influencelab.co if you have any questions</i></mat-label>
                                </div>
                            </div>
                        </div>
                        <div flex-center class="mt-3 mb-3">
                            <button (click)="createNewContent()"  class="btn  btn-secondaryWithBorder shadow-none" ><b>Finish !</b></button>
                        </div>
                    </mat-card-content>
                    
                </mat-card>
        </div>
    </body>
</html>