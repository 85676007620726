import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserLoginService } from './user-login.service';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute } from '@angular/router';
interface Path { 
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {


  paths: Array<Path> = [
  { name: 'campagne', value: 'admin' },
  { name: 'newCampaign', value: 'admin' },
  { name: 'listCampaign', value: 'admin' },
  { name: 'campaignDash', value: 'admin' },
  { name: 'campaignDashYoutube', value: 'admin' },
  { name: 'selectorCampaign', value: 'admin' },
  { name: 'listInfluencer', value: 'admin' },
  { name: 'detailCampaign', value: 'admin'},
  { name: 'detail-influencer/:id', value: 'admin'},
  { name: 'detailCampaign/:id', value: 'admin'},
  { name: 'detailOfferAdmin/:id', value: 'admin'},
  { name: 'insight-profil/:id', value: 'admin'},
  { name: 'listOffers', value: 'admin' },
  { name: 'offerDashboard/:id', value: 'admin' },
  { name: 'offerDashboard-youtube/:id', value: 'admin' },
  { name: 'offerDashboard-multiplatforme/:id', value: 'admin' },
  { name: 'home-admin', value: 'admin'},
  {name:  'invoice/:id', value: 'admin'},
  { name: 'home-contact', value: 'contact'},
  { name: 'dashboard-contact', value: 'contact'},
  { name: 'offerList', value: 'admin' },
  { name: 'formOfferAdmin', value: 'admin' },
  { name: 'pigeExternal', value: 'admin' },
  { name: 'home', value: 'influencer' },
  { name: 'profile', value: 'influencer' },
  { name: 'offer', value: 'influencer' },
  { name: 'register', value: 'influencer' },
  { name: 'billing-influencer/:id', value: 'influencer' },
  { name: 'brand', value: '' },
  { name: 'influencer', value: '' },
  { name: 'signin', value: '' },
  { name: 'loginadmin', value: '' },
  { name: 'signup', value: '' },
  { name: 'legal', value: '' },
  { name: 'legal/cgu', value: '' },
  { name: 'legal/mentionLegal', value: '' },
  { name: 'legal/contratInfluencer', value: '' },
  { name: 'legal/cookiePolitique', value: '' },
  { name: '', value: '' },
  { name: 'success-registration', value: '' },
  { name: '**', value: '' },
  ];

  pathNavbar: Array<String> = [
    "/offer", "billing-influencer", "/campagne", "/newCampaign", "/listCampaign", "/home-contact", "/dashboard-contact",
    "/campaignDash", 'invoice', '/selectorCampaign', "/listInfluencer", "/campaignDashYoutube", "/pigeExternal",
    "/detailCampaign", "detailOfferAdmin", "detailOffer","/detail-influencer","/insight-profil",
    "/listOffers", "formOffer", "formOfferAdmin", "offerDashboard", "offerDashboard-youtube","/home-admin", "/home", "/offerlist", "/list-contact", "create-contact", "detail-contact", "list-company", "create-company", "detail-company", "myOffers", "profile",
  ];

  constructor(private authService: UserLoginService, private route: ActivatedRoute, private router: Router, private service : ApiService) {

  }

  getGuardInfo(url: string): Path {
    const not_found: Path = { name: "", value: "" };
    const path = this.paths.find(obj => {
        return obj.name.search(url) != -1
    });
    return path ? path : not_found;
  }

  userIsAllowed(url: any): boolean {
    return this.pathNavbar.some(function(elem) {
      return url.indexOf(elem) != -1;
    }); 
  }


  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {  
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.authService.isAuthenticated()) {
      const userRole = this.authService.getRole();
      if (route.data.role && route.data.role.indexOf(userRole) === -1) {
        this.router.navigate(['/home']);
        return false;
      }
      return true;
    }
    if (url == "/profile#_=_"){
      this.router.navigate(['/profile']);
      return false
    }
    this.router.navigate(['/home']);
    return false;
  }
}	
