<html>
    <body >
        <div class="container page" *ngIf="init == true">
            <a [routerLink]="['/listInfluencer']" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
            <mat-card class="mt-1" >
                <div class="title-card" text-center>
                    <div>
                        <mat-label class="title">🤗 User Account 🚀</mat-label>
                    </div>
                </div>
                <mat-divider ></mat-divider>
                <mat-card-content class=" mt-4">
                    <form class="align-items-center">

                        <div class="row input-row  ">
                            <div class="col-md-6 col-12 col-lg-6 col-sm-12   ">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6  col-md-4 col-6 ">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">First Name</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 col-6">
                                        <mat-form-field >
                                            <input class="disabledtext" [(ngModel)]="dataSourceInfluencer.influencerInfo.firstname" disabled readonly [ngModelOptions]="{standalone: true}" matInput  >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 col-lg-6   col-sm-12">
                                <div class="col-md-12 display-mid  col-lg-12">
                                    <div class="col-lg-4  col-6 col-md-4 ">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Last Name</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field >
                                            <input class="disabledtext" [(ngModel)]="dataSourceInfluencer.influencerInfo.lastname" disabled readonly [ngModelOptions]="{standalone: true}" matInput  >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Contact Email</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field >
                                            <input class="disabledtext" matInput [(ngModel)]="dataSourceInfluencer.influencerInfo.secondarymail" disabled readonly [ngModelOptions]="{standalone: true}">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Phone Number</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field>
                                            <input  *ngIf="dataSourceInfluencer.phone" class="disabledtext" matInput  disabled [(ngModel)]="dataSourceInfluencer.phone" readonly [ngModelOptions]="{standalone: true}" data-format="dd-dd-dd-dd-dd" type="number"   >
                                            <input *ngIf="!dataSourceInfluencer.phone"class="disabledtext" matInput  disabled [(ngModel)]="dataSourceInfluencer.influencerInfo.phone" readonly [ngModelOptions]="{standalone: true}" data-format="dd-dd-dd-dd-dd" type="number"   >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 display-mid col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Address</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field >
                                            <input class="disabledtext" disabled [(ngModel)]="dataSourceInfluencer.influencerInfo.adress" readonly [ngModelOptions]="{standalone: true}" matInput required   >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Post Code</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field>
                                            <input class="disabledtext" disabled [(ngModel)]="dataSourceInfluencer.influencerInfo.postcode" readonly [ngModelOptions]="{standalone: true}" matInput required   >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 display-mid col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">City</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field >
                                            <input class="disabledtext" disabled [(ngModel)]="dataSourceInfluencer.influencerInfo.city" readonly [ngModelOptions]="{standalone: true}" matInput required  >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Country</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field >
                                            <input class="disabledtext" disabled [(ngModel)]="dataSourceInfluencer.influencerInfo.country" readonly [ngModelOptions]="{standalone: true}" matInput required  >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 display-mid col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Gender</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field>

                                            <input class="disabledtext" disabled matInput [(ngModel)]="dataSourceInfluencer.influencerInfo.gender" readonly [ngModelOptions]="{standalone: true}" placeholder=""  >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Birth Date</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field  >
                                            <input class="disabledtext" matInput disabled [(ngModel)]="dataSourceInfluencer.influencerInfo.birthday" readonly [ngModelOptions]="{standalone: true}"   >
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 display-mid col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Main Language</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field>

                                            <input class="disabledtext" disabled [(ngModel)]="dataSourceInfluencer.influencerInfo.language" readonly [ngModelOptions]="{standalone: true}" matInput  placeholder=""  >
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Other Languages</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field >
                                            <input class="disabledtext" disabled [(ngModel)]="dataSourceInfluencer.influencerInfo.secondaryLanguage" readonly [ngModelOptions]="{standalone: true}" matInput placeholder=""  >
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row paddingButton">
                            <div class="col-md-6 col-lg-6 display-mid col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div  class="col-lg-4 col-6 textareapostion col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Topics</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">


                                          <span   *ngFor="let item of dataSourceInfluencer.influencerInfo.topics | keyvalue" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                            <div  *ngIf="item.value == true">{{ item.key }}</div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 display-mid col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Keywords</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <span     *ngFor="let keyword of dataSourceInfluencer.influencerInfo.keywords; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                            {{ keyword }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 textareapostion">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Profile Picture</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <div class="col-lg-12 col-md-12 row">
                                            <div class="imgrow">
                                                <div class="img_wrapper_inf">
                                                    <img *ngIf="!dataSourceInfluencer.influencerInfo.picture"  src="assets/images/logo/profile-default.jpeg"   class="img-responsive center-block imgFeedProfileDefault"  >
                                                    <img *ngIf="dataSourceInfluencer.influencerInfo.picture" [defaultImage]="'assets/images/logo/profile-default.jpeg'" [errorImage]="'assets/images/logo/profile-default.jpeg'"  [lazyLoad]="fctBasic.checkUrlPath(dataSourceInfluencer.influencerInfo.picture)"  class="img-responsive center-block imgFeedMulti">
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <img  *ngIf="!dataSourceInfluencer.influencerInfo.picture" src="assets/images/logo/profile-default.jpeg"   class="img-responsive center-block"  style="max-height:100px; max-width:100px;">
                                        <img   *ngIf="dataSourceInfluencer.influencerInfo.picture"  [src]="fctBasic.checkUrlPath(dataSourceInfluencer.influencerInfo.picture)"   class="img-responsive center-block"  style="max-height:200px; max-width:200px;"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Influencer Score</mat-label>
                                    </div>
                                    <div class="col-lg-8 row">
                                        <div class="col-12 mb-1">
                                            <label [ngClass]="isDisabled ? 'disabledLabel' : ''"class="row control-label">Score</label>
                                            <ng-rating-bar class="row" colorActive="rgb(86, 86, 86)" [disabled]=true [styles]="{fontSize: '13px', margin: '0px'}" [(value)]="dataSourceInfluencer.rating"  [ratingCount]="ratingCount"  ></ng-rating-bar>
                                        </div>
                                            <div class="col-5">
                                                <label [ngClass]="isDisabled ? 'disabledLabel' : ''"class="row control-label">Quanti</label>
                                                <ng-rating-bar class="row" colorActive="rgb(86, 86, 86)" [styles]="{fontSize: '13px', margin: '0px'}" [(value)]="valueQuanti" (valueChange)="onValueChangeQuanti($event)" [ratingCount]="ratingCount"  ></ng-rating-bar>
                                            </div>
                                            <div class="col-5">
                                                <label [ngClass]="isDisabled ? 'disabledLabel' : ''"class="row control-label">Quali</label>
                                                <ng-rating-bar class="row" colorActive="rgb(86, 86, 86)" [styles]="{fontSize: '13px', margin: '0px'}" [(value)]="valueQuali" (valueChange)="onValueChangeQuali($event)" [ratingCount]="ratingCount"></ng-rating-bar>
                                            </div>
                                            <div class="col-2">
                                                <i [ngClass]="isDisabled ? 'disabledLabel' : ''" (click)="noteInfluencers()"class=" right fa fa-plus" style="font-size:15px;"></i>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div class="row input-row mt-4">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <div class="col-md-12  col-lg-12 display-mid">
                                    <div class="col-lg-4  col-12 col-md-4">
                                        <mat-label class="labelFont">Manage Networks</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-12 col-md-8">
                                        <form class="network-button shadow-none" (click)="moreSocialNetwork()" action="">
                                            <button mat-mini-fab class="mr-2"  style="color:white; background-color: #cc181e;">
                                                <i  class="fa  fa-youtube "></i>
                                              </button>
                                            <button mat-mini-fab class="mr-2" style="color:white; background-color: #8a3ab9;">
                                                <i  class="fa  fa-instagram "></i>
                                              </button>
                                              <button mat-mini-fab class="mr-2" style="color:white; background-color: #fccc63;">
                                                <i  class="fa  fa-bold "></i>
                                            </button>
                                            <button mat-mini-fab  style="color:white; background-color: black;">
                                                <mat-icon>tiktok</mat-icon>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12">
                                <div class="col-md-12 col-lg-12 display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Engagement Rate</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <form action="">
                                            <mat-form-field  >
                                                <input  matInput mask="separator.5" [(ngModel)]="engagementRate" [ngModelOptions]="{standalone: true}">
                                                <button (click)="addEngagementRate()" matSuffix mat-icon-button aria-label="Clear" >
                                                  <mat-icon>add</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </form>
                                        <span  class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                            <div  *ngIf="dataSourceInfluencer.engagementRate > 0">{{ dataSourceInfluencer.engagementRate }} %</div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row input-row mt-4">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <div class="col-md-12  col-lg-12 display-mid">
                                    <div class="col-lg-4  col-12 col-md-4">
                                        <mat-label class="labelFont">Manage Followers</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-12 col-md-8">
                                        <form class="network-button shadow-none" (click)="addFollowersOnPage()" action="">
                                            <button mat-mini-fab class="mr-2"  style="color:white; background-color: #cc181e;">
                                                <i  class="fa  fa-youtube "></i>
                                              </button>
                                            <button mat-mini-fab class="mr-2" style="color:white; background-color: #8a3ab9;">
                                                <i  class="fa  fa-instagram "></i>
                                              </button>
                                              <button mat-mini-fab class="mr-2" style="color:white; background-color: #fccc63;">
                                                <i  class="fa  fa-bold "></i>
                                            </button>
                                            <button mat-mini-fab  style="color:white; background-color: black;">
                                                <mat-icon>tiktok</mat-icon>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </form>

                </mat-card-content>

            </mat-card>
            <mat-card class="mt-4">
                <mat-card-content>
                  <div class="tableResponsiveAnnex table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  ">
                      <div  class="panel-header-campaign ">
                          <div class="col-lg-12">
                            <div style="text-transform:uppercase;" [ngClass]="isDisabled ? 'disabledLabel' : ''">Networks Overview </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                        <mat-divider ></mat-divider>
                    </div>
                      <table  class="table table-hover  tableTitle  text-center">
                        <thead>
                            <tr class="tableTitle ">
                                <th class=" text-center"scope="col"> Network </th>
                                <th class=" text-center" scope="col">Community Size </th>
                                <th class=" text-center" scope="col"> Lieux principaux </th>
                                <th class=" text-center"scope="col"> Tranche d'age </th>
                                <th class=" text-center"scope="col">Genre</th>
                                <th class=" text-center"scope="col">Status</th>
                            </tr>
                          </thead>
                        <tbody class="bodyTable">
                            <tr *ngFor = "let network of dataSourceInfluencer.userObject">
                                <td *ngIf="network.facebookPage && network.facebookPage.token"  class="col-lg-2 text-center">
                                    <a *ngIf="network.facebookPage.link" target="blank" href="{{network.facebookPage.link}}"><span class="fileText">{{network.facebookPage.name }}</span>
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                    <div (click)="fctBasic.gerUrlPathToAccountUser(network, 'Facebook')" *ngIf="!network.facebookPage.link">{{network.facebookPage.name}} </div>
                                    <div (click)="fctBasic.gerUrlPathToAccountUser(network, 'Facebook')"><i class="fa fa-facebook"></i></div>
                                    <div (click)="fctBasic.gerUrlPathToAccountUser(network, 'Instagram')" *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.name">
                                        {{network.facebookPage.instagramObject.name }}
                                    </div>
                                    <div (click)="fctBasic.gerUrlPathToAccountUser(network, 'Instagram')">
                                        <i *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.name" class="fa fa-instagram"></i>
                                    </div>
                                </td>
                                <td *ngIf="network.youtubePage" (click)="fctBasic.gerUrlPathToAccountUser(network, 'Youtube')" class=" bodyTable col-lg-2 text-center">
                                    <a *ngIf="network.urlYtoutube" target="blank" href="{{network.urlYtoutube}}"><p class="fileText">{{network.youtubePage.name }}</p></a>
                                    <p ng-click="yt(x)" *ngIf="!network.urlYtoutube">{{network.youtubePage.name | slice:0:50}}</p>
                                <i class="fa fa-youtube"></i>
                                </td>
                                <td *ngIf="network.blogToken" (click)="fctBasic.gerUrlPathToAccountUser(network, 'Blog')" class="text-center col-lg-2">
                                    <p >{{network.blogToken.url | slice:0:50}}</p>
                                <i class="fa fa-bold"></i>
                                </td>
                                <td *ngIf="network.instagramUrl" (click)="fctBasic.gerUrlPathToAccountUser(network, 'InstaUrl')" class="text-center col-lg-2">
                                    <a  *ngIf="network.instagramUrl" ><span class="fileText">{{fctBasic.getInfluenceurNetwork(network.instagramUrl) | slice:0:50 }}</span></a>
                                    <i class="fa fa-instagram"></i>
                                </td>
                                <td *ngIf="network.tiktokjson" (click)="fctBasic.gerUrlPathToAccountUser(network, 'Tiktok')" class="text-center col-lg-2">
                                    <a  *ngIf="network.tiktokjson.url" target="blank"><p> {{network.tiktokjson.url | slice:0:30}}</p></a>
                                    <mat-icon>tiktok</mat-icon>
                                </td>
                                <td class=" text-center col-lg-2">
                                    <p *ngIf="network.facebookPage && network.facebookPage.followers">{{network.facebookPage.followers | number}}</p>
                                    <p *ngIf="network.facebookPage && !network.facebookPage.followers">0</p>
                                    <p *ngIf="network.youtubePage && network.youtubePage.url">0</p>
                                    <p *ngIf="network.instagramUrl">0</p>
                                    <p *ngIf="network.tiktokjson">0</p>
                                    <p *ngIf="network.blogToken && network.blogToken.url">0</p>
                                    <p *ngIf="network.youtubePage && network.youtubePage.followers">{{network.youtubePage.followers | number}}</p>
                                    <p *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.followers">{{network.facebookPage.instagramObject.followers | number}}</p>
                                </td>
                                <td class=" text-center">
                                <apx-chart flex-center *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.audienceCountry; else elseBlock"
                                  [series]="network.facebookPage.instagramObject.audienceCountry.series"
                                  [chart]="chartOptions.chart"
                                  [labels]="network.facebookPage.instagramObject.audienceCountry.labels"
                                  [legend]="chartOptions.legend"
                                  [dataLabels]="chartOptions.dataLabels"
                                  [colors]="chartOptions.colors"
                                  [stroke]="chartOptions.stroke"
                              ></apx-chart>
                              <ng-template #elseBlock>
                                  <apx-chart flex-center
                                      [series]="[100]"
                                      [chart]="chartOptions.chart"
                                      [labels]="['N/A']"
                                      [legend]="chartOptions.legend"
                                      [dataLabels]="chartOptions.dataLabels"
                                      [colors]="chartOptions.colorsNA"
                                      [stroke]="chartOptions.stroke"
                                      [states]="chartOptions.states"
                                  ></apx-chart>
                                  </ng-template>
                                </td>

                                <td class=" text-center">
                                  <apx-chart flex-center *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.audienceAge; else elseBlock"
                                  [series]="network.facebookPage.instagramObject.audienceAge.series"
                                  [chart]="chartOptions.chart"
                                  [labels]="network.facebookPage.instagramObject.audienceAge.labels"
                                  [legend]="chartOptions.legend"
                                  [dataLabels]="chartOptions.dataLabels"
                                  [colors]="chartOptions.colors"
                                  [stroke]="chartOptions.stroke"
                              ></apx-chart>
                                <ng-template #elseBlock>
                                  <apx-chart flex-center
                                      [series]="[100]"
                                      [chart]="chartOptions.chart"
                                      [labels]="['N/A']"
                                      [legend]="chartOptions.legend"
                                      [dataLabels]="chartOptions.dataLabels"
                                      [colors]="chartOptions.colorsNA"
                                      [stroke]="chartOptions.stroke"
                                      [states]="chartOptions.states"
                                  ></apx-chart>
                                  </ng-template>
                                </td>
                                <td class=" text-center">
                                  <apx-chart flex-center *ngIf="network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.audienceGender; else elseBlock"
                                      [series]="network.facebookPage.instagramObject.audienceGender.series"
                                      [chart]="chartOptions.chart"
                                      [labels]="network.facebookPage.instagramObject.audienceGender.labels"
                                      [legend]="chartOptions.legend"
                                      [dataLabels]="chartOptions.dataLabels"
                                      [colors]="chartOptions.colors"
                                      [stroke]="chartOptions.stroke"
                                  ></apx-chart>
                                <ng-template #elseBlock>
                                  <apx-chart flex-center
                                      [series]="[100]"
                                      [chart]="chartOptions.chart"
                                      [labels]="['N/A']"
                                      [legend]="chartOptions.legend"
                                      [dataLabels]="chartOptions.dataLabels"
                                      [colors]="chartOptions.colorsNA"
                                      [stroke]="chartOptions.stroke"
                                      [states]="chartOptions.states"
                                  ></apx-chart>
                                  </ng-template>
                                </td>
                                <td class="text-center">
                                    <label class="labelCustom text-center" [ngStyle]="{'background-color':fctBasic.getColorByNetworkStatus(network.accountStatus)}" > {{fctBasic.getNameByNetworkStatus(network.accountStatus)}}</label>
                                    <div style="font-size:14px;" *ngIf="network.accountStatus === 'Awaiting for Approval'" class=" ml-2  fa fa-check-square-o" (click)="validateNetWorkPage(network)"></div>
                                    <div style="font-size:14px;" (click)="openDialog(network)" data-toggle="modal" class="ml-2 fa fa-trash "></div>
                                </td>
                            </tr>

                        </tbody>
                      </table>
                      <td *ngIf="networkInsightAccount.userObject && networkInsightAccount.userObject.length > 0" class="col-1 col-md-1 col-sm-1 text-center">
                        <button  (click)="openCampagneDashboardInNewWindow(dataSourceInfluencer._id)" type="button" class="btn btn-secondaryWithBorder mb-2 shadow-none mr-1"><b>LEARN MORE</b></button>
                      </td>
                  </div>
              </mat-card-content>
          </mat-card>

          <mat-card *ngIf="influenceurOfferCheck" class="mt-4">
            <mat-card-content>
              <div class="tableResponsiveAnnex table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  ">
                  <div  class="panel-header-campaign ">
                      <div class="col-lg-12">
                        <div [ngClass]="isDisabled ? 'disabledLabel' : ''">Influenceur Offer Overview </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                    <mat-divider ></mat-divider>
                </div>
                  <table  class="table table-hover  tableTitle  text-center">
                    <thead>
                        <tr class="tableTitle ">
                        <th class="col-2 text-center"scope="col">Status</th>
                          <th class="col-2 text-center" scope="col">Date</th>
                          <th class="col-2 text-center"scope="col">Offer Name</th>
                          <th class="col-2 text-center" scope="col">Network</th>
                          <th class="col-2 text-center" scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody class="bodyTable">
                        <tr *ngFor = "let offer of influenceurOfferData" >
                          <td class="center-text">
                            <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(offer.status)}" > {{fctBasic.getNameByStatus(offer.status)}}</label>
                          </td>
                          <td>
                            {{offer.startDate | date:'yyyy MM dd'}} - {{offer.endDate | date:'yyyy MM dd'}}
                          </td>
                          <td>{{offer.campaignName}}</td>
                          <td>
                            <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(offer.platformeType)}" style="color:white">
                              <i  *ngIf="offer.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                              <i *ngIf="offer.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                              <i *ngIf="offer.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                              <i *ngIf="offer.platformeType == 'Blog'" class="fa fa-bold "></i>
                              <i *ngIf="offer.platformeType == 'multiNetwork'" class="fa fa-globe"></i>
                            </button>
                          </td>
                          <td class=" text-center">
                            <button (click)="openOfferDetailInNewWindow(offer._id)" type="button" style="font-size: smaller;" class="btn btn-secondary shadow-none"><span class="fa fa-search"></span></button>
                          </td>
                        </tr>
                      </tbody>
                  </table>
              </div>
          </mat-card-content>
      </mat-card>
        </div>
    </body>
</html>



