import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import {Router, ActivatedRoute } from '@angular/router';
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { ToastrService } from 'ngx-toastr';
import { DeletepageComponent } from 'src/app/dialog/deletepage/deletepage.component';
import { MatDialog} from '@angular/material/dialog';
import {data} from 'src/app/model/apx-chartPieNetwork.model'
import {toast} from 'src/app/model/toast'
import {datamodelInsight} from 'src/app/model/apx-chart-insightAccount.model'
import {userModel} from 'src/app/model/user-model.model'
import { ManageSocialNetworkAdminComponent } from 'src/app/dialog/manage-social-network-admin/manage-social-network-admin.component';
import {AddFollowersComponent} from 'src/app/dialog/add-followers/add-followers.component'

@Component({
  selector: 'app-detail-influenceur-admin',
  templateUrl: './detail-influenceur-admin.component.html',
  styleUrls: ['./detail-influenceur-admin.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css', '../../../assets/styles/dashboard.css']
})
export class DetailInfluenceurAdminComponent implements OnInit {
  title = 'rating';
  currentUser : userModel
  chartest = false
  indexDetail = 0
  indexDetailYt = 0
  indexDetailFb = 0
  youtubeCheck = true
  facebookCheck = false
  IgCheck = false
  networkInsightAccount : any = {}
  networkInsightAccountBool = false
  charbart : any = {
  }
  influenceurOfferCheck = false
  influenceurOfferData : any
  ratingCount = 5;
  engagementRate : any = null
  valueQuanti = 0;  
  valueQuali = 0;  
  urlInsta = ""
  toast = toast
  isDisabled = true
  chartOptions : any = data;
  //////////////////////////////
  dataSourceInfluencer : any
  init = false
  constructor(
    private apiService : ApiService, 
    private route: ActivatedRoute,
    private cdRef:ChangeDetectorRef,
    public fctBasic:CampagneAndOfferFctService,
    public router: Router,
    public toastr: ToastrService,
    public dialog: MatDialog
    ) { 
      this.charbart = datamodelInsight
    }

  // changeArrow(x : any){
  //   x.listelem = !x.listelem
  // }

  ngOnInit(): void {
    this.apiService.getInfluencer(this.route.snapshot.params.id).subscribe(influencer => {
      if (influencer != 400 && influencer != null){
        this.dataSourceInfluencer = influencer;
        this.init = true
        this.cdRef.detectChanges();
        this.apiService.getOffreInfluencer(influencer).subscribe(offerInf =>{
          if (offerInf && offerInf.length > 0){
            this.influenceurOfferCheck = true
            this.influenceurOfferData = offerInf
          }
          this.apiService.getInfluencerNetworkSplitWithmetrics(this.route.snapshot.params.id).subscribe(influencer =>{
            this.networkInsightAccount = influencer
            this.networkInsightAccountBool = true
            this.cdRef.detectChanges();
          })
          this.chartest = true
          this.cdRef.detectChanges();
        })
      } else {
        this.router.navigate(['/listInfluencer'])
      }
    })
  }

  
  addEngagementRate(){
    if (this.engagementRate == 0 || this.engagementRate == null){
      return 
    }
    this.dataSourceInfluencer.engagementRate = this.engagementRate
    this.apiService.putUser(this.dataSourceInfluencer._id, this.dataSourceInfluencer).subscribe(res => {
      this.toastr.success(this.toast.engagementRate)
      this.engagementRate = null
      this.cdRef.detectChanges();
    })
  }


  validateNetWorkPage(netwok : any){
    if (netwok.accountStatus === "Awaiting for Approval"){
      netwok.accountStatus = 'Active'
      this.apiService.putUser(this.dataSourceInfluencer._id, this.dataSourceInfluencer).subscribe(res => {
        if (this.dataSourceInfluencer.valid == false){
          this.dataSourceInfluencer.valid = true;
          this.apiService.putUser(this.dataSourceInfluencer._id, this.dataSourceInfluencer).subscribe(res => {
            if (this.dataSourceInfluencer.influencerInfo.secondarymail != "" && this.dataSourceInfluencer.influencerInfo.secondarymail != null){
              this.apiService.inscriptionValidateEmail( this.dataSourceInfluencer.influencerInfo.secondarymail).subscribe( res => {
                this.toastr.success(this.dataSourceInfluencer.influencerInfo.secondarymail + toast.validUser);
              })
            } else {
              this.apiService.inscriptionValidateEmail( this.dataSourceInfluencer.email).subscribe( res => {
                this.toastr.success(this.dataSourceInfluencer.email + toast.validUser);
              })
            }
          })
        }
      })
    }
  }

  openDialog(network: any) {
    const dialogRef = this.dialog.open(DeletepageComponent, {data: {
      user: this.dataSourceInfluencer,
      network: network
    }});
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.dataSourceInfluencer.userObject = result
        this.cdRef.detectChanges();
      }
    });
  }

  onValueChangeQuanti($event: number) {
    this.valueQuanti = $event
  }
  onValueChangeQuali($event: number) {
    this.valueQuali = $event
  }
  
  noteInfluencers(){
    if (this.valueQuali && this.valueQuanti){
      if (!this.dataSourceInfluencer.rating){
        this.dataSourceInfluencer.rating = ((this.valueQuali + this.valueQuanti) / 2)
        this.apiService.putUser(this.dataSourceInfluencer._id, this.dataSourceInfluencer).subscribe(res => {
          this.toastr.success(this.toast.userRating);
          this.valueQuanti = 0;
          this.valueQuali = 0;
        })
      } else {
        let newrating = ((this.valueQuali + this.valueQuanti) / 2)
        let newMoyenne = ((this.dataSourceInfluencer.rating + newrating) / 2)
        if (newMoyenne >= 5){
          this.dataSourceInfluencer.rating = 5
          this.apiService.putUser(this.dataSourceInfluencer._id, this.dataSourceInfluencer).subscribe(res => {
            this.toastr.success(this.toast.userRating);
            this.valueQuanti = 0;
            this.valueQuali = 0;
          })
        } else {
          this.dataSourceInfluencer.rating = newMoyenne
          this.apiService.putUser(this.dataSourceInfluencer._id, this.dataSourceInfluencer).subscribe(res => {
            this.toastr.success(this.toast.userRating);
            this.valueQuanti = 0;
            this.valueQuali = 0;
          })
        }
      }      
    }
  }

  openOfferDetailInNewWindow(id : any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/detailOfferAdmin/${id}`])
    );
    window.open(url, '_blank');
  }

  setIndexOnAccount(x : any){
    if (x.indexDetail == 2){
      x.indexDetail = 0
      return
    }
    x.indexDetail += 1
  }
  prevIndexOnAccount(x : any){
    if (x.indexDetail == 0){
      x.indexDetail = 2
      return 
    }
    x.indexDetail -= 1
  }

  setIndexOnAccountYt(){
    if (this.indexDetailYt == 3){
      this.indexDetailYt = 0
      return
    }
    this.indexDetailYt += 1
  }
  prevIndexOnAccountYt(){
    if (this.indexDetailYt == 0){
      this.indexDetailYt = 3
      return 
    }
    this.indexDetailYt -= 1
  }

  setIndexOnAccountFb(){
    if (this.indexDetailFb == 2){
      this.indexDetailFb = 0
      return
    }
    this.indexDetailFb += 1
  }
  prevIndexOnAccountFb(){
    if (this.indexDetailFb == 0){
      this.indexDetailFb = 2
      return 
    }
    this.indexDetailFb -= 1
  }

  openDialogError(compenentName: any, option : any) {
    const dialogRef = this.dialog.open(compenentName, option);
    dialogRef.afterClosed().subscribe(result => {
      this.apiService.getUser().subscribe(user => {
        this.currentUser = user
        this.cdRef.detectChanges();
      })
    });
}

  moreSocialNetwork(){
    
    this.openDialogError(ManageSocialNetworkAdminComponent, {
      panelClass: 'dialog-container-custom',
      data: this.dataSourceInfluencer
    })
    
  }


  addFollowersOnPage(){
    this.openDialogError(AddFollowersComponent, {
      panelClass: 'dialog-container-custom',
      data: this.dataSourceInfluencer
    })
    
  }

  openCampagneDashboardInNewWindow(id : any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/insight-profil/${id}`])
    );
    window.open(url, '_blank');
  }

  

}
