export var datamodelInsight = {
    
  chart: {
    height: 250,
    type: 'bar',
    distributed: false,
    offsetY: 16,
  stacked: false,
  toolbar: {
    show: false,
  }
  },
      grid: {
        padding: {
          top: -45,
          right: 0
        },
        show: false,      // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false  //or just here to disable only x axis grids
           }
         },  
        yaxis: {
          lines: { 
            show: false  //or just here to disable only y axis
           }
         },   
      },
      plotOptions: {
        bar: {
          barHeight: '40%',
          horizontal: true,
          dataLabels: {
            position: 'top',
            maxItems: 100,
            hideOverflowingLabels: true,
            orientation: "horizontal"
        }
        }
      },
      dataLabels: {
        enabled: false,
      },
      dataLabelsPie: {
        enabled: false,
        
      },
      xaxis: {
        categories: ['Aix en provence', 'Paris', 'Marseille', 'Yenne'
        ],
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      xaxisGeneral: {
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      

////////////////////////////////////////////////////////////////////////////////////////////////////
    piechart: {
        type: 'donut',
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10
      }, 
      },
    colorpie: ["#008ffb", "#00223f", "#ABDBFF"],
    pieplotOptions: {
      
        pie: {
            customScale: 0.8,
            size: 200,
            startAngle: 0,
            endAngle: 360,
            expandOnClick: false,
            donut: {
                size: '65%',
                
                background: 'transparent',
                labels: {
                    show: true,
                    value: {
                      formatter: function (val: any) {
                        return val + "%"
                      }
                    },
                    name: {
                      fontSize: '22px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      offsetY: -10,
                },
                total : {
                    show:false
                }
            },
            
      }
    }
    },
    tooltip: {
      y: {
        formatter: function(value: string) {
          return value + "%";
        }
      },
      
    },
    legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center', 
        floating: true,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        inverseOrder: false,
        offsetX: 0,
        offsetY: 2,
    }


// showForSingleSeries influenceur selector pie

  };