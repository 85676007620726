import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import {Router, ActivatedRoute } from '@angular/router';
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { ToastrService } from 'ngx-toastr';
import {data} from 'src/app/model/apx-chartPieNetwork.model'
import {toast} from 'src/app/model/toast'
import {datamodelInsight} from 'src/app/model/apx-chart-insightAccount.model'
import {userModel} from 'src/app/model/user-model.model'

@Component({
  selector: 'app-insights-profile',
  templateUrl: './insights-profile.component.html',
  styleUrls: ['./insights-profile.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css', '../../../assets/styles/dashboard.css']
})
export class InsightsProfileComponent implements OnInit {
  networkInsightAccountBool = false
  charbart : any = {
  }

  chartest = false
  isDisabled = true
  networkInsightAccount : any = {}
  constructor(
    private apiService : ApiService, 
    private route: ActivatedRoute,
    private cdRef:ChangeDetectorRef,
    public fctBasic:CampagneAndOfferFctService,
    public router: Router,
    public toastr: ToastrService,
  ) {
    this.charbart = datamodelInsight
   }

  ngOnInit(): void {
    this.apiService.getInfluencer(this.route.snapshot.params.id).subscribe(influencer => {
      if (influencer != 400 && influencer != null){
        this.apiService.getInfluencerNetworkSplitWithmetrics(this.route.snapshot.params.id).subscribe(influencer =>{
          this.networkInsightAccount = influencer
           this.networkInsightAccountBool = true
           this.chartest = true
          this.cdRef.detectChanges();
        })
      } else {
        this.router.navigate(['/listInfluencer'])
      }
    })
  }

  setIndexOnAccount(x : any){
    if (x.indexDetail == 2){
      x.indexDetail = 0
      return
    }
    x.indexDetail += 1
  }
  prevIndexOnAccount(x : any){
    if (x.indexDetail == 0){
      x.indexDetail = 2
      return 
    }
    x.indexDetail -= 1
  }


}
