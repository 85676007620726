
    <h4 class="dialog-headline" mat-dialog-title>DASHBOARD
        CREATION <i class="fa fa-instagram"></i></h4>

<mat-dialog-content style="width: auto;height: auto;" class=" mt-2">
        <p class="mt-1">Create dashboard for Post</p>
        <!-- <select class="form-control mb-1">
            <option value=""></option>
            <option *ngFor="let x of postsList ; index as i" [value]="x.caption" (click)="additemPosts(x, i)" >{{x.caption}}</option>
        </select>
        <div class="right align-items-end">
            <div *ngIf="contenueArrayPost.length > 0" class=" mt-2 ">
                <span (click)="deleteItemPosts(z, i)" *ngFor="let z of contenueArrayPost; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                    #{{ z.caption | slice:0:10 }} x 
                </span>
            </div>
        </div> -->

        <select class="form-control mb-1" [(ngModel)]="selectedDevice" (ngModelChange)="onChange('Post')">
            <option value=""></option>
            <option *ngFor="let x of postsList; index as i" [ngValue]="x" >{{x.caption}}</option>
        </select>
        <div class="right align-items-end">
            <div *ngIf="contenueArrayPost.length > 0" class=" mt-2 ">
                <span (click)="deleteItemPosts(z, i)" *ngFor="let z of contenueArrayPost; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                    #{{ z.caption | slice:0:10 }} x 
                </span>
            </div>
        </div>
        <p class="mt-3">Create dashboard for Story</p>
        <select class="form-control mb-1" [(ngModel)]="selectedDevice" (ngModelChange)="onChange('Story')">
            <option value=""></option>
            <option *ngFor="let x of storyList ; index as i" [ngValue]="x"  >{{x.caption}}</option>
        </select>
        <div class="right align-items-end">
            <div *ngIf="contenueArrayStory.length > 0" class=" mt-2 ">
                <span (click)="deleteItemStory(z, i)" *ngFor="let z of contenueArrayStory; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                    #{{ z.caption | slice:0:10 }} x 
                </span>
            </div>
        </div>
</mat-dialog-content>
<mat-dialog-actions>
        <div class="col-12 text-center">
            <button type="button" mat-dialog-close (click)="createDasboardOnDb()"  class="btn btn-lg btn-secondary btn-template justify-content">GO !</button>
        </div>
</mat-dialog-actions>
