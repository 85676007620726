import { ApiService } from 'src/app/service/api.service';
import {OfferModel} from 'src/app/model/offer-model.model'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {toast} from 'src/app/model/toast'

@Component({
  selector: 'app-offer-detail-influenceur',
  templateUrl: './offer-detail-influenceur.component.html',
  styleUrls: ['./offer-detail-influenceur.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css']
})
export class OfferDetailInfluenceurComponent implements OnInit {
  dataSourceOffer: any;
  loadOffer = false
  dashboardOffer = false
  acceptedOffer = false
  isDisabled = true
  toast = toast
  newMessage : string = ""
  dataContenuJson : any = {}
  constructor(
    private apiService : ApiService,
    public fctBasic: CampagneAndOfferFctService,
    private cdRef:ChangeDetectorRef,
    public router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.apiService.getOffre(this.route.snapshot.params.id).subscribe(offer => { 
      this.dataSourceOffer = offer;
      
      if (this.dataSourceOffer.status == "Live" || this.dataSourceOffer.status == "Accepted"){
        this.dashboardOffer = true
      }
      if (this.dataSourceOffer.status == "New Offer"){
        this.acceptedOffer = true
      }
      this.loadOffer = true
      this.cdRef.detectChanges();

      // console.log("this page ", this.pageSize)
    })
  }

  acceptOffer(){
    this.dataSourceOffer.status = "Accepted"
    this.apiService.putOffre(this.route.snapshot.params.id, this.dataSourceOffer).subscribe(offer => {
      this.toastr.success(this.toast.offerAccept);
      this.acceptedOffer = false
      this.cdRef.detectChanges();
    })
  }

  showDashboard(){
    if (this.dataSourceOffer.posts.length > 0){
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/dashboard/${this.dataSourceOffer._id}/instagram`])
      );
      window.open(url, '_blank');
    } else {
      this.toastr.warning("No contents to display at the moment", "Warning")
    }
    
  }

}
