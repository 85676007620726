<html>
    <body>
        <div class="page container ">
            <a [routerLink]="['/listOffers']" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
            <mat-card *ngIf="loadOffer" class="mt-1" >
                <div class="title-card" text-center>
                    <div>
                        <mat-label class="title"><i>Offer</i></mat-label>
                    </div>
                    <div>
                        <mat-label class="title">🤗 {{dataSourceOffer.campaignName}} 🚀</mat-label>
                    </div>

                </div>
                <mat-divider ></mat-divider>
                <mat-card-content class="mt-4">

                    <div class="row input-row  ">
                        <div  class="col-md-6 col-12 col-lg-6 alignVertical   col-sm-12 ">
                                <div class="col-lg-4 col-6  col-md-4  ">
                                    <mat-label style="border-top: .24375em solid transparent;border-bottom: .64375em solid transparent;" [ngClass]="isDisabled ? 'disabledLabel' : ''">Status</mat-label>
                                </div>
                                <div class="col-lg-8 col-6 col-md-8">
                                    <mat-label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(dataSourceOffer.status)}" > {{fctBasic.getNameByStatus(dataSourceOffer.status)}}</mat-label>
                                </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 display-mid  col-sm-12">
                                <div class="col-lg-4  col-6 col-md-4 ">
                                    <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Change Status</mat-label>
                                </div>
                                <div class="col-lg-8 col-6 col-md-8 ">
                                    <mat-form-field class="campagneselect2byline">
                                        <mat-select (selectionChange)="changeStatusOffer($event)">
                                            <mat-option [value]="'New Offer'">
                                                New Offer
                                            </mat-option>
                                            <mat-option [value]="'Post Approved'">
                                                Content Approved
                                            </mat-option>
                                            <mat-option [value]="'Live'">
                                                Live
                                            </mat-option>
                                            <mat-option [value]="'Paid'">
                                                Paid
                                            </mat-option>
                                            <mat-option [value]="'Cancelled'">
                                                Cancelled
                                            </mat-option>
                                            <mat-option [value]="'Past'">
                                                Past
                                            </mat-option>
                                            <mat-option [value]="'Paid'">
                                                Paid
                                            </mat-option>
                                            <mat-option [value]="'Payment Pending'">
                                                Payment Pending
                                            </mat-option>

                                            <mat-option [value]="'Cancelled'">
                                                Cancelled
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                </div>
                        </div>
                    </div>
                    <div class="row input-row  ">
                        <div class="col-md-6 col-12 col-lg-6 display-mid    ">
                                <div class="col-lg-4 col-6  col-md-4 col-6 ">
                                    <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Start Date</mat-label>
                                </div>
                                <div class="col-lg-8 col-6 col-md-8 col-6">
                                <mat-form-field class="campagneselect2byline">
                                        <input matInput disabled  class="disabledtext"  [ngModel]="dataSourceOffer.startDate | date:'yyyy/MM/dd'" (ngModelChange)="dataSourceOffer.startDate = $event">
                                    </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 display-mid  col-sm-12">
                                <div class="col-lg-4  col-6 col-md-4 ">
                                    <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">End Date</mat-label>
                                </div>
                                <div class="col-lg-8 col-6 col-md-8 ">
                                    <mat-form-field class="campagneselect2byline">
                                        <input matInput disabled class="disabledtext" [ngModel]="dataSourceOffer.endDate | date:'yyyy/MM/dd'" (ngModelChange)="dataSourceOffer.endDate = $event">
                                    </mat-form-field>
                                </div>
                        </div>
                    </div>

                    <div class="row input-row  ">
                        <div class="col-md-12 col-lg-12 textareapostion">
                            <div class="col-lg-2  ">
                                <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Offer Description</mat-label>
                            </div>
                            <div class="col-lg-10 ">
                                <mat-form-field  appearance="outline">
                                    <textarea rows="15" class="disabledtext" disabled [(ngModel)]="dataSourceOffer.projectDescription" matInput ></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row input-row  ">
                        <div class="col-md-12 col-lg-12 display-mid">
                            <div class="col-lg-2  ">
                                <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Mission</mat-label>
                                <i [ngClass]="isDisabled ? 'disabledLabel' : ''" class="fa fa-info ml-1" #tooltip="matTooltip"
                                matTooltip="MEDIA : Promote existing content from the brand to your community..
                                CONTENT : Create great content about a product or service.&#13;
                                 LIVE: : Tailor-made influence marketing campaign.&#13;
                                Contact our Community Managers for more info.&#13;
                                Refer to CGU for further details."
                                aria-label="Button that displays a tooltip when focused or hovered over"
                                matTooltipPosition="right"
                                matTooltipClass="allow-cr"
                                aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                </i>
                            </div>
                            <div class="col-lg-10 ">
                                <mat-form-field class="campagneselect2byline">
                                    <input matInput class="disabledtext" disabled placeholder="" [(ngModel)]="dataSourceOffer.mission">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row input-row  ">
                        <div style="padding-bottom: 1.14375em!important;" class="col-md-12 col-lg-12 display-mid">
                            <div class="col-lg-2 col-6  col-md-4 ">
                                <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Social Network</mat-label>
                            </div>
                            <div class="col-lg-10 col-6 col-md-8">
                                <button (click)="showProfileInf()" type="button" [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(dataSourceOffer.platformeType)}" style="color:white" class="btn btn-sm shadow-none ">
                                    <i  *ngIf="dataSourceOffer.platformeType == 'Facebook'" class="fa mr-2 left fa-facebook "></i>
                                    <i *ngIf="dataSourceOffer.platformeType == 'Instagram'" class="fa mr-2 left fa-instagram "></i>
                                    <i *ngIf="dataSourceOffer.platformeType == 'Youtube'" class="fa mr-2 left fa-youtube "></i>
                                    <i *ngIf="dataSourceOffer.platformeType == 'Blog'" class="fa fa-bold mr-2 left"></i>
                                    <i *ngIf="dataSourceOffer.platformeType == 'multiNetwork'" class="fa fa-globe  mr-2 left"></i>
                                    {{fctBasic.getNetworkNameForInfluenceur(dataSourceOffer.influencerObjects, dataSourceOffer.platformeType)}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="row input-row  ">
                        <div class="col-md-12 col-lg-12 textareapostion">
                            <div class="col-lg-2  ">
                                <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Must include</mat-label>
                                <div class="textsecondary mt-1" style="color:red!important">!! Here are the key elements you must include to your posts !!</div>
                            </div>
                            <div class="col-lg-10 ">
                                <mat-form-field  appearance="outline">
                                    <textarea rows="15" class="disabledtext" disabled [(ngModel)]="dataSourceOffer.mustInclude" matInput ></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row input-row  ">
                        <div class="col-md-6 col-12 col-lg-6 col-sm-12  display-mid ">
                                <div class="col-lg-4 col-6  col-md-4 col-6 ">
                                    <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Revenue Goal</mat-label>
                                    <i [ngClass]="isDisabled ? 'disabledLabel' : ''" class="fa fa-info ml-1" #tooltip="matTooltip"
                                    matTooltip="Deliver up to the delivery target to win the revenue goal. &#13;Deliver less than the target, don’t worry, you will still get paid in proportion to what you actually delivered.&#13;Deliver more, remember you cannot earn more than the revenue goal.&#13;Refer to CGU for more explanations"
                                    aria-label="Button that displays a tooltip when focused or hovered over"
                                    matTooltipPosition="right"
                                    matTooltipClass="allow-cr"
                                    aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                    </i>
                                </div>
                                <div class="col-lg-8 col-6 col-md-8 col-6">
                                    <mat-form-field class="campagneselect2byline">
                                        <input matInput  mask="separator.2" suffix=" €" class="disabledtext" disabled  [(ngModel)]="dataSourceOffer.revenueTarget" >
                                    </mat-form-field>
                                </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 display-mid  col-sm-12">
                                <div class="col-lg-4  col-6 col-md-4 ">
                                    <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Delivery Target</mat-label>
                                    <i [ngClass]="isDisabled ? 'disabledLabel' : ''" class="fa fa-info ml-1" #tooltip="matTooltip"
                                    matTooltip="Deliver up to the delivery target to win the revenue goal. &#13;Deliver less than the target, don’t worry, you will still get paid in proportion to what you actually delivered.&#13;Deliver more, remember you cannot earn more than the revenue goal.&#13;Refer to CGU for more explanations"
                                    aria-label="Button that displays a tooltip when focused or hovered over"
                                    matTooltipPosition="right"
                                    matTooltipClass="allow-cr"
                                    aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                    </i>
                                </div>
                                <div class="col-lg-8 col-6 col-md-8 ">
                                    <mat-form-field class="campagneselect2byline">
                                        <input matInput mask="separator.2" class="disabledtext" placeholder="" disabled [(ngModel)]="dataSourceOffer.deliveryTarget">
                                    </mat-form-field>
                                </div>
                        </div>
                    </div>
                    <div class="row input-row  ">
                        <div class="col-md-12 col-lg-12 display-mid">
                            <div class="col-lg-2  ">
                                <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Sales Models</mat-label>
                                <i [ngClass]="isDisabled ? 'disabledLabel' : ''" class="fa fa-info ml-1" #tooltip="matTooltip"
                                matTooltip="Definitions of our sales models :&#13;Quotation : Remuneration for the mission described in the offer (can be remuneration in kind with the product or service to be tested)&#13;CPP : Cost Per Post&#13;CPM: Cost Per thousand impressions (technically, “cost per mille”).&#13;CPV: Cost Per View - minimum 15 seconds&#13;CPE: Cost Per engament (Like / comment /share)&#13;CPC: Cost Per Click"
                                aria-label="Button that displays a tooltip when focused or hovered over"
                                matTooltipPosition="right"
                                matTooltipClass="allow-cr"
                                aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                </i>
                            </div>
                            <div class="col-lg-10 ">
                                <mat-form-field class="campagneselect2byline">
                                    <input matInput class="disabledtext" placeholder="" disabled [(ngModel)]="dataSourceOffer.saleModels">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row input-row  ">
                        <div class="col-md-12 col-12 col-lg-12 col-sm-12  display-mid">
                            <div class="col-lg-2  ">
                                <mat-label [ngClass]="isDisabled ? 'disabledLabel' : ''">Message Box</mat-label>
                            </div>
                            <div class="col-lg-10 ">
                                <mat-accordion >
                                    <mat-expansion-panel class="mt-3 mb-2" hideToggle >
                                      <mat-expansion-panel-header >
                                        <mat-panel-title [ngClass]="isDisabled ? 'disabledLabel' : ''">
                                            Message sent ({{dataSourceOffer.boxMessage.length}})
                                        </mat-panel-title>

                                      </mat-expansion-panel-header>
                                      <div class="accordion-item" *ngFor="let x of dataSourceOffer.boxMessage;let index = index;">
                                        <div class="col-lg-12   col-md-12 ">
                                            <mat-label >Message {{index + 1}} </mat-label>
                                            <time class="text-muted float-right">{{ x.creationDate | date:'yyyy/MM/dd'}}</time>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <mat-form-field  appearance="outline">
                                                <textarea  class="disabledtext"   [disabled]="x.isDisabled ? false : true" rows="3" [(ngModel)]="x.message" [ngModelOptions]="{standalone: true}"  matInput ></textarea>
                                            </mat-form-field>
                                            <div class="mb-2">
                                                <button *ngIf="!x.isDisabled" (click)="canPutMessage(x)"class="btn  btn-secondary shadow-none" >Modify</button>
                                                <button *ngIf="x.isDisabled" (click)="putMessageBox(x, index)"class="btn  btn-secondary shadow-none" >Save</button>
                                            </div>
                                        </div>

                                      </div>
                                      <div class="accordion-item" >
                                        <div class="col-lg-4   col-md-4 ">
                                            <mat-label >New Message</mat-label>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <mat-form-field  appearance="outline">
                                                <textarea rows="3" [(ngModel)]="newMessage" [ngModelOptions]="{standalone: true}"  matInput ></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <button (click)="addMessageBox(newMessage)" type="button"  class="btn btn-secondary mb-2" >Send new message !</button>
                                        </div>
                                      </div>

                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 mb-3">
                        <div flex-center>
                            <button *ngIf=" dataSourceOffer.putManually == true" [routerLink]="['/formOfferAdmin/', dataSourceOffer._id, dataSourceOffer.platformeType]" class="btn  btn-secondaryWithBorder mr-2 shadow-none">Enter Statistics</button>
                            <button   *ngIf="createdashboardOffer == true "  (click)="clickDialogSelectContent()"  class="btn  btn-secondaryWithBorder mr-2 shadow-none" > <b>Create Dashboard</b> </button>
                            <button  *ngIf="dashboardOffer == true"  (click)="showDashboard()"  class="btn  btn-secondaryWithBorder shadow-none mr-2" > <b>Show Dashboard</b> </button>
                            <button  *ngIf="billing == true"  (click)="openOfferBilling()"  class="btn  btn-secondaryWithBorder shadow-none " > <b>Show Invoice</b> </button>

                        </div>

                        <div style="display:flex;flex-direction:row-reverse;">
                            <i><i class="fa fa-trash mr-1"></i>Cancel Offer</i>
                         </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </body>
</html>




