<h4  class="dialog-headline-facebook" mat-dialog-title>🤗 One moment please 🤗</h4>
<mat-dialog-content style="width: auto;height: auto;" class=" mt-3">
    <p  class="mt-1 text-center">Are you sure you want to leave us now ?</p>
</mat-dialog-content>
<mat-dialog-actions >
    <div class="col-12 text-center">
        <button type="button" mat-dialog-close (click)="deleteUser()"  style="background-color: rgb(167, 167, 167);border-color: rgb(167, 167, 167);" class="btn  btn-secondary btn-template ">Yes, I want to leave now</button>
        <button type="button" mat-dialog-close class="btn  btn-secondary button-top btn-template ml-3" >No, I want to continue</button>
    </div>
        
</mat-dialog-actions>