import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import {Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { ToastrService } from 'ngx-toastr';
import {MediaService } from 'src/app/service/media.service';
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { ChoiceInvoiceComponent } from 'src/app/dialog/choice-invoice/choice-invoice.component';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { DelContentInfluenceurComponent } from 'src/app/dialog/del-content-influenceur/del-content-influenceur.component';

@Component({
  selector: 'app-influenceur-reels-form',
  templateUrl: './influenceur-reels-form.component.html',
  styleUrls: ['./influenceur-reels-form.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css','../../../assets/styles/dashboard.css', '../../../assets/styles/campagne.css' ],
  providers: [MediaService]
})


export class InfluenceurReelsFormComponent implements OnInit {
  initStories = false
  plateFormType = ""

  ContenueArray : any = []
  modifContent = false
  createContent = true
  multiple = true
  showButton = false
  offer :any
  imgName = ""
  billingShow = false
  isDisabled = true
  finishForm = false
  newElement: FormGroup
  modifNewElement = false
  contentType = ""
  elementType : string = ""
  constructor(
    private fb: FormBuilder,
    public picService: MediaService,
    private route: ActivatedRoute,
    private apiService : ApiService,
    private toastr: ToastrService,
    private router: Router,
    private cdRef:ChangeDetectorRef,
    public fctBasic: CampagneAndOfferFctService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {  
    this.apiService.getOffre(this.route.snapshot.params.id).subscribe(offer => {
      this.offer = offer
      this.plateFormType = this.route.snapshot.params.plateformeType
      this.ContenueArray = this.getContentForModified(this.offer) 
      this.newElement = this.fb.group({
        impressions : new FormControl({value:"", disabled: true}, [Validators.required]),
        reach: new FormControl({value:"", disabled: true}, [Validators.required]),
        engagement: new FormControl({value:"", disabled: true}, [Validators.required]),
        type: new FormControl("", ),
        date: new FormControl(new Date(), ),
        pictures: new FormControl("")
      })
      this.modifNewElement = true
      this.cdRef.detectChanges();
    })
  }

  findPostToUp(elem : any){
    var jsonFind = -1
    this.offer.posts.forEach((element: any, index: any) => {
      if (element.instagramInfoPost && element.instagramInfoPost.setManually == true){
        if (elem.currentDate == element.currentDate){
          jsonFind = index
        }
      }
    });
    return jsonFind
  }

  addnewElement(){
    switch (this.contentType) {
      case "Post":
        this.newElement = this.fb.group({
          impressions : new FormControl("", Validators.required),
          reach: new FormControl("", Validators.required),
          engagement: new FormControl("", Validators.required),
          date: new FormControl(new Date(), ),
          type: new FormControl("Post", ),
          pictures: new FormControl("")
        })
        this.modifNewElement = true
        this.createContent = false
        this.contentType = "Post"
        this.newElement.enable()
        this.toastr.success("Now you can send your statistics for " + this.contentType + " 😎")
        this.cdRef.detectChanges();
        break;

      case "Reel":
        this.newElement = this.fb.group({
          impressions : new FormControl("", [Validators.required]),
          reach: new FormControl("", [Validators.required]),
          engagement: new FormControl("", [Validators.required]),
          type: new FormControl("Reel", ),
          date: new FormControl(new Date(), ),
          pictures: new FormControl("")
        })
        this.modifNewElement = true
        this.createContent = false
        this.contentType = "Reel"
        this.toastr.success("Now you can send your statistics for " + this.contentType + " 😎")
        this.newElement.enable()
        this.cdRef.detectChanges();
        break;
      case "Story":
        this.newElement = this.fb.group({
          impressions : new FormControl("", [Validators.required]),
          reach: new FormControl("", [Validators.required]),
          date: new FormControl(new Date(), ),
          type: new FormControl("Story", ),
          pictures: new FormControl(""),
        })
        this.contentType = "Story"
        this.createContent = false
        this.modifNewElement = true
        this.toastr.success("Now you can add your statistics for " + this.contentType + " 😎")
        this.newElement.enable()
        this.cdRef.detectChanges();
        break;
      case "Post":
          this.newElement = this.fb.group({
            impressions : new FormControl("", Validators.required),
            reach: new FormControl("", Validators.required),
            engagement: new FormControl("", Validators.required),
            date: new FormControl(new Date(), ),
            type: new FormControl("Post", ),
            pictures: new FormControl("")
          })
          this.modifNewElement = true
          this.createContent = false
          this.contentType = "Post"
          this.newElement.enable()
          this.toastr.success("Now you can send your statistics for " + this.contentType + " 😎")
          this.cdRef.detectChanges();
          break;
      default:
        break;
    }
  }

  initNewForm(){
    this.newElement = this.fb.group({
      impressions : new FormControl({value:"", disabled: true}, [Validators.required]),
      reach: new FormControl({value:"", disabled: true}, [Validators.required]),
      engagement: new FormControl({value:"", disabled: true}, [Validators.required]),
      type: new FormControl("", ),
      date: new FormControl(new Date(), ),
      pictures: new FormControl("")
    })
    this.contentType = ""
    this.createContent = true
    this.cdRef.detectChanges();
  }

  getimgTooltip(type: string){
    switch (type){
      case "Impressions":
        return "assets/images/tooltip/post-impressions.png"
      case "Reach":
        return "assets/images/tooltip/post-reach.png"
      case "Engagement":
        return "assets/images/tooltip/post-engagement.png"
    }
    return false
  }

  getcolorBytype(content : any){
    if (content.instagramInfoPost){
      switch (content.instagramInfoPost.type) {
        case "Post":
          return '#7ed957'
        case "Story":
          return '#ff66c4'
        case "Reel":
          return '#5ce1e6'
        default:
          return 'black'
      }
    } else {
      return 'black'
    }
  }

  contentModifyVal(elem : any){
    elem.isDisabled = !elem.isDisabled
    //this.toastr.success("Now you can edit your " + elem.instagramInfoPost.type + " 😎")
  }

  saveModifyContent(elem : any){
    if (!elem.totalStatistics.totalReach || !elem.totalStatistics.totalView){
      this.toastr.warning("You cannot modify your content with a invalid value ")
      return
    }
    if (elem.instagramInfoPost.type != "Story" && !elem.totalStatistics.totalEngagement){
      this.toastr.warning("You cannot modify your content with a invalid value ")
      return
    }
    let index = this.findPostToUp(elem)
    this.offer.posts[index].totalStatistics.totalReach = elem.totalStatistics.totalReach
    this.offer.posts[index].totalStatistics.totalView = elem.totalStatistics.totalView
    this.offer.posts[index].dailyStatistics[0].dailyView = elem.totalStatistics.totalView
    this.offer.posts[index].dailyStatistics[0].dailyReach = elem.totalStatistics.totalReach
    if (this.offer.posts[index].instagramInfoPost.type != "Story"){
      this.offer.posts[index].totalStatistics.totalEngagement = elem.totalStatistics.totalEngagement
      this.offer.posts[index].dailyStatistics[0].dailyEngagement = elem.totalStatistics.totalEngagement
    }
    if (this.offer.posts[index].instagramInfoPost.type === "Reel"){
      this.offer.posts[index].totalStatistics.totalPlays = elem.totalStatistics.totalPlays
      this.offer.posts[index].dailyStatistics[0].dailyPlays = elem.totalStatistics.totalPlays
    }
    this.apiService.putOffre(this.route.snapshot.params.id, this.offer).subscribe(offer => {
      this.toastr.success('Your modifications have been saved.😎');
      elem.isDisabled = !elem.isDisabled
      this.cdRef.detectChanges();
    })
    

  }

  addNewContent(){    
    if (this.newElement.valid == true){
      if (this.newElement.value['pictures'] == ""){
        this.toastr.warning('Please add a screenshot')
        return
      }
      if (this.offer.status === "Accepted" || this.offer.status === "New Offer"){
        this.offer.status = "Live"
        if (this.offer.campaignObject && this.offer.campaignObject.status && this.offer.campaignObject.status === "New Campaign"){
          this.offer.campaignObject.status = "CampaignLive"
          this.apiService.putCampaign(this.offer.campaignObject._id, this.offer.campaignObject).subscribe()
        }
      }
      switch (this.contentType) {
        case "Post":
          switch (this.plateFormType) {
            case "Instagram":
              let newContentPost = this.setNewContentPostsInstagram(this.newElement.value)
              this.offer.posts.push(newContentPost)
              break;
            default:
              let newContentPostOther = this.setNewContentPosts(this.newElement.value)
              this.offer.posts.push(newContentPostOther)
              break;
          }
          this.apiService.putOffre(this.route.snapshot.params.id, this.offer).subscribe(offer => {
            this.toastr.success('Your statistics have been well received ', 'Yeah !');
            this.ContenueArray = this.getContentForModified(this.offer) 
            this.finishForm = true
            this.initNewForm()
            this.cdRef.detectChanges();
          })
          break;
        case "Story":
          let newContentStorie = this.setNewContentStories(this.newElement.value)
          this.offer.posts.push(newContentStorie)
          this.apiService.putOffre(this.route.snapshot.params.id, this.offer).subscribe(offer => {
            this.toastr.success('Your statistics have been well received ', 'Yeah !');
            this.ContenueArray = this.getContentForModified(this.offer) 
            this.finishForm = true
            this.initNewForm()
            this.cdRef.detectChanges();
          })
          break;
        case "Reel":
          let newContentReel = this.setNewContentReels(this.newElement.value)
          this.offer.posts.push(newContentReel)
          this.apiService.putOffre(this.route.snapshot.params.id, this.offer).subscribe(offer => {
            this.toastr.success('Your statistics have been well received ', 'Yeah !');
            this.ContenueArray = this.getContentForModified(this.offer) 
            this.finishForm = true
            this.initNewForm()
            this.cdRef.detectChanges();
          })
          break;
        default:
          break;
      }
    }
   
  }

  checkChange(elem : any){
    var valid = true
    this.offer.posts.forEach((element: any) => {
      if (element.instagramInfoPost && element.instagramInfoPost.setManually == true){   
        if (elem.date == element.currentDate && elem.type == element.instagramInfoPost.type){
          valid = false  
        }
      }
    });
    return valid
  }

////////////////////////////POSTS////////////////////////////////////

setNewContentPostsInstagram(elem : any){
  let newjson : any = {}
  newjson.dailyStatistics = [];
  newjson.instagramInfoPost = {}
  newjson.currentDate = elem.date
  newjson.creationDate = elem.date
  newjson.instagramInfoPost.caption = ""
  newjson.instagramInfoPost.picture = '/api/picture/Images/' + elem.pictures
  newjson.instagramInfoPost.type = elem.type;
  newjson.instagramInfoPost.mediaType = "IMAGE"
  newjson.instagramInfoPost.setManually = true
  newjson.dailyStatistics.push({
    "date": new Date(),
    "dailyView": elem.impressions,
    "dailyReach": elem.reach,
    "dailyEngagement":elem.engagement,
  });
  newjson.totalStatistics = {
    "totalView" : elem.impressions,
    "totalReach" : elem.reach,
    "totalEngagement" : elem.engagement,
  };
  return newjson
}

setNewContentPosts(elem : any){
  let newjson : any = {}
  newjson.dailyStatistics = [];
  newjson.infoPost = {}
  newjson.currentDate = elem.date
  newjson.creationDate = elem.date
  newjson.infoPost.caption = ""
  newjson.infoPost.picture = '/api/picture/Images/' + elem.pictures
  newjson.infoPost.type = elem.type;
  newjson.infoPost.mediaType = "IMAGE"
  newjson.infoPost.setManually = true
  newjson.dailyStatistics.push({
    "date": new Date(),
    "dailyView": elem.impressions,
    "dailyReach": elem.reach,
    "dailyEngagement":elem.engagement,
  });
  newjson.totalStatistics = {
    "totalView" : elem.impressions,
    "totalReach" : elem.reach,
    "totalEngagement" : elem.engagement,
  };
  return newjson
}

keytab(event : any){
  let form = event.target.form;
  let index = [...form].indexOf(event.target);
  if (index != null && index !== -1){
    form.elements[index + 1].focus();
    event.preventDefault();
  }
}


//////////////////////////////STORIES//////////////////////////////////////////
setNewContentStories(elem : any){
  let newjson : any = {}
  newjson.dailyStatistics = [];
  newjson.instagramInfoPost = {}
  newjson.currentDate = elem.date
  newjson.creationDate = elem.date
  newjson.instagramInfoPost.picture = '/api/picture/Images/' + elem.pictures
  newjson.instagramInfoPost.caption = ""
  newjson.instagramInfoPost.type  = elem.type
  newjson.instagramInfoPost.mediaType = "IMAGE"
  newjson.instagramInfoPost.setManually = true

  newjson.dailyStatistics.push({
    "date": new Date(),
    "dailyView": elem.impressions,
    "dailyReach": elem.reach,
  });
  newjson.totalStatistics = {
    "totalView" : elem.impressions,
    "totalReach" : elem.reach,
  };
  return newjson
}


//////////////////////////////REELS//////////////////////////////////////////
setNewContentReels(elem : any){
  let newjson : any = {}
  newjson.dailyStatistics = [];
  newjson.instagramInfoPost = {}
  newjson.currentDate = elem.date
  newjson.creationDate = elem.date
  newjson.instagramInfoPost.caption = ""
  newjson.instagramInfoPost.picture = '/api/picture/Images/' + elem.pictures
  newjson.instagramInfoPost.type  = elem.type
  newjson.instagramInfoPost.mediaType = "REELS"
  newjson.instagramInfoPost.setManually = true

  newjson.dailyStatistics.push({
    "date": new Date(),
    "dailyPlays": elem.impressions,
    "dailyReach": elem.reach,
    "dailyEngagement":elem.engagement,
  });
  newjson.totalStatistics = {
    "totalReach" : elem.reach,
    "totalEngagement" : elem.engagement,
    "totalPlays" : elem.impressions,
  };
  return newjson
}


//////////////////////////////////////////////////////////////////////////////

getContentForModified(offer : any){
  var postExistArray: any[] = []
  if (offer.posts){
   offer.posts.forEach((content: any) => {
     if (content.instagramInfoPost && content.instagramInfoPost.setManually == true){
       postExistArray.push(content)
     }
   });
  }
  return postExistArray
}

async modifPicture(event : any, elem: any, type: string){
  if(!event.target.files[0] || event.target.files.length == 0) {
    return false;
  }
  await Promise.all([...event.target.files].map( (file: any) => {
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.picService.getExtentionImg(file)
      if (extention != false){
        const fd = new FormData();
        fd.append('file', file, file.name);
        this.apiService.postPicture(fd).subscribe(path => {
          elem.instagramInfoPost.picture = '/api/picture/Images/' + path[0]
            this.apiService.putOffre(this.route.snapshot.params.id, this.offer).subscribe(offer => {
              this.cdRef.detectChanges();
            })
        }, (err => {
          this.toastr.warning("Request Entity Too Large")
            this.cdRef.detectChanges()
            return
        }))
      }
    }
    reader.readAsDataURL(file);
    this.cdRef.detectChanges()
  }));
  return false
  }

  deleteImgForModif(img : any, index: number, elem: any){
    elem.instagramInfoPost.picture = ""
    this.apiService.deletePicture(img).subscribe(res => {
      this.apiService.putOffre(this.route.snapshot.params.id, this.offer).subscribe(offer => {
        this.cdRef.detectChanges();
      })
    })
  }

  async addPicture(event : any, fb: any, type: string){
    if(!event.target.files[0] || event.target.files.length == 0) {
      return false;
    }
    if (this.createContent == true ){
      this.toastr.warning('Please Select Content Type before set screenshot')
      return false
    }
    if (this.newElement.value['pictures']){
      this.deleteImg(this.newElement.value['pictures'], this.newElement)
      this.readPic(event.target.files[0])
      return false
    }
    this.readPic(event.target.files[0])
    this.imgName = ""
    return false
}

    readPic(file : any){
      var reader = new FileReader();
      reader.onload = () => {
        let extention = this.picService.getExtentionImg(file)
        if (extention != false){
          const fd = new FormData();
          fd.append('file', file, file.name);
          this.apiService.postPicture(fd).subscribe(path => {
            this.newElement.value['pictures'] = path[0]
            this.cdRef.detectChanges()
          }, (err => {
            this.toastr.warning("Request Entity Too Large")
              this.cdRef.detectChanges()
              return
          }))
        }
      }
      reader.readAsDataURL(file);
      this.cdRef.detectChanges()
      return file
    }

    deleteImg(img : any, fb: any){
      fb.value['pictures'] = ""
      this.apiService.deletePicture(img).subscribe(res => {
        this.cdRef.detectChanges()
      })
    }

    openDialogError(compenentName: any, option : any) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      const dialogRef = this.dialog.open(compenentName, option);
      dialogRef.afterClosed().subscribe(result => {
        this.apiService.getOffre(this.route.snapshot.params.id).subscribe(offer => {
          this.offer = offer
          this.ContenueArray = this.getContentForModified(this.offer) 
          this.cdRef.detectChanges();
        })
      });
  }

  delContent(content : any){
    this.openDialogError(DelContentInfluenceurComponent, {
      data: {
        elem :content,
        offer: this.offer
      }
    },)
  }

succesPost(){
  this.openDialogError(ChoiceInvoiceComponent, {
    data:this.offer
  },)
}


}
