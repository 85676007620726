import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import {toast} from 'src/app/model/toast'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { DeletepageComponent } from 'src/app/dialog/deletepage/deletepage.component';
import { MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-manage-social-network-admin',
  templateUrl: './manage-social-network-admin.component.html',
  styleUrls: ['./manage-social-network-admin.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class ManageSocialNetworkAdminComponent implements OnInit {
  currentUser : any
  urlInsta = ""
  urlYt = ""
  urlBlog = ""
  urlTikTok = ""
  urlFb = ""
  instagramArray : any = []
  ytArray  : any  = []
  blogArray : any  = []
  tiktokArray : any  = []
  toast = toast
  urlYtReady = false
  constructor(
    private dialogRef: MatDialogRef<ManageSocialNetworkAdminComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cdRef:ChangeDetectorRef,
    private apiService : ApiService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public fctBasic: CampagneAndOfferFctService,
  ) {
    this.currentUser = data;
    this.urlFb = "https://influencelab.co/login/usermanagepage/" + data._id
  }

  ngOnInit(): void {

  }

  clipboard(){
    this.toastr.success(this.toast.textCopied)
  }

  addBlogAccount(){
    if (this.urlBlog == ""){
      return
    }
    let newPageInf : any = {};
    newPageInf.blogToken = {};
    newPageInf.blogToken.url = this.urlBlog;
    newPageInf.creationDate = new Date()
    newPageInf.accountStatus = "Awaiting for Approval"
    this.currentUser.userObject.push(newPageInf)
    this.apiService.putUser(this.currentUser._id, this.currentUser).subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.toastr.success(this.toast.urlNetworkBlog)
      this.urlBlog = "";
      this.cdRef.detectChanges();
    })
  }

  addTiktokAccount(){
    if (this.urlTikTok == ""){
      return
    }
    let nameAccount = this.urlTikTok.search("https://www.tiktok.com/@")
    if (nameAccount === -1 ){
      this.urlTikTok = "https://www.tiktok.com/@" + this.urlTikTok
    }
    let newPageInf : any = {};
    newPageInf.tiktokjson = {};
    newPageInf.tiktokjson.url = this.urlTikTok;
    newPageInf.creationDate = new Date()
    newPageInf.accountStatus = "Awaiting for Approval"
    this.currentUser.userObject.push(newPageInf)
    this.apiService.putUser(this.currentUser._id, this.currentUser).subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.toastr.success(this.toast.urlNetworkTikTok)
      this.urlTikTok = "";
      this.cdRef.detectChanges();
    })
  }


  addNetworkInstagram(){
    if (this.urlInsta == ""){
      return
    }
    let nameAccount = this.urlInsta.search("https://www.instagram.com/")
    if (nameAccount === -1 ){
      this.urlInsta = "https://www.instagram.com/" + this.urlInsta
    }
    let newPageInf : any = {};
    newPageInf.instagramUrl = this.urlInsta;
    newPageInf.creationDate = new Date()
    newPageInf.accountStatus = "Awaiting for Approval"
    this.currentUser.userObject.push(newPageInf)
    this.apiService.putUser(this.currentUser._id, this.currentUser).subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.toastr.success(this.toast.urlNetworkIg)
      this.urlInsta = "";
      this.cdRef.detectChanges();
    })
  }

  addUrlYt(){
    if (this.urlYt == ""){
      return 
    }
    let nameAccount = this.urlYt.search("https://www.youtube.com/")
    if (nameAccount === -1 ){
      this.urlYt = "https://www.youtube.com/" + this.urlYt
    }
    let newPageInf : any = {};
    newPageInf.youtubePage = {}
    newPageInf.youtubePage.url = this.urlYt;
    newPageInf.creationDate = new Date()
    newPageInf.accountStatus = "Awaiting for Approval"
    this.currentUser.userObject.push(newPageInf)
    this.apiService.putUser(this.currentUser._id, this.currentUser).subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.toastr.success(this.toast.urlNetworkYoutube)
      this.urlYt = "";
      this.cdRef.detectChanges();
    })
  }


  deletepage(elem: any, index: number){
    this.currentUser.userObject.splice(index, 1)
    this.apiService.putUser(this.currentUser._id, this.currentUser).subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.toastr.success(this.toast.deleteNetwork)
      this.cdRef.detectChanges();
    })
  }

  openDialog(network: any) {
    const dialogRef = this.dialog.open(DeletepageComponent, {data: {
      user: this.currentUser,
      network: network
    }});
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.currentUser.userObject = result
        this.cdRef.detectChanges();
      }
    });
  }

}
