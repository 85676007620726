<h4 class="dialogHeadlineGenerique" mat-dialog-title>Add Social Media Account</h4>

<mat-dialog-content style="width: auto;height: auto;" class=" mt-2">
    <div class="row input-row  ">
        <div class="col-md-12 col-12 col-lg-12 col-sm-12 ">
            <div >
                You can add your networks that you want to use for future collaborations on the platform.
            </div>
        </div> 
    </div>
    <div class="row input-row  ">
        <div class="col-md-12 col-12 col-lg-12 col-sm-12  display-mid ">
                <div class="col-lg-4 col-6  col-md-4 col-6 ">
                    <button mat-mini-fab  style="color:white; background-color: #cc181e;">
                        <i  class="fa  fa-youtube "></i>
                      </button>
                    
                </div>
                <div class="col-lg-8 col-6 col-md-8 col-6">
                    <a href="{{urlYt}}">
                        <mat-form-field  >
                            <input  matInput     type="text" >
                            <button  matSuffix mat-icon-button aria-label="Clear" >
                              <mat-icon>add</mat-icon>
                            </button>
                        </mat-form-field>
                    </a>
                    
                    <div   (click)="deletepage(net, i)"  *ngFor="let net of currentUser.userObject; index as i" class="badge mt-2 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                        <div *ngIf="net.youtubePage">{{ net.youtubePage.name  }} x</div>
                    </div>
                </div>
        </div> 
    </div>
    <div class="row input-row  ">
        <div class="col-md-12 col-12 col-lg-12 col-sm-12  display-mid ">
                <div class="col-lg-4 col-6  col-md-4 col-6 ">
                    <button mat-mini-fab  style="color:white; background-color: #8a3ab9;">
                        <i  class="fa  fa-instagram "></i>
                      </button>
                    
                </div>
                <div class="col-lg-8 col-6 col-md-8 ">
                    <form action="">
                        <mat-form-field  >
                            <input  matInput  [(ngModel)]="urlInsta" [ngModelOptions]="{standalone: true}"   type="text" >
                            <button (click)="addNetworkInstagram()" matSuffix mat-icon-button aria-label="Clear" >
                              <mat-icon>add</mat-icon>
                            </button>
                        </mat-form-field>
                    </form>
                    <div   (click)="deletepage(net, i)"  *ngFor="let net of currentUser.userObject; index as i" class="badge mt-2 mb-1 mr-1 " style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                       <div  *ngIf="net.instagramUrl">
                        <span >{{ net.instagramUrl  }} x</span>
                       </div> 
                    </div>
                </div>
        </div> 
    </div>
    <div class="row input-row  ">
        <div class="col-md-12 col-12 col-lg-12 col-sm-12  display-mid ">
                <div class="col-lg-4 col-6  col-md-4 col-6 ">
                    <button mat-mini-fab  style="color:white; background-color: #fccc63;">
                        <i  class="fa  fa-bold "></i>
                    </button>
                </div>
                <div class="col-lg-8 col-6 col-md-8 col-6">
                    <form>
                        <mat-form-field  >
                            <input  matInput   [(ngModel)]="urlBlog" [ngModelOptions]="{standalone: true}"   type="text" >
                            <button  (click)="addBlogAccount()" matSuffix mat-icon-button aria-label="Clear" >
                              <mat-icon>add</mat-icon>
                            </button>
                        </mat-form-field>
                    </form>
                    <div   (click)="deletepage(net, i)"  *ngFor="let net of currentUser.userObject; index as i" class="badge mt-2 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                        <div *ngIf="net.blogToken">{{ net.blogToken.url  }} x</div>
                     </div>
                    
                </div>
        </div> 
    </div>
    <div class="row input-row  ">
        <div class="col-md-12 col-12 col-lg-12 col-sm-12  display-mid ">
                <div class="col-lg-4 col-6  col-md-4 col-6 ">
                    <button mat-mini-fab  style="color:white; background-color: black;">
                        <mat-icon>tiktok</mat-icon>
                    </button>
                </div>
                <div class="col-lg-8 col-6 col-md-8 col-6">
                    <form>
                        <mat-form-field  >
                            <input  matInput   [(ngModel)]="urlTikTok" [ngModelOptions]="{standalone: true}"   type="text" >
                            <button  (click)="addTiktokAccount()" matSuffix mat-icon-button aria-label="Clear" >
                              <mat-icon>add</mat-icon>
                            </button>
                        </mat-form-field>
                    </form>
                    <div   (click)="deletepage(net, i)"  *ngFor="let net of currentUser.userObject; index as i" class="badge mt-2 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                        <div *ngIf="net.tiktokjson">{{ net.tiktokjson.url  }} x</div> 
                     </div>
                    
                </div>
        </div> 
    </div>
    <!-- <div class="row input-row  ">
        <div class="col-md-12 col-12 col-lg-12 col-sm-12  display-mid ">
                <div class="col-lg-4 col-6  col-md-4 col-6 ">
                    <button mat-mini-fab  style="color:white; background-color: #3b5998;">
                        <i  class="fa  fa-facebook "></i>
                    </button>
                </div>
                <div class="col-lg-8 col-6 col-md-8 col-6">
                    <form>
                        <mat-form-field  >
                            <input  matInput   [(ngModel)]="urlTikTok" [ngModelOptions]="{standalone: true}"   type="text" >
                            <button  (click)="addTiktokAccount()" matSuffix mat-icon-button aria-label="Clear" >
                              <mat-icon>add</mat-icon>
                            </button>
                        </mat-form-field>
                    </form>
                    
                </div>
        </div> 
    </div> -->
</mat-dialog-content>
<mat-dialog-actions>
        <div class="col-12 text-center">
            <button type="button" mat-dialog-close  class="btn btn-lg btn-secondary btn-template justify-content">GO !</button>
        </div>
</mat-dialog-actions>