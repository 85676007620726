<html>
    <body>
        <div class="page container">
            <a [routerLink]="['/listCampaign']" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>

            <div  flex-center>
                <button (click)="modifyCampagne()" [ngStyle]="{'color' : makeModify  ? 'grey' : 'white'}" type="button" class="btn btn-secondary shadow-none mr-1">Modify</button>
                <button (click)="makedupicataCampagne()" [ngStyle]="{'color' : makeDuplicata  ? 'grey' : 'white'}" type="button" class="btn btn-secondary ml-1 shadow-none" >Duplicate</button>
            </div>

            <mat-card class="mat-elevation-z1 mt-3">
                <mat-card-content >
                    <form *ngIf="init" [formGroup]="campagnes" class="textafterDisabled">


                        <div class="row input-row  ">
                            <div  class="col-md-6 col-lg-6  col-sm-12  alignVertical ">
                                <div  class="col-lg-4 col-6  col-md-4 ">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'" >Status</mat-label>
                                </div>
                                <div class="col-lg-8 col-6  col-md-8 ">
                                    <label class="labelCustom"  [ngStyle]="{'background-color':fctBasic.getColorByStatus(dataSourceCampagne.status)}" > {{fctBasic.getNameByStatus(dataSourceCampagne.status)}}</label>
                                </div>
                            </div>
                            <div class="col-md-6  col-lg-6 display-mid  col-sm-12">
                                    <div  class="col-lg-4  col-6 col-md-4">
                                        <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Change Status</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field class="campagneselect2byline">
                                            <mat-select (selectionChange)="changeStatusCampaign($event)" >
                                                <mat-option [value]="'New Campaign'">
                                                    New
                                                </mat-option>
                                                <mat-option [value]="'CampaignLive'">
                                                    Live
                                                </mat-option>
                                                <mat-option [value]="'Past'">
                                                    Past
                                                </mat-option>
                                                <mat-option [value]="'Cancelled'">
                                                    Cancelled
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                            </div>
                        </div>


                        <div class="row input-row  ">
                            <div class="col-md-6 display-mid col-lg-6 col-sm-12   ">
                                    <div class="col-lg-4  col-6 col-md-4 ">
                                        <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'" >Start Date</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field class="campagneselect2byline">
                                            <input matInput class="disabledtext" [matDatepicker]="picker1" formControlName="startDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6 display-mid col-lg-6 col-sm-12">
                                    <div class="col-lg-4  col-6 col-md-4 ">
                                        <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">End Date</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8  ">
                                        <mat-form-field  class="campagneselect2byline">
                                            <input matInput class="disabledtext" [matDatepicker]="picker"  formControlName="endDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-12 col-lg-6 display-mid  col-sm-12  ">
                                    <div class="col-lg-4   col-md-4 ">
                                        <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Agency</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field  class="campagneselect2byline">
                                            <mat-select *ngIf="initAgency" formControlName="agency">
                                                <mat-option value=""></mat-option>
                                                <mat-option  *ngFor="let company of listCompany" [value]="company.companyName">
                                                    {{ company.companyName }}
                                                </mat-option>
                                            </mat-select>
                                            <input matInput  required placeholder="" >
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6 col-12 col-lg-6 display-mid  col-sm-12">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Advertiser</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field  class="campagneselect2byline">
                                            <mat-select formControlName="advertiser">
                                                <mat-option value=""></mat-option>
                                                <mat-option  *ngFor="let company of listCompany" [value]="company.companyName">
                                                    {{ company.companyName }}
                                                </mat-option>
                                            </mat-select>
                                            <input matInput  required placeholder="" >
                                        </mat-form-field>

                                    </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-12 col-lg-6 display-mid  col-sm-12   ">
                                    <div class="col-lg-4   col-md-4 ">
                                        <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Agency Contact</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field  class="campagneselect2byline">
                                            <mat-select *ngIf="init" formControlName="agencyContact">
                                                <mat-option value=""></mat-option>
                                                <mat-option  *ngFor="let contact of listContacts" [value]="contact.lastname">
                                                    {{ contact.lastname }}
                                                </mat-option>
                                            </mat-select>
                                            <input matInput  required placeholder="" >
                                        </mat-form-field>
                                    </div>

                            </div>
                            <div class="col-md-6 col-12 col-lg-6 display-mid  col-sm-12">
                                    <div class="col-lg-4 col-md-4">
                                        <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Advertiser Contact</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8  ">
                                        <mat-form-field  class="campagneselect2byline">
                                            <mat-select formControlName="advertiserContact">
                                                <mat-option value=""></mat-option>
                                                <mat-option  *ngFor="let contact of listContacts" [value]="contact.lastname">
                                                    {{ contact.lastname }}
                                                </mat-option>
                                            </mat-select>
                                            <input matInput  required placeholder="" >
                                        </mat-form-field>
                                    </div>
                            </div>
                        </div>

                        <div class="row input-row  ">
                            <div class="col-md-12 col-12 col-lg-12 display-mid  col-sm-12 ">
                                <div class="col-lg-2  col-6">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'"> Campaign Name</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field >
                                        <input class="disabledtext"  matInput placeholder=""  formControlName="campaignName">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row input-row  ">
                            <div class="col-md-12 col-lg-12 display-mid">
                                <div class="col-lg-2 col-6 ">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Agency Reference</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field >
                                        <input  class="disabledtext" matInput placeholder=""  formControlName="agencyReference">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-12 col-lg-12 textareapostion">
                                <div class="col-lg-2  col-6">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Campaign Description</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field  appearance="outline">
                                        <textarea rows="15" class="disabledtext" formControlName="projectDescription" matInput ></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-12 col-lg-12 display-mid">
                                <div class="col-lg-2  col-6">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Campaign Objective</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field >
                                        <mat-select  class="disabledtext" formControlName="objective">
                                            <mat-option  [value]="'Awareness'">
                                                Awareness
                                            </mat-option>
                                            <mat-option [value]="'Video Views'">
                                                Video Views
                                            </mat-option>
                                            <mat-option  [value]="'Traffic'">
                                                Traffic
                                            </mat-option>
                                            <mat-option [value]="'Engagement'">
                                                Engagement
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-12 col-lg-12 display-mid">
                                <div class="col-lg-2  col-6">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Campaign Mission</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field>
                                        <mat-select formControlName="mission">
                                            <mat-option [value]="'Content'">
                                                Content
                                            </mat-option>
                                            <mat-option [value]="'Media'">
                                                Media
                                            </mat-option>
                                            <mat-option [value]="'Live'">
                                                Live
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div style="padding-top:1.04375em!important;padding-bottom: 1.14375em!important;" class="col-md-12 col-lg-12 display-mid">
                                <div class="col-lg-2 col-6">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Social Network</mat-label>
                                </div>
                                <div  class="col-lg-10 col-6">
                                    <button [disabled]="isDisabled ? true : false" mat-button  type="button" [ngStyle]="{'background-color' : buttonValue == 'Facebook' ? 'black' : '#5285fb'}" (click)="setplatform('Facebook')" style=" color:white;"class=" btn mr-2 shadow-none btn-facebook"><i class="fa fa-facebook mr-2"></i>Facebook</button>
                                    <button [disabled]="isDisabled ? true : false" mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'Youtube' ? 'black' : '#CC181E'}"(click)="setplatform('Youtube')"  style=" color:white;" class=" btn mr-2 shadow-none btn-youtube"><i class="fa fa-youtube mr-2"></i>Youtube</button>
                                    <button [disabled]="isDisabled ? true : false" mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'Instagram' ? 'black' : '#8a3ab9'}" (click)="setplatform('Instagram')"style=" color:white;" class=" mr-2 btn shadow-none"><i class="fa fa-instagram mr-2"></i>Instagram</button>
                                    <button [disabled]="isDisabled ? true : false" mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'Blog' ? 'black' : '#fccc63'}"(click)="setplatform('Blog')" style=" color:white;" class="col-lg-2  mr-2 btn shadow-none"><i class="fa fa-bold mr-2"></i>Blog</button>
                                    <button [disabled]="isDisabled ? true : false" mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'multiNetwork' ? 'black' : '#ec1f99'}"(click)="setplatform('multiNetwork')" style=" color:white;" class=" mr-2 btn shadow-none ">Multi-Platform</button>
                                    <button [disabled]="isDisabled ? true : false" mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'TikTok' ? 'black' : 'white', color: buttonValue == 'TikTok' ? 'white' : 'black', border: buttonValue == 'TikTok' ? '' : '1px solid black'}"(click)="setplatform('TikTok')"  class=" mr-2 btn shadow-none "><mat-icon class=" mr-2">tiktok</mat-icon>TikTok</button>
                                </div>

                            </div>
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-12 col-lg-12 textareapostion">
                                <div class="col-lg-2 ">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Must Include</mat-label> <br>
                                    <mat-label class="textsecondary" [ngClass]="this.isDisabled ? 'disabledLabelMessage' : ''"><i>List your campaign key elements. Ex : #campaignhashtag ; @brandaccount ; UTM ; campaign keywords</i></mat-label>
                                </div>
                                <div class="col-lg-10 ">
                                    <mat-form-field  appearance="outline">
                                        <textarea rows="15" class="disabledtext" formControlName="mustInclude" matInput ></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-lg-12 col-12 col-md-12 col-sm-12">
                                <div class="col-lg-6 col-sm-6 col-6 col-md-6">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Script generation info</mat-label>
                                </div>
                                <div class="col-lg-12 col-sm-6 col-6 col-md-6">
                                    <mat-label class="textsecondary" [ngClass]="this.isDisabled ? 'disabledLabelMessage' : ''"><i>Indicate at least 2 @ ou # for dashboard and pige generation - Case sensitive</i></mat-label>
                                </div>
                                <div *ngIf="campagnes.value['annonceurKey']" class="col-lg-12 col-md-12 col-sm-12 paddingButton">
                                        <mat-form-field>
                                            <input [disabled]="this.isDisabled"  formControlName="valueArrTmp" matInput  id="keyword" type="text" >
                                            <button [disabled]="this.isDisabled" matSuffix mat-icon-button aria-label="Clear" (click)="addKeyWords()">
                                              <mat-icon [ngClass]="this.isDisabled ? 'disabledLabelMessage' : 'iconOffer'">add</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <div  class="row col-lg-12 col-md-12">
                                            <div  disabled="this.isDisabled" (click)="deleteKeyWords(i)"  *ngFor="let keyword of campagnes.value['annonceurKey']; index as i" class="badge  mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px!important; line-height: 1.538461 !important;">
                                                {{ keyword }} x
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 col-sm-12   ">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'"> Upload Image </mat-label>
                                </div>
                                <div class="col-lg-12 col-md-4">
                                    <mat-label class="textsecondary" [ngClass]="this.isDisabled ? 'disabledLabelMessage' : ''"> <i>We accept files in the following formats : .png, .jpg, .gif</i>  </mat-label>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <mat-form-field>
                                        <ngx-mat-file-input [disabled]="this.isDisabled" (change)="addPicture($event)"  ></ngx-mat-file-input>
                                        <mat-icon [ngClass]="this.isDisabled ? 'disabledLabelMessage' : 'iconOffer'" matSuffix>attach_file</mat-icon>
                                      </mat-form-field>
                                      <div class="row col-lg-12 col-md-12" *ngIf="campagnes.value['pictures'].length > 0">
                                        <div class="imgrow">
                                            <div  *ngFor="let pic of campagnes.value['pictures']; index as i">
                                                <div class="img_wrapper_multi">
                                                    <span (click)="deleteImg(pic, i)" class="close">&times;</span>
                                                    <img  [src]="picService.checkUrlPath(pic)"   class="img-responsive center-block imgFeedMulti">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6  col-lg-6   col-sm-12">
                                <div class="col-lg-4 col-md-4">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">  Upload File  </mat-label>
                                </div>
                                <div class="col-lg-12 col-md-4">
                                    <mat-label class="textsecondary" [ngClass]="this.isDisabled ? 'disabledLabelMessage' : ''"> <i>We accept files in the following formats : .docx, .ppt, .xlsx</i>  </mat-label>
                                </div>
                                <div *ngIf="init"  class="col-lg-12 col-md-12">
                                    <mat-form-field>
                                        <ngx-mat-file-input [disabled]="this.isDisabled" (change)="addFile($event)"  ></ngx-mat-file-input>
                                        <mat-icon [ngClass]="this.isDisabled ? 'disabledLabelMessage' : 'iconOffer'" matSuffix>attach_file</mat-icon>
                                    </mat-form-field>
                                    <div class="row col-lg-12 col-md-12" *ngIf="campagnes.value['files'].length > 0">
                                        <div class="imgrow">
                                            <div  *ngFor="let file of campagnes.value['files']; index as a;">
                                                <div class="img_wrapper_multi">
                                                    <span (click)="deleteFile(file, a)" class="close">&times;</span>
                                                    <img  [src]="picService.getExtentionFileToShow(file)"   class="img-responsive center-block imgFeedMulti">
                                                    <div class="fileText">{{file.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row input-row  ">
                            <div class="col-md-3 col-lg-3 col-sm-12   ">
                                    <div class="col-lg-12   col-md-12 ">
                                        <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Delivery Target</mat-label>
                                    </div>
                                    <div class="col-lg-12   col-md-12 ">
                                        <mat-form-field >
                                            <input matInput class="disabledtext" (selectionChange)="calculateT(campagnes)"mask="separator.2"  placeholder=""  formControlName="deliveryTarget">
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-3  col-lg-3   col-sm-12">
                                    <div class="col-lg-12 col-md-12 ">
                                        <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Prix Unitaire</mat-label>
                                    </div>
                                    <div class="col-lg-12  col-md-12">
                                        <mat-form-field >
                                            <input class="disabledtext" (selectionChange)="calculateT(campagnes)" mask="separator.2" suffix=" €"  matInput placeholder=""  formControlName="prixUnitaire">
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-12   ">
                                <div class="col-lg-12   col-md-12 ">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Sales Models</mat-label>
                                </div>
                                <div class="col-lg-12   col-md-12 ">
                                    <mat-form-field >
                                        <mat-select (selectionChange)="calculateT(campagnes)" formControlName="saleModels">
                                            <mat-option [value]="'CPM'">
                                                CPM
                                            </mat-option>
                                            <mat-option [value]="'CPV'">
                                                CPV
                                            </mat-option>
                                            <mat-option [value]="'CPE'">
                                                CPE
                                            </mat-option>
                                            <mat-option [value]="'CPC'">
                                                CPC
                                            </mat-option>
                                            <mat-option [value]="'Quotation'">
                                                Quotation
                                            </mat-option>
                                            <mat-option [value]="'CPP'">
                                                CPP
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                        </div>
                        <div class="col-md-3  col-lg-3   col-sm-12">
                                <div class="col-lg-12 col-md-12 ">
                                    <mat-label [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Net Budget</mat-label>
                                </div>
                                <div class="col-lg-12  col-md-12">
                                    <mat-form-field >
                                        <input mask="separator.2" suffix=" €"  matInput class="disabledtext" placeholder="" readonly  formControlName="revenueTarget">
                                    </mat-form-field>
                                </div>
                        </div>
                        </div>
                        <div flex-center class="mt-2 mb-3">
                            <button *ngIf="makeDuplicata == false" [disabled]="isDisabled" (click)="saveModifCampagne()"  class="btn mr-1 btn-secondaryWithBorder shadow-none"> <b>Save Modification</b> </button>
                            <button *ngIf="makeDuplicata == false" (click)="openInfluenceurSelectorInNewWindow(dataSourceCampagne._id)"  class="btn ml-1 btn-secondaryWithBorder shadow-none" > <b>Select Influenceur</b> </button>
                            <button *ngIf="makeDuplicata == true"  (click)="createNewCampagneWithDuplicata()"  class="btn btn-secondaryWithBorder shadow-none" > <b>Save New Campaign</b> </button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-elevation-z1 mt-3" *ngIf="campagneLive == true">
                  <mat-card-content class=" mt-1">
                    <div class="tableResponsiveAnnex table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  ">
                        <div  class="panel-header-campaign ">
                            <div class="col-lg-12">
                              <div [ngClass]="this.isDisabled ? 'disabledLabel' : 'labelFont'">Offers Overview </div>

                            </div>
                        </div>
                        <div class="col-lg-12">
                            <mat-divider ></mat-divider>
                        </div>
                        <table  class="table table-hover  tableTitle  text-center">
                          <thead>
                              <tr class="tableTitle ">
                                <th class="col-1 tableTitle"scope="col">Status</th>
                                <th class="col-1"scope="col">Influencer</th>
                                <th class="col-1"scope="col">Current Revenue  </th>
                                <th class="col-1" scope="col">Revenue Target  </th>
                                <th class="col-1"scope="col">Current Delivery  </th>
                                <th class="col-1"scope="col">Delivery Target  </th>
                                <th class="col-1"scope="col">Stats Form</th>
                                <th class="col-1"scope="col">Action</th>
                              </tr>
                            </thead>
                          <tbody class="bodyTable">
                            <tr *ngFor = "let offer of dataSourceCampagne.offerObject">
                              <td class=" text-center">
                                <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(offer.status)}" > {{fctBasic.getNameByStatus(offer.status)}}</label>
                              </td>
                              <td class=" text-center">{{offer.influencerObjects.name}}</td>
                              <td class="text-center">
                                {{offer.currentRevenue | mask:"separator.2"}} €
                              </td>
                              <td class="text-center">
                                {{offer.revenueTarget | mask:"separator.2"}} €
                              </td>
                              <td class="text-center">
                                {{ offer.currentDelivery | mask:"separator.2"}} {{fctBasic.getsaleModels(offer.saleModels)}}
                              </td>
                              <td class="text-center">
                                {{ offer.deliveryTarget | mask:"separator.2"}} {{fctBasic.getsaleModels(offer.saleModels)}}
                              </td>
                              <td class="text-center">
                                <mat-slide-toggle (change)="affiliedFormOnOffer(offer, $event)" [checked]="offer.putManually" ></mat-slide-toggle>
                              </td>
                              <td class="text-center">
                                <button (click)="openOfferDetailInNewWindow(offer._id)" type="button" style="font-size: smaller;" class="btn btn-secondary shadow-none"><span class="fa fa-search"></span></button>
                              </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <b>TOTAL</b>
                                  </td>

                                  <td class="text-center">
                                    <b><p></p></b>
                                  </td>
                                  <td class="text-center">
                                    <b  mask="separator.2">{{offerTotalCurrentRevenue | mask:"separator.2"}} €</b>
                                  </td>
                                  <td class="text-center">
                                    <b  mask="separator.2">{{OfferTotalRevenueTarget | mask:"separator.2"}} €</b>
                                  </td>
                                  <td class="text-center">
                                    <b mask="separator.2">{{OfferTotalCurrentDelivery | mask:"separator.2"}}</b>
                                  </td>
                                  <td class="text-center">
                                    <b mask="separator.2">{{OfferTotalDeliveryTarget | mask:"separator.2"}}</b>
                                  </td>
                                  <td class="text-center">
                                  </td>
                                  <td class="text-center">
                                  </td>
                              </tr>
                          </tbody>


                        </table>
                    </div>
                    <div  flex-center>
                        <button  (click)="openCampagneDashboardInNewWindow(dataSourceCampagne._id)" type="button" class="btn btn-secondaryWithBorder mb-2 shadow-none mr-1"><b>Show Dashboard</b></button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </body>
</html>
