
<html>

<body>
  <div class="page container-fluid ">
      <div class="row">
          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
              <div class=" tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
                <div  class="panel-header ">
                  <div class="top-lead">
                    <div class="group clearfix">
                        <i class="fa fa-bullhorn    icon mb-0 mt-0"></i>
                        <div  style="font-size:20px" class="labelFont text-normal">Campaigns</div>
                    </div>
                  </div>
                </div>
                <table class="table table-hover tableTitle text-center ">
                  <thead>
                      <tr>
                        <th class="col-3 text-center" scope="col">Status</th>
                        <th class="col-3 text-center" scope="col">Date</th>
                        <th class="col-3 text-center" scope="col">Campaign Name </th>
                        <th class="col-3 text-center" scope="col">Network</th>
                      </tr>
                    </thead>
                  <tbody class="bodyTable">
                    <tr [routerLink]="['/detailCampaign/', campaign._id]" *ngFor = "let campaign of dataSourceCampaign; let i=index">
                      <td class="center-text">
                        <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(campaign.status)}" > {{fctBasic.getNameByStatus(campaign.status)}}</label>
                      </td>
                      <td class="center-text">
                        {{campaign.startDate | date:'yyyy/MM/dd'}} - {{campaign.endDate | date:'yyyy/MM/dd'}}
                      </td>
                      <td class="center-text">
                        {{campaign.campaignName | slice:0:40}}
                      </td>
                      <td class="center-text">
                        <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(campaign.platformeType)}" style="color:white">
                          <i  *ngIf="campaign.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                          <i *ngIf="campaign.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                          <i *ngIf="campaign.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                          <i *ngIf="campaign.platformeType == 'Blog'" class="fa fa-bold "></i>
                          <i *ngIf="campaign.platformeType == 'multiNetwork'" class="fa fa-globe"></i>
                          <mat-icon *ngIf="campaign.platformeType == 'TikTok'">tiktok</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class=" tableResponsiveMessage table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1 mt-3">
                  <div  class="panel-header ">
                    <div class="top-lead">
                      <div class="group clearfix">
                          <i class="fa fa-envelope     icon mb-0 mt-0"></i>
                          <div  style="font-size:20px" class="labelFont text-normal">Message(s)</div>
                      </div>
                    </div>
                  </div>
                  <mat-accordion >
                    <div *ngIf="arrayMessage && arrayMessage.length > 0" class="mt-3">
                      <li *ngFor="let m of arrayMessage; let i=index" class="list-group-item">
                        <div class="media">
                          <div class="media-body">
                            <p>
                              <strong>{{m.senderName}}</strong>
                              <time class="text-muted float-right">{{ m.creationDate | date:'yyyy/MM/dd'}}</time>
                            </p>
                            <p class="card-text fileTextMessage">{{ m.body }}</p>
                          </div>
                        </div>
                      </li>
                      
                    </div>
                    <mat-expansion-panel  hideToggle >
                      <mat-expansion-panel-header >
                        <mat-panel-title >
                          New Messages
                        </mat-panel-title>
                        
                      </mat-expansion-panel-header>
                      <div class="col-md-12 col-lg-12">
                        <label  class="control-label">Recipient</label>
                        <select  [(ngModel)]="receiverName" class="form-control" >
                            <option value=""></option>
                            <option *ngFor="let x of userArraytosendEmmail; let i=index" [ngValue]="x">{{x.email}}</option>
                        </select>
                      </div>
                      <div class="col-md-12 col-lg-12 mt-3">
                        <label  class="control-label">Send New Message</label>
                        <mat-form-field  appearance="outline">
                          <textarea rows="5" [(ngModel)]="newMessage"  matInput ></textarea>
                        </mat-form-field>
                    </div>
                      <div flex-center>
                        <button type="button" style="font-size: smaller;" class="btn btn-secondary mb-2" (click)="sendNewMessage()">Send !</button>
                      </div>
                    </mat-expansion-panel>
                </mat-accordion>    
              </div> -->
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
              <div  class="panel-header ">
                <div class="top-lead">
                  <div class="group clearfix">
                      <i class="fa fa-gift icon mb-0 mt-0"></i>
                      <div  style="font-size:20px" class="labelFont text-normal">Offers</div>
                  </div>
                </div>
              </div>
              <table class="table table-hover tableTitle text-center ">
                <thead>
                  <tr>
                    <th class="col-2 text-center"scope="col">Status</th>
                    <th class="col-3 text-center" scope="col">Date</th>
                    <th class="col-4 text-center"scope="col">Offer Name</th>
                    <th class="col-3 text-center" scope="col">Network</th>
                  </tr>
                </thead>
                <tbody class="bodyTable">
                  <tr [routerLink]="['/detailOfferAdmin/', offer._id]" *ngFor = "let offer of dataSourceOffer; let i=index">
                    <td class=" center-text">
                      <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByStatus(offer.status)}" > {{fctBasic.getNameByStatus(offer.status)}}</label>
                    </td>
                    <td class=" center-text">
                      {{offer.startDate | date:'yyyy/MM/dd'}} - {{offer.endDate | date:'yyyy/MM/dd'}}
                    </td>
                    <td class=" center-text">{{offer.campaignName | slice:0:25}}</td>
                    <td class=" center-text">
                      <button class="center-text" type="button" [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(offer.platformeType)}" style="color:white" class="buttonSocialNet btn btn-sm btn-block rounded-pill">
                        <i  *ngIf="offer.platformeType == 'Facebook'" class="fa mr-2 left fa-facebook "></i>
                        <i *ngIf="offer.platformeType == 'Instagram'" class="fa mr-2 left fa-instagram "></i>
                        <i *ngIf="offer.platformeType == 'Youtube'" class="fa mr-2 left fa-youtube "></i>
                        <i *ngIf="offer.platformeType == 'Blog'" class="fa fa-bold mr-2 left"></i>
                        <i *ngIf="offer.platformeType == 'multiNetwork'" class="fa fa-globe mr-2 left"></i>
                        {{fctBasic.getNetworkNameForInfluenceur(offer.influencerObjects, offer.platformeType) | slice:0:20 }}</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
            </div>
              <div class="mt-3 tableResponsive table-responsive-sm table-responsive-xl table-responsive-lg table-responsive-md  mat-elevation-z1">
                <div  class="panel-header ">
                  <div class="top-lead">
                    <div class="group clearfix">
                        <i class="fa fa-child icon mb-0 mt-0"></i>
                        <div  style="font-size:20px" class="labelFont text-normal">Influenceurs</div>
                    </div>
                  </div>
                </div>
                <table class="table  table-hover text-center ">
                  
                  <thead>
                    <tr >
                      <th class="col-lg-2 center-text" scope="col-2">Status</th>
                      <th class="col-lg-2 center-text" scope="col-3">Creation Date</th>
                      <th class="col-lg-2 center-text" scope="col-2">First Name</th>
                      <th class="col-lg-2 center-text" scope="col-2">Last Name</th>
                      <th class="col-lg-2 center-text" scope="col-3">Action</th>
                    </tr>
                  </thead>
                  <tbody class="bodyTable">
                    <tr *ngFor = "let influencer of dataSourceInfluencer; let i=index">
                      <td class="col-lg-2 text-center">
                        <label class="labelCustom" [ngStyle]="{'background-color':fctBasic.getColorByUserStatus(influencer.valid)}" > {{fctBasic.getNameByUserStatus(influencer.valid)}}</label>
                      </td>
                      <td class=" col-lg-2 text-center">
                        {{influencer.influencerInfo.creationDate | date:'yyyy/MM/dd'}}
                      </td>
                      <td class="col-lg-2 center-text">{{influencer.influencerInfo.firstname}}</td>
                      <td class="col-lg-2 center-text">{{influencer.influencerInfo.lastname}}</td>
                      <td class="col-lg-2 center-text">
                        <button [routerLink]="['/detail-influencer/', influencer._id]" type="button" style="font-size: smaller;" class="btn btn-secondary"><span class="fa fa-search"></span></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
      </div>
  </div>
</body>

</html>