<html>
    <body>
      <div class="page container">
        <a *ngIf="show" [routerLink]="['/detailOfferAdmin/', dashboardData.campagne._id]" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
        <mat-card *ngIf="show">
            <div *ngIf="pigeReady" class="container">
                <div   class="row mb-4">
                  <a  href="{{excelOverviewUrl}}">
                    <button   mat-button class="btn btn-sm btn-secondary "  >Overview <i class="fa ml-1 fa-file-excel-o" aria-hidden="true"></i></button>
                  </a>
                  <a  href="{{excelUrl}}">
                    <button  mat-button class="btn btn-sm  ml-1 btn-secondary" >Detailed statistics <i class="fa ml-1 fa-file-excel-o green-color" aria-hidden="true"></i>
                    </button>
                  </a>
                  <a  href="{{pptxUrl}}">
                    <button  mat-button class="btn btn-sm ml-1 btn-secondary "  >PPTX <i class="fa fa-file-powerpoint-o ml-1" aria-hidden="true"></i>
                    </button>
                  </a>
                  <!-- <button (click)="clickDialogContact()"  mat-button class="btn btn-sm ml-1  btn-secondary "  >Share <i class="fa  ml-1 fa-share-alt" aria-hidden="true"></i>
                  </button> -->
                </div>
            </div>
          <div class="title-cardDashboard" >
            <div>
                <mat-label class="title"><i>Campaign Dashboard</i></mat-label>
            </div>
            <div>
              <mat-label class="title">🤗  {{dashboardData.campagne.campaignName}} 🚀</mat-label>
          </div>
        </div>
        <mat-divider ></mat-divider>
        <div class="title-card text-center mt-5 mb-3">
          <div class="col-lg-12  display-mid">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3 text-center" >
                  <label class="col-12 labelFont text-center">Revenue Target<span class="ml-2 fa fa-bullseye"></span></label>
                  <div  class=" text-center fileText">
                      {{dashboardData.campagne.revenueTarget}}
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-6 col-sm-3 col-3">
                  <label class="col-12 labelFont text-center">Campaign Date<span class="ml-2 fa fa-calendar"></span></label>
                  <div class="fileText text-center">
                      {{dashboardData.campagne.startDate | date:'yyyy/MM/dd'}} - {{dashboardData.campagne.endDate | date:'yyyy/MM/dd'}}
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                  <label class="col-12 labelFont text-center">Mission<span class="ml-2 fa fa-rocket"></span></label>
                  <div class="fileText text-center">
                      {{dashboardData.campagne.mission}}
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                  <label class="col-12 labelFont text-center">Social Network<span class="ml-2 fa fa-share-alt-square"></span></label>
                  <div class=" text-center">
                      
                        <button mat-mini-fab [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(dashboardData.campagne.platformeType)}" style="color:white">
                            <i  *ngIf="dashboardData.campagne.platformeType == 'Facebook'" class="fa  fa-facebook "></i>
                            <i *ngIf="dashboardData.campagne.platformeType == 'Instagram'" class="fa  fa-instagram "></i>
                            <i *ngIf="dashboardData.campagne.platformeType == 'Youtube'" class="fa fa-youtube "></i>
                            <i *ngIf="dashboardData.campagne.platformeType == 'Blog'" class="fa fa-bold "></i>
                            <i *ngIf="dashboardData.campagne.platformeType == 'multiNetwork'" class="fa fa-globe  "></i>
                          </button>
                  </div>
              </div>
          </div>
      </div>
      <mat-divider ></mat-divider>
  
          <mat-card-content>
            <div class="container" >
              <div class="cardMetrics mt-4">
                <mat-card>
                  <mat-card-content *ngIf="showOffer" (click)="showOverviewChart('impression')">
                    <div class="fa-content">
                      <div style="color: #4bdbaa;">
                        <i style="font-size:50px;" class="fa fa-eye"></i>
                      </div>
                      <div  class="cardMetricsContent ">
                        <div class="labelFont">
                         Impressions
                        </div>
                        <div  class="numberCard">
                          <span>{{dashboardData.campagneTotalStats.totalImpression | mask: "separator.2"}}</span>
                          
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card (click)="showOverviewChart('reach')">
                  <mat-card-content>
                    <div class="fa-content">
                      <div style="color: #5bb2ee;">
                        <i style="font-size:50px;display:inline-block;" class="fa fa-users"></i>
                      </div>
                      <div  class="cardMetricsContent ">
                        <div class="labelFont">
                          Average Duration
                        </div>
                        <div class="numberCard ">
                          <span>{{dashboardData.campagneTotalStats.totalAverageViewDuration | mask: "separator.2"}}</span>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card (click)="showOverviewChart('engagement')">
                  <mat-card-content>
                    <div class="fa-content">
                      <div style="color: #dc2626;">
                        <i style="font-size:50px;" class="fa fa-heart"></i>
                      </div>
                      <div  class="cardMetricsContent ">
                        <div class="labelFont">
                          Engagement
                        </div>
                        <div class="numberCard ">
                          <span>{{dashboardData.campagneTotalStats.totalLike + dashboardData.campagneTotalStats.totalComment | mask: "separator.2"}}</span>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
               <div class="chartPositionCard mt-4">
                <div  class="cardcharttitle ">
                    <div class="labelFont" *ngIf="overviewImpSeleted">Impressions Delivered (Daily)</div> 
                    <div  class="labelFont" *ngIf="overviewReachSeleted">Average (Daily)</div> 
                    <div class="labelFont" *ngIf="overviewEngagementSeleted">Engagement (Daily)</div> 
                </div>
                <apx-chart *ngIf="overviewImpSeleted"
                [series]="dashboardData.dashboardCampaign.impressionviewsdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaign.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [colors]="chartOptions.colors"
              ></apx-chart>
              <apx-chart *ngIf="overviewReachSeleted"
                [series]="dashboardData.dashboardCampaign.impressionviewsdata"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="dashboardData.dashboardCampaign.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fillReach"
                [plotOptions]="chartOptions.plotOptions"
                [colors]="chartOptions.colorsReach"
              ></apx-chart>
              <apx-chart *ngIf="overviewEngagementSeleted"
                [series]="dashboardData.dashboardCampaign.impressionengagementdata"
                [chart]="chartOptions.chartEngagement"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [dataLabels]="chartOptions.dataLabels"
                [labels]="dashboardData.dashboardCampaign.labelData"
                [fill]="chartOptions.fillEngagement"
                [colors]="chartOptions.colorsEngagement"
                [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>   
              </div>
            </div>
        </mat-card-content>
        </mat-card>
        <mat-card class="mt-3" *ngIf="showOffer && dashboardData && dashboardData.contenuCampaign.length > 0">
            <div  class="cardcharttitle ">
              <div class="labelFont" >Video(s) ({{dashboardData.contenuCampaign.length}})</div> 
          </div>
            <mat-card-content>
              <div class="container">
                <div flex-center class="row col-lg-12 col-12">
                  <div  class="card  mt-2 mb-2">
                    <div  class="profileIf ">
                      <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
                    </div>
                    <img  [src]="srcVideoImg" class="post-image" alt="">
                    <div class="card-content" (click)="expendedOverViewPosts=!expendedOverViewPosts" >
                      <div>{{(expendedOverViewPosts) ? srcVideoCaption : srcVideoCaption | slice:0:150}} <div  (click)="expendedOverViewPosts=true" *ngIf="!expendedOverViewPosts">...</div>
                        
                        <div  (click)="expendedOverViewPosts=false" *ngIf="expendedOverViewPosts">...</div>
                      </div>
                      
                    </div>
                </div>
                </div>
                
              <div flex-center class="thumbnails mt-4">
                <div *ngFor="let post of dashboardData.contenuCampaign;let index = index;" >
                  <div class="img_wrapper ">
                    <img  (click)="showPic(post)"  class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture" >
                  </div>
                </div>
              </div>
              </div>
              
            </mat-card-content>
          </mat-card>
      </div>
    </body>
  </html>