<body class="">
    <div class="failedhead ">
        <div class="container-fluid d-flex h-100 align-items-center" >
          <div class="mx-auto col-12 text-center">
            <h1 style="font-size: 20px;"class="mx-auto mt-2 text-uppercase"><mark class="mx-auto my-0 text-uppercase" style="background-color:black; opacity:1;letter-spacing:2px;">Registration failed ⚡</mark></h1>
            <h2 style="color: black;font-size: 18px;" class=" mx-auto mt-2 text-uppercase">We are sorry your regsitration cannot be completed.</h2>
            <h2 style="color: black;font-size: 18px;" class=" mx-auto mt-2 text-uppercase">Don't hesitate to try again later or to contact us for any further assistance  cm@influencelab.co</h2>
          </div>
        </div>
      </div>
</body>
