import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {CampagneModel} from 'src/app/model/campagne-model.model'
import { Router} from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CampagneAndOfferFctService {

  constructor(
    private toastr: ToastrService,
    public router: Router,
    
    ) { }

  checkUrlPath(elem : string){
    if (!elem){
      return ""
    }
    if (elem.includes('/api/picture/Images/') == true){
      return elem
    } else {
      return '/api/picture/Images/' + elem
    }
  }

  checkUrlPathSocialNetwork(network : any, platformeType: any){
    if (platformeType == 'Instagram' && network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.userName){
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/detailOfferAdmin/`])
      );
      window.open(url, '_blank');
      return network.facebookPage.instagramObject.userName
    } else if (platformeType == 'Instagram' && network.facebookPage && network.facebookPage.instagramObject && !network.facebookPage.instagramObject.userName){
      return network.facebookPage.instagramObject.name
    } else if (platformeType == 'Instagram' && network.instagramUrl){
      return network.instagramUrl
    } else if (network.platformeType == 'Facebook' &&  network.facebookPage &&  network.facebookPage.name){
      return network.facebookPage.name
    } else if (network.platformeType == 'Blog'){
      return "Blog"
    } else if (network.platformeType == 'Youtube'){
      return "Youtube"
    } else if (network.platformeType == 'multiNetwork'){
      return "Multi-Platform"
    } 
  }

  getNameByStatus(name: any): string {
    switch (name) {
      case "CampaignLive":
        return "Live"
      case "New Campaign":
        return "New"
      case "New Offer":
        return "New Offer"
      case "Accepted":
        return "Accepted"
      case "In Review":
        return "in Review"
      case "Post Approved":
        return "Post Approved"
      case "Post Rejected":
        return "Modification required"
      case "Boost Review":
        return "Boost Review"
      case "Boost Offer":
        return "Boost Offer"
      case "Live":
        return "Live"
      case "Past":
        return "Past"
      case "Paid":
        return "Paid"
      case "Cancelled":
        return "Cancelled"
      case  "Payment Pending":
        return "Payment Pending"
      default:
        return "none"
    }
  }

  getColorByStatus(status: any): string {
    switch (status) {
      case "CampaignLive":
        return "#FF5EBE"
      case "New Campaign":
        return "#008822"
      case "New Offer":
        return "#309BE5"
      case "Accepted":
        return "#77C43B"
      case "In Review":
        return "#f49D1B"
      case "Post Approved":
        return "#77C43B"
      case "Post Rejected":
        return "#EA002A"
      case "Boost Review":
        return "#BDB6B0"
      case "Boost Offer":
        return "#FF5EBE"
      case "Live":
        return "#FF5EBE"
      case "Past":
        return "#BDB6B0"
      case "Paid":
        return "#4E4B48"
      case "Cancelled":
        return "#D3D3D3"
      case "Payment Pending":
        return "#AFA59B"
      default:
        return "black"
    }
  }

  getColorByPlatformeType(platformeType: any): string {
    return platformeType === 'Instagram' ? "#8a3ab9" : 
    platformeType === "Facebook" ? "#5285fb" :
    platformeType === "Youtube" ? "#cc181e" :
    platformeType === "Blog" ? "#fccc63" : 
    platformeType === "multiNetwork" ? "#ec1f99" : 
    platformeType === "TikTok" ? "black" : "black"
  }

  getColorByUserStatus(valid: any): string {
    return valid == true ? "#00525E" : '#D447AA'
  }  

  getNameByUserStatus(valid: any): string {
    return valid == true ? "Active" : 'Awaiting for Approval'
  } 

  getColorByNetworkStatus(status: any): string {
    return status == "Active" ? "#00525E" : '#D447AA'
  }  

  getNameByNetworkStatus(status: any): string {
    return status == "Active" ? "Active" : 'Approve'
  } 

  getsaleModels(saleModels: any): string {
    return saleModels === 'CPM' ? "Impression" : 
    saleModels === "CPV" ? "Clic" :
    saleModels === "CPE" ? "Engagement (Like / comment / Bookmark / Share)" :
    saleModels === "CPC" ? "video vue à 15% minimum" : 
    saleModels === "CPP" ? "Post" : 
    saleModels === "Quotation" ? "" : "none"
  }


/////////////////////////recupere le nom du compte  l'influenceur pour l'afficher//////////////////////////////////////////////
getNetworkNameForInfluenceur(network: any, platformeType: any): any {
  if (!network){
    return "N/A"
  }
  switch (platformeType) {
    case 'Blog':
      return network.blogToken.url || 'Blog'
    case 'Youtube':
      return (network.youtubePage.name ? network.youtubePage.name : 'Youtube')
    case 'multiNetwork':
      if (network.facebookPage &&  network.facebookPage.name){
        return network.facebookPage.name
      } else if (network.instagramUrl){
        return network.instagramUrl
      } else if (network.blogToken && network.blogToken.url) {
        return network.blogToken.url
      } else if (network.tiktokjson && network.tiktokjson.name) {
        return network.tiktokjson.url
      } else {
        return "N/A"
      }
    case 'Instagram':
      if (network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.userName){
        return network.facebookPage.instagramObject.userName
      } else if (network.facebookPage && network.facebookPage.instagramObject && !network.facebookPage.instagramObject.userName){
        return network.facebookPage.instagramObject.name
      } else if (network.instagramUrl){
        return network.instagramUrl
      }
      break;
    case 'Facebook':
      return network.facebookPage.name || 'Facebook'
    case 'TikTok':
      return network.tiktokjson.name || 'TikTok'
    default:
      break;
  }
}

getNetworkColorForInfluenceur(page: any): any {
  if (page.facebookPage && page.facebookPage.name 
    && page.facebookPage.instagramObject && page.facebookPage.instagramObject.id){
      return "#8a3ab9"
  } else if (page.instagramUrl){
    return "#8a3ab9"
  } else if (page.youtubePage && (page.youtubePage.access_token || page.youtubePage.url)){
    return "#cc181e"
  } else if (page.tiktokjson && page.tiktokjson.url){
    return "black"
  } else if (page.blogToken && page.blogToken.url){
    return "#fccc63"
  } else {
    if (page.facebookPage && page.facebookPage .token){
      return "#5285fb"
    }
    return "black"
  }
}

getNetworkIconForInfluenceur(page: any): any {
  if (page.facebookPage && page.facebookPage.name 
    && page.facebookPage.instagramObject && page.facebookPage.instagramObject.id){
      return "fa fa-instagram"
  } else if (page.instagramUrl){
    return "fa fa-instagram"
  } else if (page.youtubePage && (page.youtubePage.access_token || page.youtubePage.url)){
    return "fa  fa-youtube "
  }  else if (page.blogToken && page.blogToken.url){
    return "fa fa-bold"
  } else {
    if (page.facebookPage && page.facebookPage .token){
      return "fa fa-facebook"
    }
    return "fa fa-instagram"
  }
}

gerUrlPathToAccountUser(network : any, type : string){
  switch (type) {
    case "Instagram":
      if (network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.userName){
        let nameAccount = network.facebookPage.instagramObject.userName.search("https://www.instagram.com/")
        if (nameAccount === -1 ){
          let insta = "https://www.instagram.com/" + network.facebookPage.instagramObject.userName
          
        window.open(insta, '_blank');
        }
      }
      break;
    case "Facebook":
      if (network.facebookPage &&  network.facebookPage.name){
        let fb = "https://www.facebook.com/" + network.facebookPage.id
        window.open(fb, '_blank');
      }
      break;
    case "Youtube":
      if (network.youtubePage && network.youtubePage.url){
        window.open(network.youtubePage.url, '_blank');
      }
      if (network.youtubePage && network.youtubePage.idChannel){
        let yt = "https://www.youtube.com/channel/" + network.youtubePage.idChannel
        window.open(yt, '_blank');
        
      }
      break;
    case "Blog":
      if (network.blogToken && network.blogToken.url){
        return network.blogToken.url
      }
      break;
    case "InstaUrl":
      if (network.instagramUrl){
        window.open(network.instagramUrl, '_blank');
        return 
      }
      break;
    case "Tiktok":
      if (network.tiktokjson && network.tiktokjson.url){
        return network.tiktokjson.url
      } 
      break;
    default:
      break;
  }
}

getNetwork(page: any): string {
  if (page.facebookPage && page.facebookPage.name 
    && page.facebookPage.instagramObject && page.facebookPage.instagramObject.id){
      return page.facebookPage.instagramObject.userName
  } else if (page.instagramUrl){
    return page.instagramUrl
  } else if (page.tiktokjson && page.tiktokjson.url){
    return page.tiktokjson.url
  }else if (page.youtubePage && (page.youtubePage.access_token || page.youtubePage.url)){
    return page.youtubePage.followers ? page.youtubePage.followers : 0
  }  else if (page.blogToken && page.blogToken.url){
    return page.blogToken.url
  } else {
    if (page.facebookPage && page.facebookPage.token){
      return page.facebookPage.followers
    }
    return 'N/A'
  }
  
}

getFollowersForNetwork(page: any): number {
  if (page.facebookPage && page.facebookPage.name 
    && page.facebookPage.instagramObject && page.facebookPage.instagramObject.id){
      return page.facebookPage.instagramObject.followers
  } else if (page.facebookPage && page.facebookPage .token){
        return page.facebookPage.followers ? page.facebookPage.followers : 0
  } else {
    return page.followers ? page.followers : 0
  }
  
}

getPageSizeForNetwork(network: any, platformeType: any): number {
  switch (platformeType) {
    case 'TikTok' :
      return 0
    case 'Instagram' :
      return  network.instagramUrl ? 0 : network.facebookPage.instagramObject.followers || 0   
    case 'Facebook' :
    return network.facebookPage.followers || 0
    case 'Youtube' :
      return network.youtubePage.followers || 0
    case 'multiNetwork' :
      if (network && network.facebookPage && network.facebookPage.instagramObject && network.facebookPage.instagramObject.followers){
        return network.facebookPage.instagramObject.followers
      } else if (network && network.facebookPage &&  network.facebookPage.followers){
        return 0
      } else {
        return 0
      }
    default:
      return 0
  }
  
}

getInfluenceurNetwork(url: any): any {
  return url.replace('https://www.instagram.com/', '')
}

async  initOffer(campagne : CampagneModel, user:any){
//   if (user.deliveryTarget < 0){
//     this.toastr.error('Delivery target is not defined');
//     return null;
//   }
//   if (!user.prixUnitaire){
//     this.toastr.error('Prix Unitaire is not defined');
//     return null;
// }
//   if (!user.revenueTarget){
//     this.toastr.error('Goal Revenue is not defined');
//     return null;
//   }

let newOffer : any =  {}
  newOffer.status = "New Offer"
  newOffer.campaignObject = campagne._id;
  newOffer.platformeType = campagne.platformeType;
  newOffer.revenueTarget = user.revenueTarget;
  newOffer.currentRevenue = 0;
  newOffer.currentDelivery = 0;
  newOffer.deliveryTarget = user.deliveryTarget;
  newOffer.prixUnitaire = user.prixUnitaire;
  newOffer.creationDate = new Date();
  newOffer.globalStatistics = {}
  newOffer.globalStatistics.Share = 0;
  newOffer.globalStatistics.View = 0;
  newOffer.globalStatistics.Reach = 0;
  newOffer.globalStatistics.Like = 0;
  newOffer.globalStatistics.Comment = 0;
  newOffer.globalStatistics.Share = 0;
  newOffer.globalStatistics.Replies = 0;
  newOffer.globalStatistics.VideoViews = 0
  newOffer.globalStatistics.Exits = 0
  newOffer.globalStatistics.TapsForward = 0
  newOffer.globalStatistics.TapsBack = 0
  newOffer.influencerObjects = {}
  newOffer.influencerObjects.userId = user.userId
  newOffer.influencerObjects.name = user.influencerInfo.firstname
  newOffer.influencerObjects.lastname = user.influencerInfo.lastname
  newOffer.pictures = campagne.pictures
  newOffer.files = campagne.files
  newOffer.mustInclude = campagne.mustInclude
  newOffer.objective = campagne.objective
  newOffer.saleModels = campagne.saleModels
  newOffer.mission = campagne.mission
  newOffer.campaignName = campagne.campaignName
  newOffer.startDate = campagne.startDate
  newOffer.endDate = campagne.endDate
  newOffer.projectDescription = campagne.projectDescription
if (user.facebookPage && user.facebookPage.token){
  if (user.facebookPage && user.facebookPage.instagramObject && user.facebookPage.instagramObject.id){
    newOffer.influencerObjects.facebookPage = user.facebookPage
    newOffer.influencerObjects.userIdxNetwork = user.userId + user.facebookPage.instagramObject.id;
  } else {
    newOffer.influencerObjects.facebookPage = user.facebookPage
    newOffer.influencerObjects.userIdxNetwork = user.userId + user.facebookPage.id;
  }
} else if (user.youtubePage){
  newOffer.influencerObjects.youtubePage = user.youtubePage
  newOffer.influencerObjects.userIdxNetwork = user.userId + (user.youtubePage.idChannel ? user.youtubePage.idChannel : user.youtubePage.url);
} else if (user.instagramUrl){
  newOffer.influencerObjects.instagramUrl = user.instagramUrl
  newOffer.influencerObjects.userIdxNetwork = user.userId + user.instagramUrl;
} else if (user.blogToken && user.blogToken.url){
  newOffer.influencerObjects.blogToken = {}
  newOffer.influencerObjects.blogToken.url = user.blogToken.url
  newOffer.influencerObjects.userIdxNetwork = user.userId + user.blogToken.url;
}
return newOffer
}

}
