export var data = {
    
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      toolbar: {
        show: false,
      }
    },
    chartOneValue: {
      type: "bar",
      stacked: false,
      height: 350,
      toolbar: {
        show: false,
      }
    },
    fill: {
      colors: ['#4bdbaa']
    },
    fillReach: {
      colors: ['#5bb2ee']
    },
    fillEngagement: {
      colors: ['#dc2626', '#fff408'],
      
    },
    fillEngagementReels: {
      colors: ['#dc2626', '#cc5216', '#ff671b', '#ffe4e2', '#fffbf3'],
    },
    colors:['#4bdbaa'],
    colorsReach:['#5bb2ee'],
    colorsEngagement:['#dc2626', '#fff408'],
    colorsEngagementReels:['#dc2626', '#cc5216', '#ff671b', '#ffe4e2', '#fffbf3'],
    stroke: {
      curve: 'smooth',
      width: 2.2,
      lineCap: 'butt',
    },
    
    dataLabels: {
      enabled: false,
      maxItems: 365,
      style: {
        colors: ['#565656']
      },
    },
    title: {
      style: {
        colors: ["#5bb2ee", '#5bb2ee', '#4bdbaa']
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: '70%',
        maxWidth: 1,
        align: 'left',
        dataLabels: {
          position: 'bottom', // top, center, bottom
        },
      }
    },
    
    chartEngagement: {
      type: "bar",
      height: 350,
      offsetX: 0,
      stacked: false,
      toolbar: {
        show: false,
      }
    },
    // xaxis: {
    //   min: 10,
    // }
   
  };
