import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import {toast} from 'src/app/model/toast'
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css', '../../dialog/facebook-dialog/facebook-dialog.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class SignupComponent implements OnInit {
  urlFacebook: string = "/api/facebook";
  urlFacebookWithout: string = "/api/facebook/without";
  hide = true;
  toast = toast
  hideconfirm = true;
//  sitekey: string = "6LcD08AZAAAAAFSbewphRrwVBpLjbe6phI2g_d-P";
  sitekey: string = "6LdxjxEgAAAAABTzFuu51-1WyHtXE6fP-6UcSVXD";
  agree  = false;
  captchaGroup: FormGroup;
  captchaForm = new FormControl("");
  succesCaptcha: boolean = false;
  login: FormGroup;

  constructor(
    private fb: FormBuilder, private service: ApiService,
    private toastr: ToastrService,
    private router: Router,
    ) {
      this.login = fb.group({
        username: new FormControl('',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$")]),
        password: ['', Validators.required],
        confirmpassword: ['', Validators.required],
      })
    //this.router.navigate(['/maintenance']);
    this.captchaGroup = fb.group({
      captchaForm: this.captchaForm
    })
  }


  
  submit(): void {
    if (this.agree == false){
      this.toastr.error(this.toast.acceptThermAndCondition)
      return
    }
    if (this.login.value['password'] != this.login.value['confirmpassword']){
      this.toastr.error(this.toast.passwordNotMatch)
      return
    }
    if (this.login.valid == true){
      this.service.singupInf(this.login.value).subscribe(passportResp => {
        if (passportResp.err){
          switch (passportResp.err) {
            case "User already exists":
              this.toastr.error(this.toast.userExist)
              //this.router.navigate(['/login']);
              break;
          
            default:
              this.toastr.error(passportResp.err)
              break;
          }
          
        } else if (passportResp.resp && passportResp.resp === "admin"){
          this.router.navigate(['/home-admin']);
        } else if (passportResp.resp && passportResp.resp === "influencer"){
          window.location.href = this.urlFacebookWithout
        }
      })
    }
    
  };

  handleSuccess(event: string) {
    this.succesCaptcha = true;
  }

  facebookConnect() {
    if (this.agree == false){
      this.toastr.error(this.toast.acceptThermAndCondition)
      return
    }
    window.location.href = this.urlFacebook
    //this.succesCaptcha ? window.location.href = this.urlFacebook : this.toastr.error('You need first to tell us you are not a robot 😎', 'Welcome');
  }

  handleReset() {
    this.succesCaptcha = false;
  }
  

  handleExpire() {
    this.succesCaptcha = false;
  }

  conditionAccept() {
    this.agree = !this.agree;
  }

  ngOnInit(): void {
  
  }

}
