<body >
    <div class="successhead">
        <div class="main" >
            <div class="container-fluid d-flex justify-content-center" >
                <div class="col-xs-12 text-center">
                    <h1 *ngIf="await == false" class="mx-auto my-0 text-uppercase"><mark style="background-color: black;opacity:1;letter-spacing:2px;font-size:20px;">Congratulations, your registration is now complete !</mark></h1>
				    <h2 class=" mx-auto mt-2 text-uppercase"><b style="font-size:18px;">You will soon receive an Email confirming the validation of your account.</b></h2>
				    <h2 class=" mx-auto mt-2 text-uppercase "><b style="font-size:18px;">⚠️ CHECK YOUR SPAMS JUST IN CASE 🤗 🧐</b></h2>
                </div>
            </div>
        </div>
    </div>
</body>
