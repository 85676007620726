<!DOCTYPE html>
<html lang="en">

  <head>

    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>INFLUENCE LAB</title>
  </head>
    <body>
    <app-header></app-header>

      <section class="projects-section bg-light" style="margin-top:5%;">
        <div class="container">
        <div>
              <h1 class="text-center logo">MENTIONS LEGALES INFLUENCELAB.CO</h1>
              <li class="textcard mt-4"><b>Editeur du site influencelab.co :</b></li>
              <p class=" textcard  mt-4">L’éditeur du site est la société INFLUENCE LAB, société par actions simplifiée au capital social de 10 000,00 euros, inscrite au Registre du Commerce et des Sociétés de Paris sous le numéro 842 653 602, et ayant son siège social 90, rue Raynouard à Paris 16e (75016) (« INFLUENCE LAB »).</p>
              <p class=" textcard  mt-4">INFLUENCE LAB est représentée par sa Présidente en exercice, Madame Anouck ROBERTY.</p>
              <p class=" textcard  mt-4">Numéro de téléphone : + 33 6 11 62 92 03</p>
              <li class="textcard mt-4"><b>Directeur de la publication :</b></li>
              <p class=" textcard  mt-4">Le directeur de la publication, responsable des contenus publiés sur le site, est Madame Anouck ROBERTY, en sa qualité de Présidente d’INFLUENCE LAB.</p>
              <p class=" textcard  mt-4">Le directeur de la publication peut être contacter par : </p>
              <li class="textcard mt-4">E-mail : anouck@influencelab.co</li>
              <li class="textcard mt-4">Par voie postale INFLUENCE LAB – 90 rue Raynouard – 75016 PARIS</li>
              <li class="textcard mt-4"><b>Hébergeur du site influencelab.co :</b></li>
              <p class=" textcard  mt-4">Le site est hébergé par la société 1&1 Internet IONOS S.A.R.L., ayant son siège social 7, place de la Gare, BP70109, 57200 Sarreguemines C </p>
              <p class=" textcard  mt-4">Numéro de téléphone : 0970 808 911</p>
          </div>
        </div>
      </section>

      <!-- Footer -->
      <footer class="bg-black small text-center text-white-50">
        <div class="container-fluid">
          <span>Copyright &copy; Influence Lab</span>
          <a href="/legal">
            <span> Legal</span>
          </a>
        </div>
      </footer>
  </body>
</html>
