import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { Observable, throwError } from 'rxjs';


@Injectable()
export class MediaCampagneService {
  public selectedArrayFile: any = [];
  public selectedArrayPicture: any = [];
  arrayToShowPicture: any = [];
  arrayToShowFile: any = [];
  jsonArr : any = null
  constructor(
    public ref: ChangeDetectorRef ,
    public api: ApiService,
  ) { }
  ///////////////////////////PICTURES//////////////////////////////
  checkUrlPath(elem : string){
    if (elem.includes('/api/picture/Images/') == true){
      return elem
    } else {
      return '/api/picture/Images/' + elem
    }
  }


  getExtentionImg(file : any){
    switch (file.type) {
      case "image/png":
        return true      
      case "image/jpeg":
        return true      
      default:
        return false
    }
  }
  

  
///////////////////////////////////////FILES////////////////////////////////////////////////
public getselectedFiles(){
  if (this.selectedArrayFile.length <= 0){
    return null
  }
  const fd = new FormData();
  for(let selectedFile of this.selectedArrayFile){
    fd.append('file', selectedFile, selectedFile.name);
  }
	return fd
}

selectFile(event: any) {	
  if(!event.target.files[0] || event.target.files.length == 0) {
		  return ;
	  }
    let check = this.getIfElemExist(this.selectedArrayFile, event.target.files[0])
    if (check == false){
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.getExtentionFile(event.target.files[0])
      if (extention.extention != false){
        this.selectedArrayFile.push(extention)
        this.ref.detectChanges()
      }
    }
      reader.readAsDataURL(event.target.files[0]); 
  }
}
getExtentionFile(file : any){
  if (file.name.includes(".docx")){
    file.extention = "docx"
    
  } else if (file.name.includes('.xlsx')){
    file.extention = 'xlsx'
    
  } else if (file.name.includes('.xls')){
      file.extention = 'xls'
      
  } else if (file.name.includes('.doc')){
      file.extention = 'doc'
      
    }else if (file.name.includes('.pptx')){
      file.extention = 'pptx'
      
    }else if (file.name.includes('.odt')){
      file.extention = 'odt'
      
    }else if (file.name.includes('.pdf')){
      file.extention = 'odt'
    } 
    else {
      file.extention = false
    }
  return file
}

getExtentionFileToShow(file : any){
  switch (file.extention) {
    case "pptx":
      return '/assets/images/iconInfluenceLab/ppt.png'
    case "docx":
      return '/assets/images/iconInfluenceLab/word.png'
    case "doc":
      return '/assets/images/iconInfluenceLab/word.png'
    case "xls":
      return '/assets/images/iconInfluenceLab/exel.png'
    case "xlsx":
      return '/assets/images/iconInfluenceLab/exel.png'
    case "odt":
      return '/assets/images/iconInfluenceLab/pdf.png'
    default:
      return '/assets/images/iconInfluenceLab/word.png'
  }
}

deleteFile(img : any, index : number){
  this.selectedArrayFile.splice(index, 1)
}

getIfElemExist(array : any, elem :any){
  var bool = false
  array.forEach((pic: any) => {
    if (elem.name === pic.name && elem.size === pic.size){
      bool = true
    }    
  });
  return bool
}
}
