export var data = {
    states: {
        normal: {
            filter: {
                type: 'none',
                value: 0,
            }
        },
        hover: {
            filter: {
                type: 'none',
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'darken',
                value: 0.35,
            }
        },
    },
    stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: "undefined",
        width: 2,
        dashArray: 0,      
    },
    chart: {
        width: 130,
        type: "pie"
    },
    chartdonut: {
        height: 180,
        type: "radialBar",
        
    },
    plotOptions: {
        radialBar: {
          hollow: {
            size: "70%"
          }
        }
      },
      legend: {
        show: false,
        
    },
    legendReach: {
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center', 
        floating: true,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        
        inverseOrder: false,
        offsetX: 0,
        offsetY: 2,
    },
    dataLabels: {
        enabled: false,
    },
    dataLabelsPie: {
        enabled: false,
        formatter: function (val: number) {
            return val + "%"
          },
    },
    colors: ['#006579', '#4bdbaa', "#cce386", "#e0de3d", "#f1b306", "#fc7304", "#ff3300", "#8d0000","#aa0033","#ff004c","#58013b","#7d008d", "#26008d"],
    colorsNA: ['#d0d0d0', "#d0d0d0"],
}
