export var toast = {
    //New Campaign:
    campaignCreate: "Your campaign has been successfully created 😎",
    // Campaign Detail
    campaignStatusModified: "Modification saved",
    campaignNoStatus: "Please select status",
    campaignEdit: "You can edit this campaign 😎",
    campaignDuplic: "You can duplicate this campaign 😎",
    campaignModified: "Modification saved",
    offerManualInput: "Offer has been correctly set to manual mode",
    offerManualInputOff: "Offer has been correctly set to manual mode",
    //Influenceur Selector
    notDefine: "Is not defined",
    sendOffer: " Your Offer has been sent",
    enterElement: "Enter at least one keyword",
    campaignNotUserselect: "Please select at least 1 object",
    //Campagne Dashboard
    contactaffilied: "Modification saved",
    contactNotaffilied: "Object deleted",
    //Create Company card
    companyCardCreate: "Company  has been created  😎",
    //Modif Company Card
    companyCardModified: "Modification saved",
    //Create Contact Card
    contactCreated: "Contacts has been created  😎",
    InvalidEntry: "Invalid entry",
    //Modif Contact Card
    modfiContact: "Modification saved",
    //Saisie Statitistiques
    uploadNewContent: "Now you can send your statistics for ", // content type
    modifyInvalidValue: "You cannot modify your content with a invalid value",
    modifyContent:"Modification saved",
    addScreenshot: "Please add a screenshot",
    addnewContent: "Your statistics have been well received",
    selectContentTypebeforeSendScreenShot: "Please Select Content Type before set screenshot",
    
    //Offer Detail
    changeStatus:"Please select status",
    statusmodified:"Modification saved",
    modificationMessageOffer: "Modification saved",
    InvalidEntryMessageOffer: "Invalid entry",
    editmessageOffer : "Now you can edit your message 😎",
    deletemessage : "Object deleted",
    dasboardCreate: "Dashboard successfully created.",
    nocontentselected: "No content selected",
    contentalreadyused: "This content has already been selected",
    //Offer Influenceur
    offerAccept: " You can now create authentic content! Then, submit it to us for review', 'Awesome! We are glad to work together again!",
    //Inscription Influenceur
    chooseTopics: "Please choose between 1 and 6 topics",
    chooseKeywords: "Please enter at least one keyword",
    urlNetworkBlog: " Object successfully added ",
    urlNetworkIg: " Object successfully added ",
    urlNetworkTikTok: " Object successfully added ",
    urlNetworkYoutube: " Object successfully added ",
    addPage: "Please add at least one Social Media Account",
    inscriptionComplete:" Your registration is now complete! Check your emails (and spams) regularly for your confirmation.",
    deleteNetwork: "Object deleted",

    //Detail  influenceur cote admin
    engagementRate: "Modification saved",
    validUser:" Modification saved", // nom du mail + Vaildation saved
    userRating: "Modification saved",
    // influenceur modif account
     compteModified: "Modification saved.",
     editProfil: "Now you can edit your account 😎",
    // admin detail inf
    validNetwork: "Modification saved",
    userDelete: " Object  deleted",
    // img & file
    errorImg: " It seems that you selected a wrong file format.",
    invoiceFileType:"Invalid format",
    invoiceNot:"Please upload your Invoice",
    RibNot:"Please upload your RIB",
    invoiceFileReceive:"Invoice successfully sent.",
    // inscription
    acceptThermAndCondition : "Please accept the terms and conditions",
    //connexion Login
    userExist :'This email address is already used for a InfluenceLab account. You can login with this address or reset your password.',
    accepteCondition: "Please accept the terms and conditions",
    passwordNotMatch: 'Invalid password ',
    forgotEmailAdress: "Please set adress email",
    linkresetPasswordUsed : "This link has already been used",
    //clipboard
    textCopied: "Text copied to clipboard",
}
