<!-- <html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main">

		<div class="auth-container">
			<div flex-center>
				<a href="/"><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
						class="img-fluid" /></a>
			</div>
			<div flex-center>
                <h3 class="text-normal mt-3 mb-3 text-center" style="color: white;">Welcome to our community</h3>
			</div>
            <div flex-center>
                <h3 class="text-normal text-center mb-4" style="color: white;">Ready to sign up ? :)</h3>
			</div>
			<div class="form-container">
				<div class="clearfix text-center">
					<button (click)="facebookConnect()" type="button" style="background-color:#3b5998; color:white;"
						class="form-control btn btn-block mb5"><i class="fa fa-facebook"
							style="margin-right: 15px;"></i>Login With Facebook</button>
					
				</div>
                <div class="mt-3">
                    <label style="font-weight: 500;">
                        <input type="checkbox" class="mr-2 " (click)="conditionAccept()" >
                        <span style="color: white;">I agree to the <a target="_blank" style="color: #a7a7a7;" href="/legal">terms and conditions</a></span>
                    </label>
                </div>
                <div class="mb-3">
                    <label style="font-weight: 500;">
                        <input style="background-color:black;" class="mr-2 " type="checkbox" ng-model="User.newsletter" name="newsletter">
                        <span style="color: white;">Receive updates from our site</span>
                    </label>
                </div>
				<form [formGroup]="captchaGroup">
					<ngx-recaptcha2 #captchaElem [siteKey]="sitekey" formControlName="captchaForm"
						(success)="handleSuccess($event)" (reset)="handleReset()" (expire)="handleExpire()">
					</ngx-recaptcha2>
				</form>
                <div flex-center>
                    <p class="text-account mt-5" style="color: white;">Already have an account ? <a style="color: #a7a7a7;"
                        href="/login">Just log in.</a></p>
                </div>
                
			</div>
		</div>
	</div>


</body>

</html> -->
<!-- <!DOCTYPE html>
<html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main mastheadd">

		<div class="auth-container">
			<div flex-center>
				<a href="/"><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
						class="img-fluid" /></a>
			</div>
			<div flex-center>
                <h3 class="text-normal mt-3  text-center" style="color: white;">Welcome to our community</h3>
			</div>
            <div flex-center>
                <h3 class="text-normal text-center mb-3" style="color: white;">Ready to sign up ? :)</h3>
			</div>
			<div class="mt-3" flex-center>
				<button (click)="facebookConnect()" type="button" style="background-color:#3b5998; color:white;"
					class="form-control btn btn-lg mb5"><i class="fa fa-facebook"
					style="margin-right: 15px;"></i>Login With Facebook</button>
			</div>
			<p class="text-account text-center mt-3" style="color: white;">Or continue with</p>
			<div class="form-container">
				<form [formGroup]="login">
				<div class="row input-row  " >
						<div class="col-lg-12  col-12 col-md-4 ">
							<mat-label style="color: white;" >Email *</mat-label>
						</div>
						<div class="col-lg-12 col-12  col-md-8 ">
							<mat-form-field >
								<input  matInput placeholder=""  formControlName="username" >
							</mat-form-field>
						</div>
				</div>
				<div class="row input-row  " >
						<div class="col-lg-12  col-12 col-md-4 ">
							<mat-label style="color: white;">Password *</mat-label>
						</div>
						<div class="col-lg-12 col-12  col-md-8 ">
							<mat-form-field >
								<input type="password"  matInput placeholder="" [type]="hide ? 'password' : 'text'" formControlName="password"  >
								<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
							</mat-form-field>
						</div>
				</div>
				<div class="row input-row  " >
						<div class="col-lg-12  col-12 col-md-4 ">
							<mat-label style="color: white;">Confirm password *</mat-label>
						</div>
						<div class="col-lg-12 col-12  col-md-8 ">
							<mat-form-field >
								<input   type="password" matInput placeholder="" [type]="hideconfirm ? 'password' : 'text'" formControlName="confirmpassword" >
								<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hideconfirm = !hideconfirm">{{hideconfirm ? 'visibility_off' : 'visibility'}}</mat-icon>							</mat-form-field>
						</div>
				</div>
					<div class="text-account text-right "style="color: white;">
						<input type="checkbox" class="mr-2 " (click)="conditionAccept()" >
						<span style="color: white;">I agree to the <a target="_blank" style="color: #a7a7a7;" href="/legal">terms and conditions</a></span>
					</div>
				
				<div flex-center >
					<form [formGroup]="captchaGroup">
						<ngx-invisible-recaptcha #captchaElem
						  [siteKey]="sitekey"
						  (reset)="handleReset()"
						  (success)="handleSuccess($event)"
						  [useGlobalDomain]="false"
						  [ngModel]="succesCaptcha"
						  [ngModelOptions]="{ standalone: true }">
						</ngx-invisible-recaptcha>
					  </form>
				</div>
				
                    <div flex-center class="center-block mt-4">
						<button type="submit" (click)="submit()" class="btn btncustom btn-lg btn-light text-uppercase">Signup</button>
					</div>
                </form>
			</div>
				<p class="text-account text-center mt-3" style="color: white;">Already have an account ? <a style="color: #a7a7a7;"
					href="/login">Just log in.</a></p>
		</div>
	</div>


</body>

</html> -->

<!-- <!DOCTYPE html>
<html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main mastheadd">

		<div flex-center class="container-fluid">
			<div class="widht-size">
				<div flex-center>
					<a><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
							class="img-fluid" /></a>
				</div>
				<div flex-center>
					<h3 class="text-normal mt-3  text-center" style="color: white;">Welcome to our community</h3>
				</div>
				<div flex-center>
					<h3 class="text-normal text-center mb-2" style="color: white;">Ready to sign up ? :)</h3>
				</div>
				<form >
					<div class="mt-3 row input-row">
						<div class="col-md-12 col-lg-12 col-12 col-sm-12">
							<div class="col-md-12 col-lg-12 col-12 col-sm-12 display-mid">
								<button (click)="facebookConnect()" type="button" style="background-color:#3b5998; color:white;"
								class="form-control btn btn-lg"><i class="fa fa-facebook"
								style="margin-right: 15px;"></i>Login With Facebook</button>
							</div>
						</div>
					</div>
				</form>
				<p class="text-account text-center mt-4" style="color: white;">Or continue with</p>
				<form [formGroup]="login">
					<div class="row input-row" >
						<div class="col-md-12 col-lg-12 col-12 col-sm-12">
							<div class=" display-mid">
								<div class="col-lg-4 col-12 col-md-4">
									<mat-label  style="color: white;" >Email *</mat-label>
								</div>
								<div class="col-lg-8 col-12 col-md-8">
									<mat-form-field >
										<input  matInput placeholder=""  formControlName="username" >
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<div class="row input-row  " >
						<div class="col-md-12 col-lg-12 ">
							<div class="display-mid">
								<div class="col-lg-4 col-12 col-md-4">
									<mat-label style="color: white;">Password *</mat-label>
								</div>
								<div class="col-lg-8 col-12 col-md-8">
									<mat-form-field >
										<input type="password"  matInput placeholder="" [type]="hide ? 'password' : 'text'" formControlName="password"  >
										<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<div class="row input-row  " >
						<div class="col-md-12 col-lg-12">
							<div class="display-mid ">
								<div class="col-lg-4 col-12 col-md-4 col-sm-12">
									<mat-label style="color: white;">Confirm password *</mat-label>
								</div>
								<div class="col-lg-8 col-12 col-md-8 ">
									<mat-form-field >
										<input   type="password" matInput placeholder="" [type]="hideconfirm ? 'password' : 'text'" formControlName="confirmpassword" >
										<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hideconfirm = !hideconfirm">{{hideconfirm ? 'visibility_off' : 'visibility'}}</mat-icon>							
									</mat-form-field>
								</div>
							</div>
							
						</div>
					</div>
					<div class="row input-row  " >
						<div class="col-md-12 col-lg-12">
							<div class="text-account text-right col-md-12 col-lg-12" style="color: white;">
								<input type="checkbox" class="mr-2 " (click)="conditionAccept()" >
								<span style="color: white;">I agree to the <a target="_blank" style="color: #a7a7a7;" href="/legal">terms and conditions</a></span>
							</div>
						</div>
					</div>
					
					<div flex-center >
						<form [formGroup]="captchaGroup">
							<ngx-invisible-recaptcha #captchaElem
							  [siteKey]="sitekey"
							  (reset)="handleReset()"
							  (success)="handleSuccess($event)"
							  [useGlobalDomain]="false"
							  [ngModel]="succesCaptcha"
							  [ngModelOptions]="{ standalone: true }">
							</ngx-invisible-recaptcha>
						  </form>
					</div>
					
						<div flex-center class="center-block mt-4">
							<button type="submit" (click)="submit()" class="btn btncustom btn-lg btn-light text-uppercase">Signup</button>
						</div>
					</form>
					<p class="text-account text-center mt-3" style="color: white;">Already have an account ? <a style="color: #a7a7a7;"
						href="/login">Just log in.</a></p>
			</div>
			
		</div>
	</div>


</body>

</html> -->

<!DOCTYPE html>
<html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main mastheadd">

		<div flex-center class="container-fluid mt-5">
			<div class="widht-size">
				<div flex-center>
					<a><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
							class="img-fluid" /></a>
				</div>
				<div flex-center>
					<h3 class="text-normal mt-3  text-center" style="color: white;">Welcome to our community</h3>
				</div>
				<div flex-center>
					<h3 class="text-normal text-center mb-2" style="color: white;">Ready to sign up ? :)</h3>
				</div>
				<form >
					<div class="mt-4 row ">
						<div class="col-md-12 col-lg-12 col-12 col-sm-12 display-mid">
							<button (click)="facebookConnect()" type="button" style="background-color:#3b5998; color:white;"
							class="form-control btn btn-lg"><i class="fa fa-facebook"
							style="margin-right: 15px; font-size: 20px;"></i>Login With Facebook</button>
						</div>
					</div>
				</form>
				<div>
					<p class="text-account text-center mt-4" style="color: white;">Or continue with</p>
				</div>
				<form [formGroup]="login">
					<div class="inputRow">
						<div class="row newRow" >
									<div class="col-lg-4 col-12 col-md-4">
										<mat-label  style="color: white;" >Email *</mat-label>
									</div>
									<div class="col-lg-8 col-12 col-md-8">
										<mat-form-field >
											<input  matInput placeholder=""  formControlName="username" >
										</mat-form-field>
									</div>
						</div>
						<div class="row   newRow" >
									<div class="col-lg-4 col-12 col-md-4">
										<mat-label style="color: white;">Password *</mat-label>
									</div>
									<div class="col-lg-8 col-12 col-md-8">
										<mat-form-field >
											<input type="password"  matInput placeholder="" [type]="hide ? 'password' : 'text'" formControlName="password"  >
											<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
										</mat-form-field>
									</div>
						</div>
						<div class="row   newRow" >
									<div class="col-lg-4 col-12 col-md-4 col-sm-12">
										<mat-label style="color: white;">Confirm password *</mat-label>
									</div>
									<div class="col-lg-8 col-12 col-md-8 ">
										<mat-form-field >
											<input   type="password" matInput placeholder="" [type]="hideconfirm ? 'password' : 'text'" formControlName="confirmpassword" >
											<mat-icon matSuffix mat-icon-button aria-label="Clear" (click)="hideconfirm = !hideconfirm">{{hideconfirm ? 'visibility_off' : 'visibility'}}</mat-icon>							
										</mat-form-field>
									</div>
						</div>
						<div class="row   newRow" >
							<div class="col-md-12 col-lg-12">
								<div class="text-account text-right col-md-12 col-lg-12" style="color: white;">
									<input type="checkbox" class="mr-2 " (click)="conditionAccept()" >
									<span style="color: white;">I agree to the <a target="_blank" style="color: #a7a7a7;" href="/legal">terms and conditions</a></span>
								</div>
							</div>
						</div>
						
						<div flex-center >
							<form [formGroup]="captchaGroup">
								<ngx-invisible-recaptcha #captchaElem
								  [siteKey]="sitekey"
								  (reset)="handleReset()"
								  (success)="handleSuccess($event)"
								  [useGlobalDomain]="false"
								  [ngModel]="succesCaptcha"
								  [ngModelOptions]="{ standalone: true }">
								</ngx-invisible-recaptcha>
							  </form>
						</div>
						
							<div flex-center class="center-block mt-4">
								<button type="submit" (click)="submit()" class="btn btncustom btn-lg btn-light text-uppercase">Signup</button>
							</div>
					</div>
					
					</form>
					<p class="text-account text-center mt-3" style="color: white;">Already have an account ? <a style="color: #a7a7a7;"
						href="/login">Just log in.</a></p>
			</div>
			
		</div>
	</div>


</body>

</html>