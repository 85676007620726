import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-success-register',
  templateUrl: './success-register.component.html',
  styleUrls: ['./success-register.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class SuccessRegisterComponent implements OnInit {
  await = false
  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService) {
      if (this.route.snapshot.params.toastr){
        this.await = true
        this.toastr.warning('YOUR ACCOUNT IS CURRENTLY REVIEWED', 'WAIT');
        
      }
     }

  ngOnInit(): void {
  }

}
