import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LazyLoadImageModule } from 'ng-lazyload-image';
//import { NgApexchartsModule } from 'ng-apexcharts';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import {ClipboardModule} from '@angular/cdk/clipboard'
import { ToastrModule } from 'ngx-toastr';
import {NgApexchartsModule} from 'ng-apexcharts';
//////////////////////////////////////////////////////////////////////////
import { CguComponent } from './legal/cgu/cgu.component';
import { ContratComponent } from './legal/contrat/contrat.component';
import { CookiesComponent } from './legal/cookies/cookies.component';
import { LegalComponent } from './legal/legal/legal.component';
import { MentionsComponent } from './legal/mentions/mentions.component';
//////////////////////////////////////////////////////////////////////////

import {MatSelectModule} from '@angular/material/select'; 
import {MatInputModule} from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule} from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field'; 
import {MatGridListModule} from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon'; 
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import { NgxChartsModule }from '@swimlane/ngx-charts';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog'; 
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgRatingBarModule } from 'ng-rating-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion'; 
import {MatSlideToggleModule} from '@angular/material/slide-toggle'; 

//////////////////////////////////////////////////////////////////////////
import { FooterComponent } from './component/footer/footer.component';
import { HeaderComponent } from './component/header/header.component';
//////////////////////////////////////////////////////////////////////////
import { AccueilComponent } from './edito/accueil/accueil.component';
import { SignupComponent } from './Inscription-Connexion/signup/signup.component';
import { BrandComponent } from './edito/brand/brand.component';
import { InfluencerComponent } from './edito/influencer/influencer.component'; 
import { HomeAdminComponent } from './home/home-admin/home-admin.component';
import { HomeInfluenceurComponent } from './home/home-influenceur/home-influenceur.component';
//////////////////////////////////////////////////////////////////////////
import { HeaderloggerComponent } from './component/headerlogger/headerlogger.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { SigninAdminComponent } from './Inscription-Connexion/signin-admin/signin-admin.component';
import { ErrorComponent } from './status/error/error.component';
import { ListCampagneComponent } from './campagne/listCampagne/listCampagne.component';
import { SuccessRegisterComponent } from './status/success-register/success-register.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FacebookDialogComponent } from './dialog/facebook-dialog/facebook-dialog.component';
import { InstagramDialogComponent } from './dialog/instagram-dialog/instagram-dialog.component';
import { InstagramInsightDialogComponent } from './dialog/instagram-insight-dialog/instagram-insight-dialog.component';
import { ListOfferComponent } from './offer/list-offer/list-offer.component';
import { DashboardCampagneComponent } from './campagne/dashboard-campagne/dashboard-campagne.component';
import { CampagneSelectorComponent } from './campagne/campagne-selector/campagne-selector.component';
import { CreateCampagneComponent } from './campagne/create-campagne/create-campagne.component';
import { DetailCampagneComponent } from './campagne/detail-campagne/detail-campagne.component';
import { CancelComponent } from './dialog/cancel/cancel.component';
import { ListComponent } from './influencer/list/list.component';
import { DetailInfluenceurAdminComponent } from './influencer/detail-influenceur-admin/detail-influenceur-admin.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DeletepageComponent } from './dialog/deletepage/deletepage.component';
import { OfferDetailAdminComponent } from './offer/offer-detail-admin/offer-detail-admin.component';
import { OfferDetailInfluenceurComponent } from './offer/offer-detail-influenceur/offer-detail-influenceur.component';
import { DuplicataPipe } from './pipe/duplicata.pipe';
import { ShowInstagramContenueComponent } from './dialog/show-instagram-contenue/show-instagram-contenue.component';
import { ShowYoutubeContenueComponent } from './dialog/show-youtube-contenue/show-youtube-contenue.component';
import { ShowFacebookContenueComponent } from './dialog/show-facebook-contenue/show-facebook-contenue.component';
import { RatioPictureComponent } from './component/ratio-picture/ratio-picture.component';
import { ListContactComponent } from './contact/list-contact/list-contact.component';
import { CreateContactComponent } from './contact/create-contact/create-contact.component';
import { ModifContactComponent } from './contact/modif-contact/modif-contact.component';
import { ModifCompanyComponent } from './company/modif-company/modif-company.component';
import { CreateCompanyComponent } from './company/create-company/create-company.component';
import { ListCompanyComponent } from './company/list-company/list-company.component';
import { ListOfferInfluenceurComponent } from './offer/list-offer-influenceur/list-offer-influenceur.component';
import { DetailProfilComponent } from './influencer/detail-profil/detail-profil.component';
import { InfluenceurReelsFormComponent } from './offer/influenceur-reels-form/influenceur-reels-form.component';
import { InfluenceurBillingComponent } from './influencer/influenceur-billing/influenceur-billing.component';
import { MatInputCommifiedDirective } from './directive/mat-input-commified.directive';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ShareCampagneOnContactUserComponent } from './dialog/share-campagne-on-contact-user/share-campagne-on-contact-user.component';
import { HttpErrorInterceptor } from './interceptor/http-error.interceptor';
import { SortDirective } from './directive/sort.directive';
import { AddMoreSocialNetworkComponent } from './dialog/add-more-social-network/add-more-social-network.component';
import { MajComponent } from './status/maj/maj.component';
import { LoginInfluenceurComponent } from './Inscription-Connexion/login-influenceur/login-influenceur.component';
import { InscriptionComponent } from './Inscription-Connexion/inscription/inscription.component';
import { FinishRegisterComponent } from './dialog/finish-register/finish-register.component';
import { ForgotPasswordComponent } from './Inscription-Connexion/forgot-password/forgot-password.component';
import { NopasswordComponent } from './dialog/nopassword/nopassword.component';
import { WithoutFbModalComponent } from './dialog/without-fb-modal/without-fb-modal.component';
import { RefuseContratComponent } from './dialog/refuse-contrat/refuse-contrat.component';
import { HomeContactComponent } from './user-contact/home-contact/home-contact.component';
import { ContactDetailedCampagneComponent } from './user-contact/contact-detailed-campagne/contact-detailed-campagne.component';
import { DashboardInfluenceurComponent } from './offer/dashboard-influenceur/dashboard-influenceur.component';
import { DashboardAdminYoutubeComponent } from './offer/dashboard-admin-youtube/dashboard-admin-youtube.component';
import { DashboardAdminComponent } from './offer/dashboard-admin/dashboard-admin.component';
import { ChoiceInvoiceComponent } from './dialog/choice-invoice/choice-invoice.component';
import { InvoiceAdminComponent } from './offer/invoice-admin/invoice-admin.component';
import { DelContentInfluenceurComponent } from './dialog/del-content-influenceur/del-content-influenceur.component';
import { ManageSocialNetworkAdminComponent } from './dialog/manage-social-network-admin/manage-social-network-admin.component';
import { InsightsProfileComponent } from './influencer/insights-profile/insights-profile.component';
import { OfferDataInputInfluenceurComponent } from './offer/offer-data-input-influenceur/offer-data-input-influenceur.component';
import { OfferDataInputAdminComponent } from './offer/offer-data-input-admin/offer-data-input-admin.component';
import { ShowOfferDetailsComponent } from './dialog/show-offer-details/show-offer-details.component';
import { DashboardYoutubeComponent } from './campagne/dashboard-youtube/dashboard-youtube.component';
import { PigeAdminComponent } from './pige/pige-admin/pige-admin.component';
import { AddFollowersComponent } from './dialog/add-followers/add-followers.component';
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
//////////////////////////////////////////////////////////////////////////
@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    FooterComponent,
    HeaderComponent,
    SignupComponent,
    BrandComponent,
    InfluencerComponent,
    CguComponent,
    ContratComponent,
    CookiesComponent,
    LegalComponent,
    MentionsComponent,
    HomeAdminComponent,
    HomeInfluenceurComponent,
    HeaderloggerComponent,
    SidebarComponent,
    SigninAdminComponent,
    ErrorComponent,
    ListCampagneComponent,
    SuccessRegisterComponent,
    FacebookDialogComponent,
    InstagramDialogComponent,
    InstagramInsightDialogComponent,
    ListOfferComponent,
    DashboardCampagneComponent,
    CampagneSelectorComponent,
    CreateCampagneComponent,
    DetailCampagneComponent,
    CancelComponent,
    ListComponent,
    DetailInfluenceurAdminComponent,
    DeletepageComponent,
    OfferDetailAdminComponent,
    OfferDetailInfluenceurComponent,
    DuplicataPipe,
    ShowInstagramContenueComponent,
    ShowYoutubeContenueComponent,
    ShowFacebookContenueComponent,
    RatioPictureComponent,
    ListContactComponent,
    CreateContactComponent,
    ModifContactComponent,
    ModifCompanyComponent,
    CreateCompanyComponent,
    ListCompanyComponent,
    ListOfferInfluenceurComponent,
    DetailProfilComponent,
    InfluenceurReelsFormComponent,
    InfluenceurBillingComponent,
    MatInputCommifiedDirective,
    ShareCampagneOnContactUserComponent,
    SortDirective,
    AddMoreSocialNetworkComponent,
    MajComponent,
    LoginInfluenceurComponent,
    InscriptionComponent,
    FinishRegisterComponent,
    ForgotPasswordComponent,
    NopasswordComponent,
    WithoutFbModalComponent,
    RefuseContratComponent,
    HomeContactComponent,
    ContactDetailedCampagneComponent,
    DashboardInfluenceurComponent,
    DashboardAdminYoutubeComponent,
    DashboardAdminComponent,
    ChoiceInvoiceComponent,
    InvoiceAdminComponent,
    DelContentInfluenceurComponent,
    ManageSocialNetworkAdminComponent,
    InsightsProfileComponent,
    OfferDataInputInfluenceurComponent,
    OfferDataInputAdminComponent,
    ShowOfferDetailsComponent,
    DashboardYoutubeComponent,
    PigeAdminComponent,
    AddFollowersComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    MatTabsModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxCaptchaModule,
    MatInputModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    ClipboardModule,
    MatDividerModule,
    MatGridListModule,
    MatFormFieldModule,
    MatIconModule,
    MatSidenavModule,
    ToastrModule.forRoot({timeOut: 10000,positionClass: 'toast-top-full-width'}),
    MatListModule,
    MatAutocompleteModule,
    MatDialogModule,
    MaterialFileInputModule,
    Ng2SearchPipeModule,
    NgRatingBarModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSlideToggleModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgApexchartsModule,
    LazyLoadImageModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  entryComponents:[InstagramInsightDialogComponent, InstagramDialogComponent, FacebookDialogComponent, DeletepageComponent, ShowInstagramContenueComponent, ShowYoutubeContenueComponent,ShowFacebookContenueComponent, ShareCampagneOnContactUserComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }