 <html>
    <body>
      <div class="page container">
        <a *ngIf="show" [routerLink]="['/detailOfferAdmin/', dashboardData.Offer._id]" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>
        <mat-card  class="mt-1" *ngIf="show">
          <div class="title-cardDashboard" >
            <div>
                <mat-label class="title"><i>Dashboard</i></mat-label>
            </div>
            <div>
              <mat-label class="title">🤗  {{dashboardData.Offer.campaignName}} 🚀</mat-label>
          </div>
        </div>
        <mat-divider ></mat-divider>
        <div class="title-card text-center mt-5 mb-3">
          <div class="col-lg-12  display-mid">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3 text-center" >
                  <label class="col-12 labelFont text-center">Revenue Target<span class="ml-2 fa fa-bullseye"></span></label>
                  <div  class=" text-center fileText">
                      {{dashboardData.Offer.revenueTarget}}
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-6 col-sm-3 col-3">
                  <label class="col-12 labelFont text-center">Campaign Date<span class="ml-2 fa fa-calendar"></span></label>
                  <div class="fileText text-center">
                      {{dashboardData.Offer.startDate | date:'yyyy/MM/dd'}} - {{dashboardData.Offer.endDate | date:'yyyy/MM/dd'}}
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                  <label class="col-12 labelFont text-center">Mission<span class="ml-2 fa fa-rocket"></span></label>
                  <div class="fileText text-center">
                      {{dashboardData.Offer.mission}}
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                  <label class="col-12 labelFont text-center">Social Network<span class="ml-2 fa fa-share-alt-square"></span></label>
                  <div class=" text-center">
                      <button (click)="showProfileInf()" type="button" [ngStyle]="{'background-color':fctBasic.getColorByPlatformeType(dashboardData.Offer.platformeType)}" style="color:white" class="btn btn-sm shadow-none ">
                      <i  *ngIf="dashboardData.Offer.platformeType == 'Facebook'" class="fa mr-2 left fa-facebook "></i>
                      <i *ngIf="dashboardData.Offer.platformeType == 'Instagram'" class="fa mr-2 left fa-instagram "></i>
                      <i *ngIf="dashboardData.Offer.platformeType == 'Youtube'" class="fa mr-2 left fa-youtube "></i>
                      <i *ngIf="dashboardData.Offer.platformeType == 'Blog'" class="fa fa-bold mr-2 left"></i>
                      <i *ngIf="dashboardData.Offer.platformeType == 'multiNetwork'" class="fa fa-globe  mr-2 left"></i>
                      {{fctBasic.getNetworkNameForInfluenceur(dashboardData.Offer.influencerObjects, dashboardData.Offer.platformeType)}}</button>
                  </div>
              </div>
          </div>
      </div>
      <mat-divider ></mat-divider>
  
          <mat-card-content>
            <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTabIndexContent" (selectedTabChange)="onTabChanged($event)" >
              <mat-tab  label="Overview" >
                <div class="container" *ngIf="showOffer">
                  <div class="cardMetrics mt-4">
                    <mat-card *ngIf="dashboardData.globalStatistics.totalImpression && dashboardData.globalStatistics.totalImpression > 0">
                      <mat-card-content (click)="showOverviewChart('impression')">
                        <div class="fa-content">
                          <div style="color: #4bdbaa;">
                            <i style="font-size:50px;" class="fa fa-eye"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                             Impressions
                            </div>
                            <div  class="numberCard">
                              <span>{{dashboardData.globalStatistics.totalImpression | mask: "separator.2"}}</span>
                              
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                    <mat-card *ngIf="dashboardData.globalStatistics.totalPlays && dashboardData.globalStatistics.totalPlays > 0">
                      <mat-card-content (click)="showOverviewChart('plays')">
                        <div class="fa-content">
                          <div style="color: #4bdbaa;">
                            <i style="font-size:50px;" class="fa fa-play"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                             Plays <i class="fa fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                             matTooltip="Number of times the reels starts to play after an impression is already counted. This is defined as video sessions with 1 ms or more of playback and excludes replays." 
                             aria-label="Button that displays a tooltip when focused or hovered over"           
                             matTooltipPosition="right"
                             matTooltipClass="allow-cr"
                             aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                             
                         </i>
                            </div>
                            <div  class="numberCard">
                              <span>{{dashboardData.globalStatistics.totalPlays | mask: "separator.2"}}</span>
                              
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                    <mat-card   *ngIf="dashboardData.globalStatistics.totalReach && dashboardData.globalStatistics.totalReach > 0">
                      <mat-card-content (click)="showOverviewChart('reach')">
                        <div class="fa-content">
                          <div style="color: #5bb2ee;">
                            <i style="font-size:50px;display:inline-block;" class="fa fa-users"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                             Reach
                            </div>
                            <div class="numberCard ">
                              <span>{{dashboardData.globalStatistics.totalReach | mask: "separator.2"}}</span>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                    <mat-card (click)="showOverviewChart('engagement')">
                      <mat-card-content>
                        <div class="fa-content">
                          <div style="color: #dc2626;">
                            <i style="font-size:50px;" class="fa fa-heart"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                              Engagement
                            </div>
                            <div class="numberCard ">
                              <span>{{globalEngagement | mask: "separator.2"}}</span>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                    
                  </div>
                   <div class="chartPositionCard mt-4">
                    <div  class="cardcharttitle ">
                      <div class="labelFont" *ngIf="overviewImpSeleted">Impressions Delivered (Daily)</div> 
                      <div class="labelFont" *ngIf="overviewPlaysSeleted">Plays (Daily)</div> 
                      <div  class="labelFont" *ngIf="overviewReachSeleted">Reach (Daily)</div> 
                        <div class="labelFont" *ngIf="overviewEngagementSeleted">Engagement (Daily)</div> 
                    </div>
                    <apx-chart *ngIf="overviewImpSeleted"
                    [series]="dashboardData.offerJson.impressionviewsdata"
                    [chart]="chartOptions.chart"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [labels]="dashboardData.offerJson.labelData"
                    [dataLabels]="chartOptions.dataLabels"
                    [plotOptions]="chartOptions.plotOptions"
                    [colors]="chartOptions.colors"
                  ></apx-chart>
                  <apx-chart *ngIf="overviewPlaysSeleted"
                    [series]="dashboardData.offerJson.impressionPlaysdata"
                    [chart]="chartOptions.chart"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [labels]="dashboardData.offerJson.labelData"
                    [dataLabels]="chartOptions.dataLabels"
                    [plotOptions]="chartOptions.plotOptions"
                    [colors]="chartOptions.colors"
                  ></apx-chart>
                  <apx-chart *ngIf="overviewReachSeleted"
                    [series]="dashboardData.offerJson.impressionreachdata"
                    [chart]="chartOptions.chart"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [labels]="dashboardData.offerJson.labelData"
                    [dataLabels]="chartOptions.dataLabels"
                    [fill]="chartOptions.fillReach"
                    [plotOptions]="chartOptions.plotOptions"
                    [colors]="chartOptions.colorsReach"
                  ></apx-chart>
                  <apx-chart *ngIf="overviewEngagementSeleted"
                    [series]="dashboardData.offerJson.impressionengagementdata"
                    [chart]="chartOptions.chartEngagement"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="dashboardData.offerJson.labelData"
                    [fill]="chartOptions.fillEngagement"
                    [colors]="chartOptions.colorsEngagement"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>   
                  </div>
                </div>
              </mat-tab>
              <mat-tab  *ngIf="dashboardData.arrPort.length > 0" label="Posts" >
                <div class="container" *ngIf="showPost">
                  <div class="cardMetrics mt-4">
                    <div class="contentMetrics">
                      <mat-card>
                        <mat-card-content (click)="showPostChart('impression')">
                          <div class="fa-content">
                            <div style="color: #4bdbaa;">
                              <i style="font-size:50px;" class="fa fa-eye"></i>
                            </div>
                            <div  class="cardMetricsContent ">
                              <div class="labelFont">
                               Impressions
                              </div>
                              <div  class="numberCard">
                                <span>{{dashboardData.postGlobal.totalImpression | mask: "separator.2"}}</span>
                                
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    <div class="contentMetrics">
                      <mat-card (click)="showPostChart('reach')">
                        <mat-card-content>
                          <div class="fa-content">
                            <div style="color: #5bb2ee;">
                              <i style="font-size:50px;" class="fa fa-users"></i>
                            </div>
                            <div  class="cardMetricsContent ">
                              <div class="labelFont">
                                Reach
                              </div>
                              <div class="numberCard">
                                <span>{{dashboardData.postGlobal.totalReach | mask: "separator.2"}}</span>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    <div class="contentMetrics">
                      <mat-card (click)="showPostChart('engagement')">
                        <mat-card-content>
                          <div class="fa-content">
                            <div style="color: #dc2626;">
                              <i style="font-size:50px;" class="fa fa-heart"></i>
                            </div>
                            <div  class="cardMetricsContent ">
                              <div class="labelFont">
                                Engagement
                              </div>
                              <div class="numberCard ">
                                <span>{{globalEngagementPost | mask: "separator.2"}}</span>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                  <div class="chartPositionCard mt-4">
                    <div  class="cardcharttitle ">
                      <div class="labelFont">
                        <div *ngIf="postImpSeleted">Impressions Delivered (Daily)</div> 
                        <div *ngIf="postReachSeleted">Reach (Daily)</div> 
                        <div *ngIf="postEngagementSeleted">Engagement (Daily)</div> 
                      </div>
                    </div>
                    <apx-chart *ngIf="postImpSeleted"
                    [series]="dashboardData.postjson.impressionviewsdata"
                    [chart]="chartOptions.chart"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="dashboardData.postjson.labelData"
                    [fill]="chartOptions.fill"
                    [colors]="chartOptions.colors"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>
                  <apx-chart *ngIf="postReachSeleted"
                    [series]="dashboardData.postjson.impressionreachdata"
                    [chart]="chartOptions.chart"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="dashboardData.postjson.labelData"
                    [fill]="chartOptions.fillReach"
                    [colors]="chartOptions.colorsReach"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>
                  <apx-chart *ngIf="postEngagementSeleted"
                    [series]="dashboardData.postjson.impressionengagementdata"
                    [chart]="chartOptions.chartEngagement"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="dashboardData.postjson.labelData"
                    [fill]="chartOptions.fillEngagement"
                    [colors]="chartOptions.colorsEngagement"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="dashboardData.infoReel.length > 0"  label="Reels" >
  
                <div class="container" *ngIf="showReel">
                  <div class="cardMetrics mt-4">
                    <div class="contentMetrics">
                      <mat-card>
                        <mat-card-content (click)="showReelChart('impression')">
                          <div class="fa-content">
                            <div style="color: #4bdbaa;">
                              <i style="font-size:50px;" class="fa fa-eye"></i>
                            </div>
                            <div  class="cardMetricsContent ">
                              <div class="labelFont">
                                Plays <i class="fa fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                                matTooltip="Number of times the reels starts to play after an impression is already counted. This is defined as video sessions with 1 ms or more of playback and excludes replays." 
                                aria-label="Button that displays a tooltip when focused or hovered over"           
                                matTooltipPosition="right"
                                matTooltipClass="allow-cr"
                                aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                
                            </i>
                              </div>
                              <div  class="numberCard">
                                <span>{{dashboardData.reelGlobal.totalPlays | mask: "separator.2"}}</span>
                                
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    <div class="contentMetrics">
                      <mat-card (click)="showReelChart('reach')">
                        <mat-card-content>
                          <div class="fa-content">
                            <div style="color: #5bb2ee;">
                              <i style="font-size:50px;" class="fa fa-users"></i>
                            </div>
                            <div  class="cardMetricsContent ">
                              <div class="labelFont">
                                Reach 
                              </div>
                              <div class="numberCard">
                                <span>{{dashboardData.reelGlobal.totalReach | mask: "separator.2"}}</span>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    <div class="contentMetrics">
                      <mat-card (click)="showReelChart('engagement')">
                        <mat-card-content>
                          <div class="fa-content">
                            <div style="color: #dc2626;">
                              <i style="font-size:50px;" class="fa fa-heart"></i>
                            </div>
                            <div  class="cardMetricsContent ">
                              <div class="labelFont">
                                Engagement 
                              </div>
                              <div class="numberCard">
                                <span>{{globalEngagementReel | mask: "separator.2"}}</span>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                  <div class="chartPositionCard mt-4">
                    <div  class="cardcharttitle ">
                      <div class="labelFont">
                        <div *ngIf="reelImpSeleted">Plays (Daily)</div> 
                        <div *ngIf="reelReachSeleted">Reach (Daily)</div> 
                        <div *ngIf="reelEngagementSeleted">Engagement (Daily)</div> 
                      </div>
                    </div>
                    <apx-chart *ngIf="reelImpSeleted"
                    [series]="dashboardData.reelJson.playsPostData"
                    [chart]="chartOptions.chart"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [labels]="dashboardData.reelJson.labelData"
                    [dataLabels]="chartOptions.dataLabels"
                    [fill]="chartOptions.fill"
                    [colors]="chartOptions.colors"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>
                  <apx-chart *ngIf="reelReachSeleted"
                    [series]="dashboardData.reelJson.reachPostData"
                    [chart]="chartOptions.chart"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [labels]="dashboardData.reelJson.labelData"
                    [dataLabels]="chartOptions.dataLabels"
                    [fill]="chartOptions.fillReach"
                    [colors]="chartOptions.colorsReach"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>
                  <apx-chart *ngIf="reelEngagementSeleted"
                    [series]="dashboardData.reelJson.engagementPostData"
                    [chart]="chartOptions.chartEngagement"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [labels]="dashboardData.reelJson.labelData"
                    [dataLabels]="chartOptions.dataLabels"
                    [fill]="chartOptions.fillEngagementReels"
                    [colors]="chartOptions.colorsEngagementReels"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="dashboardData.infoStory.length > 0"  label="Stories" >
  
                <div class="container" *ngIf="showStory">
                  <div class="cardMetrics mt-4">
                    <div class="contentMetrics">
                      <mat-card>
                        <mat-card-content (click)="showStoriesChart('impression')">
                          <div class="fa-content">
                            <div style="color: #4bdbaa;">
                              <i style="font-size:50px;" class="fa fa-eye"></i>
                            </div>
                            <div  class="cardMetricsContent ">
                              <div class="labelFont">
                                Impressions
                              </div>
                              <div  class="numberCard">
                                <span>{{dashboardData.storyGlobal.totalImpression | mask: "separator.2"}}</span>
                                
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    <div class="contentMetrics">
                      <mat-card (click)="showStoriesChart('reach')">
                        <mat-card-content>
                          <div class="fa-content">
                            <div style="color: #5bb2ee;">
                              <i style="font-size:50px;" class="fa fa-users"></i>
                            </div>
                            <div  class="cardMetricsContent ">
                              <div class="labelFont">
                                Reach 
                              </div>
                              <div class="numberCard">
                                <span>{{dashboardData.storyGlobal.totalReach | mask: "separator.2"}}</span>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                  <div class="chartPositionCard mt-4">
                    <div  class="cardcharttitle ">
                      <div class="labelFont">
                        <div *ngIf="storieImpSeleted">Impressions Delivered (Daily)</div> 
                        <div *ngIf="storieReachSeleted">Reach (Daily)</div> 
                        <div *ngIf="storieEngagementSeleted">Engagement (Daily)</div> 
                      </div>
                    </div>
                    <apx-chart *ngIf="storieImpSeleted"
                    [series]="dashboardData.storyJson.allArrayDataImpressions"
                    [chart]="chartOptions.chart"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [labels]="dashboardData.storyJson.labelData"
                    [dataLabels]="chartOptions.dataLabels"
                    [fill]="chartOptions.fill"
                    [colors]="chartOptions.colors"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>
                  <apx-chart *ngIf="storieReachSeleted"
                    [series]="dashboardData.storyJson.allArrayDataReach"
                    [chart]="chartOptions.chart"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [labels]="dashboardData.storyJson.labelData"
                    [dataLabels]="chartOptions.dataLabels"
                    [fill]="chartOptions.fillReach"
                    [colors]="chartOptions.colorsReach"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>
                  </div>
                </div>
              </mat-tab>
              
            </mat-tab-group>
        </mat-card-content>
        </mat-card>
        <mat-card class="mt-3" *ngIf="show && showOffer && dashboardData.infoStory.length > 0">
          <mat-card-content>
            <div class="container">
              <div  class="cardcharttitle ">
                <div class="labelFont" >Stories ({{dashboardData.infoStory.length}})</div> 
            </div>
              
              <div flex-center class="row col-lg-12 col-12">
                <div  class="card  mt-2 mb-2">
                  <div  class="profileIf ">
                    <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
                  </div>
                  <img  [src]="storiesPicOverviewSeleted" class="post-image" alt="">
                  <div (click)="expendedOverviewStories=!expendedOverviewStories" class="card-content">
                    <p>{{ (expendedOverviewStories) ? storiesTxtOverviewSeleted : storiesTxtOverviewSeleted | slice:0:150}}</p>
                    <div  (click)="expendedOverviewStories=true" *ngIf="!expendedOverviewStories">...</div>
                    <div  (click)="expendedOverviewStories=false" *ngIf="expendedOverviewStories">...</div>
                  </div>
              </div>
              </div>
              
            <div flex-center class="thumbnails mt-4">
              <div *ngFor="let post of dashboardData.infoStory;let index = index;" >
                <div class="img_wrapper ">
                  
                  <img (click)="showPic(post, 'Overview', 'Storie')" mat-card-image class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture" >
                </div>
              </div>
            </div>
            </div>
            
          </mat-card-content>
        </mat-card>
        <mat-card class="mt-3" *ngIf="show && showOffer && dashboardData.arrPort.length > 0">
          <div  class="cardcharttitle ">
            <div class="labelFont" >Posts ({{dashboardData.infoPost.length}})</div> 
        </div>
          <mat-card-content>
            <div class="container">
              <div flex-center class="row col-lg-12 col-12">
                <div  class="card  mt-2 mb-2">
                  <div  class="profileIf ">
                    <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
                  </div>
                  <img  [src]="postPicOverviewSeleted" class="post-image" alt="">
                  <div class="card-content" (click)="expendedOverViewPosts=!expendedOverViewPosts" >
                    <div>{{(expendedOverViewPosts) ? postTxtOverviewSeleted : postTxtOverviewSeleted | slice:0:150}} <div  (click)="expendedOverViewPosts=true" *ngIf="!expendedOverViewPosts">...</div>
                      
                      <div  (click)="expendedOverViewPosts=false" *ngIf="expendedOverViewPosts">...</div>
                    </div>
                    
                  </div>
              </div>
              </div>
              
            <div flex-center class="thumbnails mt-4">
              <div *ngFor="let post of dashboardData.infoPost;let index = index;" >
                <div class="img_wrapper ">
                  <img   (click)="showPic(post,'Overview', 'Post')"  class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture" >
                </div>
              </div>
            </div>
            </div>
            
          </mat-card-content>
        </mat-card>
        <mat-card class="mt-3" *ngIf="show && showOffer && dashboardData.arrReel && dashboardData.arrReel.length > 0">
          <div  class="cardcharttitle ">
            <div class="labelFont" >Reels ({{dashboardData.arrReel.length}})</div> 
        </div>
          <mat-card-content>
            <div class="container">
              <div flex-center class="row col-lg-12 col-12">
                <div  class="card  mt-2 mb-2">
                  <div  class="profileIf ">
                    <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
                  </div>
                  <img  [src]="reelPicOverviewSeleted" class="post-image" alt="">
                  <div class="card-content" (click)="expendedOverViewPosts=!expendedOverViewPosts" >
                    <div>{{(expendedOverViewPosts) ? reelTxtOverviewSeleted : reelTxtOverviewSeleted | slice:0:150}} <div  (click)="expendedOverViewPosts=true" *ngIf="!expendedOverViewPosts">...</div>
                      <div  (click)="expendedOverViewPosts=false" *ngIf="expendedOverViewPosts">...</div>
                    </div>
                  </div>
              </div>
              </div>
              
            <div flex-center class="thumbnails mt-4">
              <div *ngFor="let post of dashboardData.infoReel;let index = index;" >
                <div class="img_wrapper ">
                  <img   (click)="showPic(post,'Overview', 'Reel')"  class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.picture" >
                </div>
              </div>
            </div>
            </div>
            
          </mat-card-content>
        </mat-card>
        <mat-card class="mt-3" *ngIf="showReel && dashboardData.arrReel.length > 0">
          <mat-card-content>
            <div  class="cardcharttitle ">
              <div class="labelFont" >Detailed Statistics</div> 
          </div>
            <div class="row col-lg-12 col-12">
              <div class="col-lg-9">
                <apx-chart *ngIf="showReelImp"
                [series]="reelSeleted.chartReel.playsPostData"
                [chart]="reelchartDetailled"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [dataLabels]="chartOptions.dataLabels"
                [labels]="reelSeleted.chartReel.labelData"
                [fill]="chartOptions.fill"
                [colors]="chartOptions.colors"
                [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <apx-chart *ngIf="showReelReach"
                    [series]="reelSeleted.chartReel.reachPostData"
                    [chart]="reelchartDetailled"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="reelSeleted.chartReel.labelData"
                    [fill]="chartOptions.fillReach"
                    [colors]="chartOptions.colorsReach"
                    [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <apx-chart *ngIf="showReelEngagement"
                    [series]="reelSeleted.chartReel.engagementPostData"
                    [chart]="chartOptions.chartEngagement"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="reelSeleted.chartReel.labelData"
                    [fill]="chartOptions.fillEngagementReels"
                    [colors]="chartOptions.colorsEngagementReels"
                    [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <div flex-center>
                <div  class="cardMetricsDetailled  mt-4">
                  <div class="contentMetrics">
                    <mat-card (click)="showReelChartDetailed('impression')">
                      <mat-card-content >
                        <div class="fa-content">
                          <div style="color: #4bdbaa;">
                            <i style="font-size:25px;" class="fa fa-eye"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                             Plays
                             <i class="fa fa-info " style="font-size:12px;" #tooltip="matTooltip"  
                                matTooltip="Number of times the reels starts to play after an impression is already counted. This is defined as video sessions with 1 ms or more of playback and excludes replays." 
                                aria-label="Button that displays a tooltip when focused or hovered over"           
                                matTooltipPosition="right"
                                matTooltipClass="allow-cr"
                                aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
                                
                            </i>
                            </div>
                            <div  class="numberCard">
                              <span>{{reelSeleted.totalStatistics.totalPlays | mask: "separator.2"}}</span>
                              
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="contentMetrics">
                    <mat-card (click)="showReelChartDetailed('reach')">
                      <mat-card-content>
                        <div class="fa-content">
                          <div style="color: #5bb2ee;">
                            <i style="font-size:25px;" class="fa fa-users"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                              Reach
                            </div>
                            <div class="numberCard">
                              <span>{{reelSeleted.totalStatistics.totalReach | mask: "separator.2"}}</span>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="contentMetrics">
                    <mat-card (click)="showReelChartDetailed('engagement')">
                      <mat-card-content>
                        <div class="fa-content">
                          <div style="color: #dc2626;">
                            <i style="font-size:25px;" class="fa fa-heart"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                              Engagement
                            </div>
                            <div class="numberCard ">
                              <span>{{reelSeleted.totalStatistics.totalEngagement | mask: "separator.2"}}</span>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
              </div>
              <div  class="col-lg-3">
                <div flex-center class="cardPictureDetailed ">
                  <div flex-center class="card">
                    <div  class="profileIf ">
                      <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
                    </div>
                    <img  [src]="srcReelImg" class="post-image" alt="">
                    
                    <div class="card-content" (click)="expendedPosts = !expendedPosts">
                      <div>{{(expendedPosts) ? srcPostCaption : srcPostCaption | slice:0:150}} </div>
                      <div  (click)="expendedPosts=true" *ngIf="!expendedPosts">...</div>
                        
                        <div  (click)="expendedPosts=false" *ngIf="expendedPosts">...</div>
                    </div>
                  </div>
                </div>
                
              </div>
          </div>
          <div class="col-lg-12 mt-4">
            <div  class="cardcharttitle ">
              <div class="labelFont text-center">
                Reel(s) ({{dashboardData.arrReel.length}})
              </div>
            </div>
            <div flex-center class="thumbnails mt-4">
              <div *ngFor="let post of dashboardData.arrReel;let index = index;" >
                <div class="img_wrapper ">
                  
                  <img  (click)="setReelValue(post)" mat-card-image class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.infoPost.picture" >
                </div>
              </div>
            </div>
          </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="mt-3" *ngIf="showPost && dashboardData.arrPort.length > 0">
          <mat-card-content>
            <div  class="cardcharttitle ">
              <div class="labelFont" >Detailed Statistics</div> 
          </div>
            <div class="row col-lg-12 col-12">
              <div class="col-lg-9">
                <apx-chart *ngIf="showPostImp"
                [series]="postSeleted.chartPost.impressionviewsdata"
                [chart]="postchartDetailled"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [dataLabels]="chartOptions.dataLabels"
                [labels]="postSeleted.chartPost.labelData"
                [fill]="chartOptions.fill"
                [colors]="chartOptions.colors"
                [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <apx-chart *ngIf="showPostReach"
                    [series]="postSeleted.chartPost.impressionreachdata"
                    [chart]="postchartDetailled"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="postSeleted.chartPost.labelData"
                    [fill]="chartOptions.fillReach"
                    [colors]="chartOptions.colorsReach"
                    [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <apx-chart *ngIf="showPostEngagement"
                    [series]="postSeleted.chartPost.impressionengagementdata2"
                    [chart]="chartOptions.chartEngagement"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [dataLabels]="chartOptions.dataLabels"
                    [labels]="postSeleted.chartPost.labelData"
                    [fill]="chartOptions.fillEngagement"
                    [colors]="chartOptions.colorsEngagement"
                    [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <div flex-center>
                <div  class="cardMetricsDetailled  mt-4">
                  <div class="contentMetrics">
                    <mat-card (click)="showPostChartDetailed('impression')">
                      <mat-card-content >
                        <div class="fa-content">
                          <div style="color: #4bdbaa;">
                            <i style="font-size:25px;" class="fa fa-eye"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                             Impressions
                            </div>
                            <div  class="numberCard">
                              <span>{{postSeleted.totalStatistics.totalView | mask: "separator.2"}}</span>
                              
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="contentMetrics">
                    <mat-card (click)="showPostChartDetailed('reach')">
                      <mat-card-content>
                        <div class="fa-content">
                          <div style="color: #5bb2ee;">
                            <i style="font-size:25px;" class="fa fa-users"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                              Reach
                            </div>
                            <div class="numberCard">
                              <span>{{postSeleted.totalStatistics.totalReach | mask: "separator.2"}}</span>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="contentMetrics">
                    <mat-card (click)="showPostChartDetailed('engagement')">
                      <mat-card-content>
                        <div class="fa-content">
                          <div style="color: #dc2626;">
                            <i style="font-size:25px;" class="fa fa-heart"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                              Engagement
                            </div>
                            <div class="numberCard ">
                              <span>{{postSeleted.totalStatistics.totalEngagement | mask: "separator.2"}}</span>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
              </div>
              <div  class="col-lg-3">
                <div flex-center class="cardPictureDetailed ">
                  <div flex-center class="card">
                    <div  class="profileIf ">
                      <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
                    </div>
                    <img  [src]="srcPostImg" class="post-image" alt="">
                    
                    <div class="card-content" (click)="expendedPosts = !expendedPosts">
                      <div>{{(expendedPosts) ? srcPostCaption : srcPostCaption | slice:0:150}} </div>
                      <div  (click)="expendedPosts=true" *ngIf="!expendedPosts">...</div>
                        
                        <div  (click)="expendedPosts=false" *ngIf="expendedPosts">...</div>
                    </div>
                  </div>
                </div>
                
              </div>
          </div>
          <div class="col-lg-12 mt-4">
            <div  class="cardcharttitle ">
              <div class="labelFont text-center">
                Post(s) ({{dashboardData.arrPort.length}})
              </div>
            </div>
            <div flex-center class="thumbnails mt-4">
              <div *ngFor="let post of dashboardData.arrPort;let index = index;" >
                <div class="img_wrapper ">
                  
                  <img  (click)="setPostValue(post)" mat-card-image class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.infoPost.picture" >
                </div>
              </div>
            </div>
          </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="mt-3" *ngIf="showStory && dashboardData.arrStory.length > 0">
          <mat-card-content>
            <div  class="cardcharttitle ">
              <div class="labelFont" >Detailed Statistics</div> 
            </div>
            <div class="row col-lg-12 col-12">
              <div class="col-lg-9">
                <apx-chart *ngIf="showStorieImp" 
                [series]="storieSeleted.chartPost.allArrayDataImpressions"
                [chart]="storychartDetailled"
                [title]="chartOptions.title"
                [stroke]="chartOptions.stroke"
                [labels]="storieSeleted.chartPost.labelData"
                [dataLabels]="chartOptions.dataLabels"
                [fill]="chartOptions.fill"
                [colors]="chartOptions.colors"
                [plotOptions]="chartOptions.plotOptions"
              ></apx-chart>
              <apx-chart *ngIf="showStorieReach"
                    [series]="storieSeleted.chartPost.allArrayDataReach"
                    [chart]="storychartDetailled"
                    [title]="chartOptions.title"
                    [stroke]="chartOptions.stroke"
                    [labels]="storieSeleted.chartPost.labelData"
                    [dataLabels]="chartOptions.dataLabels"
                    [fill]="chartOptions.fillReach"
                    [colors]="chartOptions.colorsReach"
                    [plotOptions]="chartOptions.plotOptions"
                  ></apx-chart>
              <div flex-center>
                <div  class="cardMetricsDetailled col-lg-10 mt-4">
                  <div class="contentMetrics">
                    <mat-card (click)="showStoriesChartDetailled('impression')">
                      <mat-card-content >
                        <div class="fa-content">
                          <div style="color: #4bdbaa;">
                            <i style="font-size:25px;" class="fa fa-eye"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                             Impressions
                            </div>
                            <div  class="numberCard">
                              
                              <span>{{storieSeleted.totalStatistics.totalView | mask: "separator.2"}}</span>
                              
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="contentMetrics">
                    <mat-card (click)="showStoriesChartDetailled('reach')">
                      <mat-card-content>
                        <div class="fa-content">
                          <div style="color: #5bb2ee;">
                            <i style="font-size:25px;" class="fa fa-users"></i>
                          </div>
                          <div  class="cardMetricsContent ">
                            <div class="labelFont">
                              Reach
                            </div>
                            <div class="numberCard">
                              <span>{{storieSeleted.totalStatistics.totalReach | mask: "separator.2"}}</span>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
              
              </div>
              <div class="col-lg-3" >
                <div flex-center class="cardPictureDetailed ">
                  <div flex-center class="card">
                    <div  class="profileIf ">
                      <img src="assets/images/logo/logoIg.png" class="img_wrapperLogo " alt="">
                    </div>
                    <img  [src]="srcSelectedStory" class="post-image" alt="">
                    <div (click)="expendedStories = !expendedStories" class="card-content">
                      <p>{{ (expendedStories) ? textSelectedStory : textSelectedStory | slice:0:50}}</p>
                      <div  (click)="expendedStories=true" *ngIf="!expendedStories">...</div>
                      <div  (click)="expendedStories=false" *ngIf="expendedStories">...</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  class="cardcharttitle ">
              <div class="labelFont" >Storie(s) ({{dashboardData.infoStory.length}})</div> 
            </div>
            <div flex-center class="thumbnails mt-3">
              <div *ngFor="let post of dashboardData.arrStory;let index = index;" >
                <div class="img_wrapper ">
                  <img  (click)="setStoriesValue(post)" class="img-responsive imgFeed"    [defaultImage]="'assets/images/logo/logoIg.png'" [errorImage]="'assets/images/logo/logoIg.png'"  [lazyLoad]="post.infoPost.picture" >
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </body>
  </html>