<html>
    <body>
        <div class="page container">
            <mat-card >
                <mat-card-content class=" mt-3">
                    <form [formGroup]="campagnes" *ngIf="init" >
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-6 col-sm-12  display-mid  ">
                                    <div class="col-lg-4 col-6  col-md-4">
                                        <mat-label class="labelFont">Start Date</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field class="campagneselect2byline">
                                            <input matInput [matDatepicker]="picker1" formControlName="startDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6 col-lg-6  col-sm-12  display-mid">
                                    <div class="col-lg-4 col-6  col-md-4 ">
                                        <mat-label class="labelFont">End Date</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field  class="campagneselect2byline">
                                            <input matInput [matDatepicker]="picker"  formControlName="endDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 col-sm-12  display-mid    ">
                                    <div class="col-lg-4 col-6  col-md-4 ">
                                        <mat-label class="labelFont">Agency</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field  class="campagneselect2byline">
                                            <mat-select formControlName="agency">
                                                <mat-option value=""></mat-option>
                                                <mat-option  *ngFor="let company of listCompany" [value]="company.companyName">
                                                    <div *ngIf="company.companyName">
                                                        {{ company.companyName }}
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                            <input matInput  required placeholder="" > 
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12  display-mid ">
                                    <div class="col-lg-4 col-6  col-md-4">
                                        <mat-label class="labelFont">Advertiser</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8">
                                        <mat-form-field  class="campagneselect2byline">
                                            <mat-select formControlName="advertiser">
                                                <mat-option value=""></mat-option>
                                                <mat-option  *ngFor="let company of listCompany" [value]="company.companyName">
                                                    {{ company.companyName }}
                                                </mat-option>
                                            </mat-select>
                                            <input matInput  required placeholder="" >
                                        </mat-form-field>
                                         
                                    </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 col-sm-12  display-mid   ">
                                    <div class="col-lg-4 col-6  col-md-4 ">
                                        <mat-label class="labelFont">Agency Contact</mat-label>
                                    </div>
                                    <div  class="col-lg-8 col-6 col-md-8 ">
                                            <mat-form-field  class="campagneselect2byline">
                                                <mat-select formControlName="agencyContact">
                                                    <mat-option value=""></mat-option>
                                                    <mat-option  *ngFor="let contact of listContacts" [value]="contact.lastname">
                                                        {{ contact.lastname }}
                                                    </mat-option>
                                                </mat-select>
                                                <input matInput  required placeholder="" >
                                            </mat-form-field>
                                    </div>
                                
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12  display-mid">
                                    <div class="col-lg-4 col-6 col-md-4">
                                        <mat-label class="labelFont">Advertiser Contact</mat-label>
                                    </div>
                                    <div class="col-lg-8 col-6 col-md-8 ">
                                        <mat-form-field  class="campagneselect2byline">
                                            <mat-select formControlName="advertiserContact">
                                                <mat-option value=""></mat-option>
                                                <mat-option  *ngFor="let contact of listContacts" [value]="contact.lastname">
                                                    {{ contact.lastname }}
                                                </mat-option>
                                            </mat-select>
                                            <input matInput  required placeholder="" >
                                        </mat-form-field>
                                    </div>
                            </div>
                        </div>

                        <div class="row input-row  ">
                            <div class="col-md-12 col-12 col-lg-12 display-mid  col-sm-12 ">
                                <div class="col-lg-2  col-6">
                                    <mat-label class="labelFont"> Campagne Name</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field >
                                        <input   matInput placeholder=""  formControlName="campaignName">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row input-row  ">
                            <div class="col-md-12 col-12 col-lg-12 display-mid  col-sm-12 ">
                                <div class="col-lg-2  col-6">
                                    <mat-label class="labelFont"> Agency Reference</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field >
                                        <input   matInput placeholder=""  formControlName="agencyReference">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row input-row  ">
                            <div class="col-md-12 col-lg-12 textareapostion">
                                <div class="col-lg-2  col-6">
                                    <mat-label class="labelFont">Campaign Description</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field  appearance="outline">
                                        <textarea rows="15" class="disabledtext" formControlName="projectDescription" matInput ></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row input-row  ">
                            <div class="col-md-12 col-12 col-lg-12 display-mid  col-sm-12 ">
                                <div class="col-lg-2  col-6">
                                    <mat-label class="labelFont">Campaign Objective</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field>
                                        <mat-select formControlName="objective">
                                            <mat-option [value]="'Awareness'">
                                                Awareness
                                            </mat-option>
                                            <mat-option [value]="'Video Views'">
                                                Video Views
                                            </mat-option>
                                            <mat-option [value]="'Traffic'">
                                                Traffic
                                            </mat-option>
                                            <mat-option [value]="'Engagement'">
                                                Engagement
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row input-row  ">
                            <div class="col-md-12 col-12 col-lg-12 display-mid  col-sm-12 ">
                                <div class="col-lg-2  col-6">
                                    <mat-label class="labelFont">Campaign Mission</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field>
                                        <mat-select formControlName="mission">
                                            <mat-option [value]="'Content'">
                                                Content
                                            </mat-option>
                                            <mat-option [value]="'Media'">
                                                Media
                                            </mat-option>
                                            <mat-option [value]="'Live'">
                                                Live
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row input-row  ">
                            <div style="padding-top:1.04375em!important;padding-bottom: 1.14375em!important;" class="col-md-12 col-lg-12 display-mid">
                                <div class="col-lg-2  col-6">
                                    <mat-label class="labelFont">Social Network</mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <button  mat-button  type="button" [ngStyle]="{'background-color' : buttonValue == 'Facebook' ? 'black' : '#5285fb'}" (click)="setplatform('Facebook')" style=" color:white;"class=" btn mr-2  btn-facebook shadow-none"><i class="fa fa-facebook mr-2"></i>Facebook</button>
                                    <button  mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'Youtube' ? 'black' : '#CC181E'}"(click)="setplatform('Youtube')"  style=" color:white;" class="btn mr-2  btn-youtube shadow-none"><i class="fa fa-youtube mr-2"></i>Youtube</button>
                                    <button  mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'Instagram' ? 'black' : '#8a3ab9'}" (click)="setplatform('Instagram')"style=" color:white;" class=" mr-2 btn shadow-none"><i class="fa fa-instagram mr-2"></i>Instagram</button>
                                    <button  mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'Blog' ? 'black' : '#fccc63'}"(click)="setplatform('Blog')" style=" color:white;" class="  mr-2 btn shadow-none"><i class="fa fa-bold mr-2"></i>Blog</button>
                                    <button  mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'multiNetwork' ? 'black' : '#ec1f99'}"(click)="setplatform('multiNetwork')" style=" color:white;" class="  mr-2 btn shadow-none">Multi-Platform</button>
                                    <button  mat-button type="button" [ngStyle]="{'background-color' : buttonValue == 'TikTok' ? 'black' : 'white', color: buttonValue == 'TikTok' ? 'white' : 'black', border: buttonValue == 'TikTok' ? '' : '1px solid black'}"(click)="setplatform('TikTok')"  class=" mr-2 btn shadow-none "><mat-icon class=" mr-2">tiktok</mat-icon>TikTok</button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row input-row  ">
                            <div class="col-md-12 col-lg-12 textareapostion">
                                <div class="col-lg-2  col-6">
                                    <mat-label class="labelFont">Must include</mat-label>
                                    <br>
                                    <mat-label class="textsecondary"> List your campaign key elements. Ex : #campaignhashtag ; @brandaccount ; UTM ; campaign keywords  </mat-label>
                                </div>
                                <div class="col-lg-10 col-6">
                                    <mat-form-field  appearance="outline">
                                        <textarea rows="15" formControlName="mustInclude" matInput ></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        
                       
                        
                        <div class="row input-row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="col-lg-12 col-md-12">

                                    <mat-label class="labelFont">Script generation info</mat-label>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <mat-label class="textsecondary">Indicate at least 2 @ ou # for dashboard and pige generation - Case sensitive</mat-label>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                        <mat-form-field  >
                                            <input required formArrayName="annonceurKey" matInput  id="keyword" type="text" >
                                            <button  matSuffix mat-icon-button aria-label="Clear" (click)="addKeyWords()">
                                              <mat-icon>add</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <div class="row col-lg-12 col-md-12">
                                            <span   (click)="deleteKeyWords(i)"  *ngFor="let keyword of annonceurKey.value; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                                {{ keyword }} x 
                                            </span>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <div class="col-lg-12 col-md-12">
                                    <mat-label class="labelFont"> Upload Image </mat-label>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <mat-label class="textsecondary"> We accept files in the following formats : .png, .jpg, .gif </mat-label>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <mat-form-field>
                                        <ngx-mat-file-input (change)="addPicture($event)"  ></ngx-mat-file-input>
                                        <mat-icon matSuffix>attach_file</mat-icon>
                                      </mat-form-field>
                                      <div class="row col-lg-12 col-md-12" *ngIf="campagnes.value['pictures'].length > 0">
                                        <div class="imgrow">
                                            <div  *ngFor="let pic of campagnes.value['pictures']; index as i">
                                                <div class="img_wrapper_multi">
                                                    <span (click)="deleteImg(pic, i)" class="close">&times;</span>
                                                    <img  [src]="picService.checkUrlPath(pic)"   class="img-responsive center-block imgFeedMulti">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <div class="col-lg-12 col-md-12">
                                    <mat-label class="labelFont">  Upload File  </mat-label>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <mat-label class="textsecondary"> We accept files in the following formats : .docx, .ppt, .xlsx </mat-label>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <mat-form-field>
                                        <ngx-mat-file-input (change)="selectFile($event)"  ></ngx-mat-file-input>
                                        <mat-icon matSuffix>attach_file</mat-icon>
                                    </mat-form-field>
                                    <div class="row col-lg-12 col-md-12" *ngIf="campagnes.value['files'].length > 0">
                                        <div class="imgrow">
                                            <div  *ngFor="let file of campagnes.value['files']; index as a;">
                                                <div class="img_wrapper_multi">
                                                    <span (click)="deleteFile(file, a)" class="close">&times;</span>
                                                    <img  [src]="picService.getExtentionFileToShow(file)"   class="img-responsive center-block imgFeedMulti">
                                                    <div class="fileText">{{file.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-3 col-lg-3 col-sm-12   ">
                                    <div class="col-lg-12   col-md-12 ">
                                        <mat-label class="labelFont">Delivery Target</mat-label>
                                    </div>
                                    <div class="col-lg-12   col-md-12 ">
                                        <mat-form-field >
                                            <input matInput mask="separator.2" (change)="calculateT(campagnes)"   placeholder=""  formControlName="deliveryTarget">
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-3  col-lg-3   col-sm-12">
                                    <div class="col-lg-12 col-md-12 ">
                                        <mat-label class="labelFont">Prix Unitaire</mat-label>
                                    </div>
                                    <div class="col-lg-12  col-md-12">
                                        <mat-form-field >
                                            <input mask="separator.2" suffix=" €" (change)="calculateT(campagnes)" matInput placeholder=""  formControlName="prixUnitaire">
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-12   ">
                                <div class="col-lg-12   col-md-12 ">
                                    <mat-label class="labelFont">Sales Models</mat-label>
                                </div>
                                <div class="col-lg-12   col-md-12 ">
                                    <mat-form-field >
                                        <mat-select (change)="calculateT(campagnes)" formControlName="saleModels">
                                            <mat-option [value]="'CPM'">
                                                CPM
                                            </mat-option>
                                            <mat-option [value]="'CPV'">
                                                CPV
                                            </mat-option>
                                            <mat-option [value]="'CPE'">
                                                CPE
                                            </mat-option>
                                            <mat-option [value]="'CPC'">
                                                CPC
                                            </mat-option>
                                            <mat-option [value]="'Quotation'">
                                                Quotation
                                            </mat-option>
                                            <mat-option [value]="'CPP'">
                                                CPP
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                        </div>
                        <div class="col-md-3  col-lg-3   col-sm-12">
                                <div class="col-lg-12 col-md-12 ">
                                    <mat-label class="labelFont">Net Budget</mat-label>
                                </div>
                                <div class="col-lg-12  col-md-12">
                                    <mat-form-field >
                                        <input matInput mask="separator.2" suffix=" €"  placeholder="" readonly  formControlName="revenueTarget">
                                    </mat-form-field>
                                </div>
                        </div> 
                        </div>
                            <div class="row input-row mt-2 mb-2 " flex-center>
                                <button (click)="createCampagne()" class="btn  btn-secondaryWithBorder shadow-none " > <b>SAVE</b> </button>
                            </div>
                        
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </body>
</html>