import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import {OfferModel} from 'src/app/model/offer-model.model'
import {userModel} from 'src/app/model/user-model.model'
import { ApiService } from 'src/app/service/api.service';
import {UserLoginService} from 'src/app/service/user-login.service';
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import {Router} from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { FacebookDialogComponent } from 'src/app/dialog/facebook-dialog/facebook-dialog.component';
import { InstagramDialogComponent } from 'src/app/dialog/instagram-dialog/instagram-dialog.component';
import { WithoutFbModalComponent } from 'src/app/dialog/without-fb-modal/without-fb-modal.component';
import { InstagramInsightDialogComponent } from 'src/app/dialog/instagram-insight-dialog/instagram-insight-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-home-influenceur',
  templateUrl: './home-influenceur.component.html',
  styleUrls: ['./home-influenceur.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class HomeInfluenceurComponent implements OnInit {
  dataSourceOffer : any
  currentUser : userModel
  init :boolean = false
  newMessage : string = ""
  receiverName : any = ""
  arrayMessage : any[]
  constructor(
    private apiService: ApiService,
    private authService : UserLoginService,
    public fctBasic: CampagneAndOfferFctService,
    private cdRef:ChangeDetectorRef,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    let authInf = this.authService.isAuthenticated()
    if (authInf === false){
      this.apiService.getUser().subscribe(res => {
        if (res && res.confirm == true && res.valid == true){
          this.currentUser = res
          this.authService.setItemForInfluencer(res)
          this.apiService.getOffreInfluencer(this.currentUser).subscribe(offers => { 
            this.dataSourceOffer = offers;
            
            this.init = true
            this.cdRef.detectChanges();
            if (this.route.snapshot.params.plateformeTypeErr){
              switch (this.route.snapshot.params.plateformeTypeErr) {
                case "withoutFacebook":
                  this.openDialogError(WithoutFbModalComponent, {
                    panelClass: 'dialog-container-custom',
                    data: res
                  })
                  break;
                case "Facebook":
                  this.openDialogError(FacebookDialogComponent, {
                    panelClass: 'dialog-container-custom',
                    data: res
                  })
                  break;
                case "Instagram":
                  this.openDialogError(InstagramDialogComponent, {
                    panelClass: 'custom-dialog-container',
                    data: res})
                  break;
                case "InstagramInsight":
                  this.openDialogError(InstagramInsightDialogComponent, {
                    panelClass: 'custom-dialog-container',
                    data: res})
                  break;
                default:
                  break;
              }
          }
          })
        } else {
          this.authService.logout()
        }
      })
    } else {
      this.currentUser = this.authService.getUserValue()
      if (this.currentUser.confirm == true && this.currentUser.valid == true){
        this.apiService.getOffreInfluencer(this.currentUser).subscribe(offers => { 
          this.dataSourceOffer = offers;
          this.init = true
          this.cdRef.detectChanges();
          if (this.route.snapshot.params.plateformeTypeErr){
            switch (this.route.snapshot.params.plateformeTypeErr) {
              case "withoutFacebook":
                this.openDialogError(WithoutFbModalComponent, {
                  panelClass: 'dialog-container-custom',
                  data: this.currentUser
                })
                break;
              case "Facebook":
                this.openDialogError(FacebookDialogComponent, {
                  panelClass: 'dialog-container-custom',
                  data: this.currentUser
                })
                break;
              case "Instagram":
                this.openDialogError(InstagramDialogComponent, {
                  panelClass: 'custom-dialog-container',
                  data: this.currentUser})
                break;
              case "InstagramInsight":
                this.openDialogError(InstagramInsightDialogComponent, {
                  panelClass: 'custom-dialog-container',
                  data: this.currentUser})
                break;
              default:
                break;
            }
        }
        })
      } else {
        this.authService.logout()
      }
      
    }
   

    
  }

  // sendNewMessage(){
    
  //   if (this.newMessage == ""){
  //     this.toastr.error("Your message is empty")
  //     return
  //   }
  //   let newmessage = {
  //     "receiverId" : "5cee8f8b8593b793d24c9cb3",
  //     "senderName" : this.currentUser.email,
  //     "senderId" : this.currentUser._id,
  //     "senderType": "influencer",
  //     "receiverName": "admin@influencelab.co",
  //     "body": this.newMessage,
  //     "creationDate": new Date(),
  //   }
  //   this.apiService.sendMessage(newmessage).subscribe(res => {
  //     this.toastr.success('Message sent to admin@influencelab.co');
	// 		this.newMessage = ""
  //     this.cdRef.detectChanges();
  //   })
  // }

  openDialogError(compenentName: any, option : any) {
    const dialogRef = this.dialog.open(compenentName, option);
    dialogRef.afterClosed().subscribe(result => {
      this.apiService.getUser().subscribe(user => {
        this.currentUser = user
      })
    });
}

}
