<!DOCTYPE html>
<html>

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
	<meta name="description" content="INFLUENCE LAB">
	<meta name="keywords" content="webapp, admin, influencer, login">
	<meta name="author" content="INFLUENCE LAB">
	<title>INFLUENCE LAB</title>
</head>

<body style="background-color: black;">
	<div class="main">

		<div class="auth-container">
			<!-- site logo -->
			<div flex-center>
				<a href="/"><img src="/assets/images/logo/influenceLabLogo.png" height="300px" width="300px;"
						class="img-fluid" /></a>
			</div>
			<div flex-center>
				<h3 class="text-normal text-center mt-4 mb-4" style="color: white;">Sign in to your account</h3>
			</div>
			<div class="form-container">
				<form [formGroup]="inputs">
                    <div class="form-group form-group-lg">
						<input class="form-control" type="email" formControlName="username" placeholder="Email" required="required">
					</div>
					<div class="form-group form-group-lg">
						<input class="form-control" type="password" formControlName="password" placeholder="Password" required="required">
					</div>
                    <div class="center-block mb15">
						<button type="submit" (click)="submit()" class="btn btncustom btn-lg w-120 btn-light text-uppercase">Sign In</button>
					</div>
                </form>
			</div>
		</div>
	</div>
</body>
</html>