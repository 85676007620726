import { ApiService } from 'src/app/service/api.service';
import {OfferModel} from 'src/app/model/offer-model.model'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ShowInstagramContenueComponent } from 'src/app/dialog/show-instagram-contenue/show-instagram-contenue.component';
import { ShowYoutubeContenueComponent } from 'src/app/dialog/show-youtube-contenue/show-youtube-contenue.component';
import { ShowFacebookContenueComponent } from 'src/app/dialog/show-facebook-contenue/show-facebook-contenue.component';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {toast} from 'src/app/model/toast'

@Component({
  selector: 'app-offer-detail-admin',
  templateUrl: './offer-detail-admin.component.html',
  styleUrls: ['./offer-detail-admin.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css']
})

export class OfferDetailAdminComponent implements OnInit {
  dataSourceOffer: any;
  loadOffer = false
  createdashboardOffer = false
  dashboardOffer = false
  dataContenuJson : any = {}
  isDisabled = true
  billing : Boolean = false
  toast = toast
  newMessage : string = ""
  panelOpenState = false;
  public isCollapsed = false;
  constructor(
    private apiService : ApiService,
    public fctBasic: CampagneAndOfferFctService,
    private cdRef:ChangeDetectorRef,
    public router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) { 
    
  }

  ngOnInit(): void {
    this.apiService.getOffre(this.route.snapshot.params.id).subscribe(offer => { 
      if (offer && offer != 400){
        this.dataSourceOffer = offer;
        if (this.dataSourceOffer.status == "Live" || this.dataSourceOffer.status == "Accepted"){
          this.createdashboardOffer = true
        }
        if(this.dataSourceOffer.status == "Payment Pending" || (this.dataSourceOffer.billing && this.dataSourceOffer.billing.invoice.src)){
          this.billing = true
        }
        if (this.dataSourceOffer.posts.length > 0){
          this.dashboardOffer = true
        }
        this.loadOffer = true
        this.cdRef.detectChanges();
      } else {
        this.router.navigate(['/listOffers'])
      }
      

      // console.log("this page ", this.pageSize)
    })
  }

  openDialogError(compenentName: any, option : any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.dataContenuJson
    const dialogRef = this.dialog.open(compenentName, option);
    dialogRef.afterClosed().subscribe(result => {
      
      console.log(`Dialog result: ${result}`);
    });
}

  clickDialogSelectContent(){
    switch (this.dataSourceOffer.platformeType) {
      case "Instagram":
        this.apiService.getIgContenue(this.dataSourceOffer._id).subscribe(offer => {
          if (offer && offer.posts){
            this.dataContenuJson.posts = offer.posts.data
          }
          if (offer && offer.storys){
            this.dataContenuJson.story = offer.storys.data
          }
          
          this.dataContenuJson.offer = this.dataSourceOffer
          this.openDialogError(ShowInstagramContenueComponent, {
            height: '500px',
            width: '600px',
            data: this.dataContenuJson
          },)
        })
        break;

      case "Facebook":
        this.apiService.getFbContenue(this.dataSourceOffer._id).subscribe(postsArray => {
          if (postsArray){
            this.dataContenuJson.posts = postsArray
          }
          this.dataContenuJson.offer = this.dataSourceOffer
          this.openDialogError(ShowFacebookContenueComponent, {
            height: '500px',
            width: '600px',
            data: this.dataContenuJson
          },)
        })
        break;

      case "Youtube":
        this.apiService.getYtContenue(this.dataSourceOffer._id).subscribe(videoArray => {
          if (videoArray && videoArray.items){
            this.dataContenuJson.video = videoArray.items
          }
          
          this.dataContenuJson.offer = this.dataSourceOffer
          this.openDialogError(ShowYoutubeContenueComponent, {
            height: '500px',
            width: '600px',
            data: this.dataContenuJson
          },)
        })
        break;

      case "Blog":
      
        break;

      case "multiNetwork":
        if (this.dataSourceOffer && this.dataSourceOffer.influencerObjects && this.dataSourceOffer.influencerObjects.facebookPage && this.dataSourceOffer.influencerObjects.facebookPage.token && this.dataSourceOffer.influencerObjects.facebookPage.instagramObject && this.dataSourceOffer.influencerObjects.facebookPage.instagramObject.id){
          this.apiService.getIgContenue(this.dataSourceOffer._id).subscribe(offer => {
            if (offer && offer.posts){
              this.dataContenuJson.posts = offer.posts.data
            }
            if (offer && offer.storys){
              this.dataContenuJson.story = offer.storys.data
            }
            
            this.dataContenuJson.offer = this.dataSourceOffer
            this.openDialogError(ShowInstagramContenueComponent, {
              height: '500px',
              width: '600px',
              data: this.dataContenuJson
            },)
          })
        }
        break;
    
      default:
        break;
    }
    
  }


  clickDialogFb(){
    this.apiService.getIgContenue(this.dataSourceOffer._id).subscribe(offer => {
      this.dataContenuJson.posts = offer.posts.data
      this.dataContenuJson.story = offer.storys.data
      this.dataContenuJson.offer = this.dataSourceOffer
      this.openDialogError(ShowInstagramContenueComponent, {data: this.dataContenuJson})
    })
  }

  changeStatusOffer(option : any){
    if (!option.value){
      this.toastr.warning(this.toast.changeStatus)
      return
    } else {
      this.dataSourceOffer.status = option.value;
     
      this.apiService.getChangeStatusOffer(option.value, this.dataSourceOffer._id).subscribe(resOffer => {
        this.dataSourceOffer = resOffer
        this.cdRef.detectChanges();
        this.toastr.success(this.toast.statusmodified)
        if (option.value == "Live" || option.value == "Accepted"){
          this.createdashboardOffer = true
          this.cdRef.detectChanges();
          if (this.dataSourceOffer.posts.length > 0){
            this.dashboardOffer = true
            this.cdRef.detectChanges();
          }
        } else {
          this.createdashboardOffer = false
          this.cdRef.detectChanges();
          if (this.dataSourceOffer.posts.length > 0){
            this.dashboardOffer = true
            this.cdRef.detectChanges();
          }
        }
      })
    }
  }

  showProfileInf(){
    try {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/detail-influencer/${this.dataSourceOffer.influencerObjects.userId}`])
      );
      window.open(url, '_blank');
    } catch (error) {
      
    }  
  }

  showDashboard(){
    if (this.dataSourceOffer.posts.length > 0){
      switch (this.dataSourceOffer.platformeType) {
        case "Youtube":
          const urlYt = this.router.serializeUrl(
            this.router.createUrlTree([`/offerDashboard-youtube/${this.dataSourceOffer._id}`])
          );
          window.open(urlYt, '_blank');
          break;
        default:
          const url = this.router.serializeUrl(
            this.router.createUrlTree([`/offerDashboard/${this.dataSourceOffer._id}`])
          );
          window.open(url, '_blank');
          break;
      }
     
    } else {
      this.toastr.warning("No contents to display at the moment", "Warning")
    }
    
  }

  addMessageBox(newMessage : any){
    if (!this.dataSourceOffer.boxMessage){
      this.dataSourceOffer.boxMessage = []
    }
    if (!newMessage){
      this.toastr.warning(this.toast.InvalidEntryMessageOffer)
      return 
    }
    let newJsonMessage = {
      message: newMessage,
      creationDate: new Date()
    }
    this.dataSourceOffer.boxMessage.push(newJsonMessage)
    this.apiService.putOffre(this.route.snapshot.params.id, this.dataSourceOffer).subscribe(offer => {
      this.toastr.success(this.toast.modificationMessageOffer);
      this.newMessage = ""
      this.cdRef.detectChanges();
    })
    
  }

  canPutMessage(elem : any){
    elem.isDisabled = !elem.isDisabled
    this.toastr.success(this.toast.editmessageOffer)
  }

  putMessageBox(elem: any, index: number){
    if (!this.dataSourceOffer.boxMessage){
      this.dataSourceOffer.boxMessage = []
    }
    this.dataSourceOffer.boxMessage[index].newMessage = elem.newMessage
    this.dataSourceOffer.boxMessage[index].creationDate = new Date()
    this.apiService.putOffre(this.route.snapshot.params.id, this.dataSourceOffer).subscribe(offer => {
      this.toastr.success(this.toast.modificationMessageOffer);
      elem.isDisabled = !elem.isDisabled
      this.cdRef.detectChanges();
    })
  }

  deleteMessageBox(index: number){
    if (!this.dataSourceOffer.boxMessage){
      this.dataSourceOffer.boxMessage = []
    }
    this.dataSourceOffer.boxMessage.splice(index, 1)
    this.apiService.putOffre(this.route.snapshot.params.id, this.dataSourceOffer).subscribe(offer => {
      this.toastr.success(this.toast.deletemessage);
      this.cdRef.detectChanges();
    })
    
  }

  openOfferBilling(){
    const billing = this.router.serializeUrl(
      this.router.createUrlTree([`/invoice/${this.dataSourceOffer._id}`])
    );
    window.open(billing, '_blank');
  }

}
