<html>
    <body>
        <div class="container page">
            <a [routerLink]="['/list-company']" class="fa fa-arrow-left col-lg-1" style="color:black;font-size:20px;"></a>

            <mat-card class="mt-1">
                <mat-card-content class=" mt-1">
                    <form *ngIf="show" [formGroup]="company" >
                        <div class="row input-row">
                            <div class="col-lg-12 col-md-12 col-sm-12 display-mid">
                                <div class="col-lg-2 col-md-2">
                                    <mat-label class="labelFont">Company Name</mat-label>
                                </div>
                                <div class="col-lg-10 col-md-10">
                                    <mat-form-field >
                                        <input type="text" matInput placeholder=""  formControlName="companyName">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-6 col-sm-12  display-mid ">
                                    <div class="col-lg-4   col-md-4">
                                        <mat-label class="labelFont">Siren</mat-label>
                                    </div>
                                    <div class="col-lg-8   col-md-8 ">
                                        <mat-form-field >
                                            <input mask="separator.2" matInput placeholder=""  formControlName="siren">
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-md-6  col-lg-6  display-mid col-sm-12">
                                    <div class="col-lg-4 col-md-4 ">
                                        <mat-label class="labelFont">Siret</mat-label>
                                    </div>
                                    <div class="col-lg-8  col-md-8 ">
                                        <mat-form-field >
                                            <input mask="separator.2" matInput placeholder=""  formControlName="siret">
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-12 col-sm-12  display-mid ">
                                    <div class="col-lg-2   col-md-4 ">
                                        <mat-label class="labelFont">Filiale</mat-label>
                                    </div>
                                    <div class="col-lg-10   col-md-8 ">
                                        <mat-form-field >
                                            <mat-select formControlName="filiale">
                                                <mat-option  *ngFor="let contact of listContact" [value]="contact.companyName">
                                                    {{ contact.companyName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                            </div>
                             
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-6  col-lg-12 display-mid  col-sm-12">
                                    <div class="col-lg-2 col-md-2 ">
                                        <mat-label class="labelFont">Type</mat-label>
                                    </div>
                                    <div class="col-lg-10  col-md-10">
                                        <mat-form-field>
                                            <mat-select formControlName="type">
                                                <mat-option [value]="'agence'">
                                                    Agence
                                                </mat-option>
                                                <mat-option [value]="'annonceur'">
                                                    Annonceur
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div class="row input-row">
                            <div class="col-md-12 col-lg-12 col-sm-12 display-mid">
                                <div class="col-lg-2   col-md-2 ">
                                    <mat-label class="labelFont"> Upload Attestation Mandat </mat-label>
                                    <br>
                                    <mat-label class="textsecondary">We accept files in the following formats : .docx, .ppt, .xlsx</mat-label>

                                </div>
                                <div class="col-lg-10 col-md-10">
                                    <mat-form-field>
                                        <ngx-mat-file-input   ></ngx-mat-file-input>
                                        <mat-icon matSuffix>attach_file</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-12 col-sm-12  display-mid ">
                                <div class="col-lg-2 col-md-4">
                                    <mat-label class="labelFont">Email</mat-label>
                                </div>
                                <div class="col-lg-10  col-md-8 ">
                                    <mat-form-field >
                                        <input type="email" matInput placeholder=""  formControlName="email">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6  col-lg-12 display-mid  col-sm-12">
                                <div class="col-lg-2 col-md-4">
                                    <mat-label class="labelFont">Télephone</mat-label>
                                </div>
                                <div class="col-lg-10  col-md-8 ">
                                    <mat-form-field >
                                        <input mask="00 00 00 00 00"  matInput placeholder=""  formControlName="phone">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6 col-lg-12 col-sm-12 display-mid  ">
                                <div class="col-lg-2 col-md-4">
                                    <mat-label class="labelFont">website</mat-label>
                                </div>
                                <div class="col-lg-10  col-md-8 ">
                                    <mat-form-field >
                                        <input type="text" matInput placeholder=""  formControlName="website">
                                    </mat-form-field>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row input-row">
                            <div class="col-md-6  col-lg-12 display-mid  col-sm-12">
                                <div class="col-lg-2 col-md-4">
                                    <mat-label class="labelFont">Fax</mat-label>
                                </div>
                                <div class="col-lg-10  col-md-8 ">
                                    <mat-form-field >
                                        <input  type="number" matInput placeholder=""  formControlName="fax">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-12 col-sm-12  display-mid ">
                                    <div class="col-lg-2   col-md-4 ">
                                        <mat-label class="labelFont">Prélèvement</mat-label>
                                    </div>
                                    <div class="col-lg-10   col-md-8 ">
                                        <mat-form-field>
                                            <mat-select formControlName="prelevement">
                                                <mat-option [value]="'true'">
                                                    Oui
                                                </mat-option>
                                                <mat-option [value]="'false'">
                                                    Non
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                            </div> 
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-6  col-lg-12 display-mid  col-sm-12">
                                    <div class="col-lg-2 col-md-2 ">
                                        <mat-label class="labelFont">TVA</mat-label>
                                    </div>
                                    <div class="col-lg-10  col-md-12">
                                        <mat-form-field >
                                            <input mask="separator.2" matInput placeholder=""  formControlName="tvaIntracommunautaire">
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div class="row input-row  ">
                            <div class="col-md-6 col-lg-12 col-sm-12  display-mid  ">
                                    <div class="col-lg-2   col-md-4 ">
                                        <mat-label class="labelFont">Secteur</mat-label>
                                    </div>
                                    <div class="col-lg-10 col-md-8">
                                        <mat-form-field >
                                            <input matInput type="text"  placeholder=""  formControlName="secteur">
                                        </mat-form-field>
                                    </div>
                            </div>
                            
                        </div>
                        <div class="row input-row  ">
                            
                            <div class="col-md-6  col-lg-12 display-mid   col-sm-12">
                                    <div class="col-lg-2 col-md-4 ">
                                        <mat-label class="labelFont">Ape Code</mat-label>
                                    </div>
                                    <div class="col-lg-10  col-md-8">
                                        <mat-form-field >
                                            <input type="text"  matInput placeholder=""  formControlName="apeCode">
                                        </mat-form-field>
                                    </div>
                            </div>  
                        </div>
                        <div flex-center class="mt-2 mb-3">
                            <button (click)="modifCompany()"  class="btn btn-secondaryWithBorder shadow-none ">Save Modifications</button>
                        </div>
                    </form>
                    
                </mat-card-content>
            </mat-card>
        </div>
    </body>
</html>