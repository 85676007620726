<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="">
  </head>
    <body>
        <app-header></app-header>
      <section class="projects-section bg-light" style="margin-top:5%;">
        <div class="container">
        <div>
              <h1 class="text-center logo">CONDITIONS GENERALES D’UTILISATIONDU SITE ET DE LA PLATEFORME INFLUENCE LAB</h1>
              <p class=" textcard  mt-4">Les présentes Conditions Générales d’Utilisation (les  «CGU») régissent votre navigation sur le site Internet influencelab.co et votre utilisation de la plateforme proposée via ce site.</p>
              <p class=" textcard  mt-4"><b>En   naviguant   sur   le   site   et   en   utilisant   la   plateforme,   vous   êtes réputé(e) accepter sans réserve les CGU, et vous ne pourrez en aucuncas   vous   prévaloir   d’un   quelconque   défaut   de   connaissance   ou d’acceptation de ces dernières.</b></p>
              <p class=" textcard  mt-4">Si vous êtes un influenceur inscrit sur la plateforme, les CGU doivent être lues en combinaison avec le Contrat Influenceur (voir ci-après), auquel elles s’ajoutent sans rien y retrancher.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">1) Definitions</h1>
              <p class=" textcard  mt-4">Au   sein   des   présentes   CGU,   les   termes   et   expressions   ci-après   auront   les définitions suivantes:</p>
              <li class="textcard  mt-4">«Compte Utilisateur»: le droit d’accès personnel de l’Utilisateur à la Plateforme, matérialisé par un couple d’Identifiants unique et un espace personnel sur la Plateforme.</li>
              <li class="textcard  mt-4">«Contrat Influenceur»: le contrat conclu entre INFLUENCE LAB et les Utilisateurs, dont l’acceptation par ces derniers est obligatoire pour pouvoir accéder à la Plateforme, et dont le modèle peut être consulté en <a href="/legal/contratInfluencer">cliquant ici.</a></li>
              <li class="textcard  mt-4">«Identifiants»:   le   couple   unique   constitué   par   l’adresse   email de l’Utilisateur (saisie lors de son inscription à la Plateforme) et le mot de passe choisi par ce dernier.</li>
              <li class="textcard  mt-4">«INFLUENCE LAB»: la société INFLUENCE LAB, dont les coordonnées sont disponibles sur la page  «Mentions légales»  du Site, qui édite, propose et gère le Site et la Plateforme.</li>
              <li class="textcard  mt-4">«Plateforme»: la   plateforme   d’intermédiation  proposée  et gérée par INFLUENCE LAB, accessible aux Utilisateurs via le Site.</li>
              <li class="textcard  mt-4">«Site»: le présent site Internet, à savoir l’ensemble des pages et contenus accessibles sous le nom de domaine influencelab.co.</li>
              <li class="textcard  mt-4">«Utilisateur»: tout Visiteur s’étant inscrit en son nom propre, par elle-même ou par le biais d’un représentant dûment mandaté, en suivant la procédure décrite à l’article 3.2. ci-après. Dans un souci de clarté, il est expressément souligné que l’Utilisateur est un Visiteur au sens des présentes CGU.</li>
              <li class="textcard  mt-4">«Visiteur»: toute personne physique accédant au Site et consultant les pages et contenus de ce dernier, qu’elle soit ou non un Utilisateur.</li>
              <p class="textcard   mt-4">L’ensemble des termes et expressions ci-avant peuvent être employés aussi bienau singulier qu’au pluriel.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">2) Objet de la Plateforme</h1>
              <p class=" textcard  mt-4">La Plateforme est conçue, proposée et gérée par INFLUENCE LAB, et vise à permettre à des «micro-influenceurs» (les Utilisateurs) de contribuer à des campagnes publicitaires initiées par des annonceurs clients d’INFLUENCE LAB sur différents réseaux sociaux, moyennant leur inscription et l’acceptation des CGU ainsi que du Contrat Influenceur.</p>
              <p class=" textcard  mt-4">En s’inscrivant à la Plateforme, l’Utilisateur dispose ainsi d’un outil pour mettresa visibilité sur Internet au service des annonceurs clients d’INFLUENCE LAB, et obtenir en contrepartie une rémunération (selon les conditions fixées dans le Contrat Influenceur).</p>
              <p class=" textcard  mt-4">La Plateforme fonctionne au format «managed services». INFLUENCE LAB se chargera donc de sélectionner et recruter les Utilisateurs pertinents pour la réalisation des campagnes publicitaires demandées par ses clients annonceurs, sur la base de critères relatifs (par exemple) à l’objet de la campagne, à la sphère d’influence de l’Utilisateur et aux réseaux sociaux utilisés par ce dernier,le tout selon les conditions du Contrat Influenceur et en utilisant ses propres algorithmes de sélection.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
          <h1 class="text-center logo">3) Navigation et inscription</h1>
          <h4 class="mt-4"><b>3.1 Navigation sur le site</b></h4>
              <p class=" textcard  mt-4">Le Site est publiquement accessible à la navigation, pour tout Visiteur disposant d’une connexion Internet, sans condition d’âge et de façon gratuite (hormis les coûts de la connexion Internet, qui resteront en toute hypothèse à la charge du Visiteur).</p>
          <h4 class="mt-4"><b>3.2 Inscription et accès à la Plateforme</b></h4>
          <p class=" textcard  mt-4">L’accès à la Plateforme est réservé aux Utilisateurs.</p>
          <p class=" textcard  mt-4">Le Visiteur qui souhaiterait accéder à la Plateforme doit donc préalablement procéder à son inscription, soit lui-même soit par le biais d’un représentant dûment mandaté, en utilisant le formulaire d’inscription disponible en <a href="/signup">cliquant ici.</a></p>
          <p class=" textcard  mt-4">Si le Visiteur est âgé de moins de dix-huit (18) ans, il ne peut s’inscrire à la Plateforme qu’avec l’autorisation expresse écrite de son ou ses représentant(s) légal(aux). Le Visiteur âgé de moins de dix-huit (18) ans et ou ses représentant(s) légal(aux) s’engagent solidairement à tenir à la disposition d’INFLUENCE LAB une copie de cette autorisation expresse écrite, et à la lui fournir à première demande.</p>
          <p class=" textcard  mt-4"><b>L’inscription est en toute hypothèse interdite aux Visiteurs de moins de quinze (15) ans.</b></p>
          <p class=" textcard  mt-4">La finalisation de son inscription nécessite pour l’Utilisateur de suivre un lien qui lui est automatiquement adressé par courrier électronique à son adresse telle qu’indiquée dans le formulaire d’inscription.</p>
          <p class=" textcard  mt-4">L’Utilisateur garantit que les informations saisies lors de son inscription sont exactes et sont bien relatives à sa propre personne, quand bien même elles n’auraient pas été saisies par l’Utilisateur lui-même. L’utilisation du Compte Utilisateur est en effet strictement personnelle et ne saurait être cédée, louée, déléguée ou autrement transférée en tout ou partie à un quelconque tiers, à l’exception d’un représentant dûment mandaté par l’Utilisateur (auquel cas l’Utilisateur s’engage à tenir à la disposition d’INFLUENCE LAB une copie écrite de ce mandat, et à la lui fournir à première demande) ou d’un représentant légal de l’Utilisateur si ce dernier est âgé de moins de dix-huit (18) ans.</p>
          <p class=" textcard  mt-4">L’Utilisateur s’engage à mettre à jour et à corriger les informations liées à son Compte Utilisateur lorsque celles-ci ne seraient plus exactes du fait par exemple d’un changement de situation personnelle ou professionnelle de l’Utilisateur.</p>
          <p class=" textcard  mt-4">Tout manquement à ce qui précède, et plus généralement aux obligations de l’Utilisateur fixées par les CGU et/ou le Contrat Influenceur, pourra justifier la suspension sans préavis du Compte Utilisateur, voire sa résiliation dans les conditions fixées ci-après (Article 10).</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">4)	Propriété intellectuelle</h1>
              <p class=" textcard  mt-4">Aucune stipulation des CGU ne sauraient être interprétées comme octroyant au Visiteur un quelconque droit sur les éléments constitutifs du Site ou de la Plateforme, ni sur les marques, dessins, logos, textes, illustrations, logiciels, éléments sonores et visuels d’INFLUENCE LAB ou exploités par cette dernière.</p>
              <p class=" textcard  mt-4">De façon générale, le Visiteur n’est autorisé à faire aucun usage des éléments visés ci-avant qui excéderait ou serait incompatible avec une utilisation normale du Site et de la Plateforme, à savoir la simple consultation du Site et l’utilisation de la Plateforme conformément aux CGU et au Contrat Influenceur.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">5) Gestion des données personnelles</h1>
              <p class=" textcard  mt-4">INFLUENCE LAB accorde une importance cruciale à la protection de la vie privée de ses Visiteurs et Utilisateurs.</p>
              <p class=" textcard  mt-4">L'Utilisateur et le Visiteur sont informés des réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
              <h4 class="mt-4"><b>5.1 Responsable de la collecte des données personnelles</b></h4>
              <p class=" textcard  mt-4">Pour les Données Personnelles collectées dans le cadre de la création du compte personnel de l’Utilisateur et de sa navigation sur le Site, le responsable du traitement des Données Personnelles est : INFLUENCE LAB. INFLUENCE LAB est représenté par Anouck ROBERTY, son représentant légal.</p>
              <p class=" textcard  mt-4">En tant que responsable du traitement des données qu’il collecte, INFLUENCE LAB s’engage à respecter le cadre des dispositions légales en vigueur. Il lui appartient notamment d’établir les finalités de ses traitements de données, de fournir à ses prospects et clients, à partir de la collecte de leurs consentements, une information complète sur le traitement de leurs données personnelles et de maintenir un registre des traitements conforme à la réalité. Chaque fois que INFLUENCE LAB traite des Données Personnelles, INFLUENCE LAB prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour lesquelles INFLUENCE LAB les traite.</p>
              <h4 class="mt-4"><b>5.2 Finalité des données collectées</b></h4>
              <p class=" textcard  mt-4">INFLUENCE LAB est susceptible de traiter tout ou partie des données :</p>
              <li class="textcard mt-4"> pour permettre la navigation sur le Site et la gestion et la traçabilité des prestations et services commandés par l’Utilisateur et le Visiteur : données de connexion et d’utilisation du Site, facturation, historique des commandes, etc.</li>
              <li class="textcard mt-4">pour prévenir et lutter contre la fraude informatique (spamming, hacking…) : matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe (hashé)</li>
              <li class="textcard mt-4"> pour améliorer la navigation sur le Site : données de connexion et d’utilisation</li>
              <li class="textcard mt-4">pour mener des enquêtes de satisfaction facultatives sur le Site ou la Plateforme</li>
              <li class="textcard mt-4">pour mener à bien l'éxécution de Campagnes Publicitaires dans le cadre de l'acceptation d'une Offre, par l'Utilisateur dans les conditions définies dans le <a href="/legal/contratInfluencer" target="_blank">Contrat Influenceur</a> : noms, prénoms, pseudo, adresse, informations bancaires, données recoltés via les API YouTube, Facebook, Instagram ou Google telles que les données  d'audience ; statistiques et indicateurs de performance des publications et des profils sur les réseaux sociaux et blog (nombre d'abonnés, de likes, de partages, de vues, etc.).</li>
              <h4 class="mt-4"><b>5.3 Droit d’accès, de rectification et d’opposition</b></h4>
              <li class="textcard  mt-4">droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs et des Visiteurs droit de verrouillage ou d’effacement des données des Utilisateurs et des Visiteurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite</li>
              <li class="textcard  mt-4">droit de retirer à tout moment un consentement (article 13-2c RGPD)</li>
              <li class="textcard  mt-4">droit à la limitation du traitement des données des Utilisateurs et des Visiteurs (article 18 RGPD</li>
              <li class="textcard  mt-4">droit d’opposition au traitement des données des Utilisateurs et des Visiteurs (article 21 RGPD)</li>
              <li class="textcard  mt-4">droit à la portabilité des données que les Utilisateurs et les Visiteurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD)</li>
              <li class="textcard  mt-4">droit de définir le sort des données des Utilisateurs et des Visiteurs après leur mort et de choisir à qui INFLUENCE LAB devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
              <p class="mt-4 text-card">Dès que INFLUENCE LAB a connaissance du décès d’un Utilisateur ou d'un Visiteur et à défaut d’instructions de sa part, INFLUENCE LAB s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.</p>
              <p class="mt-4 text-card">Si l’Utilisateur ou le Visiteur souhaite savoir comment INFLUENCE LAB utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur et le Visiteur peuvent contacter INFLUENCE LAB par écrit à l’adresse suivante :</p>
              <li class="mt-4 text-card">INFLUENCE LAB – DPO, Anouck ROBERTY 90 rue Raynouard 75016 PARIS</li>
              <p class="mt-4 text-card">Dans ce cas, l’Utilisateur ou le Visiteur doit indiquer les Données Personnelles qu’il souhaiterait que INFLUENCE LAB corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport).</p>
              <p class="mt-4 text-card">Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à INFLUENCE LAB par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs et Visiteurs de INFLUENCE LAB peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (https://www.cnil.fr/fr/plaintes).</p>
              <h4 class="mt-4"><b>5.4 Non-communication des données personnelles</b></h4>
              <p class="mt-4 text-card">INFLUENCE LAB s’interdit de traiter, héberger ou transférer les Informations collectées sur ses Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour autant, INFLUENCE LAB reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’il présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
              <p class="mt-4 text-card">INFLUENCE LAB s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de INFLUENCE LAB, celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les mesures de corrections prises. Par ailleurs INFLUENCE LAB ne collecte aucune « données sensibles ».</p>
              <p class="mt-4 text-card">Les Données Personnelles de l’Utilisateur et le Visiteur peuvent être traitées par des filiales de INFLUENCE LAB et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la présente politique.</p>
              <p class="mt-4 text-card">Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des et des Visiteurs de INFLUENCE LAB sont principalement les agents de notre service client.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
          <h1 class="text-center logo">6) Responsabilité</h1>
          <h4 class="mt-4"><b>6.1 Responsabilité d’INFLUENCE LAB</b></h4>
              <p class=" textcard  mt-4">La mise à disposition du Site et de la Plateforme reposant sur des infrastructures et outils par nature évolutifs et insusceptibles de toute garantie, INFLUENCE LAB n’est tenue, à l’égard de cette mise à disposition, que d’une obligation de moyens, appréciée au regard des capacités, ressources et compétences techniques d’INFLUENCE LAB, des solutions raisonnablement exigibles compte tenu de la nature du Site et de la Plateforme, et de l’état des techniques à date du manquement allégué.</p>
              <p class=" textcard  mt-4">Le Visiteur comprend et accepte donc qu’INFLUENCE LAB ne saurait garantir une disponibilité permanente et un fonctionnement exempt de toute faille du Site et de la Plateforme.</p>
              <p class=" textcard  mt-4">INFLUENCE LAB ne pourra par ailleurs être tenue responsable dans le cas où le manquement allégué à ses obligations aurait pour cause un cas de force majeure, un fait de la victime alléguée, ou un fait d’un tiers extérieur à la mise à disposition du Site et de la Plateforme.</p>
          <h4 class="mt-4"><b>6.2 Responsabilité de l’Utilisateur</b></h4>
          <p class=" textcard  mt-4">L’Utilisateur répond seul et en son nom propre de l’utilisation de son Compte Utilisateur, quand bien même il aurait confié, autorisé ou permis par sa négligence l’utilisation de ce Compte Utilisateur par un tiers.</p>
          <p class=" textcard  mt-4">L’Utilisateur est de même seul responsable de la sécurité et de la confidentialité des Identifiants liés à son Compte Utilisateur. En cas de perte ou de compromission de ses Identifiants, ou plus généralement de suspicion d’un risque d’accès non autorisé à son Compte Utilisateur, l’Utilisateur s’engage à modifier immédiatement ses Identifiants via les outils mis à disposition sur le Site, et à en informer sans délai INFLUENCE LAB.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">7)	Mise à jour</h1>
              <p class=" textcard  mt-4">INFLUENCE LAB se réserve le droit de mettre à jour les CGU à tout moment.</p>
              <p class=" textcard  mt-4">Les Visiteurs en seront avertis par le biais d’un message d’information à première connexion suivant la date de mise à jour.</p>
              <p class=" textcard  mt-4">En cas de désaccord avec le contenu des nouvelles CGU, les Utilisateurs disposeront de la faculté de résilier leur Contrat Influenceur en supprimant leur Compte Utilisateur conformément à l’Article 10.1 ci-après.</p>
          </div>
        </div>
      </section>

      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">8)	Correspondance</h1>
              <p class=" textcard  mt-4">Toute communication, question ou contestation relative aux CGU, au Contrat Influenceur, au Site ou à la Plateforme doit être adressée à INFLUENCE LAB aux coordonnées indiquées sur la page « Mentions Légales » du Site.</p>
              <p class=" textcard  mt-4">Toute communication à l’Utilisateur sera, sauf demande contraire, expresse et écrite de la part de cet Utilisateur, adressée par INFLUENCE LAB à l’adresse de courrier électronique, à l’adresse postale ou au numéro de téléphone associés au Compte Utilisateur de cet Utilisateur à la date de l’envoi.</p>
          </div>
        </div>
      </section>
      <section class="projects-section bg-light">
        <div class="container">
        <div>
              <h1 class="text-center logo">9)	Droit applicable et juridiction compétente</h1>
              <p class=" textcard  mt-4">Dans l’hypothèse où (i) le Visiteur est un professionnel utilisant le Site et/ou la Plateforme à des fins entrant dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole, ou représente un tel professionnel, ainsi que dans l’hypothèse où (ii) le Visiteur serait un ressortissant ou serait domicilié sur le territoire d’un autre Etat que la France, toute contestation, différend, réclamation ou litige concernant l’existence, la validité, l’interprétation ou l’exécution des CGU sera soumis(e) au droit français, et tranché(e) par les juridictions compétentes du ressort de Paris, nonobstant toute pluralité de défendeurs et tout appel en garantie.</p>
          </div>
        </div>
      </section>
      <section class="projects-section bg-light">
        <div class="container">
        <div>
          <h1 class="text-center logo">10) Résiliation</h1>
          <h4 class="mt-4"><b>10.1 Résiliation au choix de l’Utilisateur</b></h4>
              <p class=" textcard  mt-4">L’Utilisateur dispose à tout moment de la faculté de demander la suppression de son Compte Utilisateur, sous réserve de s’être pleinement acquitté au préalable de toute obligation qui pourrait lui incomber au titre notamment d’une campagne publicitaire en cours.</p>
              <p class=" textcard  mt-4">La suppression du Compte Utilisateur entraîne de plein droit résiliation des CGU et du Contrat Influenceur à l’égard de l’Utilisateur concerné.</p>
              <p class=" textcard  mt-4">Il appartient à l’Utilisateur qui demande la suppression de son Compte Utilisateur de s’assurer qu’il a bien obtenu le versement de la totalité des sommes qui pourraient lui rester dues à cette date, et à défaut de demander ce versement à INFLUENCE LAB par écrit ou via la fonctionnalité dédiée de la Plateforme.</p>
          <h4 class="mt-4"><b>10.2 Résiliation pour faute de l’Utilisateur</b></h4>
          <p class=" textcard  mt-4">INFLUENCE LAB se réserve le droit, en cas de manquement constaté aux CGU et/ou au Contrat Influenceur, de suspendre le Compte Utilisateur de l’Utilisateur concerné.</p>
          <p class=" textcard  mt-4">Dans l’hypothèse de manquements graves et/ou répétés, INFLUENCE LAB pourra, après avoir mis en demeure l’Utilisateur concerné de fournir des justifications appropriées, procéder à la suppression de son Compte Utilisateur, sans qu’il puisse en résulter pour ce dernier aucun droit à indemnisation.</p>
          <p class=" textcard  mt-4">La suppression d’un Compte Utilisateur s’entend comme la résiliation des CGU et du Contrat Utilisateur à l’égard de l’Utilisateur concerné, aux torts exclusifs de ce dernier. Cette résiliation s’entend sans préjudice de l’indemnisation qui pourrait être due à INFLUENCE LAB au titre des préjudices subis du fait du comportement de l’Utilisateur.</p>
          </div>
        </div>
      </section>

      <!-- Footer -->
      <footer class="bg-black small text-center text-white-50">
        <div class="container-fluid">
          <span>Copyright &copy; Influence Lab</span>
          <a href="/legal">
            <span> Legal</span>
          </a>
        </div>
      </footer>
  </body>
</html>
