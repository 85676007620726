<h4 class="dialog-headline-yt" mat-dialog-title>DASHBOARD
        CREATION <i class="fa fa-youtube"></i></h4>
        <mat-dialog-content style="width: auto;height: auto;" class=" mt-2">
            <p class="mt-1">Create dashboard for Post</p>
            <select class="form-control mb-1" [(ngModel)]="selectedDevice" (ngModelChange)="onChange('Post')">
                <option value=""></option>
                <option *ngFor="let x of videoList; index as i" [ngValue]="x" >{{x.snippet.title}}</option>
            </select>
            <div class="right align-items-end">
                <div *ngIf="contenueVideoPost.length > 0" class=" mt-2 ">
                    <span (click)="deleteItemPosts(z, i)" *ngFor="let z of contenueVideoPost; index as i" class="badge mt-1 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                        #{{ z.snippet.title | slice:0:10 }} x 
                    </span>
                </div>
            </div>
    </mat-dialog-content>
    <mat-dialog-actions>
            <div class="col-12 text-center">
                <button type="button" mat-dialog-close  (click)="createDasboardOnDb()" class="btn btn-lg btn-secondary btn-template justify-content">GO !</button>
            </div>
    </mat-dialog-actions>
