import { ApiService } from 'src/app/service/api.service';
import {OfferModel} from 'src/app/model/offer-model.model'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { Component, OnInit,ChangeDetectorRef  } from '@angular/core';
import { Router} from '@angular/router';
import {UserLoginService} from 'src/app/service/user-login.service';

@Component({
  selector: 'app-list-offer-influenceur',
  templateUrl: './list-offer-influenceur.component.html',
  styleUrls: ['./list-offer-influenceur.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class ListOfferInfluenceurComponent implements OnInit {
  dataSourceOffer: any[];
  p: number = 1;
  count: number = 50;
  loadOffer = false
  currentUser : any

  constructor(
    private apiService : ApiService,
    public fctBasic: CampagneAndOfferFctService,
    private cdRef:ChangeDetectorRef,
    public router: Router,
    private authService : UserLoginService, 
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getUserValue()
    this.apiService.getOffreInfluencer(this.currentUser).subscribe(offers => { 
      this.dataSourceOffer = offers;
      this.loadOffer = true
      this.cdRef.detectChanges();
    })
  }

  openOfferDetailInNewWindow(id : any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/detailOfferAdmin/${id}`])
    );
    window.open(url, '_blank');
  }

}
