import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import {toast} from 'src/app/model/toast'
@Component({
  selector: 'app-show-instagram-contenue',
  templateUrl: './show-instagram-contenue.component.html',
  styleUrls: ['./show-instagram-contenue.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class ShowInstagramContenueComponent implements OnInit {
  jsonContenu : any
  postsList : any
  storyList : any
  toast = toast
  selectedDevice : any
  contenueArrayPost : any = []
  contenueArrayStory : any = []
  constructor(
    private dialogRef: MatDialogRef<ShowInstagramContenueComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cdRef:ChangeDetectorRef,
    private toastr: ToastrService,
    private apiService : ApiService,
    ) {
    this.jsonContenu = data;
}

ngOnInit() {
  this.postsList = this.jsonContenu.posts
  this.storyList = this.jsonContenu.story
}

verifiedItem(arr : any, timestamp : any){
  return arr.some(function(el: any) {
    let date1 = new Date(el.currentDate);
    let date2 = new Date(timestamp);
    return date1.getTime() === date2.getTime();
  }); 
}

verifiedItemOnOffer(arr : any, timestamp : any){
  return arr.some(function(el: any) {
    let date1 = new Date(el.currentDate);
    let date2 = new Date(timestamp);
    return date1.getTime() === date2.getTime();
  }); 
}

async sortByLastModifiedDesc(arr : any) {
  return arr.sort((a: any, b: any) => {
    return <any>new Date(b.timestamp) - <any>new Date(a.timestamp);
  });
}

onChange(type : string) {
  switch (type) {
    case "Post":
      let post = (element: any) => new Date(element.timestamp).getTime() == new Date(this.selectedDevice.timestamp).getTime();
      let indexPost = this.postsList.findIndex(post)
      if (indexPost != -1){
        this.additemPosts(this.selectedDevice, indexPost)
      }
      break;
    case "Story":
      let story = (element: any) => new Date(element.timestamp).getTime() == new Date(this.selectedDevice.timestamp).getTime();
      let indexStory = this.storyList.findIndex(story)
      if (indexStory != -1){
        this.additemStory(this.selectedDevice, indexStory)
      }
      break;
    default:
      break;
  }
 
  
}

additemPosts(elem : any, index: number){
  let verif = this.verifiedItem(this.contenueArrayPost, elem.timestamp)
  let verifOffer = this.verifiedItemOnOffer(this.jsonContenu.offer.posts, elem.timestamp)
  if (verif == false && verifOffer == false){
    let newElem = elem
    if (elem.media_product_type){
      switch (elem.media_product_type) {
        case "REELS":
          newElem.type = "Reel"
          break;
        case "FEED":
           newElem.type = "Post"
          break;
        default:
          break;
      }
    } else {
      newElem.type = "Post"
    }
    this.contenueArrayPost.push(newElem)
    this.postsList.splice(index, 1)
  } else {
    this.toastr.warning(this.toast.contentalreadyused)
  }
}

async deleteItemPosts(elem: any, index: number){
  this.contenueArrayPost.splice(index, 1)
  this.postsList.push(elem)
  await this.sortByLastModifiedDesc(this.postsList)
  this.cdRef.detectChanges();
}

additemStory(elem : any, index: number){
  let verif = this.verifiedItem(this.contenueArrayStory, elem.timestamp)
  let verifOffer = this.verifiedItemOnOffer(this.jsonContenu.offer.posts, elem.timestamp)
  if (verif == false && verifOffer == false){
    let newElem = elem
    newElem.type = "Story"
    this.contenueArrayStory.push(newElem)
    this.storyList.splice(index, 1)
  }else {
    this.toastr.warning(this.toast.contentalreadyused)
  }
}

async deleteItemStory(elem: any, index: number){
  this.contenueArrayStory.splice(index, 1)
  this.storyList.push(elem)
  await this.sortByLastModifiedDesc(this.storyList)
  this.cdRef.detectChanges();
}

async createDasboardOnDb(){
  let arr = this.contenueArrayStory.concat(this.contenueArrayPost)
  if (arr.length <= 0){
    this.toastr.error(this.toast.nocontentselected)
    return
  }
  let newContenues = await this.setContenueItemOnDb(arr)
  let newcontenueArray = {
    contenueArray : newContenues,
    name: this.jsonContenu.offer.influencerObjects.name,
  }
  
  this.apiService.dowloadAndCreateDashboard(newcontenueArray).subscribe(res => {
    if (this.jsonContenu.offer.campaignObject  && this.jsonContenu.offer.campaignObject._id && this.jsonContenu.offer.campaignObject.status && this.jsonContenu.offer.campaignObject.status == "New Campaign"){
      this.jsonContenu.offer.campaignObject.status = "CampaignLive"
      this.apiService.putCampaign(this.jsonContenu.offer.campaignObject._id, this.jsonContenu.offer.campaignObject).subscribe({
        next: value => console.log("ok"),
        error: err => console.log("err")
      })
    }
    switch (this.jsonContenu.offer.status) {
      case "Accepted":
        this.jsonContenu.offer.posts = res
        this.jsonContenu.offer.status = "Live"
        this.apiService.putOffre(this.jsonContenu.offer._id, this.jsonContenu.offer).subscribe(res => {
          this.toastr.success(this.toast.dasboardCreate);
        })
        break;
      case "Live":
        this.jsonContenu.offer.posts = this.jsonContenu.offer.posts.concat(res)
        this.apiService.putOffre(this.jsonContenu.offer._id, this.jsonContenu.offer).subscribe(res => {
          this.toastr.success(this.toast.dasboardCreate);
        })
        break;
    
      default:
        break;
    }
  })
}

async setContenueItemOnDb(arr : any){
  var newPosts: any[] = [];
  arr.forEach((element: any) => {
    let newJson : any = {}
    newJson.dailyStatistics = [];
    newJson.instagramInfoPost = {}
    newJson.instagramInfoPost.caption = element.caption
    newJson.instagramInfoPost.type = element.type;
    newJson.instagramInfoPost.mediaType = element.media_type
    if (element.media_type === "VIDEO"){
      newJson.instagramInfoPost.urlVideo = element.media_url
      newJson.instagramInfoPost.picture = element.thumbnail_url
      if (element.type === 'Post'){
        newJson.dailyStatistics.push({
          "date": new Date(),
          "dailyView": 0,
          "dailyLike": 0,
          "dailyComment": 0,
          "dailyReach": 0,
          "dailyEngagement":0,
          "dailyVideoViews": 0
        });
        newJson.totalStatistics = {
          "totalLike" : 0,
          "totalView" : 0,
          "totalComment" : 0,
          "totalReach" : 0,
          "totalEngagement" : 0,
          "totalVideoViews": 0
        };
      } else if (element.type === 'Story') {
        newJson.dailyStatistics.push({
          "date": new Date(),
          "dailyView": 0,
          "dailyLike": 0,
          "dailyComment": 0,
          "dailyReach": 0,
          "dailyEngagement":0,
          "dailyReplies":0,
          "dailyExits":0,
          "dailyTapsForward":0,
          "dailyTapsBack":0,
        });
        newJson.totalStatistics = {
          "totalLike" : 0,
          "totalView" : 0,
          "totalComment" : 0,
          "totalReach" : 0,
          "totalEngagement" : 0,
          "totalReplies" : 0,
          "totalExits": 0,
          "totalTapsForward": 0,
          "totalTapsBack": 0  
        };
      } else if (element.type === 'Reel'){
        newJson.dailyStatistics.push({
          "date": new Date(),
          "dailyView": 0,
          "dailyPlays": 0,
          "dailyLike": 0,
          "dailyComment": 0,
          "dailyReach": 0,
          "dailyEngagement":0,
          "dailyVideoViews": 0,
          "dailySaved":0,
          "dailyShare":0,
        });
        newJson.totalStatistics = {
          "totalLike" : 0,
          "totalView" : 0,
          "totalComment" : 0,
          "totalReach" : 0,
          "totalEngagement" : 0,
          "totalVideoViews": 0,
          "totalSaved": 0,
          "totalPlays": 0,
          "totalShare": 0,
        };
      }
    } else {
      if (element.type === 'Post'){
        newJson.dailyStatistics.push({
          "date": new Date(),
          "dailyView": 0,
          "dailyLike": 0,
          "dailyComment": 0,
          "dailyReach": 0,
          "dailyEngagement":0
        });
        newJson.totalStatistics = {
          "totalLike" : 0,
          "totalView" : 0,
          "totalComment" : 0,
          "totalReach" : 0,
          "totalEngagement" : 0
        };
      } else if (element.type === 'Story') {
        newJson.dailyStatistics.push({
          "date": new Date(),
          "dailyView": 0,
          "dailyLike": 0,
          "dailyComment": 0,
          "dailyReach": 0,
          "dailyEngagement":0,
          "dailyReplies":0,
          "dailyExits":0,
          "dailyTapsForward":0,
          "dailyTapsBack":0,
        });
        newJson.totalStatistics = {
          "totalLike" : 0,
          "totalView" : 0,
          "totalComment" : 0,
          "totalReach" : 0,
          "totalEngagement" : 0,
          "totalReplies" : 0,
          "totalExits": 0,
          "totalTapsForward": 0,
          "totalTapsBack": 0  
        };
      } else if (element.type === 'Reel'){
        newJson.dailyStatistics.push({
          "date": new Date(),
          "dailyView": 0,
          "dailyLike": 0,
          "dailyComment": 0,
          "dailyReach": 0,
          "dailyEngagement":0,
          "dailyVideoViews": 0,
          "dailySaved":0,
          "dailyShare":0,
          "dailyPlays":0,
        });
        newJson.totalStatistics = {
          "totalLike" : 0,
          "totalView" : 0,
          "totalComment" : 0,
          "totalReach" : 0,
          "totalEngagement" : 0,
          "totalVideoViews": 0,
          "totalSaved": 0,
          "totalShare": 0,
          "totalPlays": 0,
        };
      }
      newJson.instagramInfoPost.picture = element.media_url;
    }
    newJson.currentDate = element.timestamp;
    newJson.creationDate = new Date();
    newJson.networkId = element.id;
    newJson.status = "In Review";
    newPosts.push(newJson);
  });
  return newPosts
}

}
