import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, Form } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {toast} from 'src/app/model/toast'
import { Router, ActivatedRoute } from '@angular/router';
import { MediaService } from 'src/app/service/media.service';
import { CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';

export interface pigePicJson {
  // file : FormData,
  // url:  string | ArrayBuffer | null
  date: Date
  user: string
  story: [],
  post: pigePost[],
  highlight: [],
  igtv: [],
  reels: [],
}

export interface pigePost {
  date : Date,
  picture:  string
  caption: ''
}



@Component({
  selector: 'app-pige-admin',
  templateUrl: './pige-admin.component.html',
  styleUrls: ['./pige-admin.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css','../../../assets/styles/dashboard.css', '../../../assets/styles/campagne.css'],
  providers: [MediaService]
})
export class PigeAdminComponent implements OnInit {
  pigeExtern: FormGroup;
  exportArrayFiles : any = []
  toast = toast
  loading = false
  keywords: string[] = new Array
  keywordsInput: string = ""
  accountsInput: string = ""
  accounts: any[] = new Array
  exportFile : boolean = false
  exportFileUP : boolean = false
  picPige : pigePicJson = {
    user: "toto",
    date: new Date(),
    highlight: [],
    igtv: [],
    post: [],
    reels: [],
    story: [],
  }
  constructor(
    private apiService : ApiService,
    private fb: FormBuilder,
    public picService: MediaService,
    private toastr: ToastrService,
    private router: Router,
    public fctBasic: CampagneAndOfferFctService,
    private cdRef:ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.pigeExtern = this.fb.group({
      dateStart: new FormControl("", Validators.required),
      dateEnd: new FormControl("", Validators.required),
    })
  }

  keytab(event : any){
    let form = event.target.form;
    let index = [...form].indexOf(event.target);
    if (index != null && index !== -1){
      form.elements[index + 1].focus();
    }
  }



  async getExternPige(){
    if (this.pigeExtern.valid === true){
      this.loading = true
      const sum = await this.accounts.reduce(async (arrayUser: [], element: string) => {
        const test = await this.loopOnUsers(element)
        return element
      }, [])
    } else {
      this.toastr.warning(this.toast.InvalidEntry);
    }
  }

async loopOnUsers(user : string){
  this.toastr.success("User ", user);
  this.apiService.exportFilePigeExtern(user, this.keywords[0],this.pigeExtern.value.dateStart,this.pigeExtern.value.dateEnd,).subscribe(res => {
    if (res.url === "400"){
      this.toastr.error(this.toast.campaignCreate);
      return 
    }
    this.exportFile = true
    this.exportFileUP = true
    const urlPige = "/api/export/pptx/" + res.url
    this.exportArrayFiles.push(urlPige)
    this.loading = false
    this.cdRef.detectChanges();
  })
}

  checkSpaceOnString(str : string, array: string[]) {
    let tmpstr = str.split(" ")
    tmpstr.forEach((elem) =>  {
      if (elem != " "){
        let verif = this.verifiedIndexOf(elem, array)
        if (verif == false){
          array.push(elem)
        }
      }
    })
  }

  addElement(value : string){
    switch (value) {
      case 'keywords':
        this.checkSpaceOnString(this.keywordsInput, this.keywords)
        this.keywordsInput = ""
        break;
      case 'accounts':
        this.checkSpaceOnString(this.accountsInput, this.accounts)
        this.accountsInput = ""        
        break;
      default:
        break;
    }
  }

  deleteElement(index: number, value: string){
    switch (value) {
      case 'keywords':
        this.keywords.splice(index, 1)
        break;
      case 'accounts':
        this.accounts.splice(index, 1)
        break;
      default:
        break;
    }
  }

  verifiedIndexOf(elem : string, array: string[]) : Boolean {
    let check = false
    if (array.length > 0){
      array.forEach((res : any) => {
        if (elem == res ){
          check = true
        }
       })
    }
    return check
  }

  

  async modifPicture(event : any){
    if(!event.target.files[0] || event.target.files.length == 0) {
      return false;
    }
    await Promise.all([...event.target.files].map( (file: any) => {
      var reader = new FileReader();
      reader.onload = () => {
        let extention = this.picService.getExtentionImg(file)
        if (extention != false){
          const fd = new FormData();
          fd.append('file', file, file.name);
          this.apiService.postPicture(fd).subscribe(path => {
            this.picPige.post.push({picture: path[0], caption: "", date: new Date()})
            this.cdRef.detectChanges()
          })
          
        }
      }
      reader.readAsDataURL(file);
      this.cdRef.detectChanges()
    }));
    return false
    }

    deleteImgForModif(img : string,  elem: pigePost){
      this.apiService.deletePicture(img).subscribe(res => {
        this.toastr.success("Img is delete")
        elem.picture = ""
        this.cdRef.detectChanges();
      })
    }

    async generateExpress(){
      this.apiService.exportFilePigeExternExpress(this.picPige).subscribe(res => {
        if (res.url === "400"){
          this.toastr.error(this.toast.campaignCreate);
          return 
        }
        this.exportFile = true
        this.exportFileUP = true
        const urlPige = "/api/export/pptx/" + res.url
        this.exportArrayFiles.push(urlPige)
        this.loading = false
        this.cdRef.detectChanges();
      })
    }

}
