import {Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-facebook-dialog',
  templateUrl: './facebook-dialog.component.html',
  styleUrls: ['./facebook-dialog.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class FacebookDialogComponent implements OnInit {
  FacebookUrl = '/api/facebook'
  jsonContenu : any
  constructor(
    private dialogRef: MatDialogRef<FacebookDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cdRef:ChangeDetectorRef,
    private toastr: ToastrService,
    private apiService : ApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  deleteAccount(){
    this.apiService.deleteUser(this.jsonContenu._id).subscribe(() =>{
      this.router.navigate(['/login'])
    })
  }

}
