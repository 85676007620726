import { Injectable, ChangeDetectorRef } from '@angular/core';

@Injectable()
export class MediaService {
  // utilisé pour 
  
  public selectedArrayFile: any = [];
  public selectedArrayFileAttestation: any = [];
  public selectedArrayPicture: any = [];
  public selectedArrayPicturePosts: any = [];
  public selectedArrayPictureReels: any = [];
  public selectedArrayPictureStories: any = [];
  public selectedPictureInfluenceur: any = null;
  public selecteToShowPictureInfluenceur: any = "";
  arrayToShowPicture: any = [];
  arrayToShowPicturePosts: any = [];
  arrayToShowPictureReels: any = [];
  arrayToShowPictureStories: any = [];
  arrayToShowFile: any = [];
  arrayToShowFileAttestation: any = [];
  jsonArr : any = null
  constructor(
    public ref: ChangeDetectorRef ,
  ) { 
  
  }
///////////////////////////////////////PICTURE////////////////////////////////////////////////
public getselectedPictures(){
  if (this.selectedArrayPicture.length <= 0){
    return null
  }
  const fd = new FormData();
  for(let selectedPic of this.selectedArrayPicture){
    fd.append('file', selectedPic, selectedPic.name);
  }
	return fd
}

async selectPicture(event: any) {
  if(!event.target.files[0] || event.target.files.length == 0) {
		return ;
	}
  let check = this.getIfElemExist(this.selectedArrayPicture, event.target.files[0])
  if (check == false){
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.getExtentionImg(event.target.files[0])
      if (extention != false){
        let val = {
          src: reader.result,
          picFile: event.target.files[0]
        }
        this.arrayToShowPicture.push(val);
        this.selectedArrayPicture.push(event.target.files[0])
        
      }
      
    }
    reader.readAsDataURL(event.target.files[0]);
    
  }
}
getExtentionImg(file : any){
  switch (file.type) {
    case "image/png":
      return true      
    case "image/jpeg":
      return true      
    default:
      return false
  }
}

deleteImg(img : any, index: number){
  let arrindex = this.loopOnImgToGetPic(img)
  if (arrindex != null){
    this.selectedArrayPicture.splice(arrindex, 1)
  }
  this.arrayToShowPicture.splice(index, 1)
}

loopOnImgToGetPic(elem : any) : any{
  var val = null
  this.selectedArrayPicture.forEach((element: any, index : number) => {
    if (elem.picFile.name == element.name){
      val = index
    }
  });
  return val
}
///////////////////////////////////////INFLUENCEUR////////////////////////////////////////////////
async selectPictureForInfluenceur(event: any) {
  if(!event.target.files[0] || event.target.files.length == 0) {
    return ;
  }
  if (!this.selectedPictureInfluenceur){
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.getExtentionImg(event.target.files[0])
      if (extention != false){
        let val = {
          src: reader.result,
          picFile: event.target.files[0]
        }
        this.selecteToShowPictureInfluenceur = val;
        this.selectedPictureInfluenceur = event.target.files[0]
        this.ref.detectChanges();
      }
    }
    reader.readAsDataURL(event.target.files[0]);
   } else {
    if (this.selectedPictureInfluenceur && event.target.files[0].name !== this.selectedPictureInfluenceur.name && event.target.files[0].size !== this.selectedPictureInfluenceur.size){
      var reader = new FileReader();
      reader.onload = () => {
        let extention = this.getExtentionImg(event.target.files[0])
        if (extention != false){
          let val = {
            src: reader.result,
            picFile: event.target.files[0]
          }
          this.selecteToShowPictureInfluenceur = val;
          this.selectedPictureInfluenceur = event.target.files[0]
          this.ref.detectChanges();
        }
      }
      reader.readAsDataURL(event.target.files[0]); 
    }
  }
}

public getselectedPicturesForInfluenceur(){
  if (!this.selectedPictureInfluenceur || this.selectedPictureInfluenceur == null || this.selectedPictureInfluenceur == ""){
    return null
  }
  const fd = new FormData();
  fd.append('file', this.selectedPictureInfluenceur, this.selectedPictureInfluenceur.name);
  return fd  
}

deleteImgInfluenceur(){
  this.selecteToShowPictureInfluenceur = ""
  this.selectedPictureInfluenceur = ""
}
///////////////////////////////////////FILES////////////////////////////////////////////////
public getselectedFiles(){
  if (this.selectedArrayFile.length <= 0){
    return null
  }
  const fd = new FormData();
  for(let selectedFile of this.selectedArrayFile){
    fd.append('file', selectedFile, selectedFile.name);
  }
	return fd
}

selectFile(event: any) {	
  if(!event.target.files[0] || event.target.files.length == 0) {
		  return ;
	  }
    let check = this.getIfElemExist(this.selectedArrayFile, event.target.files[0])
    if (check == false){
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.getExtentionFile(event.target.files[0])
      if (extention.extention != false){
        this.selectedArrayFile.push(extention)
      }
    }
      reader.readAsDataURL(event.target.files[0]); 
  }
}
getExtentionFile(file : any){
  if (file.name.includes(".docx")){
    file.extention = "docx"
    
  } else if (file.name.includes('.xlsx')){
    file.extention = 'xlsx'
    
  } else if (file.name.includes('.xls')){
      file.extention = 'xls'
      
  } else if (file.name.includes('.doc')){
      file.extention = 'doc'
      
    }else if (file.name.includes('.pptx')){
      file.extention = 'pptx'
      
    }else if (file.name.includes('.odt')){
      file.extention = 'odt'
      
    }else if (file.name.includes('.pdf')){
      file.extention = 'odt'
    } 
    else {
      file.extention = false
    }
  return file
}

getExtentionFileToShow(file : any){
  switch (file.extention) {
    case "pptx":
      return '/assets/images/iconInfluenceLab/ppt.png'
    case "docx":
      return '/assets/images/iconInfluenceLab/word.png'
    case "doc":
      return '/assets/images/iconInfluenceLab/word.png'
    case "xls":
      return '/assets/images/iconInfluenceLab/exel.png'
    case "xlsx":
      return '/assets/images/iconInfluenceLab/exel.png'
    case "odt":
      return '/assets/images/iconInfluenceLab/pdf.png'
    case "application/pdf":
      return '/assets/images/iconInfluenceLab/pdf.png'
    default:
      return '/assets/images/iconInfluenceLab/word.png'
  }
}

deleteFile(img : any, index : number){
  this.selectedArrayFile.splice(index, 1)
}

///////////////////////////////////////POSTS////////////////////////////////////////////////
public getselectedPicturesPosts(){
  if (this.selectedArrayPicturePosts.length <= 0){
    return null
  }
  const fd = new FormData();
  for(let selectedPic of this.selectedArrayPicturePosts){
    fd.append('file', selectedPic, selectedPic.name);
  }
	return fd
}

async getPicturePost(){
  return this.selectedArrayPicturePosts
}

async selectPicturePosts(event: any) {
  if(!event.target.files[0] || event.target.files.length == 0) {
		return ;
	}
  let check = this.getIfElemExist(this.selectedArrayPicturePosts, event.target.files[0])
  if (check == false){
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.getExtentionImg(event.target.files[0])
      if (extention != false){
        this.selectedArrayPicturePosts.push(event.target.files[0])
        let val = {
          src: reader.result,
          picFile: event.target.files[0]
        }
        this.arrayToShowPicturePosts.push(val);
        this.ref.detectChanges();
      }
      
    }
    reader.readAsDataURL(event.target.files[0]);
    
  }
}

deleteImgPosts(img : any, index: number){
  let arrindex = this.loopOnImgToGetPicPosts(img)
  if (arrindex != null){
    this.selectedArrayPicturePosts.splice(arrindex, 1)
  }
  this.arrayToShowPicturePosts.splice(index, 1)
}

loopOnImgToGetPicPosts(elem : any) : any{
  var val = null
  this.selectedArrayPicturePosts.forEach((element: any, index : number) => {
    if (elem.picFile.name == element.name){
      val = index
    }
  });
  return val
}
///////////////////////////////////////REELS////////////////////////////////////////////////
public getselectedPicturesReels(){
  if (this.selectedArrayPicture.length <= 0){
    return null
  }
  const fd = new FormData();
  for(let selectedPic of this.selectedArrayPicture){
    fd.append('file', selectedPic, selectedPic.name);
  }
	return fd
}

async selectPictureReels(event: any) {
  if(!event.target.files[0] || event.target.files.length == 0) {
		return ;
	}
  let check = this.getIfElemExist(this.selectedArrayPictureReels, event.target.files[0])
  if (check == false){
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.getExtentionImg(event.target.files[0])
      if (extention != false){
        let val = {
          src: reader.result,
          picFile: event.target.files[0]
        }
        this.selectedArrayPictureReels.push(event.target.files[0])
        this.arrayToShowPictureReels.push(val);
        this.ref.detectChanges();
      }
      
    }
    reader.readAsDataURL(event.target.files[0]);
    
  }
}

deleteImgReels(img : any, index: number){
  let arrindex = this.loopOnImgToGetPicReels(img)
  if (arrindex != null){
    this.selectedArrayPictureReels.splice(arrindex, 1)
  }
  this.arrayToShowPictureReels.splice(index, 1)
}

loopOnImgToGetPicReels(elem : any) : any{
  var val = null
  this.selectedArrayPictureReels.forEach((element: any, index : number) => {
    if (elem.picFile.name == element.name){
      val = index
    }
  });
  return val
}
///////////////////////////////////////STORIES////////////////////////////////////////////////
public getselectedPicturesStories(){
  if (this.selectedArrayPictureStories.length <= 0){
    return null
  }
  const fd = new FormData();
  for(let selectedPic of this.selectedArrayPictureStories){
    fd.append('file', selectedPic, selectedPic.name);
  }
	return fd
}

async selectPictureStories(event: any) {
  if(!event.target.files[0] || event.target.files.length == 0) {
		return ;
	}
  let check = this.getIfElemExist(this.selectedArrayPictureStories, event.target.files[0])
  if (check == false){
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.getExtentionImg(event.target.files[0])
      if (extention != false){
        let val = {
          src: reader.result,
          picFile: event.target.files[0]
        }
        this.arrayToShowPictureStories.push(val);
        this.selectedArrayPictureStories.push(event.target.files[0])
        this.ref.detectChanges();
      }
      
    }
    reader.readAsDataURL(event.target.files[0]);
    
  }
}

deleteImgStories(img : any, index: number){
  let arrindex = this.loopOnImgToGetPicStories(img)
  if (arrindex != null){
    this.selectedArrayPictureStories.splice(arrindex, 1)
  }
  this.arrayToShowPictureStories.splice(index, 1)
}

loopOnImgToGetPicStories(elem : any) : any{
  var val = null
  this.selectedArrayPictureStories.forEach((element: any, index : number) => {
    if (elem.picFile.name == element.name){
      val = index
    }
  });
  return val
}



getIfElemExist(array : any, elem :any){
  var bool = false
  array.forEach((pic: any) => {
    if (elem.name === pic.name && elem.size === pic.size){
      bool = true
    }    
  });
  return bool
}


/////////////////////////////////////////////BILLING///////////////


public getselectedFilesBilling(){
  if (this.selectedArrayFileAttestation.length <= 0){
    return null
  }
  const fd = new FormData();
  for(let selectedFile of this.selectedArrayFileAttestation){
    fd.append('file', selectedFile, selectedFile.name);
  }
	return fd
}

selectFileBilling(event: any) {	
  if(!event.target.files[0] || event.target.files.length == 0) {
		  return ;
	  }
    let check = this.getIfElemExist(this.selectedArrayFileAttestation, event.target.files[0])
    if (check == false){
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.getExtentionFile(event.target.files[0])
      if (extention.extention != false){
        this.selectedArrayFileAttestation.push(extention)
      }
    }
      reader.readAsDataURL(event.target.files[0]); 
  }
}


}
