import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import {UserLoginService} from 'src/app/service/user-login.service';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import { NopasswordComponent } from 'src/app/dialog/nopassword/nopassword.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login-influenceur',
  templateUrl: './login-influenceur.component.html',
  styleUrls: ['./login-influenceur.component.css', '../../dialog/facebook-dialog/facebook-dialog.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class LoginInfluenceurComponent implements OnInit {

  urlFacebook: string = "/api/facebook";
  //  sitekey: string = "6LcD08AZAAAAAFSbewphRrwVBpLjbe6phI2g_d-P";
  sitekey: string = "6LdxjxEgAAAAABTzFuu51-1WyHtXE6fP-6UcSVXD";
  
  captchaGroup: FormGroup;
  captchaForm = new FormControl("");
  succesCaptcha: boolean = false;
  login: FormGroup;
  hide = true;
  constructor(
    private fb: FormBuilder, private service: ApiService,
    private toastr: ToastrService,
    private auth: UserLoginService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cdRef:ChangeDetectorRef,
    private userService: UserLoginService
    ) {
    //this.router.navigate(['/maintenance']);
    this.login = fb.group({
      username: new FormControl('',[
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$")]),
      password: ['', Validators.required],
    })
    
    this.captchaGroup = fb.group({
      captchaForm: this.captchaForm
    })

    let check = this.auth.isAuthenticated()
    if (check == true){
      let role = this.auth.getRole()
      switch (role) {
        case 'admin':
          this.router.navigate(['/home-admin']);
          break;
        case 'influencer':
          let user = this.auth.getUserValue()
          if (user && user.valid == true && user.confirm == true){
            this.router.navigate(['/home']);
          }
          break;
        default:
          break;
      }
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(NopasswordComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  submit(): void {
    // if (this.succesCaptcha == false){
    //   this.toastr.error('You need first to tell us you are not a robot 😎', 'Welcome')
    //   return
    // }
    if (this.login.valid){
      if (this.route.snapshot.params.type){
        this.service.LoginWithurl(this.login.value).subscribe(passportResp => {
          window.open(passportResp.redirect);
        })
      } else {
        this.service.Login(this.login.value).subscribe(passportResp => {
          switch (passportResp.resp) {
            case 'err':
              this.toastr.warning(passportResp.err)
              break;
            case 'register':
              this.router.navigate([passportResp.redirect]);
              break;
            case 'sucess registration':
  
              this.router.navigate([passportResp.redirect]);
              break;
            case 'user login':
              this.userService.setItemForInfluencer(passportResp.user)
              this.router.navigate([passportResp.redirect]);
              break;
            case 'forgot password':
              this.toastr.warning("It seems Email already exists. Please login with Facebook.")
              break;
            case 'admin':
              this.userService.loginAdmin(passportResp.user)
              this.router.navigate(['/home-admin']);
              break;
            case 'contact':
              this.userService.loginContact(passportResp.user)
          this.router.navigate(['/home-contact']);
              break;
            default:
              break;
          }
          // if (passportResp.err){
          //   this.toastr.error("E-mail ou mot de passe incorrect, échec de l'identification.")
          // } else if (passportResp.resp && passportResp.resp === "admin"){
          //   this.toastr.warning("Please Use this Url https://influencelab.co/loginadmin", "Warning")
          // } else if (passportResp.resp && passportResp.resp === "influencer"){
          //   this.router.navigate([passportResp.redirect]);
          // }
          // else if (passportResp.resp && passportResp.resp === "pas de mdp"){
          //   //this.toastr.warning("Is there an error in your email address or password ? Or maybe you have the wrong login details ? No problem! Try again.","Warning")
          //   // this.router.navigate([passportResp.redirect]);
          //   this.openDialog()
          // }
        })
      }
      
    }
    
  };

  handleSuccess(event: string) {
    this.succesCaptcha = true;
  }

  facebookConnect() {
    window.location.href = this.urlFacebook
    // this.succesCaptcha ?  : this.toastr.error('You need first to tell us you are not a robot 😎', 'Welcome');
  }

  handleReset() {
    this.succesCaptcha = false;
  }
  
  gettmpName() {
    // this.service.getUser().subscribe(result => {
    //   console.log("resulte ", result)
    // })
    this.succesCaptcha = false;
  }

  handleExpire() {
    this.succesCaptcha = false;
  }

  ngOnInit(): void {
    
  }

}
