
<h4 class="dialogHeadlineGenerique" style=" background-color: black; color: white;" mat-dialog-title>No Instagram account found 😲</h4>
<mat-dialog-content>
    <div class="text-center mt-3 textDialog">Hey Fellow,</div>  
    <div class="text-center textDialog">It seems you have an Instagram Account you can connect.</div>   
</mat-dialog-content>
<mat-dialog-actions flex-center>
    <div >
        <button type="button" mat-dialog-close (click)="deleteAccount()" style="background-color: rgb(167, 167, 167);" class="btn  btn-secondary btn-template shadow-none">Cancel</button>
        <a href="{{FacebookUrl}}">
            <button type="button"  [mat-dialog-close]="true" class="btn  btn-secondary btn-template shadow-none ml-3"> Continue  </button>
        </a>
    </div>
</mat-dialog-actions>
