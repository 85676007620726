import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators } from '@angular/forms';
import {toast} from 'src/app/model/toast'
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css', '../../dialog/facebook-dialog/facebook-dialog.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpassword: FormGroup;
  forgotemail: FormGroup;
  changepasseword = false
  ready : boolean = false
  hide = true;
  toast = toast
  hideconfirm = true;
  forgetLinkObject : any = {}
  constructor(
    private fb: FormBuilder, 
    private service: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef:ChangeDetectorRef
    ) {
      
  }

  submit(): void {
    if (this.forgotemail.value['email'] == ""){
      this.toastr.error(this.toast.forgotEmailAdress)
      return
    }
    if (this.forgotemail.valid){
      this.service.sendlinkforgot(this.forgotemail.value['email']).subscribe(passportResp => {
        if (passportResp.err){
          this.toastr.warning("Email address " + this.forgotemail.value['email'] + "has not been found. Please try again.")
          return
        }
        this.toastr.success("Email has been sent at " + this.forgotemail.value['email'] + " it contains the steps to reset your password. ")
        this.cdRef.detectChanges();
      })
    } else {
      this.cdRef.detectChanges();
    }
  };

  submitnewPassword(): void {
    if (this.forgotpassword.value['password'] != this.forgotpassword.value['confirmPassword']){
      this.toastr.error(this.toast.passwordNotMatch)
      return
    }
    if (this.forgotpassword.valid){
      let newjson = {
        userId: this.forgetLinkObject.userId,
        linkId:this.forgetLinkObject._id,
        password: this.forgotpassword.value['password']
      }
      this.service.changePassword(newjson).subscribe(passportResp => {
        this.toastr.success(this.toast.validNetwork)
        // if (passportResp.err){
        //   this.toastr.error(passportResp.err)
        // } else if (passportResp.resp && passportResp.resp === "valid"){
        //   this.router.navigate(['/success-registration/await/']);
        // } else if (passportResp.resp && passportResp.resp === "home"){
        //   this.router.navigate([passportResp.redirect]);
        // }
        this.cdRef.detectChanges();
      })
    } else {
      this.cdRef.detectChanges();
    }
  };

  ngOnInit(): void {
    if (this.route.snapshot.params.link){
      this.service.getLink(this.route.snapshot.params.link).subscribe(linkjson => {
        if (linkjson.doc == null){
          this.toastr.error(this.toast.linkresetPasswordUsed)
          this.router.navigate(['/forgot-password'])
        } else {
          this.forgetLinkObject = linkjson.doc
        }
        this.changepasseword = true
        this.forgotpassword = this.fb.group({
          email: [linkjson.doc.email, Validators.required],
          password: ['', Validators.required],
          confirmPassword: ['', Validators.required],
        })
        this.ready = true
        this.cdRef.detectChanges();
      })
      
    } else {
      this.forgotemail = this.fb.group({
        email: new FormControl('',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$")]),
      })
      this.ready = true
      this.cdRef.detectChanges();
    }
  }

}
