import { ChangeDetectorRef, Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PageNetwork } from 'src/app/model/user-model.model';

@Component({
  selector: 'app-add-followers',
  templateUrl: './add-followers.component.html',
  styleUrls: ['./add-followers.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/dialog.css']
})
export class AddFollowersComponent implements OnInit {
  userNetwork : any
  followers: number = 0
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
  private cdRef:ChangeDetectorRef,
  private apiService : ApiService,
  private toastr: ToastrService,
  public dialog: MatDialog,
  public fctBasic: CampagneAndOfferFctService,) {
    this.userNetwork = data
   }

  ngOnInit(): void {
  }
  
  updateFollowers(page : PageNetwork){
    if (this.followers == 0 || this.followers == null){
      return 
    }
    page.followers = this.followers
    this.apiService.putUser(this.userNetwork._id, this.userNetwork).subscribe(res => {
      this.followers = 0
      this.cdRef.detectChanges();
    })
  }

}
